import { addMethod, string } from 'yup';
import { requiredIf } from '../validations/methods/requiredIf';
import { digits } from '../validations/methods/digits';
import { noSpecialChars } from '../validations/methods/noSpecialChars';
import businessNumber from '../validations/methods/businessNumber';
import ssn from '../validations/methods/ssn';
import { PIAPolicyNumberYupMethod } from '@protectorinsurance/ds-can';
import { city } from 'validations/methods/city';
import { onlyAscii } from '../validations/methods/onlyAscii';
import registrationNumber from '../validations/methods/registrationNumber';
import { secureEndingEmail } from '../validations/methods/secureEndingEmail';

addMethod(string, 'businessNumber', businessNumber);
addMethod(string, 'digits', digits);
addMethod(string, 'noSpecialChars', noSpecialChars);
addMethod(string, 'requiredIf', requiredIf);
addMethod(string, 'ssn', ssn);
// @ts-ignore
addMethod(string, 'PIAPolicyNumber', PIAPolicyNumberYupMethod);
addMethod(string, 'city', city);
addMethod(string, 'onlyAscii', onlyAscii);
addMethod(string, 'registrationNumber', registrationNumber);
addMethod(string, 'secureEndingEmail', secureEndingEmail);
