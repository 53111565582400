import {
    ClaimLocationKeys,
    InsuredByProtectorKeys,
    MotorClaimCauseKeys,
    NeedVehicleTowingKeys,
    ObjectWithDynamicKey,
    TypeOfAnimalCollisionKeys,
    TypeOfAutoClaimKeys,
    WhoAtFaultKeys,
    YesNoKeys,
} from '@protectorinsurance/ds-can';

export enum MotorRoutePaths {
    INDEX = '/gbr/:language/4',
    ERROR = '/error',
    SESSION_TIMEOUT = '/session-timeout',

    // Dyn
    DYN_BASE = '/gbr/:language/4/dyn/',
    DYN_CAROUSEL = '/gbr/:language/4/dyn/:flow?/:slug',
    // Start
    DYN_CAROUSEL_START_INSURED_BY_PROTECTOR = '/gbr/:language/4/dyn/start/insured-by-protector',
    DYN_CAROUSEL_START_IS_DRIVING = '/gbr/:language/4/dyn/start/is-driving',
    // Driving
    DYN_CAROUSEL_DRIVING_IS_OTHER_VEHICLE_INVOLVED = '/gbr/:language/4/dyn/driving/is-other-vehicle-involved',
    // Driving / Vehicles
    DYN_CAROUSEL_DRIVING_VEHICLES_UNIDENTIFIED_TYPE = '/gbr/:language/4/dyn/driving-vehicles/unidentified-type',
    DYN_CAROUSEL_DRIVING_VEHICLES_CLAIM_LOCATION = '/gbr/:language/4/dyn/driving-vehicles/claim-location',
    DYN_CAROUSEL_DRIVING_VEHICLES_IS_LANE_CHANGE = '/gbr/:language/4/dyn/driving-vehicles/is-lane-change',
    // Driving / Vehicles / Head On
    DYN_CAROUSEL_DRIVING_VEHICLES_HEAD_ON_IS_COUNTERPARTY_STATIONARY = '/gbr/:language/4/dyn/driving-vehicles-head-on/is-counterparty-stationary',
    // Driving / Vehicles / Intersection
    DYN_CAROUSEL_DRIVING_VEHICLES_INTERSECTION_TYPE_OF_INTERSECTION = '/gbr/:language/4/dyn/driving-vehicles-intersection/type-of-intersection',
    DYN_CAROUSEL_DRIVING_VEHICLES_INTERSECTION_CLAIM_CAUSE = '/gbr/:language/4/dyn/driving-vehicles-intersection/claim-cause',
    // Driving / Vehicles / Lane Change
    DYN_CAROUSEL_DRIVING_VEHICLES_LANE_CHANGE_WHO_AT_FAULT = '/gbr/:language/4/dyn/driving-vehicles-lane-change/who-at-fault',
    // Driving / Vehicles / Overtaking
    DYN_CAROUSEL_DRIVING_VEHICLES_OVERTAKING_WHO_AT_FAULT = '/gbr/:language/4/dyn/driving-vehicles-overtaking/who-at-fault',
    // Driving / Vehicles / Parked
    DYN_CAROUSEL_DRIVING_VEHICLES_PARKED_HAS_PASSENGERS = '/gbr/:language/4/dyn/driving-vehicles-parked/has-passengers',
    DYN_CAROUSEL_DRIVING_VEHICLES_PARKED_WHO_AT_FAULT = '/gbr/:language/4/dyn/driving-vehicles-parked/who-at-fault',
    DYN_CAROUSEL_DRIVING_VEHICLES_PARKED_IS_REVERSING = '/gbr/:language/4/dyn/driving-vehicles-parked/is-reversing',
    // Driving / Vehicles / Parking Other
    DYN_CAROUSEL_DRIVING_VEHICLES_PARKING_OTHER_CLAIM_CAUSE = '/gbr/:language/4/dyn/driving-vehicles-parking-other/claim-cause',
    // Driving / Vehicles / Rear Ended
    DYN_CAROUSEL_DRIVING_VEHICLES_REAR_ENDED_WHO_AT_FAULT = '/gbr/:language/4/dyn/driving-vehicles-rear-ended/who-at-fault',
    // Driving / Vehicles / Reversing
    DYN_CAROUSEL_DRIVING_VEHICLES_REVERSING_WHO_AT_FAULT = '/gbr/:language/4/dyn/driving-vehicles-reversing/who-at-fault',
    // Driving / Vehicles / Road
    DYN_CAROUSEL_DRIVING_VEHICLES_ROAD_TYPE_OF_ROAD = '/gbr/:language/4/dyn/driving-vehicles-road/type-of-road',
    DYN_CAROUSEL_DRIVING_VEHICLES_ROAD_CLAIM_CAUSE = '/gbr/:language/4/dyn/driving-vehicles-road/claim-cause',
    // Driving / Vehicles / Roundabout
    DYN_CAROUSEL_DRIVING_VEHICLES_ROUNDABOUT_CLAIMANT_ROUNDABOUT_POSITIONING = '/gbr/:language/4/dyn/driving-vehicles-roundabout/claimant-roundabout-positioning',
    DYN_CAROUSEL_DRIVING_VEHICLES_ROUNDABOUT_COUNTERPARTY_ROUNDABOUT_POSITIONING = '/gbr/:language/4/dyn/driving-vehicles-roundabout/counterparty-roundabout-positioning',
    DYN_CAROUSEL_DRIVING_VEHICLES_ROUNDABOUT_CLAIM_CAUSE = '/gbr/:language/4/dyn/driving-vehicles-roundabout/claim-cause',
    // Driving / No Vehicle
    DYN_CAROUSEL_DRIVING_NO_VEHICLE_CLAIM_CAUSE = '/gbr/:language/4/dyn/driving-no-vehicle/claim-cause',
    DYN_CAROUSEL_DRIVING_NO_VEHICLE_IS_REVERSING = '/gbr/:language/4/dyn/driving-no-vehicle/is-reversing',
    // Driving / No Vehicle / Animal
    DYN_CAROUSEL_DRIVING_NO_VEHICLE_ANIMAL_TYPE_OF_ANIMAL = '/gbr/:language/4/dyn/driving-no-vehicle-animal/type-of-animal',
    DYN_CAROUSEL_DRIVING_NO_VEHICLE_ANIMAL_IS_RIDING = '/gbr/:language/4/dyn/driving-no-vehicle-animal/is-riding',
    DYN_CAROUSEL_DRIVING_NO_VEHICLE_ANIMAL_HAS_ANIMAL_ESCAPED = '/gbr/:language/4/dyn/driving-no-vehicle-animal/has-animal-escaped',
    // Parked
    DYN_CAROUSEL_PARKED_CLAIM_CAUSE = '/gbr/:language/4/dyn/parked/claim-cause',
    // Parked / Other
    DYN_CAROUSEL_PARKED_OTHER_CLAIM_CAUSE_DETAILS = '/gbr/:language/4/dyn/parked-other/claim-cause-details',
    // Parked / Parking
    DYN_CAROUSEL_PARKED_PARKING_IS_COUNTERPARTY_KNOWN = '/gbr/:language/4/dyn/parked-parking/is-counterparty-known',
    DYN_CAROUSEL_PARKED_PARKING_UNIDENTIFIED_TYPE = '/gbr/:language/4/dyn/parked-parking/unidentified-type',
    // Parked / Theft And Damages
    DYN_CAROUSEL_PARKED_THEFT_AND_DAMAGES_IS_SELF_DISCOVERED_CLAIM = '/gbr/:language/4/dyn/parked-theft-and-damages/is-self-discovered-claim',
    DYN_CAROUSEL_PARKED_THEFT_AND_DAMAGES_IS_VEHICLE_STOLEN = '/gbr/:language/4/dyn/parked-theft-and-damages/is-vehicle-stolen',
    DYN_CAROUSEL_PARKED_THEFT_AND_DAMAGES_HAS_ALL_KEYS = '/gbr/:language/4/dyn/parked-theft-and-damages/has-all-keys',
    DYN_CAROUSEL_PARKED_THEFT_AND_DAMAGES_IS_VEHICLE_RECOVERED = '/gbr/:language/4/dyn/parked-theft-and-damages/is-vehicle-recovered',
    DYN_CAROUSEL_PARKED_THEFT_AND_DAMAGES_IS_ITEM_MISSING = '/gbr/:language/4/dyn/parked-theft-and-damages/is-item-missing',
    // End
    DYN_CAROUSEL_END_HAS_VEHICLE_DAMAGES = '/gbr/:language/4/dyn/end/has-vehicle-damages',
    DYN_CAROUSEL_END_NEED_VEHICLE_TOWING = '/gbr/:language/4/dyn/end/need-vehicle-towing',
    DYN_CAROUSEL_END_HAS_NON_VEHICLE_DAMAGES = '/gbr/:language/4/dyn/end/has-non-vehicle-damages',
    DYN_CAROUSEL_END_HAS_PERSON_INJURIES = '/gbr/:language/4/dyn/end/has-person-injuries',
    DYN_CAROUSEL_END_IS_POLICE_CONTACTED = '/gbr/:language/4/dyn/end/is-police-contacted',
    DYN_CAROUSEL_END_HAS_WITNESSES = '/gbr/:language/4/dyn/end/has-witnesses',

    //General paths
    START_WELCOME = '/gbr/:language/4/start/welcome',
    START_WINDSHIELD_DAMAGE = '/gbr/:language/4/start/windshield-damage',
    START_START_SUMMARY = '/gbr/:language/4/start/start-summary',
    START_CLAIM_DATE = '/gbr/:language/4/start/claim-date',
    START_VEHICLE_REGISTRATION_NUMBER = '/gbr/:language/4/start/vehicle-registration-number',
    START_VEHICLE_INFORMATION = '/gbr/:language/4/start/vehicle-information',
    START_STOP = '/gbr/:language/4/start/stop',
    START_COMPANY_INFORMATION = '/gbr/:language/4/start/policy-holder',

    //Parked/Theft And Damages
    PARKED_CLAIM_DISCOVERER_INFORMATION = '/gbr/:language/4/parked/claim-discoverer-information',
    PARKED_PARKING_DATE = '/gbr/:language/4/parked/parking-date',
    PARKED_PARKING_INFORMATION = '/gbr/:language/4/parked/parking-information',
    PARKED_THEFT_AND_DAMAGES_CLAIM_DESCRIPTION = '/gbr/:language/4/parked/theft-and-damages-claim-description',
    PARKED_RECOVERY_DATE = '/gbr/:language/4/parked/recovery-date',
    PARKED_RECOVERER_INFORMATION = '/gbr/:language/4/parked/recoverer-information',
    PARKED_MISSING_ITEMS = '/gbr/:language/4/parked/missing-items',
    PARKED_OTHER_MISSING_ITEMS = '/gbr/:language/4/parked/other-missing-items',
    PARKED_UPLOAD_RECEIPT = '/gbr/:language/4/parked/upload-receipts',

    //Parked/Engine
    PARKED_ENGINE_CLAIM_DESCRIPTION = '/gbr/:language/4/parked/engine-claim-description',

    //Parked/Fire
    PARKED_UPLOAD_FIRE_REPORT = '/gbr/:language/4/parked/upload-fire-report',
    PARKED_FIRE_CLAIM_DESCRIPTION = '/gbr/:language/4/parked/fire-claim-description',

    //Parked/Parking
    PARKED_COUNTERPARTY_REGISTRATION_NUMBER = '/gbr/:language/4/parked/counterparty-registration-number',
    PARKED_VEHICLE_INFORMATION = '/gbr/:language/4/parked/vehicle-information',
    PARKED_COUNTERPARTY_INFORMATION = '/gbr/:language/4/parked/counterparty-information',
    PARKED_UPLOAD_ATTACHMENTS = '/gbr/:language/4/parked/upload-attachments',

    //Driving/No Vehicle/Ditch
    DRIVING_NO_VEHICLE_DITCH_CLAIM_DESCRIPTION = '/gbr/:language/4/driving/ditch-claim-description',
    DRIVING_NO_VEHICLE_FREIGHT_WEIGHT = '/gbr/:language/4/driving/freight-weight',
    DRIVING_NO_VEHICLE_UPLOAD_FREIGHT_DOCUMENT = '/gbr/:language/4/driving/upload-freight-document',
    DRIVING_NO_VEHICLE_ROAD_CONDITION_DESCRIPTION = '/gbr/:language/4/driving/road-condition-description',

    //Driving/No vehicle/Animal
    DRIVING_NO_VEHICLE_OWNER_INFORMATION_LIST = '/gbr/:language/4/driving/animal-owner-information',

    //Driving/Vehicle
    DRIVING_VEHICLE_DAMAGES = '/gbr/:language/4/driving/damages',
    DRIVING_VEHICLE_VEHICLE_REGISTRATION_NUMBER = '/gbr/:language/4/driving/vehicle-registration-number',
    DRIVING_VEHICLE_VEHICLE_INFORMATION = '/gbr/:language/4/driving/vehicle-information',
    DRIVING_VEHICLE_DRIVER_INFORMATION = '/gbr/:language/4/driving/driver-information',
    DRIVING_VEHICLE_OWNER_INFORMATION = '/gbr/:language/4/driving/owner-information',
    DRIVING_VEHICLE_SPEED_INFORMATION = '/gbr/:language/4/driving/speed-information',
    DRIVING_VEHICLE_COLLISION = '/gbr/:language/4/driving/collision',

    //Driving/Vehicle/Reversing
    DRIVING_VEHICLE_REVERSING_CLAIM_DESCRIPTION = '/gbr/:language/4/driving/reversing-claim-description',

    //Driving/Vehicle/Head On
    DRIVING_VEHICLE_ROAD_INFORMATION = '/gbr/:language/4/driving/road-information',

    //End paths
    END_ACCIDENT_LOCATION = '/gbr/:language/4/end/accident-location',
    END_ACCIDENT_DESCRIPTION = '/gbr/:language/4/end/accident-description',
    END_VEHICLE_WHO_RESPONSIBLE = '/gbr/:language/4/end/who-responsible',
    END_DAMAGES_OVERVIEW = '/gbr/:language/4/end/damage-overview',
    END_VEHICLE_REGISTRATION_NUMBER = '/gbr/:language/4/end/vehicle-registration-number',
    END_VEHICLE_INFORMATION = '/gbr/:language/4/end/vehicle-information',
    END_BODY = '/gbr/:language/4/end/body-damages',
    END_CONTACT_TOWING_SERVICE = '/gbr/:language/4/end/contact-towing-service',
    END_OWNER_INFORMATION_LIST = '/gbr/:language/4/end/owner-information',
    END_PASSENGERS = '/gbr/:language/4/end/passengers',
    END_INJURIES_PER_VEHICLE = '/gbr/:language/4/end/injuries-per-vehicle',
    END_INJURED_PERSON_INFORMATION = '/gbr/:language/4/end/injured-person-information',
    END_POLICE_CASE_NUMBER = '/gbr/:language/4/end/police-case-number',
    END_UPLOAD_POLICE_REPORT = '/gbr/:language/4/end/upload-police-report',
    END_REPORTER_INFORMATION = '/gbr/:language/4/end/reporter-information',
    END_DRIVER_INFORMATION = '/gbr/:language/4/end/driver-information',
    END_WITNESS_INFORMATION = '/gbr/:language/4/end/witness-information',
    END_UPLOAD_ATTACHMENT = '/gbr/:language/4/end/upload-attachment',
    END_POLICY_HOLDERS_CONTACT = '/gbr/:language/4/end/policy-holders-contact',
    END_FINAL_SUMMARY = '/gbr/:language/4/end/final-summary',
    END_REPORT_COMPLETED = '/gbr/:language/4/end/report-completed',
}

export const motorWizardRoutes: ObjectWithDynamicKey = {
    //Start routes
    [MotorRoutePaths.INDEX]: {
        [TypeOfAutoClaimKeys.WINDSHIELD]: MotorRoutePaths.START_WINDSHIELD_DAMAGE,
        [TypeOfAutoClaimKeys.STANDARD]: MotorRoutePaths.START_START_SUMMARY,
    },
    [MotorRoutePaths.START_START_SUMMARY]: MotorRoutePaths.START_CLAIM_DATE,
    [MotorRoutePaths.START_CLAIM_DATE]: MotorRoutePaths.START_VEHICLE_REGISTRATION_NUMBER,
    [MotorRoutePaths.START_VEHICLE_REGISTRATION_NUMBER]: MotorRoutePaths.START_VEHICLE_INFORMATION,
    [MotorRoutePaths.START_VEHICLE_INFORMATION]: MotorRoutePaths.START_COMPANY_INFORMATION,
    [MotorRoutePaths.DYN_CAROUSEL_START_INSURED_BY_PROTECTOR]: {
        [InsuredByProtectorKeys.YES_RECENTLY_INSURED_BY_PROTECTOR]: MotorRoutePaths.START_COMPANY_INFORMATION,
        [InsuredByProtectorKeys.YES]: MotorRoutePaths.START_COMPANY_INFORMATION,
        [InsuredByProtectorKeys.NO]: MotorRoutePaths.START_STOP,
    },
    [MotorRoutePaths.START_STOP]: MotorRoutePaths.START_COMPANY_INFORMATION,
    [MotorRoutePaths.START_COMPANY_INFORMATION]: MotorRoutePaths.DYN_CAROUSEL_START_IS_DRIVING,
    [MotorRoutePaths.DYN_CAROUSEL_START_IS_DRIVING]: {
        [YesNoKeys.YES]: MotorRoutePaths.DYN_CAROUSEL_DRIVING_IS_OTHER_VEHICLE_INVOLVED,
        [YesNoKeys.NO]: MotorRoutePaths.DYN_CAROUSEL_PARKED_CLAIM_CAUSE,
    },

    //Parked flow routes
    [MotorRoutePaths.DYN_CAROUSEL_PARKED_CLAIM_CAUSE]: {
        [MotorClaimCauseKeys.THEFT_AND_DAMAGE]:
            MotorRoutePaths.DYN_CAROUSEL_PARKED_THEFT_AND_DAMAGES_IS_SELF_DISCOVERED_CLAIM,
        [MotorClaimCauseKeys.ENGINE]: MotorRoutePaths.PARKED_ENGINE_CLAIM_DESCRIPTION,
        [MotorClaimCauseKeys.PARKING]: MotorRoutePaths.DYN_CAROUSEL_PARKED_PARKING_IS_COUNTERPARTY_KNOWN,
        [MotorClaimCauseKeys.FIRE]: MotorRoutePaths.PARKED_UPLOAD_FIRE_REPORT,
        [MotorClaimCauseKeys.OTHER]: MotorRoutePaths.DYN_CAROUSEL_PARKED_OTHER_CLAIM_CAUSE_DETAILS,
    },

    //Parked/Theft And Damages
    [MotorRoutePaths.DYN_CAROUSEL_PARKED_THEFT_AND_DAMAGES_IS_SELF_DISCOVERED_CLAIM]: {
        [YesNoKeys.YES]: MotorRoutePaths.DYN_CAROUSEL_PARKED_THEFT_AND_DAMAGES_IS_VEHICLE_STOLEN,
        [YesNoKeys.NO]: MotorRoutePaths.PARKED_CLAIM_DISCOVERER_INFORMATION,
    },
    [MotorRoutePaths.PARKED_CLAIM_DISCOVERER_INFORMATION]:
        MotorRoutePaths.DYN_CAROUSEL_PARKED_THEFT_AND_DAMAGES_IS_VEHICLE_STOLEN,
    [MotorRoutePaths.DYN_CAROUSEL_PARKED_THEFT_AND_DAMAGES_IS_VEHICLE_STOLEN]: {
        [YesNoKeys.YES]: MotorRoutePaths.PARKED_PARKING_DATE,
        [YesNoKeys.NO]: MotorRoutePaths.DYN_CAROUSEL_PARKED_THEFT_AND_DAMAGES_IS_ITEM_MISSING,
    },
    [MotorRoutePaths.PARKED_PARKING_DATE]: MotorRoutePaths.PARKED_PARKING_INFORMATION,
    [MotorRoutePaths.PARKED_PARKING_INFORMATION]: MotorRoutePaths.DYN_CAROUSEL_PARKED_THEFT_AND_DAMAGES_HAS_ALL_KEYS,
    [MotorRoutePaths.DYN_CAROUSEL_PARKED_THEFT_AND_DAMAGES_HAS_ALL_KEYS]: {
        [YesNoKeys.YES]: MotorRoutePaths.DYN_CAROUSEL_PARKED_THEFT_AND_DAMAGES_IS_VEHICLE_RECOVERED,
        [YesNoKeys.NO]: MotorRoutePaths.PARKED_THEFT_AND_DAMAGES_CLAIM_DESCRIPTION,
    },
    [MotorRoutePaths.PARKED_THEFT_AND_DAMAGES_CLAIM_DESCRIPTION]:
        MotorRoutePaths.DYN_CAROUSEL_PARKED_THEFT_AND_DAMAGES_IS_VEHICLE_RECOVERED,
    [MotorRoutePaths.DYN_CAROUSEL_PARKED_THEFT_AND_DAMAGES_IS_VEHICLE_RECOVERED]: {
        [YesNoKeys.YES]: MotorRoutePaths.PARKED_RECOVERY_DATE,
        [YesNoKeys.NO]: MotorRoutePaths.END_ACCIDENT_LOCATION,
    },
    [MotorRoutePaths.PARKED_RECOVERY_DATE]: MotorRoutePaths.PARKED_RECOVERER_INFORMATION,
    [MotorRoutePaths.PARKED_RECOVERER_INFORMATION]: MotorRoutePaths.END_ACCIDENT_LOCATION,
    [MotorRoutePaths.DYN_CAROUSEL_PARKED_THEFT_AND_DAMAGES_IS_ITEM_MISSING]: {
        [YesNoKeys.YES]: MotorRoutePaths.PARKED_MISSING_ITEMS,
        [YesNoKeys.NO]: MotorRoutePaths.END_ACCIDENT_LOCATION,
    },
    [MotorRoutePaths.PARKED_MISSING_ITEMS]: MotorRoutePaths.PARKED_UPLOAD_RECEIPT,
    [MotorRoutePaths.PARKED_OTHER_MISSING_ITEMS]: MotorRoutePaths.PARKED_UPLOAD_RECEIPT,
    [MotorRoutePaths.PARKED_UPLOAD_RECEIPT]: MotorRoutePaths.END_ACCIDENT_LOCATION,

    //Parked/Engine
    [MotorRoutePaths.PARKED_ENGINE_CLAIM_DESCRIPTION]: MotorRoutePaths.END_ACCIDENT_LOCATION,

    //Parked/Parking
    [MotorRoutePaths.PARKED_COUNTERPARTY_REGISTRATION_NUMBER]: MotorRoutePaths.END_ACCIDENT_LOCATION,

    //Parked/Fire
    [MotorRoutePaths.PARKED_UPLOAD_FIRE_REPORT]: MotorRoutePaths.PARKED_FIRE_CLAIM_DESCRIPTION,
    [MotorRoutePaths.PARKED_FIRE_CLAIM_DESCRIPTION]: MotorRoutePaths.END_ACCIDENT_LOCATION,

    //Parked/Parking
    [MotorRoutePaths.DYN_CAROUSEL_PARKED_PARKING_IS_COUNTERPARTY_KNOWN]: {
        [YesNoKeys.YES]: MotorRoutePaths.PARKED_COUNTERPARTY_REGISTRATION_NUMBER,
        [YesNoKeys.NO]: MotorRoutePaths.PARKED_UPLOAD_ATTACHMENTS,
    },
    [MotorRoutePaths.PARKED_COUNTERPARTY_REGISTRATION_NUMBER]:
        MotorRoutePaths.DYN_CAROUSEL_PARKED_PARKING_UNIDENTIFIED_TYPE,
    [MotorRoutePaths.DYN_CAROUSEL_PARKED_PARKING_UNIDENTIFIED_TYPE]: MotorRoutePaths.PARKED_VEHICLE_INFORMATION,
    [MotorRoutePaths.PARKED_VEHICLE_INFORMATION]: MotorRoutePaths.PARKED_COUNTERPARTY_INFORMATION,
    [MotorRoutePaths.PARKED_COUNTERPARTY_INFORMATION]: MotorRoutePaths.END_ACCIDENT_LOCATION,
    [MotorRoutePaths.PARKED_UPLOAD_ATTACHMENTS]: MotorRoutePaths.END_ACCIDENT_LOCATION,

    //Parked/Other
    [MotorRoutePaths.DYN_CAROUSEL_PARKED_OTHER_CLAIM_CAUSE_DETAILS]: MotorRoutePaths.END_ACCIDENT_LOCATION,

    //Driving flow routes
    [MotorRoutePaths.DYN_CAROUSEL_DRIVING_IS_OTHER_VEHICLE_INVOLVED]: {
        [YesNoKeys.YES]: MotorRoutePaths.DRIVING_VEHICLE_DAMAGES,
        [YesNoKeys.NO]: MotorRoutePaths.DYN_CAROUSEL_DRIVING_NO_VEHICLE_CLAIM_CAUSE,
    },

    //Driving/No Vehicle
    [MotorRoutePaths.DYN_CAROUSEL_DRIVING_NO_VEHICLE_CLAIM_CAUSE]: {
        [MotorClaimCauseKeys.ANIMAL_COLLISION]: MotorRoutePaths.DYN_CAROUSEL_DRIVING_NO_VEHICLE_ANIMAL_TYPE_OF_ANIMAL,
        [MotorClaimCauseKeys.STATIONARY_OBJECT_COLLISION]: MotorRoutePaths.DYN_CAROUSEL_DRIVING_NO_VEHICLE_IS_REVERSING,
        [MotorClaimCauseKeys.CYCLIST_COLLISION]: MotorRoutePaths.DYN_CAROUSEL_DRIVING_NO_VEHICLE_IS_REVERSING,
        [MotorClaimCauseKeys.DITCH_COLLISION]: MotorRoutePaths.DRIVING_NO_VEHICLE_ROAD_CONDITION_DESCRIPTION,
        [MotorClaimCauseKeys.PEDESTRIAN_COLLISION]: MotorRoutePaths.DYN_CAROUSEL_DRIVING_NO_VEHICLE_IS_REVERSING,
        [MotorClaimCauseKeys.OTHER]: MotorRoutePaths.DYN_CAROUSEL_DRIVING_NO_VEHICLE_IS_REVERSING,
    },

    //Driving/No Vehicle/Ditch
    [MotorRoutePaths.DRIVING_NO_VEHICLE_DITCH_CLAIM_DESCRIPTION]: MotorRoutePaths.DRIVING_NO_VEHICLE_FREIGHT_WEIGHT,
    [MotorRoutePaths.DRIVING_NO_VEHICLE_FREIGHT_WEIGHT]: MotorRoutePaths.DRIVING_NO_VEHICLE_UPLOAD_FREIGHT_DOCUMENT,
    [MotorRoutePaths.DRIVING_NO_VEHICLE_UPLOAD_FREIGHT_DOCUMENT]:
        MotorRoutePaths.DRIVING_NO_VEHICLE_ROAD_CONDITION_DESCRIPTION,
    [MotorRoutePaths.DRIVING_NO_VEHICLE_ROAD_CONDITION_DESCRIPTION]: MotorRoutePaths.END_ACCIDENT_LOCATION,

    //Driving/No Vehicle/Animal
    [MotorRoutePaths.DYN_CAROUSEL_DRIVING_NO_VEHICLE_ANIMAL_TYPE_OF_ANIMAL]: {
        [TypeOfAnimalCollisionKeys.PET]: MotorRoutePaths.DYN_CAROUSEL_DRIVING_NO_VEHICLE_ANIMAL_HAS_ANIMAL_ESCAPED,
        [TypeOfAnimalCollisionKeys.LIVESTOCK]:
            MotorRoutePaths.DYN_CAROUSEL_DRIVING_NO_VEHICLE_ANIMAL_HAS_ANIMAL_ESCAPED,
        [TypeOfAnimalCollisionKeys.GAME]: MotorRoutePaths.END_ACCIDENT_LOCATION,
        [TypeOfAnimalCollisionKeys.HORSE]: MotorRoutePaths.DYN_CAROUSEL_DRIVING_NO_VEHICLE_ANIMAL_IS_RIDING,
    },
    [MotorRoutePaths.DYN_CAROUSEL_DRIVING_NO_VEHICLE_ANIMAL_IS_RIDING]: {
        [YesNoKeys.YES]: MotorRoutePaths.DRIVING_NO_VEHICLE_OWNER_INFORMATION_LIST,
        [YesNoKeys.NO]: MotorRoutePaths.DYN_CAROUSEL_DRIVING_NO_VEHICLE_ANIMAL_HAS_ANIMAL_ESCAPED,
    },
    [MotorRoutePaths.DYN_CAROUSEL_DRIVING_NO_VEHICLE_ANIMAL_HAS_ANIMAL_ESCAPED]:
        MotorRoutePaths.DRIVING_NO_VEHICLE_OWNER_INFORMATION_LIST,
    [MotorRoutePaths.DRIVING_NO_VEHICLE_OWNER_INFORMATION_LIST]: MotorRoutePaths.END_ACCIDENT_LOCATION,

    //Driving/No Vehicle/Stationary Object
    [MotorRoutePaths.DYN_CAROUSEL_DRIVING_NO_VEHICLE_IS_REVERSING]: MotorRoutePaths.END_ACCIDENT_LOCATION,

    //Driving/Vehicle
    [MotorRoutePaths.DRIVING_VEHICLE_DAMAGES]: MotorRoutePaths.DRIVING_VEHICLE_SPEED_INFORMATION,
    [MotorRoutePaths.DRIVING_VEHICLE_VEHICLE_REGISTRATION_NUMBER]:
        MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_UNIDENTIFIED_TYPE,
    [MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_UNIDENTIFIED_TYPE]:
        MotorRoutePaths.DRIVING_VEHICLE_VEHICLE_INFORMATION,
    [MotorRoutePaths.DRIVING_VEHICLE_VEHICLE_INFORMATION]: MotorRoutePaths.DRIVING_VEHICLE_DRIVER_INFORMATION,
    [MotorRoutePaths.DRIVING_VEHICLE_DRIVER_INFORMATION]: MotorRoutePaths.DRIVING_VEHICLE_DAMAGES,
    [MotorRoutePaths.DRIVING_VEHICLE_SPEED_INFORMATION]: MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_CLAIM_LOCATION,
    [MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_CLAIM_LOCATION]: {
        [ClaimLocationKeys.ROAD]: MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_ROAD_TYPE_OF_ROAD,
        [ClaimLocationKeys.ROUNDABOUT]:
            MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_ROUNDABOUT_CLAIMANT_ROUNDABOUT_POSITIONING,
        [ClaimLocationKeys.INTERSECTION]:
            MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_INTERSECTION_TYPE_OF_INTERSECTION,
        [ClaimLocationKeys.PARKING_OTHER]: MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_PARKING_OTHER_CLAIM_CAUSE,
    },

    //Driving/Vehicle/Road
    [MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_ROAD_TYPE_OF_ROAD]:
        MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_ROAD_CLAIM_CAUSE,
    [MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_ROAD_CLAIM_CAUSE]: {
        [MotorClaimCauseKeys.REAR_ENDED]: MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_REAR_ENDED_WHO_AT_FAULT,
        [MotorClaimCauseKeys.HEAD_ON]: MotorRoutePaths.DRIVING_VEHICLE_ROAD_INFORMATION,
        [MotorClaimCauseKeys.OVERTAKING]: MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_OVERTAKING_WHO_AT_FAULT,
        [MotorClaimCauseKeys.PARKED]: MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_PARKED_WHO_AT_FAULT,
        [MotorClaimCauseKeys.LANE_CHANGE]: MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_LANE_CHANGE_WHO_AT_FAULT,
        [MotorClaimCauseKeys.REVERSING]: MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_REVERSING_WHO_AT_FAULT,
        [MotorClaimCauseKeys.NARROW_ROAD_COLLISION]: MotorRoutePaths.END_ACCIDENT_LOCATION,
        [MotorClaimCauseKeys.MULTIPLE_COLLISION]: MotorRoutePaths.END_ACCIDENT_LOCATION,
    },

    //Driving/Vehicle/Roundabout
    [MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_ROUNDABOUT_CLAIMANT_ROUNDABOUT_POSITIONING]:
        MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_ROUNDABOUT_COUNTERPARTY_ROUNDABOUT_POSITIONING,
    [MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_ROUNDABOUT_COUNTERPARTY_ROUNDABOUT_POSITIONING]:
        MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_ROUNDABOUT_CLAIM_CAUSE,
    [MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_ROUNDABOUT_CLAIM_CAUSE]: {
        [MotorClaimCauseKeys.REAR_ENDED]: MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_REAR_ENDED_WHO_AT_FAULT,
        [MotorClaimCauseKeys.LANE_CHANGE]: MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_LANE_CHANGE_WHO_AT_FAULT,
        [MotorClaimCauseKeys.REVERSING]: MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_REVERSING_WHO_AT_FAULT,
        [MotorClaimCauseKeys.MULTIPLE_COLLISION]: MotorRoutePaths.END_ACCIDENT_LOCATION,
        [MotorClaimCauseKeys.OTHER]: MotorRoutePaths.END_ACCIDENT_LOCATION,
    },
    //Driving/Vehicle/Intersection
    [MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_INTERSECTION_TYPE_OF_INTERSECTION]:
        MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_INTERSECTION_CLAIM_CAUSE,
    [MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_INTERSECTION_CLAIM_CAUSE]: {
        [MotorClaimCauseKeys.REAR_ENDED]: MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_REAR_ENDED_WHO_AT_FAULT,
        [MotorClaimCauseKeys.HEAD_ON]: MotorRoutePaths.DRIVING_VEHICLE_ROAD_INFORMATION,
        [MotorClaimCauseKeys.OVERTAKING]: MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_OVERTAKING_WHO_AT_FAULT,
        [MotorClaimCauseKeys.PARKED]: MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_PARKED_WHO_AT_FAULT,
        [MotorClaimCauseKeys.LANE_CHANGE]: MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_LANE_CHANGE_WHO_AT_FAULT,
        [MotorClaimCauseKeys.REVERSING]: MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_REVERSING_WHO_AT_FAULT,
        [MotorClaimCauseKeys.MULTIPLE_COLLISION]: MotorRoutePaths.END_ACCIDENT_LOCATION,
        [MotorClaimCauseKeys.OTHER]: MotorRoutePaths.END_ACCIDENT_LOCATION,
    },

    //Driving/Vehicle/Parking Other
    [MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_PARKING_OTHER_CLAIM_CAUSE]: {
        [MotorClaimCauseKeys.REAR_ENDED]: MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_REAR_ENDED_WHO_AT_FAULT,
        [MotorClaimCauseKeys.HEAD_ON]: MotorRoutePaths.DRIVING_VEHICLE_ROAD_INFORMATION,
        [MotorClaimCauseKeys.PARKED]: MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_PARKED_WHO_AT_FAULT,
        [MotorClaimCauseKeys.LANE_CHANGE]: MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_LANE_CHANGE_WHO_AT_FAULT,
        [MotorClaimCauseKeys.REVERSING]: MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_REVERSING_WHO_AT_FAULT,
    },

    //Driving/Vehicle/Reversing
    [MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_REVERSING_WHO_AT_FAULT]:
        MotorRoutePaths.DRIVING_VEHICLE_REVERSING_CLAIM_DESCRIPTION,
    [MotorRoutePaths.DRIVING_VEHICLE_REVERSING_CLAIM_DESCRIPTION]: MotorRoutePaths.DRIVING_VEHICLE_COLLISION,

    //Driving/Vehicle/Lane Change
    [MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_LANE_CHANGE_WHO_AT_FAULT]: MotorRoutePaths.DRIVING_VEHICLE_COLLISION,

    //Driving/Vehicle/Overtaking
    [MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_OVERTAKING_WHO_AT_FAULT]:
        MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_IS_LANE_CHANGE,

    //Driving/Vehicle/Head On
    [MotorRoutePaths.DRIVING_VEHICLE_ROAD_INFORMATION]:
        MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_HEAD_ON_IS_COUNTERPARTY_STATIONARY,
    [MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_HEAD_ON_IS_COUNTERPARTY_STATIONARY]:
        MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_IS_LANE_CHANGE,

    //Driving/Vehicle/Rear Ended
    [MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_REAR_ENDED_WHO_AT_FAULT]:
        MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_IS_LANE_CHANGE,

    //Driving/Vehicle/Parked
    [MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_PARKED_WHO_AT_FAULT]: {
        [WhoAtFaultKeys.CLAIMANT]: MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_PARKED_HAS_PASSENGERS,
        [WhoAtFaultKeys.COUNTERPARTY]: MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_PARKED_HAS_PASSENGERS,
    },
    [MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_PARKED_HAS_PASSENGERS]:
        MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_PARKED_IS_REVERSING,
    [MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_PARKED_IS_REVERSING]: MotorRoutePaths.END_ACCIDENT_LOCATION,

    //Driving/Vehicle
    [MotorRoutePaths.DRIVING_VEHICLE_COLLISION]: MotorRoutePaths.END_ACCIDENT_LOCATION,
    [MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_IS_LANE_CHANGE]: {
        [YesNoKeys.YES]: MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_LANE_CHANGE_WHO_AT_FAULT,
        [YesNoKeys.NO]: MotorRoutePaths.END_ACCIDENT_LOCATION,
    },

    //End routes
    [MotorRoutePaths.END_ACCIDENT_LOCATION]: MotorRoutePaths.DYN_CAROUSEL_END_HAS_VEHICLE_DAMAGES,
    [MotorRoutePaths.END_ACCIDENT_DESCRIPTION]: MotorRoutePaths.END_VEHICLE_WHO_RESPONSIBLE,
    [MotorRoutePaths.END_VEHICLE_WHO_RESPONSIBLE]: MotorRoutePaths.DYN_CAROUSEL_END_HAS_VEHICLE_DAMAGES,
    [MotorRoutePaths.DYN_CAROUSEL_END_HAS_VEHICLE_DAMAGES]: {
        [YesNoKeys.YES]: MotorRoutePaths.END_DAMAGES_OVERVIEW,
        [YesNoKeys.NO]: MotorRoutePaths.DYN_CAROUSEL_END_HAS_NON_VEHICLE_DAMAGES,
    },
    [MotorRoutePaths.END_DAMAGES_OVERVIEW]: MotorRoutePaths.DYN_CAROUSEL_END_NEED_VEHICLE_TOWING,
    [MotorRoutePaths.END_VEHICLE_REGISTRATION_NUMBER]: MotorRoutePaths.END_VEHICLE_INFORMATION,
    [MotorRoutePaths.END_VEHICLE_INFORMATION]: MotorRoutePaths.END_DAMAGES_OVERVIEW,
    [MotorRoutePaths.END_BODY]: MotorRoutePaths.END_DAMAGES_OVERVIEW,
    [MotorRoutePaths.DYN_CAROUSEL_END_NEED_VEHICLE_TOWING]: {
        [NeedVehicleTowingKeys.YES]: MotorRoutePaths.END_CONTACT_TOWING_SERVICE,
        [NeedVehicleTowingKeys.NO]: MotorRoutePaths.DYN_CAROUSEL_END_HAS_NON_VEHICLE_DAMAGES,
        [NeedVehicleTowingKeys.ALREADY_TOWED]: MotorRoutePaths.DYN_CAROUSEL_END_HAS_NON_VEHICLE_DAMAGES,
    },
    [MotorRoutePaths.END_CONTACT_TOWING_SERVICE]: MotorRoutePaths.DYN_CAROUSEL_END_HAS_NON_VEHICLE_DAMAGES,
    [MotorRoutePaths.DYN_CAROUSEL_END_HAS_NON_VEHICLE_DAMAGES]: {
        [YesNoKeys.YES]: MotorRoutePaths.END_OWNER_INFORMATION_LIST,
        [YesNoKeys.NO]: MotorRoutePaths.END_PASSENGERS,
    },
    [MotorRoutePaths.END_OWNER_INFORMATION_LIST]: MotorRoutePaths.END_PASSENGERS,
    [MotorRoutePaths.END_PASSENGERS]: MotorRoutePaths.DYN_CAROUSEL_END_HAS_PERSON_INJURIES,
    [MotorRoutePaths.DYN_CAROUSEL_END_HAS_PERSON_INJURIES]: {
        [YesNoKeys.YES]: MotorRoutePaths.END_INJURIES_PER_VEHICLE,
        [YesNoKeys.NO]: MotorRoutePaths.DYN_CAROUSEL_END_IS_POLICE_CONTACTED,
    },
    [MotorRoutePaths.END_INJURIES_PER_VEHICLE]: MotorRoutePaths.END_INJURED_PERSON_INFORMATION,
    [MotorRoutePaths.END_INJURED_PERSON_INFORMATION]: MotorRoutePaths.DYN_CAROUSEL_END_IS_POLICE_CONTACTED,
    [MotorRoutePaths.DYN_CAROUSEL_END_IS_POLICE_CONTACTED]: {
        [YesNoKeys.YES]: MotorRoutePaths.END_POLICE_CASE_NUMBER,
        [YesNoKeys.NO]: MotorRoutePaths.END_REPORTER_INFORMATION,
    },
    [MotorRoutePaths.END_POLICE_CASE_NUMBER]: MotorRoutePaths.END_UPLOAD_POLICE_REPORT,
    [MotorRoutePaths.END_UPLOAD_POLICE_REPORT]: MotorRoutePaths.END_REPORTER_INFORMATION,
    [MotorRoutePaths.END_REPORTER_INFORMATION]: MotorRoutePaths.DYN_CAROUSEL_END_HAS_WITNESSES,
    [MotorRoutePaths.END_DRIVER_INFORMATION]: MotorRoutePaths.DYN_CAROUSEL_END_HAS_WITNESSES,
    [MotorRoutePaths.DYN_CAROUSEL_END_HAS_WITNESSES]: {
        [YesNoKeys.YES]: MotorRoutePaths.END_WITNESS_INFORMATION,
        [YesNoKeys.NO]: MotorRoutePaths.END_UPLOAD_ATTACHMENT,
    },
    [MotorRoutePaths.END_WITNESS_INFORMATION]: MotorRoutePaths.END_UPLOAD_ATTACHMENT,
    [MotorRoutePaths.END_UPLOAD_ATTACHMENT]: MotorRoutePaths.END_POLICY_HOLDERS_CONTACT,
    [MotorRoutePaths.END_POLICY_HOLDERS_CONTACT]: MotorRoutePaths.END_FINAL_SUMMARY,
    [MotorRoutePaths.END_FINAL_SUMMARY]: MotorRoutePaths.END_REPORT_COMPLETED,
};
