import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { SERVICE_RECAPTCHA_SITE_KEY } from '../config/services';
import { verifyCaptchaToken } from '../utils/verifyCaptchaToken';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions } from '../sagas/wizardRouter';
import { BaseRoutePaths } from '../config/wizardRouter/baseWizardRoutes';
import { getLobFromUrl, is, LobKeys, PageLayout } from '@protectorinsurance/ds-can';
import { useI18n } from '../hooks/useI18n';
import { PhraseKeys } from '../config/phraseKeys';
import { selectWizardRouterPrevious } from '../sagas/selectors/wizardRouterSelectors';
import { commonActions } from '../sagas/common';
import { LpoRoutePaths } from '../config/wizardRouter/lpoWizardRoutes';
import { MotorRoutePaths } from '../config/wizardRouter/motorWizardRoutes';
import dispatcherWithPromise from '../utils/dispatcherWithPromise';

/**
 * Destructure necessary imports
 */
const { HELP_TEXT, PAGE_NAME, SUB_TITLE, TITLE } = PhraseKeys;

/**
 * Page view and page logic
 */
export const CaptchaPage = () => {
    const dispatch = useDispatch();
    const { t } = useI18n();
    const tWithNS = useI18n('captcha');
    const captchaRef = useRef(null);
    const previousUrl = useSelector(selectWizardRouterPrevious);

    const handleCaptchaSuccess = async (token: string | null) => {
        if (!token) return;
        try {
            const response = await verifyCaptchaToken(token);
            if (response.data.success) {
                sessionStorage.setItem('verifiedProtectorCaptcha', 'true');
                let goToLink: string = BaseRoutePaths.DEFAULT_INDEX;
                if (previousUrl) {
                    const url = previousUrl.split('/');
                    const lob = Number(url[3]);
                    const lpo: LobKeys[] = [LobKeys.LIABILITY, LobKeys.PROPERTY];

                    if (lob && lpo.includes(lob)) {
                        dispatch(commonActions.update({ lob }));
                        goToLink = LpoRoutePaths.START_ONBOARDING;
                    }

                    if (is(lob, LobKeys.AUTO)) {
                        dispatch(commonActions.update({ lob }));
                        goToLink = MotorRoutePaths.INDEX;
                    }
                }

                dispatch(wizardRouterActions.goTo(goToLink));
            } else {
                console.error('Server verification failed');
                // @ts-ignore
                captchaRef.current.reset();
            }
        } catch (error) {
            console.error('Server verification error', error);
        }
    };

    return (
        <PageLayout
            backBtnText={''}
            continueBtnText={''}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            showBackButton={false}
            showContinueButton={false}
        >
            {!SERVICE_RECAPTCHA_SITE_KEY ? (
                <div>{tWithNS.t('noRecaptchaSiteKey')}</div>
            ) : (
                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        width: '100%',
                    }}
                >
                    <ReCAPTCHA onChange={handleCaptchaSuccess} ref={captchaRef} sitekey={SERVICE_RECAPTCHA_SITE_KEY} />
                </div>
            )}
        </PageLayout>
    );
};
