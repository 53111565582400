import { RefObject } from 'react';
import { BodyKeys, DamagePoint, Nullable, SVGClickable } from '@protectorinsurance/ds-can';

export const createSVGPoint = (e: SVGClickable, svg: SVGSVGElement): Nullable<DamagePoint> => {
    e.preventDefault();
    const { id: partId } = e.target as SVGElement;
    let svgPoint = svg.createSVGPoint(); // sjekk om bruk i IE - lag task for IE
    svgPoint.x = e.clientX;
    svgPoint.y = e.clientY;
    const screenCTM = svg.getScreenCTM();
    if (screenCTM) {
        const { x, y } = svgPoint.matrixTransform(screenCTM.inverse());
        const tempDamagePoint: DamagePoint = { x, y, vehicleBodyPart: partId as BodyKeys, svgId: svg.id };
        return tempDamagePoint;
    }
    return null;
};

export const createOnClickAddDamagePoint = (
    refSVG: RefObject<SVGSVGElement>,
    addDamagePoint: (d: DamagePoint, refSVG?: RefObject<SVGSVGElement>) => void
) => {
    return function onClickAddDamagePoint(e: SVGClickable) {
        if (refSVG.current) {
            const tempDamagePoint = createSVGPoint(e, refSVG.current);
            if (tempDamagePoint) {
                addDamagePoint(tempDamagePoint, refSVG);
            }
        }
    };
};
