import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { PhraseKeys } from '../../config/phraseKeys';
import { useI18n } from '../../hooks/useI18n';
import { selectClaimReportId } from '../../sagas/selectors/reportSelectors';
import { Clickable, DisplayError, PageLayout } from '@protectorinsurance/ds-can';
import dispatcherWithPromise from '../../utils/dispatcherWithPromise';
import { commonActions } from '../../sagas/common';
import { expiredUUID, renderErrorContent } from '../../utils/error/errorUtils';
import {
    selectApiErrors,
    selectLocaleCountryCode,
    selectLocaleLanguageCode,
} from '../../sagas/selectors/commonSelectors';
import { BaseRoutePaths } from '../../config/wizardRouter/baseWizardRoutes';

/**
 * Destructure necessary imports
 */
const { BACK_BUTTON, PAGE_NAME } = PhraseKeys;

/**
 * Page view and page logic
 */
export const RequestErrorPage = () => {
    const dispatch = useDispatch();
    const apiErrors = useSelector(selectApiErrors);
    const claimId = useSelector(selectClaimReportId);
    const country = useSelector(selectLocaleCountryCode);
    const language = useSelector(selectLocaleLanguageCode);
    const { t } = useI18n();
    const tWithNS = useI18n('error.requestError');

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        dispatch(wizardActions.update({ skipBackToPrev: true }));
        if (apiErrors.length > 0 && apiErrors[0].error.status === expiredUUID) {
            window.location.href = `/${country}/${language}`;
        } else if (claimId !== '') {
            dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(wizardActions.goToNext()));
        } else {
            dispatch(wizardActions.goTo(BaseRoutePaths.INDEX));
        }
    };

    const errorContent = apiErrors.length > 0 ? renderErrorContent(apiErrors[0].error.status) : renderErrorContent();

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(errorContent.continueButton)}
            domainTitle={t(PAGE_NAME)}
            headerSubTitle={tWithNS.t(errorContent.sub)}
            headerTitle={tWithNS.t(errorContent.title)}
            showBackButton={false}
            {...{ handleContinueButton }}
        >
            <DisplayError
                message={tWithNS.t(errorContent.message)}
                info={tWithNS.t(errorContent.info)}
                icon={errorContent.icon}
            />
        </PageLayout>
    );
};
