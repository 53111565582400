import FileModel from '../../../models/File';
import { actionWithPromise, emptyFn, Rejectable, Resolvable } from '@protectorinsurance/ds-can';
import { api } from 'utils/api';
import { attachmentActions } from './attachment';
import { pollAttachmentActions } from './pollAttachment';
import { call, put, takeLatest } from 'redux-saga/effects';
import { select } from 'typed-redux-saga';
import { selectRequestId } from '../../selectors/commonSelectors';
import { NODE_API_BASE_URL } from '../../../config/api';

/**
 * Action Types
 */
export enum DeleteAttachmentActionTypes {
    REQUEST = '@app/upload/attachment/delete/REQUEST',
    SUCCESS = '@app/upload/attachment/delete/SUCCESS',
    FAILURE = '@app/upload/attachment/delete/FAILURE',
}

/**
 * Action Definitions
 */
export interface DeleteAttachmentAction {
    type: DeleteAttachmentActionTypes;
    data: FileModel;
    resolve?: Resolvable;
    reject?: Rejectable;
}

/**
 * Redux Actions
 */
export const deleteAttachmentActions = {
    request: actionWithPromise(DeleteAttachmentActionTypes.REQUEST),
    success: actionWithPromise(DeleteAttachmentActionTypes.SUCCESS),
    failure: actionWithPromise(DeleteAttachmentActionTypes.FAILURE),
};

/**
 * Saga Functions
 */
export const sagas = {
    *request({ data, resolve = emptyFn, reject = emptyFn }: DeleteAttachmentAction) {
        try {
            const requestId = yield* select(selectRequestId);
            if (data && data.externalId) {
                yield call(api.delete, `upload/${data.externalId}`, {
                    baseURL: NODE_API_BASE_URL,
                    headers: { 'X-Request-Id': `${requestId}` },
                });
            }

            yield put(deleteAttachmentActions.success());
            yield put(attachmentActions.remove(data));
            resolve();
        } catch (e) {
            yield put(pollAttachmentActions.failure((e as any).response.data));
            reject();
        }
    },
};

/**
 * Saga Watchers
 */
export const deleteAttachmentWatchers = function* () {
    yield takeLatest(DeleteAttachmentActionTypes.REQUEST, sagas.request);
};
