import { PersonModel, WitnessModel } from '@protectorinsurance/ds-can';
import { ThirdPartyInformationModel, ThirdPartyInformationTypeModel } from '../models/ThirdPartyInformation';

export const removePerson = (person: PersonModel, existingPersons: PersonModel[]): PersonModel[] => {
    return existingPersons.filter((p: PersonModel) => {
        if (
            p.firstName !== null &&
            person.firstName !== null &&
            p.lastName !== null &&
            person.lastName !== null &&
            p.phone !== null &&
            person.phone !== null &&
            p.email !== null &&
            person.email !== null
        ) {
            return (
                p.firstName.toLowerCase() !== person.firstName.toLowerCase() ||
                p.lastName.toLowerCase() !== person.lastName.toLowerCase() ||
                p.phone.toLowerCase() !== person.phone.toLowerCase() ||
                p.email.toLowerCase() !== person.email.toLowerCase()
            );
        }

        return null;
    });
};

export const personExists = (person: PersonModel, existingPersons: PersonModel[]): boolean => {
    return !!existingPersons.find((p: PersonModel) => {
        if (
            !!p.firstName &&
            !!person.firstName &&
            !!p.lastName &&
            !!person.lastName &&
            !!p.phone &&
            !!person.phone &&
            !!p.email &&
            !!person.email
        ) {
            return (
                p.firstName.toLowerCase() === person.firstName.toLowerCase() &&
                p.lastName.toLowerCase() === person.lastName.toLowerCase() &&
                p.phone.toLowerCase() === person.phone.toLowerCase() &&
                p.email.toLowerCase() === person.email.toLowerCase()
            );
        }

        return null;
    });
};

export const displayPerson = (person: PersonModel) => {
    const { firstName, lastName, email, phone } = person;
    return `${firstName ? firstName : ''}${lastName ? ` ${lastName}` : ''}${email ? `, ${email}` : ''}${
        phone ? `, ${phone}` : ''
    }`;
};

export const removeWitness = (witness: WitnessModel, existingWitnesses: WitnessModel[]): WitnessModel[] => {
    return existingWitnesses.filter((w: WitnessModel) => {
        if (
            w.firstName !== null &&
            witness.firstName !== null &&
            w.lastName !== null &&
            witness.lastName !== null &&
            w.phone !== null &&
            witness.phone !== null &&
            w.email !== null &&
            witness.email !== null &&
            w.street !== null &&
            witness.street !== null &&
            w.zip !== null &&
            witness.zip !== null &&
            w.city !== null &&
            witness.city !== null
        ) {
            return (
                w.firstName.toLowerCase() !== witness.firstName.toLowerCase() ||
                w.lastName.toLowerCase() !== witness.lastName.toLowerCase() ||
                w.phone.toLowerCase() !== witness.phone.toLowerCase() ||
                w.email.toLowerCase() !== witness.email.toLowerCase() ||
                w.street.toLowerCase() !== witness.street.toLowerCase() ||
                w.zip.toLowerCase() !== witness.zip.toLowerCase() ||
                w.city.toLowerCase() !== witness.city.toLowerCase()
            );
        }

        return null;
    });
};

export const thirdPartyExists = (
    thirdParty: ThirdPartyInformationModel,
    existingThirdParty: ThirdPartyInformationTypeModel
): boolean => {
    return !!existingThirdParty.find((tp: ThirdPartyInformationModel) => {
        if (!!tp.firstName && !!thirdParty.firstName && !!tp.lastName && !!thirdParty.lastName) {
            return (
                tp.firstName.toLowerCase() === thirdParty.firstName.toLowerCase() &&
                tp.lastName.toLowerCase() === thirdParty.lastName.toLowerCase()
            );
        }

        return null;
    });
};

export const removeThirdParty = (
    thirdParty: ThirdPartyInformationModel,
    existingThirdParty: ThirdPartyInformationTypeModel
): ThirdPartyInformationTypeModel => {
    return existingThirdParty.filter((tp: ThirdPartyInformationModel) => {
        if (
            tp.firstName !== null &&
            thirdParty.firstName !== null &&
            tp.lastName !== null &&
            thirdParty.lastName !== null
        ) {
            return (
                tp.firstName.toLowerCase() !== thirdParty.firstName.toLowerCase() ||
                tp.lastName.toLowerCase() !== thirdParty.lastName.toLowerCase()
            );
        }

        return null;
    });
};

export const displayThirdParty = (thirdParty: ThirdPartyInformationModel) => {
    const { firstName, lastName, email, phone, roleType } = thirdParty;
    return `${firstName} ${lastName}${roleType && roleType.key ? ` (${roleType.key})` : ''}${email && `, ${email}`}${
        phone && `, ${phone}`
    }`;
};
