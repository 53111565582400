import { ObjectWithDynamicKey, YesNoKeys } from '@protectorinsurance/ds-can';

export enum LpoRoutePaths {
    INDEX = '/gbr/:language/:lob',
    ERROR = '/error',

    // Dyn
    DYN_BASE = '/gbr/:language/:lob/dyn/',
    DYN_CAROUSEL = '/gbr/:language/:lob/dyn/:flow?/:slug',
    // Start
    DYN_CAROUSEL_START_CLAIM_REPORTER_ROLE = '/gbr/:language/:lob/dyn/start/claim-reporter-role',
    // Liability
    DYN_CAROUSEL_LIABILITY_IS_EMPLOYER = '/gbr/:language/:lob/dyn/liability/is-employer',
    DYN_CAROUSEL_LIABILITY_HAS_INJURY = '/gbr/:language/:lob/dyn/liability/has-injury',
    DYN_CAROUSEL_LIABILITY_IS_THIRD_PARTY_INVOLVED = '/gbr/:language/:lob/dyn/liability/is-third-party-involved',
    // Property
    DYN_CAROUSEL_PROPERTY_TYPE_OF_PROPERTY = '/gbr/:language/:lob/dyn/property/type-of-property',
    DYN_CAROUSEL_PROPERTY_IS_LEAK_FIXED = '/gbr/:language/:lob/dyn/property/is-leak-fixed',
    DYN_CAROUSEL_PROPERTY_IS_PROPERTY_OCCUPIED = '/gbr/:language/:lob/dyn/property/is-property-occupied',
    DYN_CAROUSEL_PROPERTY_IS_PROPERTY_TENANTED = '/gbr/:language/:lob/dyn/property/is-property-tenanted',
    DYN_CAROUSEL_PROPERTY_HAS_ACCESS_TO_WATER = '/gbr/:language/:lob/dyn/property/has-access-to-water',
    DYN_CAROUSEL_PROPERTY_HAS_ACCESS_TO_ELECTRICITY = '/gbr/:language/:lob/dyn/property/has-access-to-electricity',
    DYN_CAROUSEL_PROPERTY_HAS_ACCESS_TO_GAS = '/gbr/:language/:lob/dyn/property/has-access-to-gas',
    DYN_CAROUSEL_PROPERTY_HAS_EXISTING_CLAIM = '/gbr/:language/:lob/dyn/property/has-existing-claim',
    DYN_CAROUSEL_PROPERTY_IS_THIRD_PARTY_INVOLVED = '/gbr/:language/:lob/dyn/property/is-third-parties-involved',
    // End
    DYN_CAROUSEL_END_HAS_OTHER_INSURANCE = '/gbr/:language/:lob/dyn/end/has-other-insurance',

    // Start
    START_ONBOARDING = '/gbr/:language/:lob/start/onboarding',
    START_PRIVACY = '/gbr/:language/:lob/start/privacy',
    START_POLICY_HOLDER = '/gbr/:language/:lob/start/policy-holder',
    START_CLAIM_DATE = '/gbr/:language/:lob/start/claim-date',
    START_ACCIDENT_LOCATION = '/gbr/:language/:lob/start/accident-location',
    START_CLAIM_DESCRIPTION = '/gbr/:language/:lob/start/claim-description',

    // Liability
    LIABILITY_CLAIMANT_INFORMATION = '/gbr/:language/:lob/liability/claimant-information',
    LIABILITY_CLAIM_TYPE = '/gbr/:language/:lob/liability/claim-type',
    LIABILITY_CLAIM_CAUSE = '/gbr/:language/:lob/liability/claim-cause',
    LIABILITY_THIRD_PARTY_INFORMATION = '/gbr/:language/:lob/liability/third-party-information',

    // Property
    PROPERTY_CLAIM_TYPE = '/gbr/:language/:lob/property/claim-type',
    PROPERTY_LEAK = '/gbr/:language/:lob/property/leak',
    PROPERTY_PROPERTY_ADDRESS = '/gbr/:language/:lob/property/property-address',
    PROPERTY_EXTERNAL_PROPERTY_DAMAGE = '/gbr/:language/:lob/property/external-property-damage',
    PROPERTY_INTERNAL_PROPERTY_DAMAGE = '/gbr/:language/:lob/property/internal-property-damage',
    PROPERTY_PROPERTY_CLAIM_DESCRIPTION = '/gbr/:language/:lob/property/property-claim-description',
    PROPERTY_THIRD_PARTY_INFORMATION = '/gbr/:language/:lob/property/third-party-information',

    // End
    END_CRIME_REFERENCE = '/gbr/:language/:lob/end/crime-reference',
    END_UPLOAD_ATTACHMENT = '/gbr/:language/:lob/end/upload-attachment',
    END_OTHER_INSURANCE_COMPANY = '/gbr/:language/:lob/end/other-insurance-company',
    END_REPORTER_INFORMATION = '/gbr/:language/:lob/end/reporter-information',
    END_POLICY_HOLDERS_CONTACT = '/gbr/:language/:lob/end/policy-holders-contact',
    END_FINAL_SUMMARY = '/gbr/:language/:lob/end/final-summary',
    END_REPORT_COMPLETED = '/gbr/:language/:lob/end/report-completed',
}

export const lpoWizardRoutes: ObjectWithDynamicKey = {
    // Start
    [LpoRoutePaths.INDEX]: LpoRoutePaths.START_PRIVACY,
    [LpoRoutePaths.START_ONBOARDING]: LpoRoutePaths.START_PRIVACY,
    [LpoRoutePaths.START_PRIVACY]: LpoRoutePaths.START_POLICY_HOLDER,
    [LpoRoutePaths.START_POLICY_HOLDER]: LpoRoutePaths.DYN_CAROUSEL_START_CLAIM_REPORTER_ROLE,
    [LpoRoutePaths.DYN_CAROUSEL_START_CLAIM_REPORTER_ROLE]: LpoRoutePaths.DYN_CAROUSEL_LIABILITY_IS_EMPLOYER,
    [LpoRoutePaths.START_CLAIM_DATE]: LpoRoutePaths.START_ACCIDENT_LOCATION,
    [LpoRoutePaths.START_ACCIDENT_LOCATION]: LpoRoutePaths.START_CLAIM_DESCRIPTION,
    [LpoRoutePaths.START_CLAIM_DESCRIPTION]: LpoRoutePaths.END_CRIME_REFERENCE,

    // Liability
    [LpoRoutePaths.LIABILITY_CLAIMANT_INFORMATION]: LpoRoutePaths.DYN_CAROUSEL_LIABILITY_IS_EMPLOYER,
    [LpoRoutePaths.DYN_CAROUSEL_LIABILITY_IS_EMPLOYER]: LpoRoutePaths.DYN_CAROUSEL_LIABILITY_HAS_INJURY,
    [LpoRoutePaths.DYN_CAROUSEL_LIABILITY_HAS_INJURY]: LpoRoutePaths.LIABILITY_CLAIM_TYPE,
    [LpoRoutePaths.LIABILITY_CLAIM_TYPE]: LpoRoutePaths.LIABILITY_CLAIM_CAUSE,
    [LpoRoutePaths.LIABILITY_CLAIM_CAUSE]: LpoRoutePaths.START_CLAIM_DATE,
    [LpoRoutePaths.DYN_CAROUSEL_LIABILITY_IS_THIRD_PARTY_INVOLVED]: {
        [YesNoKeys.YES]: LpoRoutePaths.LIABILITY_THIRD_PARTY_INFORMATION,
        [YesNoKeys.NO]: LpoRoutePaths.END_REPORTER_INFORMATION,
    },
    [LpoRoutePaths.LIABILITY_THIRD_PARTY_INFORMATION]: LpoRoutePaths.END_REPORTER_INFORMATION,

    // Property
    [LpoRoutePaths.DYN_CAROUSEL_PROPERTY_TYPE_OF_PROPERTY]: LpoRoutePaths.PROPERTY_CLAIM_TYPE,
    [LpoRoutePaths.PROPERTY_CLAIM_TYPE]: LpoRoutePaths.LIABILITY_CLAIM_CAUSE,
    [LpoRoutePaths.PROPERTY_LEAK]: LpoRoutePaths.START_CLAIM_DATE,
    [LpoRoutePaths.PROPERTY_PROPERTY_ADDRESS]: LpoRoutePaths.PROPERTY_EXTERNAL_PROPERTY_DAMAGE,
    [LpoRoutePaths.PROPERTY_EXTERNAL_PROPERTY_DAMAGE]: LpoRoutePaths.PROPERTY_INTERNAL_PROPERTY_DAMAGE,
    [LpoRoutePaths.PROPERTY_INTERNAL_PROPERTY_DAMAGE]: LpoRoutePaths.START_CLAIM_DESCRIPTION,
    [LpoRoutePaths.DYN_CAROUSEL_PROPERTY_IS_LEAK_FIXED]: LpoRoutePaths.DYN_CAROUSEL_PROPERTY_IS_PROPERTY_OCCUPIED,
    [LpoRoutePaths.DYN_CAROUSEL_PROPERTY_IS_PROPERTY_OCCUPIED]: {
        [YesNoKeys.YES]: LpoRoutePaths.DYN_CAROUSEL_PROPERTY_IS_PROPERTY_TENANTED,
        [YesNoKeys.NO]: LpoRoutePaths.DYN_CAROUSEL_PROPERTY_HAS_ACCESS_TO_WATER,
    },
    [LpoRoutePaths.DYN_CAROUSEL_PROPERTY_IS_PROPERTY_TENANTED]: LpoRoutePaths.DYN_CAROUSEL_PROPERTY_HAS_ACCESS_TO_WATER,
    [LpoRoutePaths.DYN_CAROUSEL_PROPERTY_HAS_ACCESS_TO_WATER]:
        LpoRoutePaths.DYN_CAROUSEL_PROPERTY_HAS_ACCESS_TO_ELECTRICITY,
    [LpoRoutePaths.DYN_CAROUSEL_PROPERTY_HAS_ACCESS_TO_ELECTRICITY]:
        LpoRoutePaths.DYN_CAROUSEL_PROPERTY_HAS_ACCESS_TO_GAS,
    [LpoRoutePaths.DYN_CAROUSEL_PROPERTY_HAS_ACCESS_TO_GAS]: LpoRoutePaths.DYN_CAROUSEL_PROPERTY_HAS_EXISTING_CLAIM,
    [LpoRoutePaths.DYN_CAROUSEL_PROPERTY_HAS_EXISTING_CLAIM]: {
        [YesNoKeys.YES]: LpoRoutePaths.PROPERTY_PROPERTY_CLAIM_DESCRIPTION,
        [YesNoKeys.NO]: LpoRoutePaths.DYN_CAROUSEL_PROPERTY_IS_THIRD_PARTY_INVOLVED,
    },
    [LpoRoutePaths.PROPERTY_PROPERTY_CLAIM_DESCRIPTION]: LpoRoutePaths.DYN_CAROUSEL_PROPERTY_IS_THIRD_PARTY_INVOLVED,
    [LpoRoutePaths.DYN_CAROUSEL_PROPERTY_IS_THIRD_PARTY_INVOLVED]: {
        [YesNoKeys.YES]: LpoRoutePaths.PROPERTY_THIRD_PARTY_INFORMATION,
        [YesNoKeys.NO]: LpoRoutePaths.END_CRIME_REFERENCE,
    },
    [LpoRoutePaths.PROPERTY_THIRD_PARTY_INFORMATION]: LpoRoutePaths.END_CRIME_REFERENCE,

    // End
    [LpoRoutePaths.END_CRIME_REFERENCE]: LpoRoutePaths.END_UPLOAD_ATTACHMENT,
    [LpoRoutePaths.END_UPLOAD_ATTACHMENT]: LpoRoutePaths.DYN_CAROUSEL_END_HAS_OTHER_INSURANCE,
    [LpoRoutePaths.DYN_CAROUSEL_END_HAS_OTHER_INSURANCE]: {
        [YesNoKeys.YES]: LpoRoutePaths.END_OTHER_INSURANCE_COMPANY,
        [YesNoKeys.NO]: LpoRoutePaths.END_REPORTER_INFORMATION,
    },
    [LpoRoutePaths.END_OTHER_INSURANCE_COMPANY]: LpoRoutePaths.END_REPORTER_INFORMATION,
    [LpoRoutePaths.END_REPORTER_INFORMATION]: LpoRoutePaths.END_POLICY_HOLDERS_CONTACT,
    [LpoRoutePaths.END_POLICY_HOLDERS_CONTACT]: LpoRoutePaths.END_FINAL_SUMMARY,
    [LpoRoutePaths.END_FINAL_SUMMARY]: LpoRoutePaths.END_REPORT_COMPLETED,
};
