import { TFunction } from 'i18next';
import {
    ClaimTypeEnums,
    ClaimTypeTypeModel,
    is,
    LpoClaimCauseEnums,
    TypeOfPropertyKeys,
    TypeOfPropertyTypeModel,
} from '@protectorinsurance/ds-can';

/**
 * Destructure necessary imports
 */
const {
    ACCIDENTAL_DAMAGE,
    ACCIDENTAL_DAMAGE_GLASS,
    AIRCRAFT,
    ALLERGIC_REACTION,
    ANIMAL,
    ARSON,
    ASBESTOS_EXPOSURE,
    ASSAULT,
    ASSAULT_COLLEAGUE,
    ASSAULT_SERVICE_USER,
    CARELESSNESS,
    CARELESSNESS_CIGARETTE,
    CHEMICALS,
    COMPUTER_FRAUD,
    CONTACT_WITH_SHARPS,
    CONTRACTOR_NEGLIGENCE,
    CRIME,
    CUSTOMER_SUPPLIERS,
    DEFECTIVE_EQUIPMENT,
    DEFECTIVE_MACHINERY,
    DEFECTIVE_PPE,
    DEFECTIVE_PREMISES,
    DELIVERY_INJURY,
    DENIAL_OF_ACCESS,
    DISEASE,
    EARTHQUAKE,
    ELECTIONS,
    ELECTRICAL,
    ESCAPE_OIL,
    ESCAPE_WATER,
    EXPLOSION,
    FALL,
    FALLING_OBJECT,
    FALLING_TREE,
    FIDELITY,
    FINANCIAL_LOSS,
    FIRE,
    FLOOD,
    FLOOD_COASTAL,
    FLOOD_RIVER,
    FROZEN_PIPES,
    HAIL,
    HEAVE,
    HIGHWAY,
    HORSEPLAY,
    HOT_WORK,
    INJURY,
    INJURY_FROM_RTA,
    LACK_OF_MAINTENANCE,
    LAND_CHARGES,
    LANDSLIP,
    LIBEL,
    LIGHTNING,
    MALICIOUS_DAMAGE,
    MANUAL_HANDLING,
    NOISE_INDUCED_HEARING_LOSS,
    OTHER,
    OTHER_DISEASE,
    OWN_VEHICLE,
    PHYSICAL_ABUSE,
    POOR_HOUSEKEEPING,
    PUBLIC_HEALTH,
    PUBLIC_UTILITIES,
    RAIN,
    RIOT,
    RSI,
    SEXUAL_ABUSE,
    SINKHOLE,
    SLANDER,
    SLIP_ON_DEBRIS,
    SLIP_ON_FLOOR,
    SLIP_ON_ICE,
    SLIP_ON_STEPS,
    SLIP_ON_WET_FLOOR,
    SMOKE_INHALATION,
    SNOW,
    SPRINKLER_LEAKAGE,
    SPONTANEOUS_COMBUSTION,
    SPREAD_FIRE,
    STRESS,
    STRUCK_BY_OBJECT,
    STRUCK_BY_VEHICLE,
    TERRORISM,
    THEFT_AND_DAMAGE,
    TP_VEHICLE,
    TRAPPED_PHYSICAL_INJURY,
    TRAPPED_PSYCHOLOGICAL_INJURY,
    TREE,
    TRIP_ON_EQUIPMENT,
    WEIGHT_SNOW,
    WIND,
} = LpoClaimCauseEnums;
const {
    EMPLOYERS_LIABILITY,
    EXTENSION,
    FIDELITY_GUARANTEE,
    FIRE: FIRE_CLAIM_TYPE,
    IMPACT,
    LIBEL_SLANDER,
    NON_INJURY,
    OFFICIALS_INDEMNITY,
    OTHER: OTHER_CLAIM_TYPE,
    PUBLIC_LIABILITY,
    STORM_FLOOD,
    SUBSIDIENCE,
    WATER,
} = ClaimTypeEnums;
const { BUSINESS_INTERRUPTION, CONTRACT_WORKS, PROPERTY_DAMAGE } = TypeOfPropertyKeys;

/**
 * Utility logic
 */
export const createClaimCauseKey = (id: string) => {
    return `lpo.claimCause.select.${id}`;
};

export const getClaimCauseOption = (t: TFunction, key: LpoClaimCauseEnums) => {
    return {
        value: key,
        label: t(createClaimCauseKey(key)),
    };
};

export const getClaimCauseOptionList = (
    t: TFunction,
    claimType: ClaimTypeTypeModel,
    typeOfProperty: TypeOfPropertyTypeModel
) => {
    // Options
    const accidentalDamageOption = getClaimCauseOption(t, ACCIDENTAL_DAMAGE);
    const accidentalDamageGlassOption = getClaimCauseOption(t, ACCIDENTAL_DAMAGE_GLASS);
    const aircraftOption = getClaimCauseOption(t, AIRCRAFT);
    const allergicReactionOption = getClaimCauseOption(t, ALLERGIC_REACTION);
    const animalOption = getClaimCauseOption(t, ANIMAL);
    const arsonOption = getClaimCauseOption(t, ARSON);
    const asbestosExposureOption = getClaimCauseOption(t, ASBESTOS_EXPOSURE);
    const assaultOption = getClaimCauseOption(t, ASSAULT);
    const assaultColleagueOption = getClaimCauseOption(t, ASSAULT_COLLEAGUE);
    const assaultServiceUserOption = getClaimCauseOption(t, ASSAULT_SERVICE_USER);
    const carelessnessOption = getClaimCauseOption(t, CARELESSNESS);
    const carelessnessCigaretteOption = getClaimCauseOption(t, CARELESSNESS_CIGARETTE);
    const computerFraudOption = getClaimCauseOption(t, COMPUTER_FRAUD);
    const contactWithSharpsOption = getClaimCauseOption(t, CONTACT_WITH_SHARPS);
    const contractorNegligenceOption = getClaimCauseOption(t, CONTRACTOR_NEGLIGENCE);
    const crimeOption = getClaimCauseOption(t, CRIME);
    const customerAndSuppliersOption = getClaimCauseOption(t, CUSTOMER_SUPPLIERS);
    const defectiveEquipmentOption = getClaimCauseOption(t, DEFECTIVE_EQUIPMENT);
    const defectiveMachineryOption = getClaimCauseOption(t, DEFECTIVE_MACHINERY);
    const defectivePPEOption = getClaimCauseOption(t, DEFECTIVE_PPE);
    const defectivePremisesOption = getClaimCauseOption(t, DEFECTIVE_PREMISES);
    const denialOfAccessOption = getClaimCauseOption(t, DENIAL_OF_ACCESS);
    const diseaseOption = getClaimCauseOption(t, DISEASE);
    const earthquakeOption = getClaimCauseOption(t, EARTHQUAKE);
    const electionsOption = getClaimCauseOption(t, ELECTIONS);
    const electricalOption = getClaimCauseOption(t, ELECTRICAL);
    const escapeOfOilOption = getClaimCauseOption(t, ESCAPE_OIL);
    const escapeOfWaterOption = getClaimCauseOption(t, ESCAPE_WATER);
    const exposureToChemicalsOption = getClaimCauseOption(t, CHEMICALS);
    const exposureToExplosionOption = getClaimCauseOption(t, EXPLOSION);
    const exposureToFireOption = getClaimCauseOption(t, FIRE);
    const fallFromHeightOption = getClaimCauseOption(t, FALL);
    const fallingObjectOption = getClaimCauseOption(t, FALLING_OBJECT);
    const fallingTreeOption = getClaimCauseOption(t, FALLING_TREE);
    const fidelityOption = getClaimCauseOption(t, FIDELITY);
    const financialLossOption = getClaimCauseOption(t, FINANCIAL_LOSS);
    const floodOption = getClaimCauseOption(t, FLOOD);
    const floodCoastalOption = getClaimCauseOption(t, FLOOD_COASTAL);
    const floodRiverOption = getClaimCauseOption(t, FLOOD_RIVER);
    const frozenPipesOption = getClaimCauseOption(t, FROZEN_PIPES);
    const hailOption = getClaimCauseOption(t, HAIL);
    const heaveOption = getClaimCauseOption(t, HEAVE);
    const highwayOption = getClaimCauseOption(t, HIGHWAY);
    const horseplayOption = getClaimCauseOption(t, HORSEPLAY);
    const hotWorkOption = getClaimCauseOption(t, HOT_WORK);
    const injuryOption = getClaimCauseOption(t, INJURY);
    const injuryResultingFromRTAOption = getClaimCauseOption(t, INJURY_FROM_RTA);
    const injuryToCustomerDuringDeliveryOption = getClaimCauseOption(t, DELIVERY_INJURY);
    const lackOfMaintenanceOption = getClaimCauseOption(t, LACK_OF_MAINTENANCE);
    const landChargesOption = getClaimCauseOption(t, LAND_CHARGES);
    const landslipOption = getClaimCauseOption(t, LANDSLIP);
    const lightningOption = getClaimCauseOption(t, LIGHTNING);
    const libelOption = getClaimCauseOption(t, LIBEL);
    const maliciousDamageOption = getClaimCauseOption(t, MALICIOUS_DAMAGE);
    const manualHandlingOption = getClaimCauseOption(t, MANUAL_HANDLING);
    const noiseInducedHearingLossOption = getClaimCauseOption(t, NOISE_INDUCED_HEARING_LOSS);
    const otherOption = getClaimCauseOption(t, OTHER);
    const otherDiseaseOption = getClaimCauseOption(t, OTHER_DISEASE);
    const ownVehicleOption = getClaimCauseOption(t, OWN_VEHICLE);
    const physicalAbuseOption = getClaimCauseOption(t, PHYSICAL_ABUSE);
    const poorHousekeepingOption = getClaimCauseOption(t, POOR_HOUSEKEEPING);
    const publicHealthActOption = getClaimCauseOption(t, PUBLIC_HEALTH);
    const publicUtilitiesOption = getClaimCauseOption(t, PUBLIC_UTILITIES);
    const rainOption = getClaimCauseOption(t, RAIN);
    const riotOption = getClaimCauseOption(t, RIOT);
    const RSIOption = getClaimCauseOption(t, RSI);
    const sexualAbuseOption = getClaimCauseOption(t, SEXUAL_ABUSE);
    const sinkholeOption = getClaimCauseOption(t, SINKHOLE);
    const slanderOption = getClaimCauseOption(t, SLANDER);
    const slipOnFlooringOption = getClaimCauseOption(t, SLIP_ON_FLOOR);
    const slipOnIceOption = getClaimCauseOption(t, SLIP_ON_ICE);
    const slipOnWetFlooringOption = getClaimCauseOption(t, SLIP_ON_WET_FLOOR);
    const slipOnDebrisOption = getClaimCauseOption(t, SLIP_ON_DEBRIS);
    const slipOnStepsOption = getClaimCauseOption(t, SLIP_ON_STEPS);
    const smokeInhalationOption = getClaimCauseOption(t, SMOKE_INHALATION);
    const snowOption = getClaimCauseOption(t, SNOW);
    const sprinklerLeakageOption = getClaimCauseOption(t, SPRINKLER_LEAKAGE);
    const spontaneousCombustionOption = getClaimCauseOption(t, SPONTANEOUS_COMBUSTION);
    const spreadOfFireOption = getClaimCauseOption(t, SPREAD_FIRE);
    const stressOption = getClaimCauseOption(t, STRESS);
    const struckByObjectOption = getClaimCauseOption(t, STRUCK_BY_OBJECT);
    const struckByVehicleOption = getClaimCauseOption(t, STRUCK_BY_VEHICLE);
    const terrorismOption = getClaimCauseOption(t, TERRORISM);
    const theftOption = getClaimCauseOption(t, THEFT_AND_DAMAGE);
    const tpVehicleOption = getClaimCauseOption(t, TP_VEHICLE);
    const trappedPhysicalInjuryOption = getClaimCauseOption(t, TRAPPED_PHYSICAL_INJURY);
    const trappedPsychologicalInjuryOption = getClaimCauseOption(t, TRAPPED_PSYCHOLOGICAL_INJURY);
    const treeOption = getClaimCauseOption(t, TREE);
    const tripOnEquipmentOption = getClaimCauseOption(t, TRIP_ON_EQUIPMENT);
    const weightOfSnowOption = getClaimCauseOption(t, WEIGHT_SNOW);
    const windOption = getClaimCauseOption(t, WIND);

    let options = [financialLossOption, injuryOption];
    if (is(claimType, EMPLOYERS_LIABILITY)) {
        options = [
            allergicReactionOption,
            animalOption,
            asbestosExposureOption,
            assaultColleagueOption,
            assaultServiceUserOption,
            contactWithSharpsOption,
            defectiveEquipmentOption,
            defectiveMachineryOption,
            defectivePPEOption,
            defectivePremisesOption,
            exposureToChemicalsOption,
            exposureToExplosionOption,
            exposureToFireOption,
            fallFromHeightOption,
            highwayOption,
            horseplayOption,
            injuryResultingFromRTAOption,
            manualHandlingOption,
            noiseInducedHearingLossOption,
            otherOption,
            otherDiseaseOption,
            physicalAbuseOption,
            poorHousekeepingOption,
            RSIOption,
            sexualAbuseOption,
            slipOnFlooringOption,
            slipOnIceOption,
            slipOnWetFlooringOption,
            slipOnDebrisOption,
            slipOnStepsOption,
            smokeInhalationOption,
            stressOption,
            struckByVehicleOption,
            struckByObjectOption,
            trappedPhysicalInjuryOption,
            trappedPsychologicalInjuryOption,
            tripOnEquipmentOption,
        ];
    } else if (is(claimType, PUBLIC_LIABILITY)) {
        options = [
            allergicReactionOption,
            animalOption,
            assaultOption,
            contactWithSharpsOption,
            defectiveEquipmentOption,
            defectivePremisesOption,
            exposureToChemicalsOption,
            exposureToExplosionOption,
            exposureToFireOption,
            highwayOption,
            injuryToCustomerDuringDeliveryOption,
            otherOption,
            poorHousekeepingOption,
            slipOnFlooringOption,
            slipOnIceOption,
            slipOnWetFlooringOption,
            slipOnDebrisOption,
            slipOnStepsOption,
            smokeInhalationOption,
            stressOption,
            struckByVehicleOption,
            struckByObjectOption,
            trappedPhysicalInjuryOption,
            trappedPsychologicalInjuryOption,
            tripOnEquipmentOption,
        ];
    } else if (is(claimType, NON_INJURY)) {
        options = [
            contractorNegligenceOption,
            fallingObjectOption,
            fallingTreeOption,
            exposureToFireOption,
            lackOfMaintenanceOption,
            treeOption,
            otherOption,
        ];
    } else if (is(claimType, LIBEL_SLANDER)) {
        options = [libelOption, slanderOption];
    } else if (is(claimType, OFFICIALS_INDEMNITY)) {
        options = [electionsOption, landChargesOption, publicHealthActOption, otherOption];
    } else if (is(claimType, FIDELITY_GUARANTEE)) {
        options = [computerFraudOption, crimeOption, fidelityOption];
    } else if (is(typeOfProperty, BUSINESS_INTERRUPTION)) {
        if (is(claimType, WATER)) {
            options = [escapeOfWaterOption, frozenPipesOption, sprinklerLeakageOption];
        } else if (is(claimType, EXTENSION)) {
            options = [customerAndSuppliersOption, denialOfAccessOption, diseaseOption, publicUtilitiesOption];
        } else if (is(claimType, FIRE_CLAIM_TYPE)) {
            options = [
                arsonOption,
                carelessnessOption,
                carelessnessCigaretteOption,
                electricalOption,
                exposureToExplosionOption,
                hotWorkOption,
                lightningOption,
                spontaneousCombustionOption,
                spreadOfFireOption,
            ];
        } else if (is(claimType, IMPACT)) {
            options = [animalOption, ownVehicleOption, tpVehicleOption];
        } else if (is(claimType, OTHER_CLAIM_TYPE)) {
            options = [
                accidentalDamageOption,
                accidentalDamageGlassOption,
                aircraftOption,
                earthquakeOption,
                escapeOfOilOption,
                maliciousDamageOption,
                otherOption,
                riotOption,
                terrorismOption,
                theftOption,
            ];
        } else if (is(claimType, STORM_FLOOD)) {
            options = [
                floodCoastalOption,
                floodRiverOption,
                floodOption,
                hailOption,
                rainOption,
                snowOption,
                weightOfSnowOption,
                windOption,
            ];
        } else if (is(claimType, SUBSIDIENCE)) {
            options = [heaveOption, landslipOption, sinkholeOption];
        }
    } else if (is(typeOfProperty, CONTRACT_WORKS)) {
        if (is(claimType, WATER)) {
            options = [escapeOfWaterOption, frozenPipesOption, sprinklerLeakageOption];
        } else if (is(claimType, FIRE_CLAIM_TYPE)) {
            options = [
                arsonOption,
                carelessnessOption,
                carelessnessCigaretteOption,
                electricalOption,
                exposureToExplosionOption,
                hotWorkOption,
                lightningOption,
                spontaneousCombustionOption,
                spreadOfFireOption,
            ];
        } else if (is(claimType, IMPACT)) {
            options = [animalOption, ownVehicleOption, tpVehicleOption];
        } else if (is(claimType, OTHER_CLAIM_TYPE)) {
            options = [
                accidentalDamageOption,
                accidentalDamageGlassOption,
                aircraftOption,
                earthquakeOption,
                escapeOfOilOption,
                maliciousDamageOption,
                otherOption,
                riotOption,
                terrorismOption,
                theftOption,
            ];
        } else if (is(claimType, STORM_FLOOD)) {
            options = [
                floodCoastalOption,
                floodRiverOption,
                floodOption,
                hailOption,
                rainOption,
                snowOption,
                weightOfSnowOption,
                windOption,
            ];
        } else if (is(claimType, SUBSIDIENCE)) {
            options = [heaveOption, landslipOption, sinkholeOption];
        }
    } else if (is(typeOfProperty, PROPERTY_DAMAGE)) {
        if (is(claimType, WATER)) {
            options = [escapeOfWaterOption, frozenPipesOption, sprinklerLeakageOption];
        } else if (is(claimType, FIRE_CLAIM_TYPE)) {
            options = [
                arsonOption,
                carelessnessOption,
                carelessnessCigaretteOption,
                electricalOption,
                exposureToExplosionOption,
                hotWorkOption,
                lightningOption,
                spontaneousCombustionOption,
                spreadOfFireOption,
            ];
        } else if (is(claimType, IMPACT)) {
            options = [animalOption, ownVehicleOption, tpVehicleOption];
        } else if (is(claimType, OTHER_CLAIM_TYPE)) {
            options = [
                accidentalDamageOption,
                accidentalDamageGlassOption,
                aircraftOption,
                earthquakeOption,
                escapeOfOilOption,
                maliciousDamageOption,
                otherOption,
                riotOption,
                terrorismOption,
                theftOption,
            ];
        } else if (is(claimType, STORM_FLOOD)) {
            options = [
                floodCoastalOption,
                floodRiverOption,
                floodOption,
                hailOption,
                rainOption,
                snowOption,
                weightOfSnowOption,
                windOption,
            ];
        } else if (is(claimType, SUBSIDIENCE)) {
            options = [heaveOption, landslipOption, sinkholeOption];
        }
    }

    return options;
};
