import React, { useState } from 'react';
import {
    Changable,
    dtParse,
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    initDriverInformation,
    MuiDatePickerInput,
    MuiTextInput,
    Nullable,
    PageLayout,
    ReporterInformationModel,
    SingleCheckbox,
    YesNoKeys,
} from '@protectorinsurance/ds-can';
import { PhraseKeys } from '../../../config/phraseKeys';
import { FormFieldErrors, FormFieldNames } from '../../../config/formFieldNames';
import { MotorRoutePaths } from '../../../config/wizardRouter/motorWizardRoutes';
import { AutocompleteValueKeys } from '../../../config/AutocompleteValueKeys';
import {
    selectClaimDate,
    selectHasParkedWitnesses,
    selectIsDriving,
    selectReporterInformation,
} from '../../../sagas/selectors/motorSelectors';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { reporterInformationSchema } from '../../../validations/schemas/reporterInformationSchema';
import { reporterAndDriverInformationSchema } from '../../../validations/schemas/reporterAndDriverInformationSchema';
import { useI18n } from '../../../hooks/useI18n';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions } from '../../../sagas/wizardRouter';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../sagas/common';
import { motorActions } from '../../../sagas/motor';
import moment from 'moment';
import { crossContactInfoFieldValidation } from '../../../utils/validation/crossContactInfoFieldValidation';
import { useGoBack } from '../../../hooks/useGoBack';
import { getMomentLocale } from '../../../utils/getMomentLocale';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const {
    BACK_BUTTON,
    CONTINUE_BUTTON,
    EMAIL_LABEL,
    EMAIL_PLACEHOLDER,
    FIRST_NAME_LABEL,
    FIRST_NAME_PLACEHOLDER,
    HELP_TEXT,
    IS_DRIVER_LABEL,
    LAST_NAME_LABEL,
    LAST_NAME_PLACEHOLDER,
    DATE_OF_BIRTH_LABEL,
    PAGE_NAME,
    PHONE_LABEL,
    PHONE_PLACEHOLDER,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;
const { FIRST_NAME, LAST_NAME, DATE_OF_BIRTH, EMAIL, PHONE, IS_DRIVER } = FormFieldNames;
const { END_DRIVER_INFORMATION, END_WITNESS_INFORMATION } = MotorRoutePaths;
const { EMAIL: EMAIL_AUTOCOMPLETE, FAMILY_NAME, GIVEN_NAME, TEL } = AutocompleteValueKeys;
const { YES } = YesNoKeys;
const { MIN_REQUIRED_AGE } = FormFieldErrors;

/**
 * Interfaces
 */
interface ReporterFormModel extends Omit<ReporterInformationModel, 'dateOfBirth'> {
    dateOfBirth?: Nullable<Date>;
}

/**
 * Page view and page logic
 */
export const ReporterInformationPage = () => {
    const dispatch = useDispatch();
    const reporterInformation = useSelector(selectReporterInformation);
    const { dateOfBirth, email, firstName, isDriver, lastName, phone } = reporterInformation;
    const isDriving = useSelector(selectIsDriving);
    const hasParkedWitnesses = useSelector(selectHasParkedWitnesses);
    const claimDate = useSelector(selectClaimDate);
    const customCAN = useSelector(selectCustomCAN);
    const [dateError, setDateError] = useState<string>('');
    const { t } = useI18n();
    const tWithNS = useI18n('motor.end.reporterInformation');
    const schema = isDriver ? reporterAndDriverInformationSchema(t) : reporterInformationSchema(t);
    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger,
        watch,
    } = useForm<ReporterFormModel>({
        resolver: yupResolver(schema),
        defaultValues: {
            firstName,
            lastName,
            dateOfBirth: dateOfBirth ? dateOfBirth.toDate() : null,
            email,
            phone,
            isDriver,
        },
    });
    const date = watch(DATE_OF_BIRTH);

    const handleBackButton = useGoBack();

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        await setValue(id, value, { shouldValidate: true });
        await crossContactInfoFieldValidation(id, trigger);
    };

    const handleChecked = (e: Changable) => {
        const { checked } = e.target;
        setValue('isDriver', checked);
        dispatch(wizardRouterActions.skipBackToPrev(true));
        dispatcherWithPromise(dispatch, motorActions.update, {
            reporterInformation: { ...reporterInformation, isDriver: checked },
        }).then(() => {
            if (!checked) {
                dispatch(motorActions.update({ driverInformation: initDriverInformation }));
            }
        });
    };

    const handleDateChanged = async (date: Nullable<Date>) => {
        setValue('dateOfBirth', date, { shouldValidate: true });
        const minRequiredAgeToDrive = 16;

        if (date && claimDate) {
            const driverDate = dtParse(date);
            claimDate.startOf('day');
            driverDate.startOf('day');
            const yearDifference = claimDate.diff(driverDate, 'years', true);

            if (yearDifference < minRequiredAgeToDrive) {
                setDateError(t(MIN_REQUIRED_AGE));
            } else {
                setDateError('');
            }
        }
    };

    const onSubmit = (values: ReporterFormModel) => {
        if (!dateError) {
            let formValues = {
                reporterInformation: {
                    ...values,
                    isDriver,
                    dateOfBirth: values.dateOfBirth ? moment(values.dateOfBirth) : null,
                },
            };
            if (isDriver) {
                formValues = {
                    ...formValues,
                    ...{
                        driverInformation: {
                            ...values,
                            dateOfBirth: values.dateOfBirth ? moment(values.dateOfBirth) : null,
                        },
                    },
                };
            }
            isDriver && dispatch(wizardRouterActions.skipBackToPrev(true));

            if (isDriving === YES && !isDriver) {
                dispatcherWithPromise(dispatch, motorActions.update, formValues)
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardRouterActions.goTo(END_DRIVER_INFORMATION)));
            } else if (hasParkedWitnesses === YES) {
                dispatcherWithPromise(dispatch, motorActions.update, formValues)
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardRouterActions.goTo(END_WITNESS_INFORMATION)));
            } else {
                dispatcherWithPromise(dispatch, motorActions.update, formValues)
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardRouterActions.goToNext()));
            }
        }
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={handleSubmit(onSubmit)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <HiddenInputSubmit />
                <Grid className={'align-center'}>
                    {isDriving === YES && (
                        <SingleCheckbox
                            checked={isDriver}
                            handleChange={handleChecked}
                            name={IS_DRIVER}
                            wrapperClass={'col-12 multiple'}
                            {...{ customCAN }}
                        >
                            {t(IS_DRIVER_LABEL)}
                        </SingleCheckbox>
                    )}
                    <Controller
                        control={control}
                        name={FIRST_NAME}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={GIVEN_NAME}
                                error={!!errors.firstName}
                                errorMessage={errors.firstName?.message}
                                id={FIRST_NAME}
                                inputFieldWrapper={'col-6'}
                                label={t(FIRST_NAME_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(FIRST_NAME_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={LAST_NAME}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={FAMILY_NAME}
                                error={!!errors.lastName}
                                errorMessage={errors.lastName?.message}
                                id={LAST_NAME}
                                inputFieldWrapper={'col-6'}
                                label={t(LAST_NAME_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(LAST_NAME_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    {isDriver && (
                        <MuiDatePickerInput
                            disableFuture={true}
                            error={!!dateError}
                            errorMessage={dateError}
                            id={DATE_OF_BIRTH}
                            inputFieldWrapper={'col-4'}
                            label={t(DATE_OF_BIRTH_LABEL)}
                            locale={getMomentLocale()}
                            onChange={handleDateChanged}
                            value={date}
                            {...{ customCAN }}
                        />
                    )}
                    <Controller
                        control={control}
                        name={EMAIL}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={EMAIL_AUTOCOMPLETE}
                                error={!!errors.email}
                                errorMessage={errors.email?.message}
                                id={EMAIL}
                                inputFieldWrapper={isDriver ? 'col-4' : 'col-6'}
                                label={t(EMAIL_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(EMAIL_PLACEHOLDER)}
                                reference={ref}
                                type={'email'}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={PHONE}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={TEL}
                                error={!!errors.phone}
                                errorMessage={errors.phone?.message}
                                id={PHONE}
                                inputFieldWrapper={isDriver ? 'col-4' : 'col-6'}
                                inputMode={'tel'}
                                label={t(PHONE_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(PHONE_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                </Grid>
            </form>
        </PageLayout>
    );
};
