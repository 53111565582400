import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import {
    Clickable,
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    initVehicleModel,
    MotorClaimCauseKeys,
    MuiTextInput,
    Nullable,
    PageLayout,
    PartyRelationKeys,
    SecondaryButton,
    VehicleModel,
    WhoResponsibleClaimDescriptionModel,
} from '@protectorinsurance/ds-can';
import { useGoBack } from '../../../hooks/useGoBack';
import { useI18n } from '../../../hooks/useI18n';
import { PhraseKeys } from '../../../config/phraseKeys';
import { FormFieldNames } from '../../../config/formFieldNames';
import {
    selectClaimCause,
    selectIsCyclistResponsible,
    selectVehicles,
    selectWhoResponsibleClaimDescription,
} from '../../../sagas/selectors/motorSelectors';
import { Controller, useForm } from 'react-hook-form';
import { motorActions } from '../../../sagas/motor';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../sagas/common';
import { whoResponsibleClaimDescriptionSchema } from '../../../validations/schemas/whoResponsibleClaimDescriptionSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const {
    BACK_BUTTON,
    CLAIM_DESCRIPTION_LABEL,
    CLAIM_DESCRIPTION_PLACEHOLDER,
    CONTINUE_BUTTON,
    HELP_TEXT,
    PAGE_NAME,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;
const { WHO_RESPONSIBLE_CLAIM_DESCRIPTION } = FormFieldNames;
const { CLAIM_VEHICLE } = PartyRelationKeys;
const { CYCLIST_COLLISION } = MotorClaimCauseKeys;

/**
 * Page view and page logic
 */
export const EndVehicleWhoResponsiblePage = () => {
    const dispatch = useDispatch();
    const claimDescription = useSelector(selectWhoResponsibleClaimDescription);
    const vehicles = useSelector(selectVehicles);
    const claimCause = useSelector(selectClaimCause);
    const isCyclistResponsible = useSelector(selectIsCyclistResponsible);
    const customCAN = useSelector(selectCustomCAN);
    const responsibleVehicles = vehicles.filter((v: VehicleModel) => v.isResponsible);
    const notResponsibleVehicles = vehicles.filter((v: VehicleModel) => !v.isResponsible);
    const { t } = useI18n();
    const tWithNS = useI18n('motor.driving.whoResponsible');
    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger,
    } = useForm<WhoResponsibleClaimDescriptionModel>({
        resolver: yupResolver(whoResponsibleClaimDescriptionSchema(t, CLAIM_DESCRIPTION_LABEL)),
        defaultValues: {
            whoResponsibleClaimDescription: claimDescription,
        },
    });

    const handleBackButton = useGoBack();

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        await trigger(id);
        await setValue(id, value, { shouldValidate: true });
    };

    const onSubmit = ({ whoResponsibleClaimDescription }: WhoResponsibleClaimDescriptionModel, e?: FormChangeable) => {
        e?.preventDefault();
        dispatcherWithPromise(dispatch, motorActions.update, { whoResponsibleClaimDescription }).then(() =>
            dispatch(wizardActions.goToNext())
        );
    };

    const handleButtonAction = (internalId: Nullable<string>, e?: Clickable) => {
        e?.preventDefault();
        const vehiclesList = [...vehicles];
        const vehicle = vehicles.find((v: VehicleModel) => v.internalId === internalId);
        const vehicleIndex = vehicles.findIndex((v: VehicleModel) => v.internalId === internalId);
        vehiclesList[vehicleIndex] = {
            ...initVehicleModel,
            ...vehicle,
            isResponsible: !vehicle?.isResponsible,
        };
        dispatcherWithPromise(dispatch, motorActions.update, { vehicles: vehiclesList }).then(() =>
            dispatcherWithPromise(dispatch, commonActions.send)
        );
    };

    const handleCyclistButton = (e?: Clickable) => {
        e?.preventDefault();
        dispatcherWithPromise(dispatch, motorActions.update, { isCyclistResponsible: !isCyclistResponsible }).then(() =>
            dispatcherWithPromise(dispatch, commonActions.send)
        );
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={handleSubmit(onSubmit)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton }}
        >
            <Grid className={'align-center'}>
                <div className={'col-6 no-padding padding-right'}>
                    <Grid>
                        <div className={'col-12'}>
                            <h2 className={'sub-title'}>{tWithNS.t('notSelected.title')}</h2>
                        </div>
                        {notResponsibleVehicles.map((vehicle: VehicleModel, idx: number) => {
                            return (
                                <div className={'col-6 no-padding padding-top'} key={idx}>
                                    <p className={'overflow-paragraph'}>
                                        {vehicle.make}
                                        {vehicle.model}
                                    </p>
                                    <SecondaryButton
                                        ariaLabel={
                                            vehicle.registrationNumber
                                                ? vehicle.registrationNumber.toUpperCase()
                                                : t(
                                                      vehicle.partyRelation === CLAIM_VEHICLE
                                                          ? 'motor.driving.whoResponsible.select.CLAIM_VEHICLE'
                                                          : `motor.driving.unidentifiedType.select.${vehicle.unidentifiedType}`
                                                  )
                                        }
                                        className={'center-button'}
                                        onClick={() => handleButtonAction(vehicle.internalId)}
                                        value={
                                            vehicle.registrationNumber
                                                ? vehicle.registrationNumber.toUpperCase()
                                                : t(
                                                      vehicle.partyRelation === CLAIM_VEHICLE
                                                          ? 'motor.driving.whoResponsible.select.CLAIM_VEHICLE'
                                                          : `motor.driving.unidentifiedType.select.${vehicle.unidentifiedType}`
                                                  )
                                        }
                                        {...{ customCAN }}
                                    />
                                </div>
                            );
                        })}
                        {claimCause === CYCLIST_COLLISION && !isCyclistResponsible && (
                            <div className={'col-6 no-padding padding-top'}>
                                <SecondaryButton
                                    ariaLabel={tWithNS.t('text.cyclist').toUpperCase()}
                                    className={'center-button'}
                                    onClick={() => handleCyclistButton()}
                                    value={tWithNS.t('text.cyclist').toUpperCase()}
                                    {...{ customCAN }}
                                />
                            </div>
                        )}
                    </Grid>
                </div>
                <div className={'col-6 no-padding padding-right'}>
                    <Grid>
                        <div className={'col-12'}>
                            <h2 className={'sub-title'}>{tWithNS.t('selected.title')}</h2>
                        </div>
                        {responsibleVehicles.map((vehicle: VehicleModel, idx: number) => {
                            return (
                                <div className={'col-6 no-padding padding-top'} key={idx}>
                                    <p className={'overflow-paragraph'}>
                                        {vehicle.make}
                                        {vehicle.model}
                                    </p>
                                    <SecondaryButton
                                        ariaLabel={
                                            vehicle.registrationNumber
                                                ? vehicle.registrationNumber.toUpperCase()
                                                : t(
                                                      vehicle.partyRelation === CLAIM_VEHICLE
                                                          ? 'motor.driving.whoResponsible.select.CLAIM_VEHICLE'
                                                          : `motor.driving.unidentifiedType.select.${vehicle.unidentifiedType}`
                                                  )
                                        }
                                        className={'active-secondary center-button'}
                                        onClick={() => handleButtonAction(vehicle.internalId)}
                                        value={
                                            vehicle.registrationNumber
                                                ? vehicle.registrationNumber.toUpperCase()
                                                : t(
                                                      vehicle.partyRelation === CLAIM_VEHICLE
                                                          ? 'motor.driving.whoResponsible.select.CLAIM_VEHICLE'
                                                          : `motor.driving.unidentifiedType.select.${vehicle.unidentifiedType}`
                                                  )
                                        }
                                        {...{ customCAN }}
                                    />
                                </div>
                            );
                        })}
                        {claimCause === CYCLIST_COLLISION && isCyclistResponsible && (
                            <div className={'col-6 no-padding padding-top'}>
                                <SecondaryButton
                                    ariaLabel={tWithNS.t('text.cyclist').toUpperCase()}
                                    className={'center-button active-secondary'}
                                    onClick={() => handleCyclistButton()}
                                    testId={'cyclist-button'}
                                    value={tWithNS.t('text.cyclist').toUpperCase()}
                                    {...{ customCAN }}
                                />
                            </div>
                        )}
                    </Grid>
                </div>
            </Grid>
            <form onSubmit={handleSubmit(onSubmit)}>
                <HiddenInputSubmit />
                <Grid className={'align-center'}>
                    <Controller
                        control={control}
                        name={WHO_RESPONSIBLE_CLAIM_DESCRIPTION}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                error={!!errors.whoResponsibleClaimDescription}
                                errorMessage={errors.whoResponsibleClaimDescription?.message}
                                id={WHO_RESPONSIBLE_CLAIM_DESCRIPTION}
                                inputFieldWrapper={'col-12 padding-top'}
                                label={t(CLAIM_DESCRIPTION_LABEL)}
                                multiline={true}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(CLAIM_DESCRIPTION_PLACEHOLDER)}
                                reference={ref}
                                rows={10}
                                {...{ customCAN }}
                            />
                        )}
                    />
                </Grid>
            </form>
        </PageLayout>
    );
};
