import React from 'react';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    Clickable,
    NoIcon,
    YesIcon,
    YesNoKeys,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../utils/carouselPageLookup';
import { LpoRoutePaths } from '../../../wizardRouter/lpoWizardRoutes';
import { selectIsThirdPartyInvolved } from '../../../../sagas/selectors/lpoSelectors';
import { wizardRouterActions } from '../../../../sagas/wizardRouter';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { lpoActions } from '../../../../sagas/lpo';
import { commonActions } from '../../../../sagas/common';

/**
 * Destructure necessary imports
 */
const { YES, NO } = YesNoKeys;

/**
 * Page view and page logic
 */
export const liabilityIsThirdPartyInvolvedPage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<LpoRoutePaths> => {
    const options = [
        { id: YES, name: t(`lpo.liability.isThirdPartyInvolved.select.${YES}`), icon: <YesIcon /> },
        { id: NO, name: t(`lpo.liability.isThirdPartyInvolved.select.${NO}`), icon: <NoIcon /> },
    ];

    return {
        slug: LpoRoutePaths.DYN_CAROUSEL_LIABILITY_IS_THIRD_PARTY_INVOLVED,
        stateKey: 'isThirdPartyInvolved',
        i18n: {
            ns: 'lpo.liability.isThirdPartyInvolved',
        },
        selectorValue: selectIsThirdPartyInvolved(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                dispatcherWithPromise(dispatch, lpoActions.update, { isThirdPartyInvolved: option.id })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardRouterActions.goToNext(option.id)));
            }
        },
    };
};
