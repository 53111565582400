import React from 'react';
import { VehicleTypeKeys, Grid } from '@protectorinsurance/ds-can';
import { CarArialImage } from './car/CarArialImage';
import { CarRightImage } from './car/CarRightImage';
import { CarLeftImage } from './car/CarLeftImage';
import { LightTruckArialImage } from './lightTruck/LightTruckArialImage';
import { LightTruckRightImage } from './lightTruck/LightTruckRightImage';
import { LightTruckLeftImage } from './lightTruck/LightTruckLeftImage';
import { MCArialImage } from './MC/MCArialImage';
import { MCRightImage } from './MC/MCRightImage';
import { MCLeftImage } from './MC/MCLeftImage';
import { HeavyTruckArialImage } from './heavyTruck/HeavyTruckArialImage';
import { HeavyTruckRightImage } from './heavyTruck/HeavyTruckRightImage';
import { HeavyTruckLeftImage } from './heavyTruck/HeavyTruckLeftImage';
import { BusArialImage } from './bus/BusArialImage';
import { BusRightImage } from './bus/BusRightImage';
import { BusLeftImage } from './bus/BusLeftImage';
import { VehicleBodyDisplayProps } from './VehicleBodyDamages';
import './PrintableVehicleBodyDamages.scss';

interface PrintableVehicleBodyDamagesProps extends VehicleBodyDisplayProps {
    className?: string;
}
export const PrintableVehicleBodyDamages = (props: PrintableVehicleBodyDamagesProps) => {
    return <div className={`${props.className || ''}`}>{getVehicleBodySVG(props)}</div>;
};
export const getVehicleBodySVG = (props: PrintableVehicleBodyDamagesProps) => {
    const { vehicleType, tWithNs } = props;
    switch (vehicleType) {
        case VehicleTypeKeys.PRIVATE_CAR:
        case VehicleTypeKeys.CAR:
            return (
                <div className={'show-in-print padding-top padding-bottom car'}>
                    <Grid>
                        <div className={'col-4'}>
                            <CarArialImage {...props} title={tWithNs('image.car.title.top')} />
                        </div>
                        <div className={'col-8 no-padding justify-content-center'}>
                            <CarRightImage {...props} title={tWithNs('image.car.title.right')} />
                            <CarLeftImage {...props} title={tWithNs('image.car.title.left')} />
                        </div>
                    </Grid>
                </div>
            );
        case VehicleTypeKeys.MOPED:
        case VehicleTypeKeys.MOTORCYCLE:
            return (
                <div className={'show-in-print padding-top padding-bottom motorcycle'}>
                    <div className={'col-4'}>
                        <MCArialImage {...props} title={tWithNs('image.mc.title.top')} />
                    </div>
                    <div className={'col-8 no-padding'}>
                        <MCRightImage {...props} title={tWithNs('image.mc.title.right')} />
                        <MCLeftImage {...props} title={tWithNs('image.mc.title.left')} />
                    </div>
                </div>
            );
        case VehicleTypeKeys.LIGHT_COMMERCIAL_VEHICLE:
        case VehicleTypeKeys.LIGHT_TRUCK:
            return (
                <div className={'show-in-print padding-top padding-bottom light-truck'}>
                    <div className={'col-4'}>
                        <LightTruckArialImage {...props} title={tWithNs('image.lightTruck.title.top')} />
                    </div>
                    <div className={'col-8 no-padding'}>
                        <LightTruckRightImage {...props} title={tWithNs('image.lightTruck.title.right')} />
                        <LightTruckLeftImage {...props} title={tWithNs('image.lightTruck.title.left')} />
                    </div>
                </div>
            );
        case VehicleTypeKeys.COMMERCIAL_VEHICLE:
        case VehicleTypeKeys.TRUCK:
            return (
                <div className={'show-in-print padding-top padding-bottom truck'}>
                    <Grid>
                        <div className={'col-4'}>
                            <HeavyTruckArialImage {...props} title={tWithNs('image.heavyTruck.title.top')} />
                        </div>
                        <div className={'col-8 no-padding justify-content-center'}>
                            <HeavyTruckRightImage {...props} title={tWithNs('image.heavyTrick.title.right')} />
                            <HeavyTruckLeftImage {...props} title={tWithNs('image.heavyTruck.title.left')} />
                        </div>
                    </Grid>
                </div>
            );
        case VehicleTypeKeys.COACHES:
        case VehicleTypeKeys.BUS:
            return (
                <div className={'show-in-print padding-top padding-bottom bus'}>
                    <div className={'col-4'}>
                        <BusArialImage {...props} title={tWithNs('image.bus.title.top')} />
                    </div>
                    <div className={'col-8 no-padding'}>
                        <BusRightImage {...props} title={tWithNs('image.bus.title.right')} />
                        <BusLeftImage {...props} title={tWithNs('image.bus.title.left')} />
                    </div>
                </div>
            );
        default:
            return (
                <div className={'show-in-print padding-top padding-bottom car'}>
                    <div className={'col-4'}>
                        <CarArialImage {...props} title={tWithNs('image.car.title.top')} />
                    </div>
                    <div className={'col-8 no-padding'}>
                        <CarRightImage {...props} title={tWithNs('image.car.title.right')} />
                        <CarLeftImage {...props} title={tWithNs('image.car.title.left')} />
                    </div>
                </div>
            );
    }
};
