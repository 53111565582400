import React from 'react';
import { MotorRoutePaths } from '../../../config/wizardRouter/motorWizardRoutes';
import {
    FileList,
    findClaimVehicle,
    findVehicleWithUnidentifiedType,
    is,
    isYes,
    MotorClaimCauseKeys,
    SummaryItem,
    VehicleTypeKeys,
} from '@protectorinsurance/ds-can';
import { createTextYesNoDontKnow, driverInformationButtonText } from '../../../utils/displayUtils';
import { claimCauseSummary, createClaimCauseText } from '../../../utils/claimCauseUtils';
import { MotorSummaryProps } from '../../../types/SummaryProps';
import { TEST_ID_PREFIX } from '../../../utils/testIdUtil';
import { displayPerson } from '../../../utils/personUtils';
import { dtParseAndFormat } from '../../../utils/date/dateUtils';

/**
 * Destructure necessary imports
 */
const {
    DYN_CAROUSEL_PARKED_CLAIM_CAUSE,
    DYN_CAROUSEL_PARKED_OTHER_CLAIM_CAUSE_DETAILS,
    DYN_CAROUSEL_PARKED_PARKING_IS_COUNTERPARTY_KNOWN,
    DYN_CAROUSEL_PARKED_PARKING_UNIDENTIFIED_TYPE,
    DYN_CAROUSEL_PARKED_THEFT_AND_DAMAGES_HAS_ALL_KEYS,
    DYN_CAROUSEL_PARKED_THEFT_AND_DAMAGES_IS_ITEM_MISSING,
    DYN_CAROUSEL_PARKED_THEFT_AND_DAMAGES_IS_SELF_DISCOVERED_CLAIM,
    DYN_CAROUSEL_PARKED_THEFT_AND_DAMAGES_IS_VEHICLE_RECOVERED,
    DYN_CAROUSEL_PARKED_THEFT_AND_DAMAGES_IS_VEHICLE_STOLEN,
    PARKED_CLAIM_DISCOVERER_INFORMATION,
    PARKED_MISSING_ITEMS,
    PARKED_PARKING_DATE,
    PARKED_PARKING_INFORMATION,
    PARKED_UPLOAD_ATTACHMENTS,
    PARKED_UPLOAD_FIRE_REPORT,
    PARKED_RECOVERER_INFORMATION,
    PARKED_RECOVERY_DATE,
    PARKED_UPLOAD_RECEIPT,
} = MotorRoutePaths;
const { FIRE, PARKING, THEFT_AND_DAMAGE } = MotorClaimCauseKeys;
const { BUS, TRUCK } = VehicleTypeKeys;

/**
 * Page view and logic
 */
export const DisplayMotorParkedSummary = ({ model, upload, t, tWithNS }: MotorSummaryProps) => {
    /**
     * Helper constants/functions
     */
    const {
        claimCause,
        claimDiscovererInformation,
        driverInformation,
        hasAllKeys,
        isCounterpartyKnown,
        isItemMissing,
        isSelfDiscoveredClaim,
        isVehicleRecovered,
        isVehicleStolen,
        missingItems,
        parkingDate,
        recovererInformation,
        recoveryDate,
        vehicles,
    } = model;
    const { attachment, receipt } = upload;
    const claimVehicle = findClaimVehicle(vehicles);

    const renderClaimCause = () => {
        return (
            <>
                {/* Claim Cause */}
                <SummaryItem
                    textKey={tWithNS('claimCause.heading.title')}
                    dataTestId={TEST_ID_PREFIX.claimCause}
                    buttonText={t(`motor.parked.claimCause.select.${createClaimCauseText(claimCause)}`)}
                    path={DYN_CAROUSEL_PARKED_CLAIM_CAUSE}
                    inputWrapperClass={`${
                        claimCauseSummary(claimCause) ? 'spacing' : 'divider'
                    } col-12 text-align-left`}
                />

                {/* Claim Cause Details */}
                {claimCauseSummary(claimCause) && (
                    <SummaryItem
                        textKey={tWithNS('claimCauseDetails.heading.title')}
                        dataTestId={TEST_ID_PREFIX.claimCauseDetails}
                        buttonText={t(`motor.parked.claimCauseDetails.select.${claimCause}`)}
                        path={DYN_CAROUSEL_PARKED_OTHER_CLAIM_CAUSE_DETAILS}
                        inputWrapperClass={'col-12 divider text-align-left'}
                    />
                )}
            </>
        );
    };

    const renderTheftAndDamage = () => {
        const missingItemsList = missingItems.map((item) => t(`motor.parked.missingItems.select.${item}`));
        return (
            is(claimCause, THEFT_AND_DAMAGE) && (
                <>
                    {/* Is Self Discovered Claim */}
                    <SummaryItem
                        textKey={tWithNS('isSelfDiscoveredClaim.heading.title')}
                        dataTestId={TEST_ID_PREFIX.isSelfDiscoveredClaim}
                        buttonText={t(createTextYesNoDontKnow(isSelfDiscoveredClaim))}
                        path={DYN_CAROUSEL_PARKED_THEFT_AND_DAMAGES_IS_SELF_DISCOVERED_CLAIM}
                        inputWrapperClass={`${
                            isYes(isSelfDiscoveredClaim) ? 'spacing' : 'divider'
                        } col-12 text-align-left`}
                    />

                    {/* Claim Discoverer Information */}
                    {!isYes(isSelfDiscoveredClaim) && (
                        <SummaryItem
                            textKey={tWithNS('claimDiscovererInformation.heading.title')}
                            dataTestId={TEST_ID_PREFIX.claimDiscovererInformation}
                            buttonText={displayPerson(claimDiscovererInformation)}
                            path={PARKED_CLAIM_DISCOVERER_INFORMATION}
                            inputWrapperClass={'col-12 divider text-align-left'}
                        />
                    )}

                    {/* Is Vehicle Stolen */}
                    <SummaryItem
                        textKey={tWithNS('isVehicleStolen.heading.title')}
                        dataTestId={TEST_ID_PREFIX.isVehicleStolen}
                        buttonText={t(createTextYesNoDontKnow(isVehicleStolen))}
                        path={DYN_CAROUSEL_PARKED_THEFT_AND_DAMAGES_IS_VEHICLE_STOLEN}
                        inputWrapperClass={'col-12 divider text-align-left'}
                    />

                    {isYes(isVehicleStolen) && (
                        <>
                            {/* Parking Date */}
                            <SummaryItem
                                textKey={tWithNS('parkingDate.heading.title')}
                                dataTestId={TEST_ID_PREFIX.parkingDate}
                                buttonText={parkingDate ? dtParseAndFormat(parkingDate) : ''}
                                path={PARKED_PARKING_DATE}
                                inputWrapperClass={'col-12 spacing text-align-left'}
                            />

                            {/* Parking Information */}
                            <SummaryItem
                                textKey={tWithNS('parkingInformation.heading.title')}
                                dataTestId={TEST_ID_PREFIX.driverInformation}
                                buttonText={driverInformationButtonText(driverInformation)}
                                path={PARKED_PARKING_INFORMATION}
                                inputWrapperClass={'col-12 spacing text-align-left'}
                            />

                            {/* Has all keys */}
                            <SummaryItem
                                textKey={tWithNS('hasAllKeys.heading.title')}
                                dataTestId={TEST_ID_PREFIX.hasAllKeys}
                                buttonText={t(createTextYesNoDontKnow(hasAllKeys))}
                                path={DYN_CAROUSEL_PARKED_THEFT_AND_DAMAGES_HAS_ALL_KEYS}
                                inputWrapperClass={'col-12 divider text-align-left'}
                            />

                            {/* Is Vehicle Recovered */}
                            <SummaryItem
                                textKey={tWithNS('isVehicleRecovered.heading.title')}
                                dataTestId={TEST_ID_PREFIX.isVehicleRecovered}
                                buttonText={t(createTextYesNoDontKnow(isVehicleRecovered))}
                                path={DYN_CAROUSEL_PARKED_THEFT_AND_DAMAGES_IS_VEHICLE_RECOVERED}
                                inputWrapperClass={`${
                                    isYes(isVehicleRecovered) ? 'spacing' : 'divider'
                                } col-12 text-align-left`}
                            />

                            {isYes(isVehicleRecovered) && (
                                <>
                                    {/* Recovery Date */}
                                    <SummaryItem
                                        textKey={tWithNS('recoveryDate.heading.title')}
                                        dataTestId={TEST_ID_PREFIX.recoveryDate}
                                        buttonText={recoveryDate ? dtParseAndFormat(recoveryDate) : ''}
                                        path={PARKED_RECOVERY_DATE}
                                        inputWrapperClass={'col-12 spacing text-align-left'}
                                    />

                                    {/* Recoverer Information */}
                                    <SummaryItem
                                        textKey={tWithNS('recovererInformation.heading.title')}
                                        dataTestId={TEST_ID_PREFIX.recovererInformation}
                                        buttonText={displayPerson(recovererInformation)}
                                        path={PARKED_RECOVERER_INFORMATION}
                                        inputWrapperClass={'col-12 divider text-align-left'}
                                    />
                                </>
                            )}
                        </>
                    )}

                    {/* Items Missing */}
                    {!isYes(isVehicleStolen) && (
                        <SummaryItem
                            textKey={tWithNS('isItemMissing.heading.title')}
                            dataTestId={TEST_ID_PREFIX.isItemMissing}
                            buttonText={t(createTextYesNoDontKnow(isItemMissing))}
                            path={DYN_CAROUSEL_PARKED_THEFT_AND_DAMAGES_IS_ITEM_MISSING}
                            inputWrapperClass={`${isYes(isItemMissing) ? 'spacing' : 'divider'} col-12 text-align-left`}
                        />
                    )}

                    {/* Missing Items */}
                    {isYes(isItemMissing) && (
                        <>
                            <SummaryItem
                                textKey={tWithNS('missingItems.heading.title')}
                                dataTestId={TEST_ID_PREFIX.missingItems}
                                buttonText={missingItemsList.join(', ')}
                                path={PARKED_MISSING_ITEMS}
                                inputWrapperClass={'col-12 spacing text-align-left'}
                            />
                            {/* Upload Receipt */}
                            <div className={'col-12 divider text-align-left'}>
                                <FileList
                                    list={receipt.files}
                                    path={PARKED_UPLOAD_RECEIPT}
                                    show={receipt.files.length > 0}
                                    dataTestId={TEST_ID_PREFIX.uploadReceipt}
                                    textKey={`${tWithNS('uploadReceipt.heading.title')}`}
                                />
                            </div>
                        </>
                    )}
                </>
            )
        );
    };

    const renderFire = () => {
        return (
            is(claimCause, FIRE) && (
                <>
                    {/* Upload Fire Report */}
                    <div
                        className={`${
                            claimVehicle &&
                            (is(claimVehicle.vehicleType, BUS) ||
                                is(claimVehicle.vehicleType, TRUCK) ||
                                (claimVehicle.weight && claimVehicle.weight > 3500))
                                ? 'spacing'
                                : 'divider'
                        } col-12 text-align-left`}
                    >
                        <FileList
                            list={attachment.files}
                            path={PARKED_UPLOAD_FIRE_REPORT}
                            show={attachment.files.length > 0}
                            dataTestId={TEST_ID_PREFIX.uploadAttachments}
                            textKey={`${tWithNS('uploadFireReport.heading.title')}`}
                        />
                    </div>
                </>
            )
        );
    };

    const renderParking = () => {
        const unidentifiedType = findVehicleWithUnidentifiedType(vehicles)?.unidentifiedType;
        return (
            is(claimCause, PARKING) && (
                <>
                    {/* Is Counterparty Known */}
                    <SummaryItem
                        textKey={tWithNS('isCounterpartyKnown.heading.title')}
                        dataTestId={TEST_ID_PREFIX.isCounterpartyKnown}
                        buttonText={t(createTextYesNoDontKnow(isCounterpartyKnown))}
                        path={DYN_CAROUSEL_PARKED_PARKING_IS_COUNTERPARTY_KNOWN}
                        inputWrapperClass={`${
                            isYes(isCounterpartyKnown) ? 'spacing' : 'divider'
                        } col-12 text-align-left`}
                    />

                    {isYes(isCounterpartyKnown) && (
                        <>
                            {/*Counterparty Unidentified type */}
                            {!!unidentifiedType && (
                                <SummaryItem
                                    textKey={tWithNS('unidentifiedType.heading.title')}
                                    dataTestId={TEST_ID_PREFIX.unidentifiedType}
                                    buttonText={t(`motor.parked.unidentifiedType.select.${unidentifiedType}`)}
                                    path={DYN_CAROUSEL_PARKED_PARKING_UNIDENTIFIED_TYPE}
                                    inputWrapperClass={'col-12 divider text-align-left'}
                                />
                            )}
                        </>
                    )}

                    {!isYes(isCounterpartyKnown) && (
                        <>
                            {/* Upload */}
                            <div className={'col-12 divider text-align-left'}>
                                <FileList
                                    list={attachment.files}
                                    textKey={`${tWithNS('uploadAttachments.heading.title')}`}
                                    dataTestId={TEST_ID_PREFIX.uploadAttachments}
                                    path={PARKED_UPLOAD_ATTACHMENTS}
                                    show={attachment.files.length > 0}
                                />
                            </div>
                        </>
                    )}
                </>
            )
        );
    };

    return (
        <>
            {renderClaimCause()}

            {renderTheftAndDamage()}

            {renderFire()}

            {renderParking()}
        </>
    );
};
