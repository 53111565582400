import {
    CountryCodeISOEnums,
    LanguageCodeEnums,
    LocaleServiceInstance,
    MomentLocaleEnums,
} from '@protectorinsurance/ds-can';

LocaleServiceInstance.setCountry(CountryCodeISOEnums.UK)
    .setLanguage(LanguageCodeEnums.EN)
    .setMoment(MomentLocaleEnums.EN)
    .initialize();
