import { useDispatch, useSelector } from 'react-redux';
import { selectWizardRouterHistoryListener } from '../sagas/selectors/wizardRouterSelectors';
import { useHistory } from 'react-router-dom';
import { useCallback } from 'react';
import { wizardRouterActions } from '../sagas/wizardRouter';
import { HistoryListener } from '@protectorinsurance/ds-can';

export const useHistoryListen = () => {
    const dispatch = useDispatch();
    const listener = useSelector(selectWizardRouterHistoryListener);
    const history = useHistory();
    const listen = useCallback(
        (cb: HistoryListener) => {
            if (!listener) {
                dispatch(wizardRouterActions.update({ listener: history.listen(cb) }));
            }
        },
        [listener, dispatch, history]
    );

    const unlisten = useCallback(() => {
        if (listener) {
            listener();
            dispatch(wizardRouterActions.update({ listener: null }));
        }
    }, [listener, dispatch]);

    return { listen, unlisten };
};
