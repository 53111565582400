import { TFunction } from 'i18next';
import { PropertyDamageEnums } from '@protectorinsurance/ds-can';

/**
 * Utility logic
 */
export const createPropertyDamageKey = (id: string) => {
    return `lpo.propertyDamage.select.${id}`;
};

export const getPropertyDamageOption = (t: TFunction, key: PropertyDamageEnums, value: string) => {
    return {
        value,
        label: t(createPropertyDamageKey(key)),
    };
};
