import * as Yup from 'yup';
import { PhraseKeys } from '../../config/phraseKeys';
import { TFunction } from 'i18next';

/**
 * Destructure necessary imports
 */
const { DATE_OF_BIRTH_LABEL } = PhraseKeys;

/**
 * Validation logic
 */
export const dateOfBirthSchema = (t: TFunction) => {
    return {
        dateOfBirth: Yup.date().label(t(DATE_OF_BIRTH_LABEL)).nullable(),
    };
};
