import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { FormFieldErrors, MAX_LENGTH_250 } from '../../config/formFieldNames';
import { ZIP_REGEX } from '../formats/stringFormats';
import { PhraseKeys } from '../../config/phraseKeys';
import { PHONE_REGEX } from '../../utils/validation/stringFormats';

/**
 * Destructure necessary imports
 */
const { MAX_LENGTH_ERROR_MESSAGE, EMAIL_ERROR_MESSAGE, FIELD_ERROR, INVALID_ZIP_CODE } = FormFieldErrors;
const { COMPANY_NAME_LABEL, COMPANY_BUSINESS_NUMBER_LABEL } = PhraseKeys;
const { STREET_LABEL, CITY_LABEL, ZIP_LABEL, EMAIL_LABEL, PHONE_LABEL, DAMAGED_ITEM_LABEL } = PhraseKeys;
const { FIRST_NAME_LABEL, LAST_NAME_LABEL } = PhraseKeys;

export const isCompany = (isCompany: boolean, schema: Yup.StringSchema) =>
    isCompany ? schema.required() : schema.notRequired();
export const isBusinessNumberRequired = (isCompany: boolean, schema: Yup.StringSchema) =>
    isCompany ? schema.required().businessNumber(true) : schema.notRequired();
export const isNotCompany = (isCompany: boolean, schema: Yup.StringSchema) =>
    isCompany ? schema.notRequired() : schema.required();

export const ownerInformationListSchema = (t: TFunction) => {
    return Yup.object().shape({
        // COMMON
        isCompany: Yup.boolean(),
        email: Yup.string()
            .label(t(EMAIL_LABEL))
            .email(t(EMAIL_ERROR_MESSAGE))
            .secureEndingEmail(t(EMAIL_ERROR_MESSAGE))
            .onlyAscii()
            .nullable(),
        phone: Yup.string()
            .label(t(PHONE_LABEL))
            .matches(PHONE_REGEX, {
                message: t(FIELD_ERROR),
                excludeEmptyString: true,
            })
            .nullable(),

        // COMPANY
        name: Yup.string().label(t(COMPANY_NAME_LABEL)).noSpecialChars().when('isCompany', isCompany).nullable(),
        businessNumber: Yup.string().label(t(COMPANY_BUSINESS_NUMBER_LABEL)).businessNumber(false).nullable(),
        // PERSON
        firstName: Yup.string()
            .label(t(FIRST_NAME_LABEL))
            .when('isCompany', isNotCompany)
            .max(
                MAX_LENGTH_250,
                t(MAX_LENGTH_ERROR_MESSAGE, {
                    field: t(FIRST_NAME_LABEL),
                    max: MAX_LENGTH_250,
                })
            )
            .noSpecialChars()
            .nullable(),
        lastName: Yup.string()
            .label(t(LAST_NAME_LABEL))
            .when('isCompany', isNotCompany)
            .max(
                MAX_LENGTH_250,
                t(MAX_LENGTH_ERROR_MESSAGE, {
                    field: t(LAST_NAME_LABEL),
                    max: MAX_LENGTH_250,
                })
            )
            .noSpecialChars()
            .nullable(),
        street: Yup.string()
            .label(t(STREET_LABEL))
            .noSpecialChars()
            .max(
                MAX_LENGTH_250,
                t(MAX_LENGTH_ERROR_MESSAGE, {
                    field: t(STREET_LABEL),
                    max: MAX_LENGTH_250,
                })
            )
            .nullable(),
        city: Yup.string()
            .label(t(CITY_LABEL))
            .noSpecialChars()
            .max(
                MAX_LENGTH_250,
                t(MAX_LENGTH_ERROR_MESSAGE, {
                    field: t(CITY_LABEL),
                    max: MAX_LENGTH_250,
                })
            )
            .nullable(),
        zip: Yup.string()
            .label(t(ZIP_LABEL))
            .matches(ZIP_REGEX, {
                message: t(INVALID_ZIP_CODE),
                excludeEmptyString: true,
            })
            .nullable(),
        damagedItem: Yup.string()
            .label(t(DAMAGED_ITEM_LABEL))
            .max(
                MAX_LENGTH_250,
                t(MAX_LENGTH_ERROR_MESSAGE, {
                    field: t(FIRST_NAME_LABEL),
                    max: MAX_LENGTH_250,
                })
            )
            .noSpecialChars()
            .nullable(),
    });
};
