import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    ClaimTypeEnums,
    Clickable,
    FormChangeable,
    Grid,
    is,
    MuiAutocomplete,
    OptionType,
    PageLayout,
    TypeOfPropertyKeys,
} from '@protectorinsurance/ds-can';
import { selectClaimType, selectTypeOfProperty } from '../../../sagas/selectors/lpoSelectors';
import { useI18n } from '../../../hooks/useI18n';
import { PhraseKeys } from '../../../config/phraseKeys';
import { useGoBack } from '../../../hooks/useGoBack';
import { FormFieldErrors, FormFieldNames } from '../../../config/formFieldNames';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { lpoActions } from '../../../sagas/lpo';
import { wizardRouterActions } from '../../../sagas/wizardRouter';
import { commonActions } from '../../../sagas/common';
import { getClaimTypeOption } from '../../../utils/lpo/claimTypeUtils';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const {
    BACK_BUTTON,
    CLAIM_TYPE_LABEL,
    CLAIM_TYPE_PLACEHOLDER,
    CONTINUE_BUTTON,
    HELP_TEXT,
    NO_OPTIONS_MESSAGE,
    PAGE_NAME,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;
const { REQUIRED_ERROR_MESSAGE } = FormFieldErrors;
const { CLAIM_TYPE } = FormFieldNames;
const { EXTENSION, FIRE, IMPACT, OTHER, STORM_FLOOD, SUBSIDIENCE, WATER } = ClaimTypeEnums;
const { CONTRACT_WORKS, PROPERTY_DAMAGE } = TypeOfPropertyKeys;

/**
 * Page view and page logic
 */
export const PropertyClaimTypePage = () => {
    const dispatch = useDispatch();
    const [type, setType] = useState<OptionType | null>(null);
    const [error, setError] = useState<string>('');
    const claimType = useSelector(selectClaimType);
    const typeOfProperty = useSelector(selectTypeOfProperty);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('lpo.property.claimType');

    // Options
    const escapeOfWaterOption = getClaimTypeOption(t, WATER);
    const extensionOption = getClaimTypeOption(t, EXTENSION);
    const fireOption = getClaimTypeOption(t, FIRE);
    const impactOption = getClaimTypeOption(t, IMPACT);
    const otherOption = getClaimTypeOption(t, OTHER);
    const stormAndFloodOption = getClaimTypeOption(t, STORM_FLOOD);
    const subsidienceOption = getClaimTypeOption(t, SUBSIDIENCE);

    let options = [
        escapeOfWaterOption,
        extensionOption,
        fireOption,
        impactOption,
        otherOption,
        stormAndFloodOption,
        subsidienceOption,
    ];

    if (is(typeOfProperty, CONTRACT_WORKS) || is(typeOfProperty, PROPERTY_DAMAGE)) {
        options = [escapeOfWaterOption, fireOption, impactOption, otherOption, stormAndFloodOption, subsidienceOption];
    }

    useEffect(() => {
        const selected = options.find((x) => x.value === claimType);
        if (selected) {
            setType(selected);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [claimType, setType]);

    const handleBackButton = useGoBack();

    const handleBlur = (e: FormChangeable) => {
        e.preventDefault();
        if (is(claimType, null)) {
            setError(t(REQUIRED_ERROR_MESSAGE));
        } else {
            setError('');
        }
    };

    const handleSelect = (e: SyntheticEvent, option: OptionType) => {
        const object = option ? (option as OptionType) : null;
        const value = option ? option.value : null;
        setError('');
        setType(object);
        dispatch(lpoActions.update({ claimType: value }));
    };

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        if (is(claimType, null)) {
            setError(t(REQUIRED_ERROR_MESSAGE));
        } else {
            setError('');
            dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(wizardRouterActions.goToNext()));
        }
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            <Grid className={'align-center'}>
                <MuiAutocomplete
                    error={!!error}
                    errorMessage={error}
                    id={CLAIM_TYPE}
                    inputFieldWrapper={'col-12'}
                    label={t(CLAIM_TYPE_LABEL)}
                    noOptionsText={t(NO_OPTIONS_MESSAGE)}
                    onBlur={handleBlur}
                    onChange={handleSelect}
                    openOnFocus={true}
                    placeholder={t(CLAIM_TYPE_PLACEHOLDER)}
                    {...{ customCAN, options }}
                    value={type}
                />
            </Grid>
        </PageLayout>
    );
};
