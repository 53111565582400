import React from 'react';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    Clickable,
    HeadOnCollisionIcon,
    is,
    LaneChangeIcon,
    MotorClaimCauseKeys,
    ParkedIcon,
    RearEndedIcon,
    ReversingIcon,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../../../utils/carouselPageLookup';
import { MotorRoutePaths } from '../../../../../wizardRouter/motorWizardRoutes';
import { selectClaimCause } from '../../../../../../sagas/selectors/motorSelectors';
import { wizardRouterActions as wizardActions } from '../../../../../../sagas/wizardRouter';
import dispatcherWithPromise from '../../../../../../utils/dispatcherWithPromise';
import { motorActions } from '../../../../../../sagas/motor';
import { commonActions } from '../../../../../../sagas/common';
import { flowActions } from '../../../../../../sagas/flow';

/**
 * Destructure necessary imports
 */
const { CYCLIST_COLLISION, HEAD_ON, LANE_CHANGE, PARKED, REAR_ENDED, REVERSING } = MotorClaimCauseKeys;

/**
 * Page view and page logic
 */
export const drivingVehiclesParkingOtherClaimCausePage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<MotorRoutePaths> => {
    const claimCause = selectClaimCause(state);
    const options = [
        {
            id: REAR_ENDED,
            name: t(`motor.driving.parkingOtherClaimCause.select.${REAR_ENDED}`),
            icon: <RearEndedIcon />,
        },
        {
            id: HEAD_ON,
            name: t(`motor.driving.parkingOtherClaimCause.select.${HEAD_ON}`),
            icon: <HeadOnCollisionIcon />,
        },
        { id: PARKED, name: t(`motor.driving.parkingOtherClaimCause.select.${PARKED}`), icon: <ParkedIcon /> },
        {
            id: LANE_CHANGE,
            name: t(`motor.driving.parkingOtherClaimCause.select.${LANE_CHANGE}`),
            icon: <LaneChangeIcon />,
        },
        { id: REVERSING, name: t(`motor.driving.parkingOtherClaimCause.select.${REVERSING}`), icon: <ReversingIcon /> },
    ];

    return {
        slug: MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_PARKING_OTHER_CLAIM_CAUSE,
        stateKey: 'claimCause',
        i18n: {
            ns: 'motor.driving.parkingOtherClaimCause',
        },
        selectorValue: selectClaimCause(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                if (!is(option.id, claimCause)) {
                    dispatch(wizardActions.skipBackToPrev(true));
                }

                if (!is(claimCause, CYCLIST_COLLISION)) {
                    dispatcherWithPromise(dispatch, motorActions.update, { claimCause: option.id });
                }

                dispatcherWithPromise(dispatch, flowActions.update, option.id)
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext(option.id)));
            }
        },
    };
};
