import { TFunction } from 'i18next';
import { RoleEnums } from '@protectorinsurance/ds-can';

/**
 * Utility logic
 */
export const createRoleKey = (id: string) => {
    return `lpo.role.select.${id}`;
};

export const getRoleOption = (t: TFunction, key: RoleEnums, value: string) => {
    return {
        value,
        label: t(createRoleKey(key)),
    };
};
