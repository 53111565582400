import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { optionalNameSchema } from '../fieldSchemas/optionalNameSchema';
import { optionalContactDetailsSchema } from '../fieldSchemas/optionalContactDetailsSchema';

export const personSchema = (t: TFunction) => {
    return Yup.object().shape({
        ...optionalNameSchema(t),
        ...optionalContactDetailsSchema(t),
    });
};
