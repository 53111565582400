import React from 'react';
import { LpoRoutePaths } from '../../../config/wizardRouter/lpoWizardRoutes';
import { LpoSummaryProps } from '../../../types/SummaryProps';
import { TEST_ID_PREFIX } from '../../../utils/testIdUtil';
import { displayPerson, isYes } from '@protectorinsurance/ds-can';
import { createTextYesNoDontKnow } from '../../../utils/displayUtils';
import { renderReporterInformation } from './components/end/RenderReporterInformation';
import { renderAttachment } from './components/end/RenderAttachment';
import { renderPolicyHoldersContact } from './components/end/RenderPolicyHoldersContact';
import { renderHasOtherInsurance } from './components/end/RenderHasOtherInsurance';
import { renderOtherInsurance } from './components/end/RenderOtherInsurance';
import { renderIsThirdPartyInvolved } from './components/end/RenderIsThirdPartyInvolved';
import classNames from 'classnames';
import { renderCrimeReference } from './components/end/RenderCrimeReference';
import { renderConstabulary } from './components/end/RenderConstabulary';
import { renderIsLeakFixed } from './components/end/RenderIsLeakFixed';
import { renderIsPropertyOccupied } from './components/end/RenderIsPropertyOccupied';
import { renderIsPropertyTenanted } from './components/end/RenderIsPropertyTenanted';
import { renderHasAccessToWater } from './components/end/RenderHasAccessToWater';
import { renderHasAccessToElectricity } from './components/end/RenderHasAccessToElectricity';
import { renderHasAccessToGas } from './components/end/RenderHasAccessToGas';
import { renderHasExistingClaim } from './components/end/RenderHasExistingClaim';
import { renderPropertyClaimDescription } from './components/end/RenderPropertyClaimDescription';
import { renderThirdPartyInformation } from './components/end/RenderThirdPartyInformation';

/**
 * Destructure necessary imports
 */
const {
    DYN_CAROUSEL_PROPERTY_HAS_ACCESS_TO_ELECTRICITY,
    DYN_CAROUSEL_PROPERTY_HAS_ACCESS_TO_GAS,
    DYN_CAROUSEL_PROPERTY_HAS_ACCESS_TO_WATER,
    DYN_CAROUSEL_PROPERTY_HAS_EXISTING_CLAIM,
    DYN_CAROUSEL_END_HAS_OTHER_INSURANCE,
    DYN_CAROUSEL_PROPERTY_IS_LEAK_FIXED,
    DYN_CAROUSEL_PROPERTY_IS_PROPERTY_OCCUPIED,
    DYN_CAROUSEL_PROPERTY_IS_PROPERTY_TENANTED,
    DYN_CAROUSEL_PROPERTY_IS_THIRD_PARTY_INVOLVED,
    END_CRIME_REFERENCE,
    END_OTHER_INSURANCE_COMPANY,
    END_POLICY_HOLDERS_CONTACT,
    END_REPORTER_INFORMATION,
    END_UPLOAD_ATTACHMENT,
    PROPERTY_PROPERTY_CLAIM_DESCRIPTION,
    PROPERTY_THIRD_PARTY_INFORMATION,
} = LpoRoutePaths;

/**
 * Component view and component logic
 */
export const DisplayEndSummary = ({ model, t, tWithNS, handleClick, upload, common }: LpoSummaryProps) => {
    const {
        claimCause,
        constabulary,
        crimeReference,
        hasAccessToElectricity,
        hasAccessToGas,
        hasAccessToWater,
        hasExistingClaim,
        isLeakFixed,
        isPropertyOccupied,
        isPropertyTenanted,
        isThirdPartyInvolved,
        otherInsurance,
        otherInsuranceCompany,
        policyHoldersContact,
        propertyClaimDescription,
        reporterInformation,
        thirdPartyInformation,
    } = model;
    const { attachment } = upload;
    const { lob } = common;

    // Classes
    const hasOtherInsuranceClass = classNames(
        {
            'col-4': isYes(otherInsurance),
            'col-12': !isYes(otherInsurance),
        },
        'divider text-align-left'
    );
    const hasCrimeReferenceClass = classNames(
        {
            'col-6': crimeReference,
            'col-12': !crimeReference,
        },
        'divider text-align-left'
    );

    return (
        <>
            {renderIsLeakFixed(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('isLeakFixed.heading.title'),
                    dataTestId: TEST_ID_PREFIX.isLeakFixed,
                    buttonText: t(createTextYesNoDontKnow(isLeakFixed)),
                    path: DYN_CAROUSEL_PROPERTY_IS_LEAK_FIXED,
                    handleClick,
                },
                lob,
                claimCause
            )}

            {renderIsPropertyOccupied(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('isPropertyOccupied.heading.title'),
                    dataTestId: TEST_ID_PREFIX.isPropertyOccupied,
                    buttonText: t(createTextYesNoDontKnow(isPropertyOccupied)),
                    path: DYN_CAROUSEL_PROPERTY_IS_PROPERTY_OCCUPIED,
                    handleClick,
                },
                lob
            )}

            {renderIsPropertyTenanted(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('isPropertyTenanted.heading.title'),
                    dataTestId: TEST_ID_PREFIX.isPropertyTenanted,
                    buttonText: t(createTextYesNoDontKnow(isPropertyTenanted)),
                    path: DYN_CAROUSEL_PROPERTY_IS_PROPERTY_TENANTED,
                    handleClick,
                },
                lob,
                isPropertyOccupied
            )}

            {renderHasAccessToWater(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('hasAccessToWater.heading.title'),
                    dataTestId: TEST_ID_PREFIX.hasAccessToWater,
                    buttonText: t(createTextYesNoDontKnow(hasAccessToWater)),
                    path: DYN_CAROUSEL_PROPERTY_HAS_ACCESS_TO_WATER,
                    handleClick,
                },
                lob
            )}

            {renderHasAccessToElectricity(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('hasAccessToElectricity.heading.title'),
                    dataTestId: TEST_ID_PREFIX.hasAccessToElectricity,
                    buttonText: t(createTextYesNoDontKnow(hasAccessToElectricity)),
                    path: DYN_CAROUSEL_PROPERTY_HAS_ACCESS_TO_ELECTRICITY,
                    handleClick,
                },
                lob
            )}

            {renderHasAccessToGas(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('hasAccessToGas.heading.title'),
                    dataTestId: TEST_ID_PREFIX.hasAccessToGas,
                    buttonText: t(createTextYesNoDontKnow(hasAccessToGas)),
                    path: DYN_CAROUSEL_PROPERTY_HAS_ACCESS_TO_GAS,
                    handleClick,
                },
                lob
            )}

            {renderHasExistingClaim(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('hasExistingClaim.heading.title'),
                    dataTestId: TEST_ID_PREFIX.hasExistingClaim,
                    buttonText: t(createTextYesNoDontKnow(hasExistingClaim)),
                    path: DYN_CAROUSEL_PROPERTY_HAS_EXISTING_CLAIM,
                    handleClick,
                },
                lob
            )}

            {renderPropertyClaimDescription(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('propertyClaimDescription.heading.title'),
                    dataTestId: TEST_ID_PREFIX.propertyClaimDescription,
                    buttonText: propertyClaimDescription ?? '-',
                    path: PROPERTY_PROPERTY_CLAIM_DESCRIPTION,
                    handleClick,
                },
                lob,
                hasExistingClaim
            )}

            {renderIsThirdPartyInvolved(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('isThirdPartyInvolved.heading.title'),
                    dataTestId: TEST_ID_PREFIX.isThirdPartyInvolved,
                    buttonText: t(createTextYesNoDontKnow(isThirdPartyInvolved)),
                    path: DYN_CAROUSEL_PROPERTY_IS_THIRD_PARTY_INVOLVED,
                    handleClick,
                },
                lob
            )}

            {renderThirdPartyInformation(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('thirdPartyInformation.heading.title'),
                    dataTestId: TEST_ID_PREFIX.thirdPartyInformation,
                    buttonText: '-',
                    path: PROPERTY_THIRD_PARTY_INFORMATION,
                    handleClick,
                },
                lob,
                isThirdPartyInvolved,
                thirdPartyInformation
            )}

            {renderCrimeReference(
                {
                    inputWrapperClass: hasCrimeReferenceClass,
                    textKey: tWithNS('crimeReference.heading.title'),
                    dataTestId: TEST_ID_PREFIX.crimeReference,
                    buttonText: crimeReference ?? '-',
                    path: END_CRIME_REFERENCE,
                    handleClick,
                },
                lob
            )}

            {renderConstabulary(
                {
                    inputWrapperClass: 'col-6 divider text-align-left',
                    textKey: tWithNS('constabulary.heading.title'),
                    dataTestId: TEST_ID_PREFIX.constabulary,
                    buttonText: constabulary && constabulary.key ? constabulary.key : '-',
                    path: END_CRIME_REFERENCE,
                    handleClick,
                },
                crimeReference
            )}

            {renderAttachment(
                {
                    textKey: `${tWithNS('uploadAttachment.heading.title')}`,
                    dataTestId: TEST_ID_PREFIX.uploadAttachments,
                    path: END_UPLOAD_ATTACHMENT,
                    buttonText: '',
                },
                `${tWithNS('uploadAttachment.heading.title')}`,
                TEST_ID_PREFIX.uploadAttachments,
                attachment
            )}

            {renderHasOtherInsurance({
                inputWrapperClass: hasOtherInsuranceClass,
                textKey: tWithNS('hasOtherInsurance.heading.title'),
                dataTestId: TEST_ID_PREFIX.hasOtherInsurance,
                buttonText: t(createTextYesNoDontKnow(otherInsurance)),
                path: DYN_CAROUSEL_END_HAS_OTHER_INSURANCE,
                handleClick,
            })}

            {renderOtherInsurance(
                {
                    inputWrapperClass: 'col-8 divider text-align-left',
                    textKey: tWithNS('otherInsuranceCompany.heading.title'),
                    dataTestId: TEST_ID_PREFIX.otherInsuranceCompany,
                    buttonText: otherInsuranceCompany ?? '-',
                    path: END_OTHER_INSURANCE_COMPANY,
                    handleClick,
                },
                otherInsurance
            )}

            {renderReporterInformation({
                inputWrapperClass: 'col-12 divider text-align-left',
                textKey: tWithNS('reporterInformation.heading.title'),
                dataTestId: TEST_ID_PREFIX.reporterInformation,
                buttonText: displayPerson(reporterInformation),
                path: END_REPORTER_INFORMATION,
                handleClick,
            })}

            {renderPolicyHoldersContact({
                inputWrapperClass: 'col-12 divider text-align-left',
                textKey: tWithNS('policyHoldersContact.heading.title'),
                dataTestId: TEST_ID_PREFIX.policyHoldersContact,
                buttonText: displayPerson(policyHoldersContact),
                path: END_POLICY_HOLDERS_CONTACT,
                handleClick,
            })}
        </>
    );
};
