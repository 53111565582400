import { TFunction } from 'i18next';
import { OptionType, VehicleTypeKeys } from '@protectorinsurance/ds-can';

/**
 * Utility logic
 */
const createVehicleType = (id: string) => {
    return `motor.vehicleInformation.vehicleType.select.${id}`;
};

export const getVehicleTypeOptions = (t: TFunction): OptionType[] => {
    const {
        PRIVATE_CAR,
        MOTORCYCLE,
        COACHES,
        COMMERCIAL_VEHICLE,
        LIGHT_COMMERCIAL_VEHICLE,
        TRAILER,
        SPECIAL_TYPE_VEHICLE,
        OTHER,
    } = VehicleTypeKeys;
    return [
        { value: PRIVATE_CAR, label: t(createVehicleType(PRIVATE_CAR)) },
        { value: MOTORCYCLE, label: t(createVehicleType(MOTORCYCLE)) },
        { value: COACHES, label: t(createVehicleType(COACHES)) },
        { value: COMMERCIAL_VEHICLE, label: t(createVehicleType(COMMERCIAL_VEHICLE)) },
        { value: LIGHT_COMMERCIAL_VEHICLE, label: t(createVehicleType(LIGHT_COMMERCIAL_VEHICLE)) },
        { value: TRAILER, label: t(createVehicleType(TRAILER)) },
        { value: SPECIAL_TYPE_VEHICLE, label: t(createVehicleType(SPECIAL_TYPE_VEHICLE)) },
        { value: OTHER, label: t(createVehicleType(OTHER)) },
    ];
};
