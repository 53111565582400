import FileModel from '../../../models/File';
import { actionWithPromise, Rejectable, Resolvable, YesNoKeys } from '@protectorinsurance/ds-can';
import AttachmentModel from '../../../models/Attachment';
import { produce } from 'immer';

/**
 * Action Types
 */
export enum AttachmentActionTypes {
    REQUEST = '@app/upload/attachment/REQUEST',
    SUCCESS = '@app/upload/attachment/SUCCESS',
    ADD = '@app/upload/attachment/ADD',
    UPDATE = '@app/upload/attachment/UPDATE',
    FAILURE = '@app/upload/attachment/FAILURE',
    REMOVE = '@app/upload/attachment/REMOVE',
}

/**
 * Action Definitions
 */
export interface AttachmentAction {
    type: AttachmentActionTypes;
    data: FileModel;
    resolve?: Resolvable;
    reject?: Rejectable;
}

/**
 * Init State
 */
export const attachmentInitState: AttachmentModel = {
    files: [],
    hasAttachment: null,
};

/**
 * Default Reducer
 */
export default function (state = attachmentInitState, { type, data }: AttachmentAction) {
    return produce(state, (draft: AttachmentModel) => {
        switch (type) {
            case AttachmentActionTypes.ADD:
                draft.files.push(data);
                draft.hasAttachment = YesNoKeys.YES;
                break;
            case AttachmentActionTypes.UPDATE:
                const { id, status, errors, externalId } = data;
                const index = state.files.findIndex((x: FileModel) => x.id === id);
                draft.files[index].status = status;
                draft.files[index].errors = errors;
                if (externalId) {
                    draft.files[index].externalId = externalId;
                }
                break;
            case AttachmentActionTypes.REMOVE:
                draft.files = state.files.filter((x) => x.id !== data.id);
                break;
            default:
                break;
        }
    });
}

/**
 * Redux Actions
 */
export const attachmentActions = {
    add: actionWithPromise<AttachmentActionTypes, FileModel>(AttachmentActionTypes.ADD),
    failure: actionWithPromise(AttachmentActionTypes.FAILURE),
    success: actionWithPromise(AttachmentActionTypes.SUCCESS),
    remove: actionWithPromise(AttachmentActionTypes.REMOVE),
    request: actionWithPromise(AttachmentActionTypes.REQUEST),
    update: actionWithPromise(AttachmentActionTypes.UPDATE),
};
