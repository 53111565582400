import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { LpoRoutePaths } from '../config/wizardRouter/lpoWizardRoutes';

// Dynamic Imports
import { DynCarouselPage } from '../pages/DynCarouselPage';

// Start Imports
import { StartOnboardingPage } from '../pages/lpo/start/Onboarding';
import { StartPrivacyPage } from '../pages/lpo/start/Privacy';
import { StartPolicyHolderPage } from '../pages/lpo/start/PolicyHolder';
import { StartClaimDatePage } from '../pages/lpo/start/ClaimDate';
import { StartAccidentLocationPage } from '../pages/lpo/start/AccidentLocation';
import { StartClaimDescriptionPage } from '../pages/lpo/start/ClaimDescription';

// Liability Imports
import { LiabilityClaimantInformationPage } from '../pages/lpo/liability/ClaimantInformation';
import { LiabilityClaimTypePage } from '../pages/lpo/liability/ClaimType';
import { LiabilityClaimCausePage } from '../pages/lpo/liability/ClaimCause';
import { LiabilityThirdPartyInformationPage } from '../pages/lpo/liability/ThirdPartyInformation';

// Property Imports
import { PropertyClaimTypePage } from '../pages/lpo/property/ClaimType';
import { PropertyLeakPage } from '../pages/lpo/property/Leak';
import { PropertyPropertyAddressPage } from '../pages/lpo/property/PropertyAddress';
import { PropertyExternalPropertyDamagePage } from '../pages/lpo/property/ExternalPropertyDamage';
import { PropertyInternalPropertyDamagePage } from '../pages/lpo/property/InternalPropertyDamage';
import { PropertyPropertyClaimDescriptionPage } from '../pages/lpo/property/PropertyClaimDescription';
import { PropertyThirdPartyInformationPage } from '../pages/lpo/property/ThirdPartyInformation';

// End Imports
import { EndCrimeReferencePage } from '../pages/lpo/end/CrimeReference';
import { EndUploadAttachmentPage } from '../pages/lpo/end/UploadAttachment';
import { EndOtherInsuranceCompanyPage } from '../pages/lpo/end/OtherInsuranceCompany';
import { EndReporterInformationPage } from '../pages/lpo/end/ReporterInformation';
import { EndPolicyHoldersContactPage } from '../pages/lpo/end/PolicyHoldersContact';
import { EndFinalSummaryPage } from '../pages/lpo/end/FinalSummary';
import { EndReportCompletedPage } from '../pages/lpo/end/ReportCompleted';

export const Lpo: FC = () => {
    return (
        <Switch>
            {/* DYN */}
            <Route exact path={LpoRoutePaths.DYN_CAROUSEL} component={DynCarouselPage} />
            {/* START */}
            <Route exact path={LpoRoutePaths.INDEX} component={DynCarouselPage} />
            <Route exact path={LpoRoutePaths.START_ONBOARDING} component={StartOnboardingPage} />
            <Route exact path={LpoRoutePaths.START_PRIVACY} component={StartPrivacyPage} />
            <Route exact path={LpoRoutePaths.START_POLICY_HOLDER} component={StartPolicyHolderPage} />
            <Route exact path={LpoRoutePaths.START_CLAIM_DATE} component={StartClaimDatePage} />
            <Route exact path={LpoRoutePaths.START_ACCIDENT_LOCATION} component={StartAccidentLocationPage} />
            <Route exact path={LpoRoutePaths.START_CLAIM_DESCRIPTION} component={StartClaimDescriptionPage} />

            {/* LIABILITY */}
            <Route
                exact
                path={LpoRoutePaths.LIABILITY_CLAIMANT_INFORMATION}
                component={LiabilityClaimantInformationPage}
            />
            <Route exact path={LpoRoutePaths.LIABILITY_CLAIM_TYPE} component={LiabilityClaimTypePage} />
            <Route exact path={LpoRoutePaths.LIABILITY_CLAIM_CAUSE} component={LiabilityClaimCausePage} />
            <Route
                exact
                path={LpoRoutePaths.LIABILITY_THIRD_PARTY_INFORMATION}
                component={LiabilityThirdPartyInformationPage}
            />

            {/* PROPERTY */}
            <Route exact path={LpoRoutePaths.PROPERTY_CLAIM_TYPE} component={PropertyClaimTypePage} />
            <Route exact path={LpoRoutePaths.PROPERTY_LEAK} component={PropertyLeakPage} />
            <Route exact path={LpoRoutePaths.PROPERTY_PROPERTY_ADDRESS} component={PropertyPropertyAddressPage} />
            <Route
                exact
                path={LpoRoutePaths.PROPERTY_EXTERNAL_PROPERTY_DAMAGE}
                component={PropertyExternalPropertyDamagePage}
            />
            <Route
                exact
                path={LpoRoutePaths.PROPERTY_INTERNAL_PROPERTY_DAMAGE}
                component={PropertyInternalPropertyDamagePage}
            />
            <Route
                exact
                path={LpoRoutePaths.PROPERTY_PROPERTY_CLAIM_DESCRIPTION}
                component={PropertyPropertyClaimDescriptionPage}
            />
            <Route
                exact
                path={LpoRoutePaths.DYN_CAROUSEL_PROPERTY_IS_THIRD_PARTY_INVOLVED}
                component={DynCarouselPage}
            />
            <Route
                exact
                path={LpoRoutePaths.PROPERTY_THIRD_PARTY_INFORMATION}
                component={PropertyThirdPartyInformationPage}
            />

            {/* END */}
            <Route exact path={LpoRoutePaths.END_CRIME_REFERENCE} component={EndCrimeReferencePage} />
            <Route exact path={LpoRoutePaths.END_UPLOAD_ATTACHMENT} component={EndUploadAttachmentPage} />
            <Route exact path={LpoRoutePaths.END_OTHER_INSURANCE_COMPANY} component={EndOtherInsuranceCompanyPage} />
            <Route exact path={LpoRoutePaths.END_REPORTER_INFORMATION} component={EndReporterInformationPage} />
            <Route exact path={LpoRoutePaths.END_POLICY_HOLDERS_CONTACT} component={EndPolicyHoldersContactPage} />
            <Route exact path={LpoRoutePaths.END_FINAL_SUMMARY} component={EndFinalSummaryPage} />
            <Route exact path={LpoRoutePaths.END_REPORT_COMPLETED} component={EndReportCompletedPage} />
        </Switch>
    );
};
