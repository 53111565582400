import { LpoRoutePaths } from './lpoWizardRoutes';
import { FlowKeys, getLobFromUrl, getLocaleFromUrl, ObjectWithDynamicKey } from '@protectorinsurance/ds-can';
import { strReplace } from '../../utils/strings/strReplace';

const lpoProgressBar = (path: LpoRoutePaths) => {
    const { language } = getLocaleFromUrl();
    const lob = getLobFromUrl();
    const find = [':language', ':lob'];
    const replace = [language ? language : '', lob.lob ? lob.lob.toString() : ''];
    return strReplace(path, find, replace);
};

//TODO: Progress bar not working, update when flow is ready
export const lpoProgressBarRoutes: ObjectWithDynamicKey = {
    [FlowKeys.START]: [
        lpoProgressBar(LpoRoutePaths.START_ONBOARDING),
        lpoProgressBar(LpoRoutePaths.START_PRIVACY),
        lpoProgressBar(LpoRoutePaths.START_POLICY_HOLDER),
        lpoProgressBar(LpoRoutePaths.DYN_CAROUSEL_START_CLAIM_REPORTER_ROLE),
        lpoProgressBar(LpoRoutePaths.DYN_CAROUSEL_PROPERTY_TYPE_OF_PROPERTY),
        lpoProgressBar(LpoRoutePaths.DYN_CAROUSEL_LIABILITY_IS_EMPLOYER),
        lpoProgressBar(LpoRoutePaths.DYN_CAROUSEL_LIABILITY_HAS_INJURY),
        lpoProgressBar(LpoRoutePaths.LIABILITY_CLAIM_TYPE),
        lpoProgressBar(LpoRoutePaths.PROPERTY_CLAIM_TYPE),
        lpoProgressBar(LpoRoutePaths.LIABILITY_CLAIM_CAUSE),
        lpoProgressBar(LpoRoutePaths.PROPERTY_LEAK),
        lpoProgressBar(LpoRoutePaths.START_CLAIM_DATE),
        lpoProgressBar(LpoRoutePaths.START_ACCIDENT_LOCATION),
        lpoProgressBar(LpoRoutePaths.PROPERTY_PROPERTY_ADDRESS),
        lpoProgressBar(LpoRoutePaths.PROPERTY_EXTERNAL_PROPERTY_DAMAGE),
        lpoProgressBar(LpoRoutePaths.PROPERTY_INTERNAL_PROPERTY_DAMAGE),
        lpoProgressBar(LpoRoutePaths.DYN_CAROUSEL_PROPERTY_IS_THIRD_PARTY_INVOLVED),
        lpoProgressBar(LpoRoutePaths.START_CLAIM_DESCRIPTION),
    ],

    [FlowKeys.END]: [
        lpoProgressBar(LpoRoutePaths.DYN_CAROUSEL_PROPERTY_IS_LEAK_FIXED),
        lpoProgressBar(LpoRoutePaths.DYN_CAROUSEL_PROPERTY_IS_PROPERTY_OCCUPIED),
        lpoProgressBar(LpoRoutePaths.DYN_CAROUSEL_PROPERTY_IS_PROPERTY_TENANTED),
        lpoProgressBar(LpoRoutePaths.DYN_CAROUSEL_PROPERTY_HAS_ACCESS_TO_WATER),
        lpoProgressBar(LpoRoutePaths.DYN_CAROUSEL_PROPERTY_HAS_ACCESS_TO_ELECTRICITY),
        lpoProgressBar(LpoRoutePaths.DYN_CAROUSEL_PROPERTY_HAS_ACCESS_TO_GAS),
        lpoProgressBar(LpoRoutePaths.DYN_CAROUSEL_PROPERTY_HAS_EXISTING_CLAIM),
        lpoProgressBar(LpoRoutePaths.DYN_CAROUSEL_PROPERTY_IS_THIRD_PARTY_INVOLVED),
        lpoProgressBar(LpoRoutePaths.PROPERTY_THIRD_PARTY_INFORMATION),
        lpoProgressBar(LpoRoutePaths.PROPERTY_PROPERTY_CLAIM_DESCRIPTION),
        lpoProgressBar(LpoRoutePaths.END_CRIME_REFERENCE),
        lpoProgressBar(LpoRoutePaths.END_UPLOAD_ATTACHMENT),
        lpoProgressBar(LpoRoutePaths.DYN_CAROUSEL_END_HAS_OTHER_INSURANCE),
        lpoProgressBar(LpoRoutePaths.END_OTHER_INSURANCE_COMPANY),
        lpoProgressBar(LpoRoutePaths.DYN_CAROUSEL_LIABILITY_IS_THIRD_PARTY_INVOLVED),
        lpoProgressBar(LpoRoutePaths.LIABILITY_THIRD_PARTY_INFORMATION),
        lpoProgressBar(LpoRoutePaths.END_REPORTER_INFORMATION),
        lpoProgressBar(LpoRoutePaths.END_POLICY_HOLDERS_CONTACT),
        lpoProgressBar(LpoRoutePaths.END_FINAL_SUMMARY),
        lpoProgressBar(LpoRoutePaths.END_REPORT_COMPLETED),
    ],
};
