export const MAX_LENGTH_250 = 250;
export const MAX_LENGTH_80 = 80;
export const MAX_LENGTH_CLAIM_DESCRIPTION = 100000;

export enum FormFieldNames {
    ACCIDENT_DESCRIPTION = 'accidentDescription',
    ACCIDENT_SKETCH = 'accidentSketch',
    BUSINESS_NUMBER = 'businessNumber',
    CHASSIS_NUMBER = 'chassisNumber',
    CLAIM_DESCRIPTION = 'claimDescription',
    CITY = 'city',
    CLAIM_CAUSE = 'claimCause',
    CLAIM_DATE = 'claimDate',
    CLAIM_TIME = 'claimTime',
    CLAIM_TYPE = 'claimType',
    CONSTABULARY = 'constabulary',
    COUNTRY = 'country',
    DAMAGE_DESCRIPTION = 'damageDescription',
    CRIME_REFERENCE = 'crimeReference',
    DAMAGED_ITEM = 'damagedItem',
    DATE_OF_BIRTH = 'dateOfBirth',
    DISTANCE_FROM_ROAD_SIDE = 'distanceFromRoadSide',
    DITCH_CLAIM_DESCRIPTION = 'ditchClaimDescription',
    DRIVING_SPEED = 'drivingSpeed',
    EMAIL = 'email',
    ENGINE_CLAIM_DESCRIPTION = 'engineClaimDescription',
    EXTERNAL_PROPERTY_DAMAGE = 'externalPropertyDamage',
    EXTERNAL_REFERENCE = 'externalReference',
    FIRE_CLAIM_DESCRIPTION = 'fireClaimDescription',
    FIRST_NAME = 'firstName',
    FREIGHT_WEIGHT = 'freightWeight',
    INJURIES_PER_VEHICLE = 'injuriesPerVehicle',
    INTERNAL_PROPERTY_DAMAGE = 'internalPropertyDamage',
    IS_DRIVER = 'isDriver',
    IS_REPORTER = 'isReporter',
    IS_COMPANY = 'isCompany',
    IS_UNKNOWN_LOCATION = 'isUnknownLocation',
    LAST_NAME = 'lastName',
    LEAK_CAUSE = 'leakCause',
    LEAK_ORIGIN = 'leakOrigin',
    MAKE = 'make',
    MILEAGE = 'mileage',
    MISSING_ITEMS = 'missingItems',
    MODEL = 'model',
    NAME = 'name',
    NATIONAL_IDENTITY = 'nationalIdentity',
    NOTE = 'note',
    NUMBER_OF_PEOPLE_INJURED = 'numberOfPeopleInjured',
    NUMBER_OF_PEOPLE_HOSPITALIZED = 'numberOfPeopleHospitalized',
    OTHER_ID = 'otherId',
    OTHER_INSURANCE_COMPANY = 'otherInsuranceCompany',
    OTHER_INSURANCE_POLICY_NUMBER = 'otherInsurancePolicyNumber',
    OTHER_MISSING_ITEMS_CLAIM_DESCRIPTION = 'otherMissingItemsClaimDescription',
    PARKING_DATE = 'parkingDate',
    PARKING_TIME = 'parkingTime',
    PASSENGERS = 'passengers',
    PHONE = 'phone',
    POLICY_NUMBER = 'policyNumber',
    POLICE_CASE_NUMBER = 'policeCaseNumber',
    PROPERTY_ADDRESS = 'note',
    PROPERTY_CLAIM_DESCRIPTION = 'propertyClaimDescription',
    PROPERTY_UNIT = 'unit',
    RECOVERY_DATE = 'recoveryDate',
    REFERENCE = 'reference',
    REG_NR = 'registrationNumber',
    REVERSING_CLAIM_DESCRIPTION = 'reversingClaimDescription',
    ROAD_CONDITION_CLAIM_DESCRIPTION = 'roadConditionClaimDescription',
    ROAD_WIDTH = 'roadWidth',
    ROLE = 'role',
    SPEED_LIMIT = 'speedLimit',
    SPEED_ON_IMPACT = 'speedOnImpact',
    STREET = 'street',
    THEFT_AND_DAMAGES_CLAIM_DESCRIPTION = 'theftAndDamagesClaimDescription',
    THIRD_PARTY_SPEED = 'thirdPartySpeed',
    TIME_SINCE_ACTION = 'timeSinceAction',
    TYPE = 'type',
    VEHICLE_TYPE = 'vehicleType',
    WHO_RESPONSIBLE_CLAIM_DESCRIPTION = 'whoResponsibleClaimDescription',
    ZIP = 'zip',
}

export enum FormFieldErrors {
    EMAIL_ERROR_MESSAGE = 'form.validation.string.email',
    FIELD_ERROR = 'form.validation.string.fieldError',
    IF_REQUIRED_ERROR_MESSAGE = 'form.validation.string.ifRequired',
    INVALID_REGISTRATION_NUMBER = 'form.validation.string.invalidRegistrationNumberFormat',
    INVALID_ZIP_CODE = 'form.validation.string.invalidZipCode',
    MANDATORY_DATE_FIELD = 'form.validation.string.mandatoryDateField',
    MANDATORY_TIME_FIELD = 'form.validation.string.mandatoryTimeField',
    MAX_LENGTH_ERROR_MESSAGE = 'form.validation.string.maxLength',
    MIN_LENGTH_ERROR_MESSAGE = 'form.validation.string.minLength',
    MIN_REQUIRED_AGE = 'form.validation.string.minRequiredAge',
    MISSING_ITEMS_REQUIRED = 'form.validation.string.missingItems',
    NUMBER_ERROR_MESSAGE = 'form.validation.string.numberError',
    NUMBER_OF_DIGITS_ERROR = 'form.validation.number.numberOfDigits',
    PERSON_EXISTS = 'form.validation.string.personExists',
    REGISTRATION_NUMBER_EXISTS = 'form.validation.string.registrationNumberExists',
    REQUIRED_ERROR_MESSAGE = 'form.validation.string.required',
    TYPE_ERROR = 'form.validation.number.typeError',
    DUPLICATE_OWNERS = 'form.validation.string.duplicateOwners',
    NO_NEGATIVE_NUMBERS = 'form.validation.number.noNegativeNumbers',
}
