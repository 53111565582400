import React, { MouseEvent, SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../hooks/useI18n';
import { useGoBack } from '../../../hooks/useGoBack';
import {
    AddIcon,
    CircleIconButton,
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    is,
    MuiAutocomplete,
    MuiTextInput,
    OptionType,
    PageLayout,
    personExists,
    PersonList,
    RoleEnums,
} from '@protectorinsurance/ds-can';
import { PhraseKeys } from '../../../config/phraseKeys';
import { FormFieldErrors, FormFieldNames } from '../../../config/formFieldNames';
import { Controller, useForm } from 'react-hook-form';
import { AutocompleteValueKeys } from '../../../config/AutocompleteValueKeys';
import { crossContactInfoFieldValidation } from '../../../utils/validation/crossContactInfoFieldValidation';
import { lpoActions } from '../../../sagas/lpo';
import { selectThirdPartyInformation } from '../../../sagas/selectors/lpoSelectors';
import { thirdPartyInformationSchema } from '../../../validations/schemas/thirdPartyInformationSchema';
import { ThirdPartyInformationModel } from '../../../models/ThirdPartyInformation';
import { removeThirdParty } from '../../../utils/personUtils';
import { getRoleOption } from '../../../utils/lpo/thirdPartyInformationUtils';
import { yupResolver } from '@hookform/resolvers/yup';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../sagas/common';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const {
    ADD_BUTTON,
    BACK_BUTTON,
    CONTINUE_BUTTON,
    DELETE_BUTTON,
    EMAIL_LABEL,
    EMAIL_PLACEHOLDER,
    FIRST_NAME_LABEL,
    FIRST_NAME_PLACEHOLDER,
    HELP_TEXT,
    LAST_NAME_LABEL,
    LAST_NAME_PLACEHOLDER,
    NO_OPTIONS_MESSAGE,
    PAGE_NAME,
    PHONE_LABEL,
    PHONE_PLACEHOLDER,
    ROLE_LABEL,
    ROLE_PLACEHOLDER,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;
const { EMAIL, FIRST_NAME, LAST_NAME, PHONE, ROLE } = FormFieldNames;
const { EMAIL: EMAIL_AUTOCOMPLETE, FAMILY_NAME, GIVEN_NAME, TEL } = AutocompleteValueKeys;
const { PERSON_EXISTS, REQUIRED_ERROR_MESSAGE } = FormFieldErrors;
const {
    ACCOMMODATION_SUPPLIER,
    BROKER,
    CLAIMS_HANDLING_COMPANY,
    CO_INSURED,
    CONTRACTOR,
    DEFENDANT_SOLICITORS,
    DISASTER_RESTORATION_PROVIDER,
    EXPERTS_OTHER,
    FORENSIC_PROPERTY_SUPPLIER,
    INSURANCE_COMPANY,
    LEASEHOLDER,
    LOSS_ADJUSTER,
    LOSS_ASSESSOR,
    MANAGEMENT_COMPANY,
    OFFICIAL_BODY,
    OWN_SOLICITORS,
    POLICE,
    POLICY_HOLDER,
    POLICY_HOLDERS_CONTACT_PERSON,
    POWER_OF_ATTORNEY,
    PROPERTY_SUPPLIERS,
    REINSURER,
    SALVAGE_COMPANY,
    SURVEYOR,
    TENANT,
    THIRD_PARTY,
    VALIDATION_SUPPLIER,
} = RoleEnums;

/**
 * Page view and page logic
 */
export const PropertyThirdPartyInformationPage = () => {
    const dispatch = useDispatch();
    const thirdPartyInformation = useSelector(selectThirdPartyInformation);
    const customCAN = useSelector(selectCustomCAN);
    const [canGoNext, setCanGoNext] = useState<boolean>(false);
    const [role, setRole] = useState<OptionType | null>(null);
    const [roleError, setRoleError] = useState<string>('');
    const { t } = useI18n();
    const tWithNS = useI18n('lpo.property.thirdPartyInformation');
    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
        setError,
        setValue,
        trigger,
        watch,
    } = useForm<ThirdPartyInformationModel>({
        resolver: yupResolver(thirdPartyInformationSchema(t)),
        defaultValues: {
            email: '',
            firstName: '',
            lastName: '',
            phone: '',
        },
    });
    const firstNameField = watch(FIRST_NAME);
    const lastNameField = watch(LAST_NAME);
    const emailField = watch(EMAIL);
    const phoneField = watch(PHONE);
    const fieldValues = [firstNameField, lastNameField, emailField, phoneField];
    const isFormEmpty = fieldValues.filter(Boolean).length === 0;

    // Options
    const accommodationSupplierOption = getRoleOption(t, ACCOMMODATION_SUPPLIER, '481');
    const brokerOption = getRoleOption(t, BROKER, '204');
    const claimsHandlingCompanyOption = getRoleOption(t, CLAIMS_HANDLING_COMPANY, '8');
    const coInsuredOption = getRoleOption(t, CO_INSURED, '321');
    const contractorOption = getRoleOption(t, CONTRACTOR, '446');
    const defendantSupplierOption = getRoleOption(t, DEFENDANT_SOLICITORS, '482');
    const disasterRestorationSupplierOption = getRoleOption(t, DISASTER_RESTORATION_PROVIDER, '483');
    const expertsOtherOption = getRoleOption(t, EXPERTS_OTHER, '462');
    const forensicPropertySupplierOption = getRoleOption(t, FORENSIC_PROPERTY_SUPPLIER, '484');
    const insuranceCompanyOption = getRoleOption(t, INSURANCE_COMPANY, '62');
    const leaseholderOption = getRoleOption(t, LEASEHOLDER, '485');
    const lossAdjusterOption = getRoleOption(t, LOSS_ADJUSTER, '436');
    const lossAssessorOption = getRoleOption(t, LOSS_ASSESSOR, '440');
    const managementCompanyOption = getRoleOption(t, MANAGEMENT_COMPANY, '486');
    const officialBodyOption = getRoleOption(t, OFFICIAL_BODY, '487');
    const ownSolicitorsOption = getRoleOption(t, OWN_SOLICITORS, '471');
    const policeOption = getRoleOption(t, POLICE, '203');
    const policyHolderOption = getRoleOption(t, POLICY_HOLDER, '2');
    const policyHoldersContactPersonOption = getRoleOption(t, POLICY_HOLDERS_CONTACT_PERSON, '261');
    const powerOfAttorneyOption = getRoleOption(t, POWER_OF_ATTORNEY, '479');
    const propertySuppliersOption = getRoleOption(t, PROPERTY_SUPPLIERS, '438');
    const reinsurerOption = getRoleOption(t, REINSURER, '6');
    const salvageCompanyOption = getRoleOption(t, SALVAGE_COMPANY, '65');
    const surveyorOption = getRoleOption(t, SURVEYOR, '367');
    const tenantOption = getRoleOption(t, TENANT, '397');
    const thirdPartyOption = getRoleOption(t, THIRD_PARTY, '384');
    const validationSupplierOption = getRoleOption(t, VALIDATION_SUPPLIER, '478');

    const options = [
        accommodationSupplierOption,
        brokerOption,
        claimsHandlingCompanyOption,
        coInsuredOption,
        contractorOption,
        defendantSupplierOption,
        disasterRestorationSupplierOption,
        expertsOtherOption,
        forensicPropertySupplierOption,
        insuranceCompanyOption,
        leaseholderOption,
        lossAdjusterOption,
        lossAssessorOption,
        managementCompanyOption,
        officialBodyOption,
        ownSolicitorsOption,
        policeOption,
        policyHolderOption,
        policyHoldersContactPersonOption,
        propertySuppliersOption,
        salvageCompanyOption,
        surveyorOption,
        tenantOption,
        thirdPartyOption,
        validationSupplierOption,
        powerOfAttorneyOption,
        reinsurerOption,
    ];

    useEffect(() => {
        setCanGoNext(thirdPartyInformation.length > 0 && isFormEmpty);
    }, [isFormEmpty, thirdPartyInformation]);

    const handleBackButton = useGoBack();

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleRoleBlur = (e: FormChangeable) => {
        e.preventDefault();
        if (is(role, null)) {
            setRoleError(t(REQUIRED_ERROR_MESSAGE));
        } else {
            setRoleError('');
        }
    };

    const handleDelete = (e: MouseEvent, thirdParty: ThirdPartyInformationModel) => {
        e.preventDefault();
        const updatedThirdParty = removeThirdParty(thirdParty, thirdPartyInformation);
        dispatch(lpoActions.update({ thirdPartyInformation: updatedThirdParty }));
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        await setValue(id, value, { shouldValidate: true });
        await crossContactInfoFieldValidation(id, trigger);
    };

    const handleSelect = (e: SyntheticEvent, option: OptionType) => {
        const object = option ? (option as OptionType) : null;
        setRoleError('');
        setRole(object);
    };

    const handleNext = () => {
        dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(wizardActions.goToNext()));
    };

    const handleFormSubmit = (values: ThirdPartyInformationModel): boolean => {
        const value = role ? role.value : null;
        const key = role ? role.label : null;

        // Check if third party exists
        if (personExists(values, thirdPartyInformation)) {
            setError(FIRST_NAME, { type: 'exists', message: t(PERSON_EXISTS) });
            return false;
        } else {
            if (is(role, null)) {
                setRoleError(t(REQUIRED_ERROR_MESSAGE));
                return false;
            } else {
                setRoleError('');
                dispatch(
                    lpoActions.update({
                        thirdPartyInformation: [
                            ...thirdPartyInformation,
                            { ...values, roleType: { id: Number(value), key } },
                        ],
                    })
                );
                reset();
                return true;
            }
        }
    };

    const onSubmit = (values: ThirdPartyInformationModel) => {
        handleFormSubmit(values);
    };

    const onSubmitFormNext = (values: ThirdPartyInformationModel) => {
        if (handleFormSubmit(values)) {
            handleNext();
        }
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={canGoNext ? handleNext : handleSubmit(onSubmitFormNext)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <HiddenInputSubmit />
                <Grid className={'align-center'}>
                    <Controller
                        control={control}
                        name={FIRST_NAME}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={GIVEN_NAME}
                                error={!!errors.firstName}
                                errorMessage={errors.firstName?.message}
                                id={FIRST_NAME}
                                inputFieldWrapper={'col-6'}
                                label={t(FIRST_NAME_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(FIRST_NAME_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={LAST_NAME}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={FAMILY_NAME}
                                error={!!errors.lastName}
                                errorMessage={errors.lastName?.message}
                                id={LAST_NAME}
                                inputFieldWrapper={'col-6'}
                                label={t(LAST_NAME_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(LAST_NAME_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <MuiAutocomplete
                        error={!!roleError}
                        errorMessage={roleError}
                        id={ROLE}
                        inputFieldWrapper={'col-4'}
                        label={t(ROLE_LABEL)}
                        noOptionsText={t(NO_OPTIONS_MESSAGE)}
                        onBlur={handleRoleBlur}
                        onChange={handleSelect}
                        openOnFocus={true}
                        placeholder={t(ROLE_PLACEHOLDER)}
                        {...{ customCAN, options }}
                        value={role}
                    />
                    <Controller
                        control={control}
                        name={EMAIL}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={EMAIL_AUTOCOMPLETE}
                                error={!!errors.email}
                                errorMessage={errors.email?.message}
                                id={EMAIL}
                                inputFieldWrapper={'col-4'}
                                label={t(EMAIL_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(EMAIL_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={PHONE}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={TEL}
                                error={!!errors.phone}
                                errorMessage={errors.phone?.message}
                                id={PHONE}
                                inputFieldWrapper={'col-4'}
                                label={t(PHONE_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(PHONE_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <CircleIconButton
                        ariaLabel={t(ADD_BUTTON)}
                        className={'flex flex-col single-4-center'}
                        dataTestId={'btn-add-third-party'}
                        handleClick={handleSubmit(onSubmit)}
                        icon={<AddIcon />}
                    />
                </Grid>
            </form>

            <PersonList persons={thirdPartyInformation} handleDelete={handleDelete} buttonText={t(DELETE_BUTTON)} />
        </PageLayout>
    );
};
