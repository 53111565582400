import { CarouselConfigPageProps } from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../utils/carouselPageLookup';
import { liabilityIsEmployerPage } from './liability/isEmployer';
import { startClaimReporterRolePage } from './start/claimReporterRole';
import { liabilityHasInjuryPage } from './liability/hasInjury';
import { endHasOtherInsurancePage } from './end/hasOtherInsurance';
import { propertyTypeOfPropertyPage } from './property/typeOfProperty';
import { propertyIsLeakFixedPage } from './property/isLeakFixed';
import { propertyIsPropertyOccupiedPage } from './property/isPropertyOccupied';
import { propertyIsPropertyTenantedPage } from './property/isPropertyTenanted';
import { propertyHasAccessToWaterPage } from './property/hasAccessToWater';
import { propertyHasAccessToElectricityPage } from './property/hasAccessToElectricity';
import { propertyHasAccessToGasPage } from './property/hasAccessToGas';
import { propertyHasExistingClaimPage } from './property/hasExistingClaim';
import { propertyIsThirdPartyInvolvedPage } from './property/isThirdPartyInvolved';
import { liabilityIsThirdPartyInvolvedPage } from './liability/isThirdPartyInvolved';

export const lpoCarouselConfigPages = (props: CarouselConfigLookupProps): CarouselConfigPageProps => [
    // Start
    startClaimReporterRolePage(props),

    // Liability
    liabilityIsEmployerPage(props),
    liabilityHasInjuryPage(props),
    liabilityIsThirdPartyInvolvedPage(props),

    // Property
    propertyTypeOfPropertyPage(props),
    propertyIsLeakFixedPage(props),
    propertyIsPropertyOccupiedPage(props),
    propertyIsPropertyTenantedPage(props),
    propertyHasAccessToWaterPage(props),
    propertyHasAccessToElectricityPage(props),
    propertyHasAccessToGasPage(props),
    propertyHasExistingClaimPage(props),
    propertyIsThirdPartyInvolvedPage(props),

    // End
    endHasOtherInsurancePage(props),
];
