import React, { useEffect, useState } from 'react';
import { Clickable, Grid, PageLayout, PrintLink, StandardModal } from '@protectorinsurance/ds-can';
import { PhraseKeys } from '../../../config/phraseKeys';
import '../../../styles/utils/_mixins.scss';
import { PrintableSummaryPage } from 'components/summary/final/PrintableSummary';
import { useI18n } from '../../../hooks/useI18n';
import { useSelector } from 'react-redux';
import { selectLocaleLanguageCode } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { PAGE_NAME, HELP_TEXT, TITLE, SUB_TITLE, COMPLETED_BUTTON } = PhraseKeys;

/**
 * Page view and page logic
 */
export const ReportCompletedPage = () => {
    const tWithNs = useI18n('motor.end.reportCompleted');
    const { t } = useI18n();
    const language = useSelector(selectLocaleLanguageCode);
    const [showModal, setShowModal] = useState<boolean>(false);

    const handleBackButton = (e: any) => {
        e.preventDefault();
        setShowModal(true);
    };

    const handleCloseModal = (e: Clickable) => {
        e.preventDefault();
        window.history.pushState(null, '', window.location.pathname);
        setShowModal(false);
    };

    const handleConfirmModal = (e: Clickable) => {
        e.preventDefault();
        window.location.href = `/nor/${language}`;
    };

    useEffect(() => {
        window.history.pushState(null, '', window.location.pathname);
        window.addEventListener('popstate', handleBackButton);

        return () => {
            window.removeEventListener('popstate', handleBackButton);
        };
    }, []);

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        window.location.replace(tWithNs.t('content.homePageLink'));
    };

    const printSummary = (e: Clickable) => {
        e.preventDefault();
        window.print();
    };

    return (
        <>
            <PageLayout
                backBtnText={''}
                continueBtnText={t(COMPLETED_BUTTON)}
                domainTitle={t(PAGE_NAME)}
                footerComponent={<PrintLink title={tWithNs.t(HELP_TEXT)} onClick={printSummary} />}
                handleContinueButton={handleContinueButton}
                headerSubTitle={tWithNs.t(SUB_TITLE)}
                headerTitle={tWithNs.t(TITLE)}
                pageClassName={'no-print'}
                showBackButton={false}
            >
                <Grid>
                    <p className={'no-print col-12'} tabIndex={0}>
                        {tWithNs.t('body.paragraph.one')}{' '}
                    </p>
                </Grid>
            </PageLayout>
            <PrintableSummaryPage editDescription={false} showSummary={false} />
            {showModal && (
                <StandardModal
                    closeButtonText={t('modal.reportCompleted.closeButtonText')}
                    confirmButtonText={t('modal.reportCompleted.confirmButtonText')}
                    onClose={handleCloseModal}
                    onConfirm={handleConfirmModal}
                    showCloseButton={true}
                    showConfirmButton={true}
                    title={t('modal.reportCompleted.title')}
                >
                    <div className={'dialog__content text-center'}>{t('modal.reportCompleted.bodyText')}</div>
                </StandardModal>
            )}
        </>
    );
};
