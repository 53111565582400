import React from 'react';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    Clickable,
    FlowKeys,
    is,
    MotorClaimCauseKeys,
    NoIcon,
    YesIcon,
    YesNoKeys,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../../utils/carouselPageLookup';
import { MotorRoutePaths } from '../../../../wizardRouter/motorWizardRoutes';
import { selectClaimCause, selectIsReversing } from '../../../../../sagas/selectors/motorSelectors';
import { wizardRouterActions as wizardActions } from '../../../../../sagas/wizardRouter';
import dispatcherWithPromise from '../../../../../utils/dispatcherWithPromise';
import { motorActions } from '../../../../../sagas/motor';
import { commonActions } from '../../../../../sagas/common';
import { flowActions } from '../../../../../sagas/flow';

/**
 * Destructure necessary imports
 */
const { NO, YES } = YesNoKeys;
const { CYCLIST_COLLISION } = MotorClaimCauseKeys;
const { DRIVING_VEHICLE_SPEED_INFORMATION } = MotorRoutePaths;
const { END } = FlowKeys;

/**
 * Page view and page logic
 */
export const drivingNoVehicleIsReversingPage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<MotorRoutePaths> => {
    const claimCause = selectClaimCause(state);
    const options = [
        { id: YES, name: t(`motor.driving.noVehicleIsReversing.select.${YES}`), icon: <YesIcon /> },
        { id: NO, name: t(`motor.driving.noVehicleIsReversing.select.${NO}`), icon: <NoIcon /> },
    ];

    return {
        slug: MotorRoutePaths.DYN_CAROUSEL_DRIVING_NO_VEHICLE_IS_REVERSING,
        stateKey: 'isReversing',
        i18n: {
            ns: 'motor.driving.noVehicleIsReversing',
        },
        selectorValue: selectIsReversing(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                if (is(option.id, YES)) {
                    dispatch(wizardActions.skipBackToPrev(true));
                }

                if (is(claimCause, CYCLIST_COLLISION)) {
                    dispatcherWithPromise(dispatch, motorActions.update, { isReversing: option.id })
                        .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                        .then(() => dispatch(wizardActions.goTo(DRIVING_VEHICLE_SPEED_INFORMATION)));
                } else {
                    dispatcherWithPromise(dispatch, motorActions.update, { isReversing: option.id })
                        .then(() => dispatcherWithPromise(dispatch, flowActions.update, END))
                        .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                        .then(() => dispatch(wizardActions.goToNext()));
                }
            }
        },
    };
};
