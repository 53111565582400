import {
    actionWithPromise,
    ClaimantModel,
    ClaimDescriptionTypeModel,
    ClaimReporterRoleTypeModel,
    ClaimTypeTypeModel,
    CompanyModel,
    CrimeReferenceTypeModel,
    Datable,
    emptyFn,
    ExternalPropertyDamageTypeModel,
    ExternalReferenceTypeModel,
    initClaimantModel,
    InternalPropertyDamageTypeModel,
    LeakCauseTypeModel,
    LeakOriginTypeModel,
    LocationModel,
    LpoClaimCauseTypeModel,
    OtherInsuranceCompanyTypeModel,
    OtherInsurancePolicyNumberTypeModel,
    PolicyHoldersContactModel,
    PrivacyTypeModel,
    Rejectable,
    ReporterInformationModel,
    Resolvable,
    TypeOfPropertyTypeModel,
    YesNoModel,
} from '@protectorinsurance/ds-can';
import { put, takeEvery } from 'redux-saga/effects';
import { ConstabularyTypeModel } from '../models/Constabulary';
import { ThirdPartyInformationTypeModel } from '../models/ThirdPartyInformation';

/**
 * Constants
 */
export enum LpoActionTypes {
    UPDATE = '@lpo/UPDATE',
    UPDATED = '@lpo/UPDATED',
}

/**
 * Interfaces
 */
export interface LpoAction {
    type: LpoActionTypes;
    data?: Partial<LpoState>;
    resolve?: Resolvable;
    reject?: Rejectable;
}

export interface LpoState {
    acceptedPrivacy: PrivacyTypeModel;
    accidentLocation: LocationModel;
    claimCause: LpoClaimCauseTypeModel;
    claimDate: Datable;
    claimDescription: ClaimDescriptionTypeModel;
    claimReporterRole: ClaimReporterRoleTypeModel;
    claimType: ClaimTypeTypeModel;
    claimantInformation: ClaimantModel;
    companyInformation: CompanyModel;
    constabulary: ConstabularyTypeModel;
    crimeReference: CrimeReferenceTypeModel;
    externalPropertyDamage: ExternalPropertyDamageTypeModel;
    externalReference: ExternalReferenceTypeModel;
    hasAccessToElectricity: YesNoModel;
    hasAccessToGas: YesNoModel;
    hasAccessToWater: YesNoModel;
    hasExistingClaim: YesNoModel;
    hasInjury: YesNoModel;
    internalPropertyDamage: InternalPropertyDamageTypeModel;
    isEmployer: YesNoModel;
    isLeakFixed: YesNoModel;
    isPropertyOccupied: YesNoModel;
    isPropertyTenanted: YesNoModel;
    isThirdPartyInvolved: YesNoModel;
    leakCause: LeakCauseTypeModel;
    leakOrigin: LeakOriginTypeModel;
    otherInsurance: YesNoModel;
    otherInsuranceCompany: OtherInsuranceCompanyTypeModel;
    otherInsurancePolicyNumber: OtherInsurancePolicyNumberTypeModel;
    policyHoldersContact: PolicyHoldersContactModel;
    propertyClaimDescription: ClaimDescriptionTypeModel;
    reporterInformation: ReporterInformationModel;
    thirdPartyInformation: ThirdPartyInformationTypeModel;
    typeOfProperty: TypeOfPropertyTypeModel;
}

/**
 * Initial State
 */
export const lpoInitState: LpoState = {
    acceptedPrivacy: false,
    accidentLocation: {
        isUnknownLocation: false,
        latitude: null,
        longitude: null,
        note: null,
        unit: null,
    },
    claimCause: null,
    claimDate: null,
    claimDescription: '',
    claimReporterRole: null,
    claimType: null,
    claimantInformation: initClaimantModel,
    companyInformation: {
        name: null,
        businessNumber: null,
        policyNumber: null,
    },
    constabulary: null,
    crimeReference: null,
    externalPropertyDamage: [],
    externalReference: null,
    hasAccessToElectricity: null,
    hasAccessToGas: null,
    hasAccessToWater: null,
    hasExistingClaim: null,
    hasInjury: null,
    internalPropertyDamage: [],
    isEmployer: null,
    isLeakFixed: null,
    isPropertyOccupied: null,
    isPropertyTenanted: null,
    isThirdPartyInvolved: null,
    leakCause: null,
    leakOrigin: null,
    otherInsurance: null,
    otherInsuranceCompany: null,
    otherInsurancePolicyNumber: null,
    policyHoldersContact: {
        email: null,
        firstName: null,
        isReporter: false,
        lastName: null,
        phone: null,
    },
    propertyClaimDescription: '',
    reporterInformation: {
        dateOfBirth: null,
        email: null,
        firstName: null,
        isDriver: false,
        lastName: null,
        missingSSN: false,
        nationalIdentity: null,
        phone: null,
    },
    thirdPartyInformation: [],
    typeOfProperty: null,
};

/**
 * Default Reducer
 *
 * @param state
 * @param action
 */
export default function (state = lpoInitState, { type, data }: LpoAction) {
    switch (type) {
        case LpoActionTypes.UPDATED:
            return { ...state, ...data };
        default:
            return state;
    }
}

/**
 * Redux Actions
 */
export const lpoActions = {
    update: actionWithPromise<LpoActionTypes.UPDATE, Partial<LpoState>>(LpoActionTypes.UPDATE),
    updated: actionWithPromise<LpoActionTypes.UPDATED, Partial<LpoState>>(LpoActionTypes.UPDATED),
};

/**
 * Saga watchers
 */
export const lpoWatcher = function* () {
    yield takeEvery(LpoActionTypes.UPDATE, lpoSagas.update);
};

/**
 * Saga functions
 */
export const lpoSagas = {
    *update({ data, resolve = emptyFn, reject = emptyFn }: LpoAction) {
        try {
            yield put(lpoActions.updated({ ...data }));
            resolve();
        } catch (e) {
            reject();
        }
    },
};
