import React from 'react';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    Clickable,
    NoIcon,
    YesIcon,
    YesNoKeys,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../../../utils/carouselPageLookup';
import { MotorRoutePaths } from '../../../../../wizardRouter/motorWizardRoutes';
import { selectIsCounterpartyStationary } from '../../../../../../sagas/selectors/motorSelectors';
import { wizardRouterActions as wizardActions } from '../../../../../../sagas/wizardRouter';
import dispatcherWithPromise from '../../../../../../utils/dispatcherWithPromise';
import { motorActions } from '../../../../../../sagas/motor';
import { commonActions } from '../../../../../../sagas/common';

/**
 * Destructure necessary imports
 */
const { NO, YES } = YesNoKeys;

/**
 * Page view and page logic
 */
export const drivingVehiclesHeadOnIsCounterpartyStationaryPage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<MotorRoutePaths> => {
    const options = [
        { id: YES, name: t(`motor.driving.isCounterpartyStationary.select.${YES}`), icon: <YesIcon /> },
        { id: NO, name: t(`motor.driving.isCounterpartyStationary.select.${NO}`), icon: <NoIcon /> },
    ];

    return {
        slug: MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_HEAD_ON_IS_COUNTERPARTY_STATIONARY,
        stateKey: 'isCounterpartyStationary',
        i18n: {
            ns: 'motor.driving.isCounterpartyStationary',
        },
        selectorValue: selectIsCounterpartyStationary(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                dispatcherWithPromise(dispatch, motorActions.update, { isCounterpartyStationary: option.id })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext()));
            }
        },
    };
};
