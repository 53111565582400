import { Nullable, PropertyTypeModel } from '@protectorinsurance/ds-can';

export enum ConstabularyEnums {
    AVON_SOMERSET = 'AVON_SOMERSET',
    BEDFORDSHIRE = 'BEDFORDSHIRE',
    BRITISH_TRANSPORT = 'BRITISH_TRANSPORT',
    CAMBRIDGESHIRE = 'CAMBRIDGESHIRE',
    CHESHIRE = 'CHESHIRE',
    CITY_OF_LONDON = 'CITY_OF_LONDON',
    CLEVELAND = 'CLEVELAND',
    CUMBRIA = 'CUMBRIA',
    DERBYSHIRE = 'DERBYSHIRE',
    DEVON_CORNWALL = 'DEVON_CORNWALL',
    DORSET = 'DORSET',
    DURHAM = 'DURHAM',
    DYFED_POWYS = 'DYFED_POWYS',
    ESSEX = 'ESSEX',
    GLOUCESTERSHIRE = 'GLOUCESTERSHIRE',
    GREATER_MANCHESTER = 'GREATER_MANCHESTER',
    GWENT = 'GWENT',
    HAMPSHIRE = 'HAMPSHIRE',
    HERTFORDSHIRE = 'HERTFORDSHIRE',
    HUMBERSIDE = 'HUMBERSIDE',
    KENT = 'KENT',
    LANCASHIRE = 'LANCASHIRE',
    LEICESTERSHIRE = 'LEICESTERSHIRE',
    LINCOLNSHIRE = 'LINCOLNSHIRE',
    MERSEYSIDE = 'MERSEYSIDE',
    METROPOLITAN = 'METROPOLITAN',
    MINISTRY_OF_DEFENCE = 'MINISTRY_OF_DEFENCE',
    NORFOLK = 'NORFOLK',
    NORTHAMPTONSHIRE = 'NORTHAMPTONSHIRE',
    NORTHUMBRIA = 'NORTHUMBRIA',
    NORTH_IRELAND = 'NORTH_IRELAND',
    NORTH_WALES = 'NORTH_WALES',
    NORTH_YORKSHIRE = 'NORTH_YORKSHIRE',
    NOTTINGHAMSHIRE = 'NOTTINGHAMSHIRE',
    SCOTLAND = 'SCOTLAND',
    SOUTH_WALES = 'SOUTH_WALES',
    SOUTH_YORKSHIRE = 'SOUTH_YORKSHIRE',
    STAFFORDSHIRE = 'STAFFORDSHIRE',
    SUFFOLK = 'SUFFOLK',
    SURREY = 'SURREY',
    SUSSEX = 'SUSSEX',
    THAMES = 'THAMES',
    WARWICKSHIRE = 'WARWICKSHIRE',
    WEST_MERCIA = 'WEST_MERCIA',
    WEST_MIDLANDS = 'WEST_MIDLANDS',
    WEST_YORKSHIRE = 'WEST_YORKSHIRE',
    WILTSHIRE = 'WILTSHIRE',
}

export interface ConstabularyModel {
    constabulary: ConstabularyTypeModel;
}

export type ConstabularyTypeModel = Nullable<PropertyTypeModel>;
