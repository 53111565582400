import React from 'react';
import { SummaryItem, SummaryItemProps } from '@protectorinsurance/ds-can';

/**
 * Component view and component logic
 */
export const renderClaimCause = ({
    inputWrapperClass,
    textKey,
    dataTestId,
    buttonText,
    path,
    handleClick,
}: SummaryItemProps) => {
    return <SummaryItem {...{ buttonText, dataTestId, handleClick, inputWrapperClass, path, textKey }} />;
};
