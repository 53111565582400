import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    ClaimTypeEnums,
    Clickable,
    FormChangeable,
    Grid,
    is,
    isYes,
    LpoClaimCauseEnums,
    MuiAutocomplete,
    OptionType,
    PageLayout,
    YesNoKeys,
} from '@protectorinsurance/ds-can';
import { selectClaimType, selectHasInjury, selectIsEmployer } from '../../../sagas/selectors/lpoSelectors';
import { useI18n } from '../../../hooks/useI18n';
import { PhraseKeys } from '../../../config/phraseKeys';
import { useGoBack } from '../../../hooks/useGoBack';
import { FormFieldErrors, FormFieldNames } from '../../../config/formFieldNames';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { lpoActions } from '../../../sagas/lpo';
import { wizardRouterActions } from '../../../sagas/wizardRouter';
import { commonActions } from '../../../sagas/common';
import { getClaimTypeOption } from '../../../utils/lpo/claimTypeUtils';
import { LpoRoutePaths } from '../../../config/wizardRouter/lpoWizardRoutes';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const {
    BACK_BUTTON,
    CLAIM_TYPE_LABEL,
    CLAIM_TYPE_PLACEHOLDER,
    CONTINUE_BUTTON,
    HELP_TEXT,
    NO_OPTIONS_MESSAGE,
    PAGE_NAME,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;
const { REQUIRED_ERROR_MESSAGE } = FormFieldErrors;
const { CLAIM_TYPE } = FormFieldNames;
const {
    FIDELITY_GUARANTEE,
    LIBEL_SLANDER,
    NON_INJURY,
    OFFICIALS_INDEMNITY,
    PRODUCT_LIABILITY,
    PROFESSIONAL_INDEMNITY,
    PUBLIC_LIABILITY,
} = ClaimTypeEnums;
const { NO } = YesNoKeys;
const { START_CLAIM_DATE } = LpoRoutePaths;
const { BREACH } = LpoClaimCauseEnums;

/**
 * Page view and page logic
 */
export const LiabilityClaimTypePage = () => {
    const dispatch = useDispatch();
    const [type, setType] = useState<OptionType | null>(null);
    const [error, setError] = useState<string>('');
    const claimType = useSelector(selectClaimType);
    const isEmployer = useSelector(selectIsEmployer);
    const hasInjury = useSelector(selectHasInjury);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('lpo.liability.claimType');

    // Options
    const fidelityGuaranteeOption = getClaimTypeOption(t, FIDELITY_GUARANTEE);
    const libelAndSlanderOption = getClaimTypeOption(t, LIBEL_SLANDER);
    const officialsIndemnityOption = getClaimTypeOption(t, OFFICIALS_INDEMNITY);
    const PLNonInjuryOption = getClaimTypeOption(t, NON_INJURY);
    const productLiabilityOption = getClaimTypeOption(t, PRODUCT_LIABILITY);
    const professionalIndemnityOption = getClaimTypeOption(t, PROFESSIONAL_INDEMNITY);
    const publicLiabilityOption = getClaimTypeOption(t, PUBLIC_LIABILITY);

    let options = [
        PLNonInjuryOption,
        libelAndSlanderOption,
        officialsIndemnityOption,
        professionalIndemnityOption,
        fidelityGuaranteeOption,
        productLiabilityOption,
    ];
    if (is(isEmployer, NO) && isYes(hasInjury)) {
        options = [publicLiabilityOption, productLiabilityOption];
    }

    useEffect(() => {
        const selected = options.find((x) => x.value === claimType);
        if (selected) {
            setType(selected);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [claimType, setType]);

    const handleBackButton = useGoBack();

    const handleBlur = (e: FormChangeable) => {
        e.preventDefault();
        if (is(claimType, null)) {
            setError(t(REQUIRED_ERROR_MESSAGE));
        } else {
            setError('');
        }
    };

    const handleSelect = (e: SyntheticEvent, option: OptionType) => {
        const object = option ? (option as OptionType) : null;
        const value = object ? object.value : null;
        setError('');
        setType(object);
        dispatcherWithPromise(dispatch, lpoActions.update, { claimType: value });
    };

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        if (is(claimType, null)) {
            setError(t(REQUIRED_ERROR_MESSAGE));
        } else {
            setError('');

            let nextAction = wizardRouterActions.goToNext();
            if (is(claimType, PROFESSIONAL_INDEMNITY)) {
                nextAction = wizardRouterActions.goTo(START_CLAIM_DATE);
                dispatcherWithPromise(dispatch, lpoActions.update, { claimCause: BREACH });
            }
            dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(nextAction));
        }
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            <Grid className={'align-center'}>
                <MuiAutocomplete
                    error={!!error}
                    errorMessage={error}
                    id={CLAIM_TYPE}
                    inputFieldWrapper={'col-12'}
                    label={t(CLAIM_TYPE_LABEL)}
                    noOptionsText={t(NO_OPTIONS_MESSAGE)}
                    onBlur={handleBlur}
                    onChange={handleSelect}
                    openOnFocus={true}
                    placeholder={t(CLAIM_TYPE_PLACEHOLDER)}
                    {...{ customCAN, options }}
                    value={type}
                />
            </Grid>
        </PageLayout>
    );
};
