import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { otherInsuranceSchema } from '../fieldSchemas/otherInsuranceSchema';
import { otherInsurancePolicyNumberSchema } from '../fieldSchemas/otherInsurancePolicyNumberSchema';

/**
 * Validation logic
 */
export const lpoOtherInsuranceCompanySchema = (t: TFunction) => {
    return Yup.object().shape({
        ...otherInsuranceSchema(t),
        ...otherInsurancePolicyNumberSchema(t),
    });
};
