import React from 'react';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    Clickable,
    NoIcon,
    YesIcon,
    YesNoKeys,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../utils/carouselPageLookup';
import { LpoRoutePaths } from '../../../wizardRouter/lpoWizardRoutes';
import { selectHasAccessToWater } from '../../../../sagas/selectors/lpoSelectors';
import { wizardRouterActions } from '../../../../sagas/wizardRouter';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { lpoActions } from '../../../../sagas/lpo';
import { commonActions } from '../../../../sagas/common';

/**
 * Destructure necessary imports
 */
const { NO, YES } = YesNoKeys;

/**
 * Page view and page logic
 */
export const propertyHasAccessToWaterPage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<LpoRoutePaths> => {
    const options = [
        { id: YES, name: t(`lpo.property.hasAccessToWater.select.${YES}`), icon: <YesIcon /> },
        { id: NO, name: t(`lpo.property.hasAccessToWater.select.${NO}`), icon: <NoIcon /> },
    ];

    return {
        slug: LpoRoutePaths.DYN_CAROUSEL_PROPERTY_HAS_ACCESS_TO_WATER,
        stateKey: 'hasAccessToWater',
        i18n: {
            ns: 'lpo.property.hasAccessToWater',
        },
        selectorValue: selectHasAccessToWater(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                dispatcherWithPromise(dispatch, lpoActions.update, { hasAccessToWater: option.id })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardRouterActions.goToNext()));
            }
        },
    };
};
