import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WizardRouterAction, wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../hooks/useI18n';
import { Clickable, MotorClaimCauseKeys, PageLayout, StartSummary } from '@protectorinsurance/ds-can';
import { useGoBack } from '../../../hooks/useGoBack';
import { PhraseKeys } from '../../../config/phraseKeys';
import { MotorRoutePaths } from '../../../config/wizardRouter/motorWizardRoutes';
import { selectClaimCause } from '../../../sagas/selectors/motorSelectors';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../sagas/common';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { BACK_BUTTON, CONTINUE_BUTTON, HELP_TEXT, PAGE_NAME, TITLE, SUB_TITLE } = PhraseKeys;
const { THEFT_AND_DAMAGE, PARKING, ENGINE } = MotorClaimCauseKeys;

/**
 * Page view and page logic
 */
export const ContactTowingServicePage = () => {
    const dispatch = useDispatch();
    const { t } = useI18n();
    const tWithNS = useI18n('motor.end.contactTowingService');
    const claimCause = useSelector(selectClaimCause);
    const customCAN = useSelector(selectCustomCAN);

    const handleBackButton = useGoBack();
    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        let nextAction: WizardRouterAction<string | undefined> = wizardActions.goToNext();

        if (claimCause && [THEFT_AND_DAMAGE, ENGINE, PARKING].includes(claimCause)) {
            nextAction = wizardActions.goTo(MotorRoutePaths.DYN_CAROUSEL_END_IS_POLICE_CONTACTED);
        }
        dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(nextAction));
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            <StartSummary
                title={tWithNS.t('body.title')}
                description={tWithNS.t('body.paragraph.one')}
                sessionText={tWithNS.t('body.paragraph.two')}
                {...{ customCAN }}
            />
        </PageLayout>
    );
};
