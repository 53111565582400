import 'initializers/locale';
import {
    ClaimLocationKeys,
    FlowKeys,
    LocaleServiceInstance,
    MotorClaimCauseKeys,
    ObjectWithDynamicKey,
} from '@protectorinsurance/ds-can';
import { MotorRoutePaths } from './motorWizardRoutes';

const { language } = LocaleServiceInstance.getLocaleFromUrl();

const motorProgressBar = (path: MotorRoutePaths) => {
    return path.replace(':language', language);
};

export const motorProgressBarRoutes: ObjectWithDynamicKey = {
    [FlowKeys.START]: [
        motorProgressBar(MotorRoutePaths.START_WELCOME),
        motorProgressBar(MotorRoutePaths.START_WINDSHIELD_DAMAGE),
        motorProgressBar(MotorRoutePaths.START_START_SUMMARY),
        motorProgressBar(MotorRoutePaths.START_CLAIM_DATE),
        motorProgressBar(MotorRoutePaths.START_VEHICLE_REGISTRATION_NUMBER),
        motorProgressBar(MotorRoutePaths.START_VEHICLE_INFORMATION),
        motorProgressBar(MotorRoutePaths.DYN_CAROUSEL_START_INSURED_BY_PROTECTOR),
        motorProgressBar(MotorRoutePaths.START_STOP),
        motorProgressBar(MotorRoutePaths.START_COMPANY_INFORMATION),
        motorProgressBar(MotorRoutePaths.DYN_CAROUSEL_START_IS_DRIVING),
        motorProgressBar(MotorRoutePaths.DYN_CAROUSEL_DRIVING_IS_OTHER_VEHICLE_INVOLVED),
        motorProgressBar(MotorRoutePaths.DYN_CAROUSEL_PARKED_CLAIM_CAUSE),
    ],
    [FlowKeys.VEHICLES]: [
        motorProgressBar(MotorRoutePaths.DRIVING_VEHICLE_DAMAGES),
        motorProgressBar(MotorRoutePaths.DRIVING_VEHICLE_VEHICLE_REGISTRATION_NUMBER),
        motorProgressBar(MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_UNIDENTIFIED_TYPE),
        motorProgressBar(MotorRoutePaths.DRIVING_VEHICLE_VEHICLE_INFORMATION),
        motorProgressBar(MotorRoutePaths.DRIVING_VEHICLE_DRIVER_INFORMATION),
        motorProgressBar(MotorRoutePaths.DRIVING_VEHICLE_OWNER_INFORMATION),
        motorProgressBar(MotorRoutePaths.DRIVING_VEHICLE_SPEED_INFORMATION),
        motorProgressBar(MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_CLAIM_LOCATION),
    ],
    [ClaimLocationKeys.ROAD]: [
        motorProgressBar(MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_ROAD_TYPE_OF_ROAD),
        motorProgressBar(MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_ROAD_CLAIM_CAUSE),
    ],
    [ClaimLocationKeys.ROUNDABOUT]: [
        motorProgressBar(MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_ROUNDABOUT_CLAIMANT_ROUNDABOUT_POSITIONING),
        motorProgressBar(MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_ROUNDABOUT_COUNTERPARTY_ROUNDABOUT_POSITIONING),
        motorProgressBar(MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_ROUNDABOUT_CLAIM_CAUSE),
    ],
    [ClaimLocationKeys.INTERSECTION]: [
        motorProgressBar(MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_INTERSECTION_TYPE_OF_INTERSECTION),
        motorProgressBar(MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_INTERSECTION_CLAIM_CAUSE),
    ],
    [ClaimLocationKeys.PARKING_OTHER]: [
        motorProgressBar(MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_PARKING_OTHER_CLAIM_CAUSE),
    ],
    [MotorClaimCauseKeys.REVERSING]: [
        motorProgressBar(MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_REVERSING_WHO_AT_FAULT),
        motorProgressBar(MotorRoutePaths.DRIVING_VEHICLE_REVERSING_CLAIM_DESCRIPTION),
        motorProgressBar(MotorRoutePaths.DRIVING_VEHICLE_COLLISION),
    ],
    [MotorClaimCauseKeys.LANE_CHANGE]: [
        motorProgressBar(MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_LANE_CHANGE_WHO_AT_FAULT),
        motorProgressBar(MotorRoutePaths.DRIVING_VEHICLE_COLLISION),
    ],
    [MotorClaimCauseKeys.OVERTAKING]: [
        motorProgressBar(MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_OVERTAKING_WHO_AT_FAULT),
        motorProgressBar(MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_IS_LANE_CHANGE),
        motorProgressBar(MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_LANE_CHANGE_WHO_AT_FAULT),
        motorProgressBar(MotorRoutePaths.DRIVING_VEHICLE_COLLISION),
    ],
    [MotorClaimCauseKeys.HEAD_ON]: [
        motorProgressBar(MotorRoutePaths.DRIVING_VEHICLE_ROAD_INFORMATION),
        motorProgressBar(MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_HEAD_ON_IS_COUNTERPARTY_STATIONARY),
        motorProgressBar(MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_IS_LANE_CHANGE),
        motorProgressBar(MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_LANE_CHANGE_WHO_AT_FAULT),
        motorProgressBar(MotorRoutePaths.DRIVING_VEHICLE_COLLISION),
    ],
    [MotorClaimCauseKeys.REAR_ENDED]: [
        motorProgressBar(MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_REAR_ENDED_WHO_AT_FAULT),
        motorProgressBar(MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_IS_LANE_CHANGE),
        motorProgressBar(MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_LANE_CHANGE_WHO_AT_FAULT),
        motorProgressBar(MotorRoutePaths.DRIVING_VEHICLE_COLLISION),
    ],
    [MotorClaimCauseKeys.NARROW_ROAD_COLLISION]: [],
    [MotorClaimCauseKeys.MULTIPLE_COLLISION]: [],
    [MotorClaimCauseKeys.PARKED]: [
        motorProgressBar(MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_PARKED_WHO_AT_FAULT),
        motorProgressBar(MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_PARKED_HAS_PASSENGERS),
        motorProgressBar(MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_PARKED_IS_REVERSING),
    ],

    [FlowKeys.NO_VEHICLES]: [motorProgressBar(MotorRoutePaths.DYN_CAROUSEL_DRIVING_NO_VEHICLE_CLAIM_CAUSE)],
    [MotorClaimCauseKeys.DITCH_COLLISION]: [
        motorProgressBar(MotorRoutePaths.DRIVING_NO_VEHICLE_DITCH_CLAIM_DESCRIPTION),
        motorProgressBar(MotorRoutePaths.DRIVING_NO_VEHICLE_FREIGHT_WEIGHT),
        motorProgressBar(MotorRoutePaths.DRIVING_NO_VEHICLE_UPLOAD_FREIGHT_DOCUMENT),
        motorProgressBar(MotorRoutePaths.DRIVING_NO_VEHICLE_ROAD_CONDITION_DESCRIPTION),
    ],
    [MotorClaimCauseKeys.ANIMAL_COLLISION]: [
        motorProgressBar(MotorRoutePaths.DYN_CAROUSEL_DRIVING_NO_VEHICLE_ANIMAL_TYPE_OF_ANIMAL),
        motorProgressBar(MotorRoutePaths.DYN_CAROUSEL_DRIVING_NO_VEHICLE_ANIMAL_HAS_ANIMAL_ESCAPED),
        motorProgressBar(MotorRoutePaths.DRIVING_NO_VEHICLE_OWNER_INFORMATION_LIST),
    ],
    [MotorClaimCauseKeys.STATIONARY_OBJECT_COLLISION]: [
        motorProgressBar(MotorRoutePaths.DYN_CAROUSEL_DRIVING_NO_VEHICLE_IS_REVERSING),
    ],
    [MotorClaimCauseKeys.PEDESTRIAN_COLLISION]: [
        motorProgressBar(MotorRoutePaths.DYN_CAROUSEL_DRIVING_NO_VEHICLE_IS_REVERSING),
    ],
    [MotorClaimCauseKeys.CYCLIST_COLLISION]: [
        motorProgressBar(MotorRoutePaths.DYN_CAROUSEL_DRIVING_NO_VEHICLE_IS_REVERSING),
        motorProgressBar(MotorRoutePaths.DRIVING_VEHICLE_SPEED_INFORMATION),
        motorProgressBar(MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_CLAIM_LOCATION),
    ],
    [MotorClaimCauseKeys.THEFT_AND_DAMAGE]: [
        motorProgressBar(MotorRoutePaths.DYN_CAROUSEL_PARKED_THEFT_AND_DAMAGES_IS_SELF_DISCOVERED_CLAIM),
        motorProgressBar(MotorRoutePaths.PARKED_CLAIM_DISCOVERER_INFORMATION),
        motorProgressBar(MotorRoutePaths.DYN_CAROUSEL_PARKED_THEFT_AND_DAMAGES_IS_VEHICLE_STOLEN),
        motorProgressBar(MotorRoutePaths.PARKED_PARKING_DATE),
        motorProgressBar(MotorRoutePaths.DYN_CAROUSEL_PARKED_THEFT_AND_DAMAGES_IS_ITEM_MISSING),
        motorProgressBar(MotorRoutePaths.PARKED_PARKING_INFORMATION),
        motorProgressBar(MotorRoutePaths.PARKED_MISSING_ITEMS),
        motorProgressBar(MotorRoutePaths.DYN_CAROUSEL_PARKED_THEFT_AND_DAMAGES_HAS_ALL_KEYS),
        motorProgressBar(MotorRoutePaths.PARKED_OTHER_MISSING_ITEMS),
        motorProgressBar(MotorRoutePaths.PARKED_THEFT_AND_DAMAGES_CLAIM_DESCRIPTION),
        motorProgressBar(MotorRoutePaths.PARKED_UPLOAD_RECEIPT),
        motorProgressBar(MotorRoutePaths.DYN_CAROUSEL_PARKED_THEFT_AND_DAMAGES_IS_VEHICLE_RECOVERED),
        motorProgressBar(MotorRoutePaths.PARKED_RECOVERY_DATE),
        motorProgressBar(MotorRoutePaths.PARKED_RECOVERER_INFORMATION),
    ],
    [MotorClaimCauseKeys.ENGINE]: [motorProgressBar(MotorRoutePaths.PARKED_ENGINE_CLAIM_DESCRIPTION)],
    [MotorClaimCauseKeys.FIRE]: [
        motorProgressBar(MotorRoutePaths.PARKED_UPLOAD_FIRE_REPORT),
        motorProgressBar(MotorRoutePaths.PARKED_FIRE_CLAIM_DESCRIPTION),
    ],
    [MotorClaimCauseKeys.PARKING]: [
        motorProgressBar(MotorRoutePaths.DYN_CAROUSEL_PARKED_PARKING_IS_COUNTERPARTY_KNOWN),
        motorProgressBar(MotorRoutePaths.PARKED_COUNTERPARTY_REGISTRATION_NUMBER),
        motorProgressBar(MotorRoutePaths.DYN_CAROUSEL_PARKED_PARKING_UNIDENTIFIED_TYPE),
        motorProgressBar(MotorRoutePaths.PARKED_VEHICLE_INFORMATION),
        motorProgressBar(MotorRoutePaths.PARKED_UPLOAD_ATTACHMENTS),
        motorProgressBar(MotorRoutePaths.PARKED_COUNTERPARTY_INFORMATION),
    ],
    [MotorClaimCauseKeys.OTHER]: [
        motorProgressBar(MotorRoutePaths.DYN_CAROUSEL_PARKED_OTHER_CLAIM_CAUSE_DETAILS),
        motorProgressBar(MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_PARKED_IS_REVERSING),
    ],
    [FlowKeys.END]: [
        motorProgressBar(MotorRoutePaths.END_ACCIDENT_LOCATION),
        motorProgressBar(MotorRoutePaths.END_ACCIDENT_DESCRIPTION),
        motorProgressBar(MotorRoutePaths.END_VEHICLE_WHO_RESPONSIBLE),
        motorProgressBar(MotorRoutePaths.DYN_CAROUSEL_END_HAS_VEHICLE_DAMAGES),
        motorProgressBar(MotorRoutePaths.END_DAMAGES_OVERVIEW),
        motorProgressBar(MotorRoutePaths.END_VEHICLE_REGISTRATION_NUMBER),
        motorProgressBar(MotorRoutePaths.END_VEHICLE_INFORMATION),
        motorProgressBar(MotorRoutePaths.END_BODY),
        motorProgressBar(MotorRoutePaths.DYN_CAROUSEL_END_NEED_VEHICLE_TOWING),
        motorProgressBar(MotorRoutePaths.END_CONTACT_TOWING_SERVICE),
        motorProgressBar(MotorRoutePaths.DYN_CAROUSEL_END_HAS_NON_VEHICLE_DAMAGES),
        motorProgressBar(MotorRoutePaths.END_OWNER_INFORMATION_LIST),
        motorProgressBar(MotorRoutePaths.DYN_CAROUSEL_END_HAS_PERSON_INJURIES),
        motorProgressBar(MotorRoutePaths.END_INJURED_PERSON_INFORMATION),
        motorProgressBar(MotorRoutePaths.DYN_CAROUSEL_END_IS_POLICE_CONTACTED),
        motorProgressBar(MotorRoutePaths.END_POLICE_CASE_NUMBER),
        motorProgressBar(MotorRoutePaths.END_UPLOAD_POLICE_REPORT),
        motorProgressBar(MotorRoutePaths.END_REPORTER_INFORMATION),
        motorProgressBar(MotorRoutePaths.END_DRIVER_INFORMATION),
        motorProgressBar(MotorRoutePaths.DYN_CAROUSEL_END_HAS_WITNESSES),
        motorProgressBar(MotorRoutePaths.END_WITNESS_INFORMATION),
        motorProgressBar(MotorRoutePaths.END_UPLOAD_ATTACHMENT),
        motorProgressBar(MotorRoutePaths.END_POLICY_HOLDERS_CONTACT),
        motorProgressBar(MotorRoutePaths.END_FINAL_SUMMARY),
        motorProgressBar(MotorRoutePaths.END_REPORT_COMPLETED),
    ],
};
