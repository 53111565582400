import FileModel, { createFileError } from '../../../models/File';
import {
    actionWithPromise,
    api,
    emptyFn,
    FileErrorTypes,
    LogServiceModel,
    MULTI_PART_HEADER,
    Rejectable,
    Resolvable,
} from '@protectorinsurance/ds-can';
import { attachmentActions } from './attachment';
import { takeEvery } from 'redux-saga/effects';
import { selectClaimReportId } from '../../selectors/reportSelectors';
import { createFormData } from '../../../utils/createFormData';
import { pollAttachmentActions } from './pollAttachment';
import { call, put, select } from 'typed-redux-saga';
import { selectRequestId } from '../../selectors/commonSelectors';
import { logServiceActions } from 'sagas/services/logService';
import { NODE_API_BASE_URL } from '../../../config/api';

/**
 * Action Types
 */
export enum UploadAttachmentActionTypes {
    REQUEST = '@app/upload/attachment/upload/REQUEST',
    SUCCESS = '@app/upload/attachment/upload/SUCCESS',
    FAILURE = '@app/upload/attachment/upload/FAILURE',
}

/**
 * Action Definitions
 */
export interface UploadAttachmentAction {
    type: UploadAttachmentActionTypes;
    data: FileModel;
    resolve?: Resolvable;
    reject?: Rejectable;
}

/**
 * Redux Actions
 */
export const uploadAttachmentActions = {
    request: actionWithPromise(UploadAttachmentActionTypes.REQUEST),
    success: actionWithPromise(UploadAttachmentActionTypes.SUCCESS),
    failure: actionWithPromise(UploadAttachmentActionTypes.FAILURE),
};

/**
 * Saga Functions
 */
export const sagas = {
    *request({ data, resolve = emptyFn, reject = emptyFn }: UploadAttachmentAction) {
        try {
            yield* put(attachmentActions.add(data));

            const requestId = yield* select(selectRequestId);
            const submissionId = yield* select(selectClaimReportId);
            const formData = createFormData(data);
            const config = {
                baseURL: NODE_API_BASE_URL,
                headers: {
                    ...MULTI_PART_HEADER,
                    'X-Request-Id': `${requestId}`,
                },
            };

            if (data.errors.length === 0) {
                const res: any = yield* call(api.post, `upload/submission/${submissionId}/files`, formData, config);
                const externalId = res.headers.location.replace('/api/', '').replace('/meta', '');
                const file = { ...data, externalId };

                yield* put(attachmentActions.update(file));
                yield* put(pollAttachmentActions.request(file));
                yield* put(uploadAttachmentActions.success());
                resolve();
            } else {
                const file = createFileError(data, UploadAttachmentActionTypes.FAILURE, {
                    name: 'FileValidationError',
                    message: FileErrorTypes.UNSUPPORTED,
                });
                yield* put(attachmentActions.update(file));
                yield* put(uploadAttachmentActions.failure(file));
                reject();
            }
        } catch (e) {
            const file = createFileError(data, UploadAttachmentActionTypes.FAILURE, e as any);
            if ((e as any).url?.includes('null')) {
                const requestId = yield* select(selectRequestId);
                const errorPayload: LogServiceModel = {
                    level: 'error',
                    message: `Missing UUID: Tried to upload file by name of ${file.name} in uploadAttachment (UK). X-Request-Id=${requestId}`,
                };
                yield* put(logServiceActions.request(errorPayload));
            }
            yield* put(attachmentActions.update(file));
            yield* put(uploadAttachmentActions.failure(file));
            reject();
        }
    },
};

/**
 * Saga Watchers
 */
export const uploadAttachmentWatchers = function* () {
    yield takeEvery(UploadAttachmentActionTypes.REQUEST, sagas.request);
};
