import React from 'react';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    Clickable,
    HeadOnCollisionIcon,
    is,
    LaneChangeIcon,
    MotorClaimCauseKeys,
    MultipleCollisionIcon,
    NarrowRoadCollisionIcon,
    OvertakingIcon,
    ParkedIcon,
    RearEndedIcon,
    ReversingIcon,
    TypeOfRoadKeys,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../../../utils/carouselPageLookup';
import { MotorRoutePaths } from '../../../../../wizardRouter/motorWizardRoutes';
import { selectClaimCause, selectTypeOfRoad } from '../../../../../../sagas/selectors/motorSelectors';
import { wizardRouterActions as wizardActions } from '../../../../../../sagas/wizardRouter';
import dispatcherWithPromise from '../../../../../../utils/dispatcherWithPromise';
import { motorActions } from '../../../../../../sagas/motor';
import { commonActions } from '../../../../../../sagas/common';
import { flowActions } from '../../../../../../sagas/flow';

/**
 * Destructure necessary imports
 */
const {
    CYCLIST_COLLISION,
    HEAD_ON,
    LANE_CHANGE,
    MULTIPLE_COLLISION,
    NARROW_ROAD_COLLISION,
    OVERTAKING,
    PARKED,
    REAR_ENDED,
    REVERSING,
} = MotorClaimCauseKeys;
const { ROAD } = TypeOfRoadKeys;
/**
 * Page view and page logic
 */
export const drivingVehiclesRoadClaimCausePage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<MotorRoutePaths> => {
    const claimCause = selectClaimCause(state);
    const typeOfRoad = selectTypeOfRoad(state);
    let options = [
        { id: REAR_ENDED, name: t(`motor.driving.roadClaimCause.select.${REAR_ENDED}`), icon: <RearEndedIcon /> },
        { id: HEAD_ON, name: t(`motor.driving.roadClaimCause.select.${HEAD_ON}`), icon: <HeadOnCollisionIcon /> },
        { id: OVERTAKING, name: t(`motor.driving.roadClaimCause.select.${OVERTAKING}`), icon: <OvertakingIcon /> },
        { id: PARKED, name: t(`motor.driving.roadClaimCause.select.${PARKED}`), icon: <ParkedIcon /> },
        { id: LANE_CHANGE, name: t(`motor.driving.roadClaimCause.select.${LANE_CHANGE}`), icon: <LaneChangeIcon /> },
        { id: REVERSING, name: t(`motor.driving.roadClaimCause.select.${REVERSING}`), icon: <ReversingIcon /> },
    ];

    if (is(typeOfRoad, ROAD)) {
        options = [
            ...options,
            {
                id: NARROW_ROAD_COLLISION,
                name: t(`motor.driving.roadClaimCause.select.${NARROW_ROAD_COLLISION}`),
                icon: <NarrowRoadCollisionIcon />,
            },
        ];
    } else {
        options = [
            ...options,
            {
                id: MULTIPLE_COLLISION,
                name: t(`motor.driving.roadClaimCause.select.${MULTIPLE_COLLISION}`),
                icon: <MultipleCollisionIcon />,
            },
        ];
    }

    return {
        slug: MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_ROAD_CLAIM_CAUSE,
        stateKey: 'claimCause',
        i18n: {
            ns: 'motor.driving.roadClaimCause',
        },
        selectorValue: selectClaimCause(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                if (!is(option.id, claimCause)) {
                    dispatch(wizardActions.skipBackToPrev(true));
                }

                if (!is(claimCause, CYCLIST_COLLISION)) {
                    dispatcherWithPromise(dispatch, motorActions.update, { claimCause: option.id });
                }

                dispatcherWithPromise(dispatch, flowActions.update, option.id)
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext(option.id)));
            }
        },
    };
};
