import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { propertyDescriptionSchema } from '../fieldSchemas/propertyDescriptionSchema';

/**
 * Validation logic
 */
export const propertyClaimDescriptionSchema = (t: TFunction, label: string): Yup.ObjectSchema => {
    return Yup.object().shape({
        ...propertyDescriptionSchema(t, label),
    });
};
