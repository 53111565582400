/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react';
import React, { Fragment } from 'react';
import {
    ButtonClickable,
    Clickable,
    Nullable,
} from '../../../interfaces/types';

import {
    BusIcon,
    CommonCarIcon,
    CommonMotorcycleIcon,
    ExcavatorIcon,
    ForkliftIcon,
    MiniTruckIcon,
    MotorredskapIcon,
    TerrainScootersIcon,
    TerrianTrailerIcon,
    TerrianWagonIcon,
    TractorIcon,
    TrailerIcon,
    TruckIcon,
} from '../icons/CommonIcons';
import { GarbageIcon, OtherInjuriesIcon } from '../icons/BaseIcons';
import { vehicleListItemCss } from './VehicleListItem.style';
import { CircleIconButton } from '../buttons/CircleIconButton';
import {
    VehicleTypeKeys,
    VehicleTypeTypeModel,
} from '../../../modules/can/motor/interfaces/models';
import {
    YesNoKeys,
    YesNoModel,
} from '../../../modules/can/common/interfaces/models';
import { customCANProps } from '../../../config/customCANCompanies';

/**
 * Interfaces
 */
interface VehicleListItemProps {
    bodyTitle?: string;
    buttonNoTitle?: string;
    buttonUnknownTitle?: string;
    buttonYesTitle?: string;
    customCAN?: customCANProps;
    damages?: { key: string; value: any }[];
    dataTestId?: string;
    handleClick?: (e: ButtonClickable) => void;
    handleDelete?: (e: Clickable) => void;
    hasDamages?: boolean;
    insuranceCompany?: string;
    isTotalDamage?: boolean;
    isTotalDamageTitle?: string;
    make: Nullable<string>;
    model: Nullable<number>;
    registrationNumber: Nullable<string>;
    type: Nullable<string>;
    vehicleHasDamages?: YesNoModel;
    vehicleType: VehicleTypeTypeModel;
}

/**
 * Component view and component logic
 */
export const getVehicleTypeIcon = ({
    type,
    wh = 30,
}: {
    type: Nullable<VehicleTypeKeys>;
    wh?: number;
}): JSX.Element => {
    if (!type) {
        return <span></span>;
    }
    switch (type) {
        case VehicleTypeKeys.BUS:
            return <BusIcon wh={wh} />;
        case VehicleTypeKeys.CAR:
            return <CommonCarIcon wh={wh} />;
        case VehicleTypeKeys.EXCAVATOR:
            return <ExcavatorIcon wh={wh} />;
        case VehicleTypeKeys.LIGHT_TRUCK:
            return <MiniTruckIcon wh={wh} />;
        case VehicleTypeKeys.MOPED:
            return <CommonMotorcycleIcon wh={wh} />;
        case VehicleTypeKeys.MOTORCYCLE:
            return <CommonMotorcycleIcon wh={wh} />;
        case VehicleTypeKeys.MOTORIZED_TOOL:
            return <MotorredskapIcon wh={wh} />;
        case VehicleTypeKeys.OTHER:
            return <OtherInjuriesIcon wh={wh} />;
        case VehicleTypeKeys.POWERED_INDUSTRIAL_TRUCK:
            return <ForkliftIcon wh={wh} />;
        case VehicleTypeKeys.SUP_400_TERRAIN_VEHICLE:
            return <TerrianWagonIcon wh={wh} />;
        case VehicleTypeKeys.TERRAIN_SCOOTER:
            return <TerrainScootersIcon wh={wh} />;
        case VehicleTypeKeys.TERRAIN_TRAILER:
            return <TerrianTrailerIcon wh={wh} />;
        case VehicleTypeKeys.TERRAIN_VEHICLE:
            return <TerrianWagonIcon wh={wh} />;
        case VehicleTypeKeys.TRACTOR:
            return <TractorIcon wh={wh} />;
        case VehicleTypeKeys.TRAILER:
            return <TrailerIcon wh={wh} />;
        case VehicleTypeKeys.TRUCK:
            return <TruckIcon wh={wh} />;
        default:
            return <OtherInjuriesIcon wh={wh} />;
    }
};

export const VehicleListItem = ({
    bodyTitle,
    buttonNoTitle,
    buttonUnknownTitle,
    buttonYesTitle,
    customCAN,
    damages,
    dataTestId,
    handleClick,
    handleDelete,
    insuranceCompany,
    isTotalDamage,
    isTotalDamageTitle,
    hasDamages,
    make,
    model,
    registrationNumber,
    type,
    vehicleHasDamages,
    vehicleType,
}: VehicleListItemProps) => {
    const { YES, NO, DONT_KNOW } = YesNoKeys;

    return (
        <Fragment>
            {insuranceCompany && (
                <div
                    className={`${damages ? 'col-3' : 'col-4'} vehicle-divider`}
                    tabIndex={0}
                    css={css(vehicleListItemCss(customCAN))}
                >
                    {insuranceCompany}
                </div>
            )}
            <div
                className={'col-2 vehicle-divider'}
                tabIndex={0}
                css={css(vehicleListItemCss(customCAN))}
                data-testid={dataTestId}
            >
                <span className={'regnr'}>
                    {registrationNumber
                        ? registrationNumber.toUpperCase()
                        : '-'}
                </span>
            </div>
            <div
                className={`${
                    insuranceCompany
                        ? damages
                            ? 'col-1'
                            : 'col-2'
                        : damages
                        ? 'col-1'
                        : 'col-3'
                } vehicle-divider`}
                css={css(vehicleListItemCss(customCAN))}
            >
                {getVehicleTypeIcon({ type: vehicleType })}
            </div>
            <div
                className={`${
                    insuranceCompany
                        ? 'col-3'
                        : damages && damages.length > 0
                        ? 'col-3'
                        : hasDamages
                        ? 'col-4'
                        : 'col-5'
                } vehicle-divider`}
                tabIndex={0}
                css={css(vehicleListItemCss(customCAN))}
            >
                {model && model} {make && make} {type && type}{' '}
                {!model && !make && !type && '-'}
            </div>
            {damages && damages.length > 0 && (
                <div
                    className={'col-2 vehicle-divider'}
                    css={css(vehicleListItemCss(customCAN))}
                >
                    {damages && (
                        <Fragment>
                            {bodyTitle && <h2>{bodyTitle}</h2>}
                            <ul>
                                {damages?.map(
                                    (
                                        damage: { key: string; value: number },
                                        idx: number
                                    ) => {
                                        return (
                                            <li key={idx}>
                                                {damage.key} x {damage.value}
                                            </li>
                                        );
                                    }
                                )}
                            </ul>
                        </Fragment>
                    )}
                </div>
            )}
            {isTotalDamage && (
                <div
                    className={'col-3 vehicle-divider'}
                    css={css(vehicleListItemCss(customCAN))}
                >
                    {isTotalDamageTitle}
                </div>
            )}
            {hasDamages && (
                <div
                    className={`${
                        damages && damages.length > 0 ? 'col-3' : 'col-4'
                    } vehicle-divider`}
                    css={css(vehicleListItemCss(customCAN))}
                >
                    <div className={'button-wrapper'}>
                        <button
                            type={'button'}
                            className={`has-damages-buttons ${
                                vehicleHasDamages == YES
                                    ? 'has-damages-buttons-active'
                                    : 'button-hover'
                            }`}
                            onClick={handleClick}
                            value={YES}
                        >
                            {buttonYesTitle}
                        </button>
                        <button
                            type={'button'}
                            className={`has-damages-buttons ${
                                vehicleHasDamages == NO
                                    ? 'has-damages-buttons-active'
                                    : 'button-hover'
                            }`}
                            onClick={handleClick}
                            value={NO}
                        >
                            {buttonNoTitle}
                        </button>
                        <button
                            type={'button'}
                            className={`has-damages-buttons ${
                                vehicleHasDamages == DONT_KNOW
                                    ? 'has-damages-buttons-active'
                                    : 'button-hover'
                            }`}
                            onClick={handleClick}
                            value={DONT_KNOW}
                        >
                            {buttonUnknownTitle}
                        </button>
                    </div>
                </div>
            )}

            <div
                className={'col-1 vehicle-divider last delete'}
                css={css(vehicleListItemCss(customCAN))}
            >
                {handleDelete && (
                    <CircleIconButton
                        dataTestId={'btn-delete'}
                        icon={<GarbageIcon />}
                        name={'btn-delete'}
                        className={'delete-button small'}
                        handleClick={handleDelete}
                        ariaLabel={'Delete vehicle'}
                    />
                )}
            </div>
        </Fragment>
    );
};
