import React, { MouseEvent } from 'react';
import { SmallPersonIcon } from '@protectorinsurance/ds-can';
import { DamagedItemKeys, OwnerInformationListModel, OwnerInformationListTypeModel } from '@protectorinsurance/ds-can';
import './DisplayOwnerInformationList.scss';
import { useSelector } from 'react-redux';
import { selectVehicles } from '../../sagas/selectors/motorSelectors';
import { Nullable } from '@protectorinsurance/ds-can';

const { OTHER } = DamagedItemKeys;

interface DisplayOwnerInformationListProps {
    owners: OwnerInformationListTypeModel;
    handleDelete(e: MouseEvent, owner: OwnerInformationListModel): void;
    buttonText: string;
}

export const DisplayOwnerInformationList = ({ owners, handleDelete, buttonText }: DisplayOwnerInformationListProps) => {
    const vehicles = useSelector(selectVehicles);
    const vehicleInternalIds = vehicles.map((vehicle) => vehicle.internalId);

    const renderPerson = (owner: OwnerInformationListModel) => (
        <>
            {owner.firstName} {owner.lastName}
            {owner.damagedItem !== OTHER && ': ' + owner.damagedItem}
        </>
    );
    const renderCompany = (owner: OwnerInformationListModel) => (
        <>
            {owner.name}
            {owner.businessNumber && ', ' + owner.businessNumber}
            {owner.damagedItem !== OTHER && ': ' + owner.damagedItem}
        </>
    );

    const displayOwner = (damagedItem: Nullable<string>) => {
        return !vehicleInternalIds.includes(damagedItem) && damagedItem !== DamagedItemKeys.ANIMAL;
    };

    const renderOwnerList = owners.map((owner: OwnerInformationListModel, idx) => {
        if (displayOwner(owner.damagedItem)) {
            return (
                <li key={idx} className={'owner-list-item'}>
                    <SmallPersonIcon />
                    <span className={'list-text'} data-hj-suppress>
                        {owner.isCompany ? renderCompany(owner) : renderPerson(owner)}
                    </span>
                    <button
                        type={'button'}
                        data-testid={'delete-btn'}
                        className={'delete-btn'}
                        onClick={(e) => handleDelete(e, owner)}
                    >
                        {buttonText}
                    </button>
                </li>
            );
        } else return null;
    });

    return (
        <ul className={'list-container'} data-testid={'owner-list'}>
            {renderOwnerList}
        </ul>
    );
};
