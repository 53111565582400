import { TFunction } from 'i18next';
import { LeakCauseEnums } from '@protectorinsurance/ds-can';

/**
 * Utility logic
 */
export const createLeakCauseKey = (id: string) => {
    return `lpo.leakCause.select.${id}`;
};

export const getLeakCauseOption = (t: TFunction, key: LeakCauseEnums, value: string) => {
    return {
        value,
        label: t(createLeakCauseKey(key)),
    };
};
