import moment from 'moment';
import 'moment/locale/en-gb';
import { getMomentLocale } from '../utils/getMomentLocale';

const initMoment = () => {
    const momentLocale = getMomentLocale();
    moment.locale(momentLocale);
};

initMoment();
