import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useKeys } from 'rooks';
import { UseL10NReturn } from './useI18n.types';
import { joinNSWithKey } from '@protectorinsurance/ds-can';
import { isDev, isTest } from '../utils/env';

export const useI18n = (namespace?: string): UseL10NReturn<string> => {
    const [ns, setNs] = useState<string | undefined>(namespace);
    const [displayKey, setDisplayKey] = useState<boolean>(false);
    const { t, i18n } = useTranslation();

    useKeys(['l', 't'], () => setDisplayKey(!displayKey));

    return {
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        t: (key: any, options: any) => {
            const k = joinNSWithKey(key, ns);
            if (displayKey && (isDev() || isTest())) {
                return k;
            }
            return t(k, options);
        },
        setNs,
        i18n,
    } as UseL10NReturn<string>;
};
