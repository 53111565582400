import React from 'react';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    ClaimTypeEnums,
    Clickable,
    is,
    isYes,
    NoIcon,
    YesIcon,
    YesNoKeys,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../utils/carouselPageLookup';
import { LpoRoutePaths } from '../../../wizardRouter/lpoWizardRoutes';
import { selectHasInjury, selectIsEmployer } from '../../../../sagas/selectors/lpoSelectors';
import { wizardRouterActions } from '../../../../sagas/wizardRouter';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { lpoActions } from '../../../../sagas/lpo';
import { commonActions } from '../../../../sagas/common';

/**
 * Destructure necessary imports
 */
const { YES, NO } = YesNoKeys;
const { LIABILITY_CLAIM_CAUSE } = LpoRoutePaths;
const { EMPLOYERS_LIABILITY, LIBEL_SLANDER } = ClaimTypeEnums;

/**
 * Page view and page logic
 */
export const liabilityHasInjuryPage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<LpoRoutePaths> => {
    const isEmployer = selectIsEmployer(state);
    const options = [
        { id: YES, name: t(`lpo.liability.hasInjury.select.${YES}`), icon: <YesIcon /> },
        { id: NO, name: t(`lpo.liability.hasInjury.select.${NO}`), icon: <NoIcon /> },
    ];

    return {
        slug: LpoRoutePaths.DYN_CAROUSEL_LIABILITY_HAS_INJURY,
        stateKey: 'hasInjury',
        i18n: {
            ns: 'lpo.liability.hasInjury',
        },
        selectorValue: selectHasInjury(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                let nextAction = wizardRouterActions.goToNext();
                if (isYes(isEmployer) && isYes(option.id)) {
                    nextAction = wizardRouterActions.goTo(LIABILITY_CLAIM_CAUSE);
                    dispatcherWithPromise(dispatch, lpoActions.update, { claimType: EMPLOYERS_LIABILITY });
                }

                if (isYes(isEmployer) && is(option.id, NO)) {
                    nextAction = wizardRouterActions.goTo(LIABILITY_CLAIM_CAUSE);
                    dispatcherWithPromise(dispatch, lpoActions.update, { claimType: LIBEL_SLANDER });
                }

                dispatcherWithPromise(dispatch, lpoActions.update, { hasInjury: option.id })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(nextAction));
            }
        },
    };
};
