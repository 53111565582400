import React, { MouseEvent, SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../hooks/useI18n';
import { useGoBack } from '../../../hooks/useGoBack';
import {
    AddIcon,
    CircleIconButton,
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    is,
    MuiAutocomplete,
    MuiTextInput,
    OptionType,
    PageLayout,
    PersonList,
    RoleEnums,
} from '@protectorinsurance/ds-can';
import { PhraseKeys } from '../../../config/phraseKeys';
import { FormFieldErrors, FormFieldNames } from '../../../config/formFieldNames';
import { Controller, useForm } from 'react-hook-form';
import { AutocompleteValueKeys } from '../../../config/AutocompleteValueKeys';
import { crossContactInfoFieldValidation } from '../../../utils/validation/crossContactInfoFieldValidation';
import { lpoActions } from '../../../sagas/lpo';
import { selectThirdPartyInformation } from '../../../sagas/selectors/lpoSelectors';
import { thirdPartyInformationSchema } from '../../../validations/schemas/thirdPartyInformationSchema';
import { removeThirdParty, thirdPartyExists } from '../../../utils/personUtils';
import { ThirdPartyInformationModel } from '../../../models/ThirdPartyInformation';
import { getRoleOption } from '../../../utils/lpo/thirdPartyInformationUtils';
import { yupResolver } from '@hookform/resolvers/yup';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../sagas/common';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const {
    ADD_BUTTON,
    BACK_BUTTON,
    CONTINUE_BUTTON,
    DELETE_BUTTON,
    EMAIL_LABEL,
    EMAIL_PLACEHOLDER,
    FIRST_NAME_LABEL,
    FIRST_NAME_PLACEHOLDER,
    HELP_TEXT,
    LAST_NAME_LABEL,
    LAST_NAME_PLACEHOLDER,
    NO_OPTIONS_MESSAGE,
    PAGE_NAME,
    PHONE_LABEL,
    PHONE_PLACEHOLDER,
    REFERENCE_LABEL,
    REFERENCE_PLACEHOLDER,
    ROLE_LABEL,
    ROLE_PLACEHOLDER,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;
const { EMAIL, FIRST_NAME, LAST_NAME, PHONE, REFERENCE, ROLE } = FormFieldNames;
const { EMAIL: EMAIL_AUTOCOMPLETE, FAMILY_NAME, GIVEN_NAME, TEL } = AutocompleteValueKeys;
const { PERSON_EXISTS, REQUIRED_ERROR_MESSAGE } = FormFieldErrors;
const {
    ACCIDENT_MANAGEMENT_COMPANY,
    BROKER,
    CLAIMANT,
    CLAIMANT_CONTACT_PERSON,
    CLAIMANT_SOLICITORS,
    CLAIMS_HANDLING_COMPANY,
    CO_INSURED,
    CONTRACTOR,
    COUNSEL,
    COUNTER_PARTY,
    COURT,
    CREDIT_HIRE_COMPANY,
    CRU,
    DENTIST,
    DRIVER,
    DVLA,
    ENGINEERS,
    EXPERTS_OTHER,
    FINANCE_PROVIDER,
    FORENSIC_EXPERT,
    GENERAL_PRACTITIONERS,
    HOSPITAL,
    INSURANCE_COMPANY,
    LAND_REGISTRY,
    LEASING_COMPANY,
    LITIGATION_FRIEND,
    LOSS_ADJUSTER,
    MEDICAL_EXPERT,
    MOTOR_INSURERS_BUREAU,
    OWN_HIRE_COMPANY,
    OWN_SOLICITORS,
    POLICE,
    POLICY_HOLDER,
    POLICY_HOLDERS_CONTACT_PERSON,
    POWER_OF_ATTORNEY,
    PROPERTY_SUPPLIERS,
    REGULATORY_OFFICERS,
    REHABILITATION_PROVIDER,
    REINSURER,
    SALVAGE_COMPANY,
    UNINSURED_LOSS_RECOVERY_AGENT,
    VALIDATION_SUPPLIER,
    WITNESS,
} = RoleEnums;
/**
 * Page view and page logic
 */
export const LiabilityThirdPartyInformationPage = () => {
    const dispatch = useDispatch();
    const thirdPartyInformation = useSelector(selectThirdPartyInformation);
    const customCAN = useSelector(selectCustomCAN);
    const [canGoNext, setCanGoNext] = useState<boolean>(false);
    const [role, setRole] = useState<OptionType | null>(null);
    const [roleError, setRoleError] = useState<string>('');
    const { t } = useI18n();
    const tWithNS = useI18n('lpo.liability.thirdPartyInformation');
    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
        setError,
        setValue,
        trigger,
        watch,
    } = useForm<ThirdPartyInformationModel>({
        resolver: yupResolver(thirdPartyInformationSchema(t)),
        defaultValues: {
            email: '',
            firstName: '',
            lastName: '',
            phone: '',
            reference: '',
        },
    });
    const firstNameField = watch(FIRST_NAME);
    const lastNameField = watch(LAST_NAME);
    const emailField = watch(EMAIL);
    const phoneField = watch(PHONE);
    const fieldValues = [firstNameField, lastNameField, emailField, phoneField];
    const isFormEmpty = fieldValues.filter(Boolean).length === 0;

    // Options
    const accidentManagementCompanyOption = getRoleOption(t, ACCIDENT_MANAGEMENT_COMPANY, '445');
    const brokerOption = getRoleOption(t, BROKER, '204');
    const claimantOption = getRoleOption(t, CLAIMANT, '3');
    const claimantContactPersonOption = getRoleOption(t, CLAIMANT_CONTACT_PERSON, '456');
    const claimantSolicitorsOption = getRoleOption(t, CLAIMANT_SOLICITORS, '457');
    const claimsHandlingCompanyOption = getRoleOption(t, CLAIMS_HANDLING_COMPANY, '8');
    const coInsuredOption = getRoleOption(t, CO_INSURED, '321');
    const contractorOption = getRoleOption(t, CONTRACTOR, '446');
    const counselOption = getRoleOption(t, COUNSEL, '458');
    const counterPartyOption = getRoleOption(t, COUNTER_PARTY, '183');
    const courtOption = getRoleOption(t, COURT, '43');
    const creditHireCompanyOption = getRoleOption(t, CREDIT_HIRE_COMPANY, '444');
    const cruOption = getRoleOption(t, CRU, '459');
    const dentistOption = getRoleOption(t, DENTIST, '363');
    const driverOption = getRoleOption(t, DRIVER, '61');
    const dvlaOption = getRoleOption(t, DVLA, '460');
    const engineersOption = getRoleOption(t, ENGINEERS, '461');
    const expertsOtherOption = getRoleOption(t, EXPERTS_OTHER, '462');
    const financeProviderOption = getRoleOption(t, FINANCE_PROVIDER, '463');
    const forensicExpertOption = getRoleOption(t, FORENSIC_EXPERT, '465');
    const generalPractitionersOption = getRoleOption(t, GENERAL_PRACTITIONERS, '361');
    const hospitalOption = getRoleOption(t, HOSPITAL, '362');
    const insuranceCompanyOption = getRoleOption(t, INSURANCE_COMPANY, '62');
    const landRegistryOption = getRoleOption(t, LAND_REGISTRY, '480');
    const leasingCompanyOption = getRoleOption(t, LEASING_COMPANY, '386');
    const litigationFriendOption = getRoleOption(t, LITIGATION_FRIEND, '466');
    const lossAdjusterOption = getRoleOption(t, LOSS_ADJUSTER, '436');
    const medicalExpertOption = getRoleOption(t, MEDICAL_EXPERT, '467');
    const motorInsurersBureauOption = getRoleOption(t, MOTOR_INSURERS_BUREAU, '469');
    const ownHireCompanyOption = getRoleOption(t, OWN_HIRE_COMPANY, '470');
    const ownSolicitorsOption = getRoleOption(t, OWN_SOLICITORS, '471');
    const policeOption = getRoleOption(t, POLICE, '203');
    const policyHolderOption = getRoleOption(t, POLICY_HOLDER, '2');
    const policyHoldersContactPersonOption = getRoleOption(t, POLICY_HOLDERS_CONTACT_PERSON, '261');
    const powerOfAttorneyOption = getRoleOption(t, POWER_OF_ATTORNEY, '479');
    const propertySuppliersOption = getRoleOption(t, PROPERTY_SUPPLIERS, '438');
    const regulatoryOfficersOption = getRoleOption(t, REGULATORY_OFFICERS, '475');
    const rehabilitationProviderOption = getRoleOption(t, REHABILITATION_PROVIDER, '476');
    const reinsurerOption = getRoleOption(t, REINSURER, '6');
    const salvageCompanyOption = getRoleOption(t, SALVAGE_COMPANY, '65');
    const uninsuredLossRecoveryAgentOption = getRoleOption(t, UNINSURED_LOSS_RECOVERY_AGENT, '477');
    const validationSupplierOption = getRoleOption(t, VALIDATION_SUPPLIER, '478');
    const witnessOption = getRoleOption(t, WITNESS, '394');

    const options = [
        accidentManagementCompanyOption,
        brokerOption,
        claimantOption,
        claimantContactPersonOption,
        claimantSolicitorsOption,
        claimsHandlingCompanyOption,
        coInsuredOption,
        contractorOption,
        counselOption,
        counterPartyOption,
        courtOption,
        creditHireCompanyOption,
        cruOption,
        dentistOption,
        driverOption,
        dvlaOption,
        engineersOption,
        expertsOtherOption,
        financeProviderOption,
        forensicExpertOption,
        generalPractitionersOption,
        hospitalOption,
        insuranceCompanyOption,
        landRegistryOption,
        leasingCompanyOption,
        litigationFriendOption,
        lossAdjusterOption,
        medicalExpertOption,
        motorInsurersBureauOption,
        ownHireCompanyOption,
        ownSolicitorsOption,
        policeOption,
        policyHolderOption,
        policyHoldersContactPersonOption,
        propertySuppliersOption,
        regulatoryOfficersOption,
        rehabilitationProviderOption,
        salvageCompanyOption,
        uninsuredLossRecoveryAgentOption,
        validationSupplierOption,
        witnessOption,
        powerOfAttorneyOption,
        reinsurerOption,
    ];

    useEffect(() => {
        setCanGoNext(thirdPartyInformation.length > 0 && isFormEmpty);
    }, [isFormEmpty, thirdPartyInformation]);

    const handleBackButton = useGoBack();

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleRoleBlur = (e: FormChangeable) => {
        e.preventDefault();
        if (is(role, null)) {
            setRoleError(t(REQUIRED_ERROR_MESSAGE));
        } else {
            setRoleError('');
        }
    };

    const handleDelete = (e: MouseEvent, thirdParty: ThirdPartyInformationModel) => {
        e.preventDefault();
        const updatedThirdParty = removeThirdParty(thirdParty, thirdPartyInformation);
        dispatch(lpoActions.update({ thirdPartyInformation: updatedThirdParty }));
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        await setValue(id, value, { shouldValidate: true });
        await crossContactInfoFieldValidation(id, trigger);
    };

    const handleSelect = (e: SyntheticEvent, option: OptionType) => {
        const object = option ? (option as OptionType) : null;
        setRoleError('');
        setRole(object);
    };

    const handleNext = () => {
        dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(wizardActions.goToNext()));
    };

    const handleFormSubmit = (values: ThirdPartyInformationModel): boolean => {
        const value = role ? role.value : null;
        const key = role ? role.label : null;

        // Check if third party exists
        if (thirdPartyExists(values, thirdPartyInformation)) {
            setError(FIRST_NAME, { type: 'exists', message: t(PERSON_EXISTS) });
            return false;
        } else {
            if (is(role, null)) {
                setRoleError(t(REQUIRED_ERROR_MESSAGE));
                return false;
            } else {
                setRoleError('');
                dispatch(
                    lpoActions.update({
                        thirdPartyInformation: [
                            ...thirdPartyInformation,
                            {
                                ...values,
                                roleType: { id: Number(value), key },
                            },
                        ],
                    })
                );
                reset();
                setRole(null);
                return true;
            }
        }
    };

    const onSubmit = (values: ThirdPartyInformationModel) => {
        handleFormSubmit(values);
    };

    const onSubmitFormNext = (values: ThirdPartyInformationModel) => {
        if (handleFormSubmit(values)) {
            handleNext();
        }
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={canGoNext ? handleNext : handleSubmit(onSubmitFormNext)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <HiddenInputSubmit />
                <Grid className={'align-center'}>
                    <Controller
                        control={control}
                        name={FIRST_NAME}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={GIVEN_NAME}
                                error={!!errors.firstName}
                                errorMessage={errors.firstName?.message}
                                id={FIRST_NAME}
                                inputFieldWrapper={'col-4'}
                                label={t(FIRST_NAME_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(FIRST_NAME_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={LAST_NAME}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={FAMILY_NAME}
                                error={!!errors.lastName}
                                errorMessage={errors.lastName?.message}
                                id={LAST_NAME}
                                inputFieldWrapper={'col-4'}
                                label={t(LAST_NAME_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(LAST_NAME_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <MuiAutocomplete
                        error={!!roleError}
                        errorMessage={roleError}
                        id={ROLE}
                        inputFieldWrapper={'col-4'}
                        label={t(ROLE_LABEL)}
                        noOptionsText={t(NO_OPTIONS_MESSAGE)}
                        onBlur={handleRoleBlur}
                        onChange={handleSelect}
                        openOnFocus={true}
                        placeholder={t(ROLE_PLACEHOLDER)}
                        {...{ customCAN, options }}
                        value={role}
                    />
                    <Controller
                        control={control}
                        name={EMAIL}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={EMAIL_AUTOCOMPLETE}
                                error={!!errors.email}
                                errorMessage={errors.email?.message}
                                id={EMAIL}
                                inputFieldWrapper={'col-4'}
                                label={t(EMAIL_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(EMAIL_PLACEHOLDER)}
                                reference={ref}
                                type={'email'}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={PHONE}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                autoComplete={TEL}
                                error={!!errors.phone}
                                errorMessage={errors.phone?.message}
                                id={PHONE}
                                inputFieldWrapper={'col-4'}
                                label={t(PHONE_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(PHONE_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={REFERENCE}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                error={!!errors.reference}
                                errorMessage={errors.reference?.message}
                                id={REFERENCE}
                                inputFieldWrapper={'col-4'}
                                label={t(REFERENCE_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(REFERENCE_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <CircleIconButton
                        ariaLabel={t(ADD_BUTTON)}
                        className={'flex flex-col single-4-center'}
                        dataTestId={'btn-add-third-party'}
                        handleClick={handleSubmit(onSubmit)}
                        icon={<AddIcon />}
                    />
                </Grid>
            </form>

            <PersonList
                buttonText={t(DELETE_BUTTON)}
                persons={thirdPartyInformation}
                {...{ customCAN, handleDelete }}
            />
        </PageLayout>
    );
};
