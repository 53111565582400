import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useI18n } from '../../../hooks/useI18n';
import {
    ClaimVehicleSummary,
    Clickable,
    DamagePoint,
    findClaimVehicle,
    findOwnerByDamagedItem,
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    IncidentSummary,
    InputValidationError,
    isYes,
    MuiTextInput,
    NeedVehicleTowingKeys,
    NeedVehicleTowingModel,
    OwnerInformationListModel,
    PageLayout,
    PartyRelationKeys,
    pdfServiceActions,
    PDFServiceActionTypes,
    Printable,
    SummaryItemText,
    SummaryText,
    SummaryVehicleItem,
    VehicleModel,
} from '@protectorinsurance/ds-can';
import { useGoBack } from '../../../hooks/useGoBack';
import { PhraseKeys } from '../../../config/phraseKeys';
import { FormFieldNames } from '../../../config/formFieldNames';
import {
    selectAccidentDescription,
    selectClaimDescription,
    selectMotorState,
} from '../../../sagas/selectors/motorSelectors';
import { motorActions, MotorState } from '../../../sagas/motor';
import { Controller, useForm } from 'react-hook-form';
import { claimDescriptionSchema } from '../../../validations/schemas/claimDescriptionSchema';
import 'components/summary/final/finalSummary.scss';
import { DisplayEndSummary } from './DisplayEndSummary';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { selectUpload } from '../../../sagas/selectors/uploadSelectors';
import { getClaimDescriptionHistory } from '../../../utils/summary/getClaimDescriptionHistory';
import { dtParseAndFormat } from '../../../utils/date/dateUtils';
import { PrintableVehicleBodyDamages } from '../../vehicle/PrintableVehicleBodyDamages';
import { commonActions } from '../../../sagas/common';
import { DisplayIncidentEndSummary } from './DisplayIncidentEndSummary';
import { DisplayMotorStartSummary } from './DisplayMotorStartSummary';
import { DisplayMotorParkedSummary } from './DisplayParkedSummary';
import { DisplayMotorDrivingSummary } from './DisplayMotorDrivingSummary';
import {
    selectApiId,
    selectCustomCAN,
    selectLob,
    selectLocaleCountryCode,
    selectLocaleLanguageCode,
    selectRequestId,
    selectSubmitted,
} from '../../../sagas/selectors/commonSelectors';
import { formatOwner } from 'utils/strings/formatStrings';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import useErrorLoader from '../../../hooks/useErrorSelector';
import { claimDescriptionUtil } from '../../../utils/claimDescriptionUtil';

/**
 * Destructure necessary imports
 */
const {
    BACK_BUTTON,
    CLAIM_DESCRIPTION_PLACEHOLDER,
    CLAIM_DESCRIPTION_LABEL,
    DAMAGE_DESCRIPTION_LABEL,
    HELP_TEXT,
    PAGE_NAME,
    SEND_BUTTON,
    SUB_TITLE,
    SUBMITTING_BUTTON,
    TITLE,
} = PhraseKeys;
const { CLAIM_DESCRIPTION } = FormFieldNames;
const { CLAIM_VEHICLE } = PartyRelationKeys;
const { YES, NO, ALREADY_TOWED } = NeedVehicleTowingKeys;

/**
 * Interface
 */
interface PrintableSummaryPageProps {
    editDescription: boolean;
    showSummary?: boolean;
    handleClickOption?: (e: Clickable, path: string) => void;
}

/**
 * Page view and page logic
 */
export const PrintableSummaryPage = ({
    editDescription,
    showSummary = true,
    handleClickOption,
}: PrintableSummaryPageProps) => {
    const dispatch = useDispatch();
    const uuid = useSelector(selectApiId);
    const country = useSelector(selectLocaleCountryCode);
    const language = useSelector(selectLocaleLanguageCode);
    const lob = useSelector(selectLob);
    const requestId = useSelector(selectRequestId);
    const motorState = useSelector(selectMotorState);
    const uploadState = useSelector(selectUpload);
    const temporaryClaimDescription = useSelector(selectClaimDescription);
    const accidentDescription = useSelector(selectAccidentDescription);
    const customCAN = useSelector(selectCustomCAN);
    const { error: pdfError } = useErrorLoader(PDFServiceActionTypes.FAILURE);
    const claimDescriptionHistory = getClaimDescriptionHistory(motorState);
    const claimDescription =
        temporaryClaimDescription === ''
            ? [claimDescriptionHistory, accidentDescription, temporaryClaimDescription].join('\n')
            : temporaryClaimDescription;
    const { t } = useI18n();
    const tWithNS = useI18n('motor.end.finalSummary');
    const test = useTranslation('motor');
    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger,
    } = useForm<Pick<MotorState, 'claimDescription'>>({
        resolver: yupResolver(claimDescriptionSchema(t, CLAIM_DESCRIPTION_LABEL)),
        defaultValues: {
            claimDescription,
        },
    });
    const submitted = useSelector(selectSubmitted);

    const model = {
        ...motorState,
    };

    const upload = {
        ...uploadState,
    };

    const handleBackButton = useGoBack();
    const {
        accidentLocation,
        claimCause,
        claimDate,
        isDriving,
        needVehicleTowing,
        ownerInformationList,
        vehicles,
        accidentSketch,
    } = model;
    const claimVehicle = findClaimVehicle(vehicles);
    const otherVehicles = vehicles.filter((v: VehicleModel) => v.partyRelation !== CLAIM_VEHICLE);

    useEffect(() => {
        const claimDescription =
            temporaryClaimDescription === ''
                ? [claimDescriptionHistory, accidentDescription, temporaryClaimDescription].join('\n')
                : temporaryClaimDescription;

        //@ts-ignore
        setValue(CLAIM_DESCRIPTION, claimDescription);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accidentDescription, claimDescriptionHistory, temporaryClaimDescription]);

    useEffect(() => {
        dispatch(motorActions.update({ claimDescription }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleContinueButton = ({ claimDescription }: Pick<MotorState, 'claimDescription'>, e?: FormChangeable) => {
        const data = { uuid, requestId, lob, locale: { country, language }, model: { ...model, claimDescription } };
        e?.preventDefault();
        dispatch(commonActions.update({ submitted: true }));
        dispatcherWithPromise(dispatch, motorActions.update, { claimDescription })
            .then(() => dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => dispatcherWithPromise(dispatch, pdfServiceActions.request, data))
            .then(() => dispatch(commonActions.submit()))
            .catch(() => dispatch(commonActions.update({ submitted: false })));
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        claimDescriptionUtil(e);
        const { id, value } = e.currentTarget;
        await trigger(id);
        await setValue(id, value, { shouldValidate: true });
    };

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleNeedTowingChange = (e: NeedVehicleTowingModel) => {
        dispatcherWithPromise(dispatch, motorActions.update, { needVehicleTowing: e }).then(() =>
            dispatcherWithPromise(dispatch, commonActions.send)
        );
    };

    const handleDamages = (v: VehicleModel) => {
        const damages = v.damages.map((item) => t(`motor.end.damagesSummary.select.${item.body}`));
        const damagesList = damages.reduce((damagesCount: any, currentDamage) => {
            damagesCount[currentDamage] = (damagesCount[currentDamage] || 0) + 1;
            return damagesCount;
        }, {});

        const reducedDamagesList = Object.entries(damagesList).map(([key, value]) => ({ key, value }));
        return reducedDamagesList;
    };

    const renderDamages = (claimVehicle: VehicleModel) => {
        const damages: DamagePoint[] = claimVehicle.damages.map((da): DamagePoint => {
            const t = da.svgLocation;
            return { x: t.x, y: t.y, vehicleBodyPart: da.body, svgId: t.svgId };
        });
        return (
            <PrintableVehicleBodyDamages
                addDamagePoint={() => null}
                removeDamagePoint={() => null}
                selectedDamagePoints={damages}
                vehicleType={claimVehicle.vehicleType}
                tWithNs={() => null}
            />
        );
    };

    const renderFooterHelpComponent = `<span class="summary-help-text">${test.t('help.marked')}</span>`;
    return (
        <PageLayout
            backBtnAriaLabel={t(BACK_BUTTON)}
            backBtnText={t(BACK_BUTTON)}
            continueBtnAriaLabel={submitted ? t(SUBMITTING_BUTTON) : t(SEND_BUTTON)}
            continueBtnText={submitted ? t(SUBMITTING_BUTTON) : t(SEND_BUTTON)}
            disableBackButton={submitted}
            disableContinueButton={submitted}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT, { component: renderFooterHelpComponent })}
            handleContinueButton={handleSubmit(handleContinueButton)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            pageClassName={!showSummary ? 'hide-content' : ''}
            {...{ handleBackButton }}
        >
            <Printable title={tWithNS.t('print.title')} className={'hide-all-content'}>
                <Grid>
                    {/* Incident Summary */}
                    <IncidentSummary
                        className={'col-12 divider'}
                        registrationNumber={claimVehicle?.registrationNumber}
                        make={claimVehicle?.make}
                        type={claimVehicle?.type}
                        incidentOn={tWithNS.t('text.incidentOn')}
                        incidentAt={tWithNS.t('text.incidentAt')}
                        incidentBy={tWithNS.t('text.incidentBy')}
                        incidentInvolving={tWithNS.t('text.incidentInvolving')}
                        claimDate={claimDate ? dtParseAndFormat(claimDate) : ''}
                        location={accidentLocation ? accidentLocation.note : ''}
                        claimCause={claimCause ? tWithNS.t(`claimCause.select.${claimCause}`) : ''}
                        {...{ customCAN }}
                    >
                        {vehicles.map((vehicle: VehicleModel, idx: number) => {
                            const owner: OwnerInformationListModel | undefined = ownerInformationList.find(
                                (o: OwnerInformationListModel) => o.damagedItem === vehicle.internalId
                            );
                            let contactInformation = '';
                            if (owner) {
                                const { firstName, lastName } = owner;
                                contactInformation = `${firstName || lastName ? tWithNS.t('text.ownedBy') : ''}
                                ${firstName ? firstName : ''} ${lastName ? lastName : ''}`;
                            } else if (vehicle.driverInformation) {
                                const { firstName, lastName } = vehicle.driverInformation;
                                contactInformation = `${firstName || lastName ? tWithNS.t('text.drivenBy') : ''}
                                ${firstName ? firstName : ''} ${lastName ? lastName : ''}`;
                            }

                            return (
                                <p key={idx} className={'incident-text'}>
                                    {`${vehicle.registrationNumber ? vehicle.registrationNumber : ''}, ${
                                        vehicle.model ? vehicle.model : ''
                                    } ${vehicle.make ? vehicle.make : ''} ${vehicle.type ? vehicle.type : ''}`}{' '}
                                    {contactInformation}
                                </p>
                            );
                        })}
                        {ownerInformationList.map((owner: OwnerInformationListModel, idx: number) => {
                            const isVehicle = vehicles.find((v: VehicleModel) => v.internalId === owner.damagedItem);
                            return (
                                <p key={idx} className={'incident-text'}>
                                    {formatOwner(isVehicle, owner, t)}
                                </p>
                            );
                        })}
                    </IncidentSummary>

                    {/* Claim Description */}
                    <SummaryItemText
                        label={t(CLAIM_DESCRIPTION_LABEL)}
                        text={claimDescription}
                        className={`col-12 divider text-align-left`}
                    />

                    {/* Registered Vehicles and Damages */}
                    <div className={'col-12 divider'}>
                        {claimVehicle && (
                            <ClaimVehicleSummary
                                claimVehicle={claimVehicle}
                                needVehicleTowing={needVehicleTowing}
                                textKey={tWithNS.t('claimVehicle.heading.title')}
                                printSummary={true}
                                needTowingTextKey={tWithNS.t('needVehicleTowing.heading.title')}
                                needVehicleTowingSummary={t(`motor.end.needVehicleTowing.select.${needVehicleTowing}`)}
                                handleChange={handleNeedTowingChange}
                                passengersText={tWithNS.t('passengers.heading.title')}
                                damagesTextKey={tWithNS.t('damages.heading.title')}
                                damages={handleDamages(claimVehicle)}
                                claimVehicleDamages={renderDamages(claimVehicle)}
                                damageDescription={claimVehicle.otherDamageComment}
                                damageDescriptionTitle={t(DAMAGE_DESCRIPTION_LABEL)}
                                {...{ customCAN }}
                            />
                        )}
                        <Grid>
                            {otherVehicles && otherVehicles.length > 0 && (
                                <div className={'col-12'}>
                                    <SummaryText text={tWithNS.t('otherVehicle.heading.title')} />
                                </div>
                            )}
                            {otherVehicles.map((vehicle: VehicleModel, idx: number) => {
                                const owner = findOwnerByDamagedItem(ownerInformationList, vehicle.internalId);
                                return (
                                    <SummaryVehicleItem
                                        key={idx}
                                        vehicle={vehicle}
                                        textKey={tWithNS.t('vehicleOwnedBy.heading.title')}
                                        ownerInformationTextKey={`${owner?.firstName ? owner.firstName : ''}${
                                            owner?.lastName ? ` ${owner.lastName}` : ''
                                        }`}
                                        drivingText={tWithNS.t('driving.heading.title')}
                                        passengersText={tWithNS.t('passengers.heading.title')}
                                        damagesTextKey={tWithNS.t('damages.heading.title')}
                                        damages={handleDamages(vehicle)}
                                        vehicleDamages={renderDamages(vehicle)}
                                        printSummary={true}
                                        damageDescription={vehicle.otherDamageComment}
                                        damageDescriptionTitle={t(DAMAGE_DESCRIPTION_LABEL)}
                                        {...{ customCAN }}
                                    />
                                );
                            })}
                        </Grid>
                    </div>

                    <DisplayIncidentEndSummary
                        model={model}
                        upload={upload}
                        t={t}
                        tWithNS={tWithNS.t}
                        printSummary={true}
                        handleClick={handleClickOption}
                    />

                    {/* Start Summary */}
                    <DisplayMotorStartSummary tWithNS={tWithNS.t} {...{ model, upload, t }} />

                    {/* Parked Summary */}
                    {isYes(isDriving) ? (
                        <DisplayMotorDrivingSummary tWithNS={tWithNS.t} {...{ model, upload, t }} />
                    ) : (
                        <DisplayMotorParkedSummary tWithNS={tWithNS.t} {...{ model, upload, t }} />
                    )}

                    {/* End Summary */}
                    <DisplayEndSummary tWithNS={tWithNS.t} {...{ model, upload, t }} />
                </Grid>
            </Printable>
            <Printable title={tWithNS.t('print.title')}>
                <Grid>
                    {/* Incident Summary */}
                    <IncidentSummary
                        className={'col-12 divider'}
                        registrationNumber={claimVehicle?.registrationNumber}
                        make={claimVehicle?.make}
                        type={claimVehicle?.type}
                        incidentOn={tWithNS.t('text.incidentOn')}
                        incidentAt={tWithNS.t('text.incidentAt')}
                        incidentBy={tWithNS.t('text.incidentBy')}
                        incidentInvolving={tWithNS.t('text.incidentInvolving')}
                        claimDate={claimDate ? dtParseAndFormat(claimDate) : ''}
                        location={accidentLocation ? accidentLocation.note : ''}
                        claimCause={claimCause ? tWithNS.t(`claimCause.select.${claimCause}`) : ''}
                        {...{ customCAN }}
                    >
                        {vehicles.map((vehicle: VehicleModel, idx: number) => {
                            const owner: OwnerInformationListModel | undefined = ownerInformationList.find(
                                (o: OwnerInformationListModel) => o.damagedItem === vehicle.internalId
                            );
                            let contactInformation = 'tom';
                            if (owner) {
                                const { firstName, lastName } = owner;
                                contactInformation = `${firstName || lastName ? tWithNS.t('text.ownedBy') : ''}
                                 ${firstName ? firstName : ''} ${lastName ? lastName : ''}`;
                            } else if (vehicle.driverInformation) {
                                const { firstName, lastName } = vehicle.driverInformation;
                                contactInformation = `${firstName || lastName ? tWithNS.t('text.drivenBy') : ''}
                                 ${firstName ? firstName : ''} ${lastName ? lastName : ''}`;
                            }
                            return (
                                <p key={idx} className={'incident-text'}>
                                    {`${vehicle.registrationNumber ? vehicle.registrationNumber : ''}, ${
                                        vehicle.model ? vehicle.model : ''
                                    } ${vehicle.make ? vehicle.make : ''} ${vehicle.type ? vehicle.type : ''}`}{' '}
                                    {contactInformation}
                                </p>
                            );
                        })}
                        {ownerInformationList.map((owner: OwnerInformationListModel, idx: number) => {
                            const isVehicle = vehicles.find((v: VehicleModel) => v.internalId === owner.damagedItem);
                            return (
                                <p key={idx} className={'incident-text'}>
                                    {formatOwner(isVehicle, owner, t)}
                                </p>
                            );
                        })}
                    </IncidentSummary>

                    {/* Claim Description */}
                    {editDescription ? (
                        <div className={`col-12 divider`}>
                            <form onSubmit={handleSubmit(handleContinueButton)}>
                                <HiddenInputSubmit />
                                <Grid className={'align-center'}>
                                    <Controller
                                        control={control}
                                        name={CLAIM_DESCRIPTION}
                                        render={({ field: { ref, ...field } }) => (
                                            <MuiTextInput
                                                {...field}
                                                error={!!errors.claimDescription}
                                                errorMessage={errors.claimDescription?.message}
                                                id={CLAIM_DESCRIPTION}
                                                inputFieldWrapper={'col-12'}
                                                label={t(CLAIM_DESCRIPTION_LABEL)}
                                                multiline={true}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                placeholder={t(CLAIM_DESCRIPTION_PLACEHOLDER)}
                                                reference={ref}
                                                rows={10}
                                                {...{ customCAN }}
                                            />
                                        )}
                                    />
                                </Grid>
                            </form>
                        </div>
                    ) : (
                        <SummaryItemText
                            label={t(CLAIM_DESCRIPTION_LABEL)}
                            text={claimDescription}
                            className={`col-12 divider text-align-left`}
                        />
                    )}

                    {/* Accident Description / Sketch */}
                    {accidentSketch?.blob && (
                        <>
                            <div className={`col-12 divider`}>
                                <img src={accidentSketch?.blob} alt={claimDescription} />
                            </div>
                        </>
                    )}

                    {/* Registered Vehicles and Damages */}
                    <div className={'col-12 divider'}>
                        {claimVehicle && (
                            <ClaimVehicleSummary
                                claimVehicle={claimVehicle}
                                needVehicleTowing={needVehicleTowing}
                                textKey={tWithNS.t('claimVehicle.heading.title')}
                                printSummary={!showSummary}
                                needTowingTextKey={tWithNS.t('needVehicleTowing.heading.title')}
                                needVehicleTowingSummary={t(`motor.end.needVehicleTowing.select.${needVehicleTowing}`)}
                                needVehicleTowingYes={t(`motor.end.needVehicleTowing.select.${YES}`)}
                                needVehicleTowingNo={t(`motor.end.needVehicleTowing.select.${NO}`)}
                                needVehicleTowingAlreadyTowed={t(`motor.end.needVehicleTowing.select.${ALREADY_TOWED}`)}
                                handleChange={handleNeedTowingChange}
                                passengersText={tWithNS.t('passengers.heading.title')}
                                damagesTextKey={tWithNS.t('damages.heading.title')}
                                damages={handleDamages(claimVehicle)}
                                claimVehicleDamages={renderDamages(claimVehicle)}
                                damageDescription={claimVehicle.otherDamageComment}
                                damageDescriptionTitle={t(DAMAGE_DESCRIPTION_LABEL)}
                                {...{ customCAN }}
                            />
                        )}
                        <Grid>
                            {otherVehicles && otherVehicles.length > 0 && (
                                <div className={'col-12'}>
                                    <SummaryText text={tWithNS.t('otherVehicle.heading.title')} />
                                </div>
                            )}
                            {otherVehicles.map((vehicle: VehicleModel, idx: number) => {
                                const owner = findOwnerByDamagedItem(ownerInformationList, vehicle.internalId);
                                return (
                                    <SummaryVehicleItem
                                        key={idx}
                                        vehicle={vehicle}
                                        textKey={tWithNS.t('vehicleOwnedBy.heading.title')}
                                        ownerInformationTextKey={`${owner?.firstName ? owner.firstName : ''}${
                                            owner?.lastName ? ` ${owner.lastName}` : ''
                                        }`}
                                        drivingText={tWithNS.t('driving.heading.title')}
                                        passengersText={tWithNS.t('passengers.heading.title')}
                                        damagesTextKey={tWithNS.t('damages.heading.title')}
                                        damages={handleDamages(vehicle)}
                                        vehicleDamages={renderDamages(vehicle)}
                                        className={`${showSummary ? 'col-6' : 'col-12'} no-padding padding-top`}
                                        printSummary={!showSummary}
                                        damageDescription={vehicle.otherDamageComment}
                                        damageDescriptionTitle={t(DAMAGE_DESCRIPTION_LABEL)}
                                        {...{ customCAN }}
                                    />
                                );
                            })}
                        </Grid>
                    </div>

                    <DisplayIncidentEndSummary
                        model={model}
                        upload={upload}
                        t={t}
                        tWithNS={tWithNS.t}
                        handleClick={handleClickOption}
                        printSummary={!showSummary}
                    />

                    {pdfError ? (
                        <div className={'col-12'}>
                            <InputValidationError
                                fieldName={'PdfError'}
                                error={t('generatePDF.' + pdfError.status + 'Error')}
                            />
                        </div>
                    ) : (
                        ''
                    )}
                </Grid>
            </Printable>
        </PageLayout>
    );
};
