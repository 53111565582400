import React from 'react';
import { Clickable, emptyFn, StandardModal } from '@protectorinsurance/ds-can';
import { useI18n } from '../../hooks/useI18n';

interface TimeoutModalProps {
    onClose?: (e: Clickable) => void;
    onConfirm?: (e: Clickable) => void;
    isLoading?: boolean;
}

export const MaintenanceModeModal = ({
    onClose = emptyFn,
    onConfirm = emptyFn,
    isLoading = false,
}: TimeoutModalProps) => {
    const { t } = useI18n('maintenance');

    return (
        <StandardModal
            title={t('title')}
            onClose={onClose}
            onConfirm={onConfirm}
            confirmButtonText={isLoading ? t('btn.isLoading') : t('btn.text')}
            showConfirmButton={true}
        >
            <div className={'dialog__content text-center'} data-testid={'maintenanceModeModal'}>
                {t('text')}
            </div>
        </StandardModal>
    );
};
