import { MotorClaimCauseKeys, MotorClaimCauseTypeModel } from '@protectorinsurance/ds-can';

const { COMPARTMENT_DAMAGE, LOST_KEY, NATURAL_DISASTER, OTHER, WRONG_FUEL } = MotorClaimCauseKeys;

export const claimCauseSelected = (option: MotorClaimCauseTypeModel, claimCause: MotorClaimCauseTypeModel): boolean => {
    if (
        option === claimCause ||
        (option === OTHER &&
            (claimCause === COMPARTMENT_DAMAGE ||
                claimCause === LOST_KEY ||
                claimCause === NATURAL_DISASTER ||
                claimCause === WRONG_FUEL))
    ) {
        return true;
    }

    return false;
};

export const createClaimCauseText = (claimCause: MotorClaimCauseTypeModel) => {
    if (
        claimCause === COMPARTMENT_DAMAGE ||
        claimCause === LOST_KEY ||
        claimCause === NATURAL_DISASTER ||
        claimCause === WRONG_FUEL
    ) {
        return OTHER;
    }

    return claimCause;
};

export const claimCauseSummary = (claimCause: MotorClaimCauseTypeModel) => {
    if (
        claimCause === COMPARTMENT_DAMAGE ||
        claimCause === LOST_KEY ||
        claimCause === NATURAL_DISASTER ||
        claimCause === WRONG_FUEL
    ) {
        return true;
    }

    return false;
};
