import { DamagedItemKeys, OwnerInformationListModel, VehicleModel } from '@protectorinsurance/ds-can';
import { TFunction } from 'i18next';

/**
 * Destructure necessary imports
 */
const { ANIMAL, OTHER } = DamagedItemKeys;

/**
 * Utility logic
 */
export const formatOwner = (isVehicle: VehicleModel | undefined, owner: OwnerInformationListModel, t: TFunction) => {
    return `${
        isVehicle
            ? ''
            : owner.damagedItem && owner.damagedItem.includes(ANIMAL || OTHER)
            ? t(`motor.end.finalSummary.damagedItem.select.${owner.damagedItem}`)
            : owner.damagedItem
    }${isVehicle ? '' : ` ${t('motor.end.finalSummary.text.ownedBy')} `}${
        isVehicle ? '' : owner.firstName ? owner.firstName : owner.name ? owner.name : ''
    }${isVehicle ? '' : owner.lastName ? ` ${owner.lastName}` : ''}`;
};
