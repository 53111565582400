import { StringSchema, TestContext, ValidationError } from 'yup';
import { yupCustomLocale } from '../../config/yup';
import { CITY_REGEX } from '../../utils/validation/stringFormats';

export function city(this: StringSchema, key: string, message: string = yupCustomLocale.string.chars) {
    // @ts-ignore
    return this.test('city', message, function (this: TestContext, value: string):
        | boolean
        | ValidationError
        | Promise<boolean | ValidationError> {
        return !value || !!value.match(CITY_REGEX);
    });
}
