import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import {
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    MuiTextInput,
    PageLayout,
    SpeedModel,
} from '@protectorinsurance/ds-can';
import { useGoBack } from '../../../../hooks/useGoBack';
import { useI18n } from '../../../../hooks/useI18n';
import { PhraseKeys } from '../../../../config/phraseKeys';
import {
    selectDrivingSpeed,
    selectSpeedLimit,
    selectSpeedOnImpact,
    selectThirdPartySpeed,
} from '../../../../sagas/selectors/motorSelectors';
import { Controller, useForm } from 'react-hook-form';
import { speedInformationSchema } from '../../../../validations/schemas/speedInformationSchema';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { motorActions } from '../../../../sagas/motor';
import { commonActions } from '../../../../sagas/common';
import { FormFieldNames } from '../../../../config/formFieldNames';
import { yupResolver } from '@hookform/resolvers/yup';
import { selectCustomCAN } from '../../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const {
    BACK_BUTTON,
    CONTINUE_BUTTON,
    DRIVING_SPEED_LABEL,
    DRIVING_SPEED_PLACEHOLDER,
    HELP_TEXT,
    PAGE_NAME,
    SPEED_LIMIT_LABEL,
    SPEED_LIMIT_PLACEHOLDER,
    SPEED_ON_IMPACT_LABEL,
    SPEED_ON_IMPACT_PLACEHOLDER,
    SUB_TITLE,
    THIRD_PARTY_SPEED_LABEL,
    THIRD_PARTY_SPEED_PLACEHOLDER,
    TITLE,
} = PhraseKeys;
const { DRIVING_SPEED, SPEED_LIMIT, SPEED_ON_IMPACT, THIRD_PARTY_SPEED } = FormFieldNames;

/**
 * Page view and page logic
 */
export const DVSpeedInformationPage = () => {
    const dispatch = useDispatch();
    const speedLimit = useSelector(selectSpeedLimit);
    const drivingSpeed = useSelector(selectDrivingSpeed);
    const speedOnImpact = useSelector(selectSpeedOnImpact);
    const thirdPartySpeed = useSelector(selectThirdPartySpeed);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('motor.driving.speedInformation');
    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger,
    } = useForm<SpeedModel>({
        resolver: yupResolver(speedInformationSchema(t)),
        defaultValues: {
            speedLimit,
            drivingSpeed,
            speedOnImpact,
            thirdPartySpeed,
        },
    });

    const handleBackButton = useGoBack();

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        await setValue(id, value, { shouldValidate: true });
    };

    const onSubmit = ({ speedLimit, drivingSpeed, speedOnImpact, thirdPartySpeed }: SpeedModel) => {
        dispatcherWithPromise(dispatch, motorActions.update, {
            speedLimit,
            drivingSpeed,
            speedOnImpact,
            thirdPartySpeed,
        })
            .then(() => dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => dispatch(wizardActions.goToNext()));
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={handleSubmit(onSubmit)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <HiddenInputSubmit />
                <Grid className={'align-center'}>
                    <Controller
                        control={control}
                        name={SPEED_LIMIT}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                error={!!errors.speedLimit}
                                errorMessage={errors.speedLimit?.message}
                                id={SPEED_LIMIT}
                                inputFieldWrapper={'col-6'}
                                label={t(SPEED_LIMIT_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(SPEED_LIMIT_PLACEHOLDER)}
                                reference={ref}
                                type={'number'}
                                value={speedLimit?.toString()}
                                {...{ customCAN }}
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name={DRIVING_SPEED}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                error={!!errors.drivingSpeed}
                                errorMessage={errors.drivingSpeed?.message}
                                id={DRIVING_SPEED}
                                inputFieldWrapper={'col-6'}
                                label={t(DRIVING_SPEED_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(DRIVING_SPEED_PLACEHOLDER)}
                                reference={ref}
                                type={'number'}
                                value={drivingSpeed?.toString()}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={SPEED_ON_IMPACT}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                error={!!errors.speedOnImpact}
                                errorMessage={errors.speedOnImpact?.message}
                                id={SPEED_ON_IMPACT}
                                inputFieldWrapper={'col-6'}
                                label={t(SPEED_ON_IMPACT_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(SPEED_ON_IMPACT_PLACEHOLDER)}
                                reference={ref}
                                type={'number'}
                                value={speedOnImpact?.toString()}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={THIRD_PARTY_SPEED}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                error={!!errors.thirdPartySpeed}
                                errorMessage={errors.thirdPartySpeed?.message}
                                id={THIRD_PARTY_SPEED}
                                inputFieldWrapper={'col-6'}
                                label={t(THIRD_PARTY_SPEED_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(THIRD_PARTY_SPEED_PLACEHOLDER)}
                                reference={ref}
                                type={'number'}
                                value={thirdPartySpeed?.toString()}
                                {...{ customCAN }}
                            />
                        )}
                    />
                </Grid>
            </form>
        </PageLayout>
    );
};
