import { TFunction } from 'i18next';
import { LeakOriginEnums } from '@protectorinsurance/ds-can';

/**
 * Utility logic
 */
export const createLeakOriginKey = (id: string) => {
    return `lpo.leakOrigin.select.${id}`;
};

export const getLeakOriginOption = (t: TFunction, key: LeakOriginEnums, value: string) => {
    return {
        value,
        label: t(createLeakOriginKey(key)),
    };
};
