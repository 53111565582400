import React from 'react';
import { LocationModel, SummaryItem, SummaryItemProps } from '@protectorinsurance/ds-can';

/**
 * Component view and component logic
 */
export const renderLocation = (
    { inputWrapperClass, textKey, dataTestId, buttonText, path, handleClick }: SummaryItemProps,
    accidentLocation: LocationModel
) => {
    if (accidentLocation.latitude && accidentLocation.longitude) {
        return <SummaryItem {...{ buttonText, dataTestId, handleClick, inputWrapperClass, path, textKey }} />;
    }
    return <></>;
};
