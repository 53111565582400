import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../hooks/useI18n';
import {
    findClaimVehicle,
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    MuiAutocomplete,
    MuiTextInput,
    OptionType,
    PageLayout,
    PartyRelationKeys,
    VehicleModel,
    VehicleTypeKeys,
} from '@protectorinsurance/ds-can';
import { useGoBack } from '../../../hooks/useGoBack';
import { PhraseKeys } from '../../../config/phraseKeys';
import { FormFieldNames } from '../../../config/formFieldNames';
import { Controller, useForm } from 'react-hook-form';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { motorActions } from '../../../sagas/motor';
import { commonActions } from '../../../sagas/common';
import { getVehicleTypeOptions } from '../../../utils/flow/vehicleType';
import { selectVehicles } from '../../../sagas/selectors/motorSelectors';
import { claimVehicleInformationSchema } from '../../../validations/schemas/claimVehicleInformationSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const {
    BACK_BUTTON,
    CHASSIS_NUMBER_LABEL,
    CHASSIS_NUMBER_PLACEHOLDER,
    CONTINUE_BUTTON,
    HELP_TEXT,
    MAKE_LABEL,
    MAKE_PLACEHOLDER,
    MODEL_LABEL,
    MODEL_PLACEHOLDER,
    NO_OPTIONS_MESSAGE,
    PAGE_NAME,
    SUB_TITLE,
    TITLE,
    TYPE_LABEL,
    TYPE_PLACEHOLDER,
    VEHICLE_TYPE_LABEL,
    VEHICLE_TYPE_PLACEHOLDER,
} = PhraseKeys;
const { CHASSIS_NUMBER, MAKE, MODEL, TYPE, VEHICLE_TYPE } = FormFieldNames;
const { CLAIM_VEHICLE } = PartyRelationKeys;

/**
 * Page view and page logic
 */
export const StartVehicleInformationPage = () => {
    const dispatch = useDispatch();
    const [vehicleType, setVehicleType] = useState<OptionType | null>(null);
    const vehicles = useSelector(selectVehicles);
    const customCAN = useSelector(selectCustomCAN);
    const claimVehicle = findClaimVehicle(vehicles);
    const { t } = useI18n();
    const tWithNS = useI18n('motor.start.vehicleInformation');
    const options = getVehicleTypeOptions(t);
    const {
        control,
        formState: { errors },
        handleSubmit,
        register,
        setValue,
        trigger,
    } = useForm<VehicleModel>({
        resolver: yupResolver(claimVehicleInformationSchema(t)),
        defaultValues: {
            chassisNumber: claimVehicle?.chassisNumber,
            make: claimVehicle?.make,
            model: claimVehicle?.model,
            type: claimVehicle?.type,
            vehicleType: claimVehicle?.vehicleType,
        },
    });

    useEffect(() => {
        register(VEHICLE_TYPE);
    }, [register]);

    useEffect(() => {
        const selected = options.find((x) => x.value.toUpperCase() === (claimVehicle?.vehicleType as VehicleTypeKeys));

        if (selected) {
            setVehicleType(selected);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [claimVehicle]);

    const handleBackButton = useGoBack();

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        await setValue(id, value, { shouldValidate: true });
    };

    const handleSelect = async (e: SyntheticEvent, option: OptionType) => {
        const selected = option ? (option as OptionType) : null;
        if (selected) {
            await setValue('vehicleType', VehicleTypeKeys[selected.value.toUpperCase() as VehicleTypeKeys], {
                shouldValidate: true,
            });
        }
        setVehicleType(selected);
    };

    const onSubmit = (values: VehicleModel) => {
        dispatcherWithPromise(dispatch, motorActions.updateClaimVehicle, {
            chassisNumber: values.chassisNumber,
            make: values.make,
            model: values.model,
            partyRelation: CLAIM_VEHICLE,
            registrationNumber: claimVehicle?.registrationNumber,
            type: values.type,
            vehicleType: values.vehicleType,
        })
            .then(() => dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => dispatch(wizardActions.goToNext()));
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={handleSubmit(onSubmit)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <HiddenInputSubmit />
                <Grid className={'align-center'}>
                    <Controller
                        control={control}
                        name={MAKE}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                error={!!errors.make}
                                errorMessage={errors.make?.message}
                                id={MAKE}
                                inputFieldWrapper={'col-4'}
                                label={t(MAKE_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(MAKE_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={TYPE}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                error={!!errors.type}
                                errorMessage={errors.type?.message}
                                id={TYPE}
                                inputFieldWrapper={'col-4'}
                                label={t(TYPE_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(TYPE_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <MuiAutocomplete
                        error={!!errors.vehicleType}
                        errorMessage={errors.vehicleType?.message}
                        id={VEHICLE_TYPE}
                        inputFieldWrapper={'col-4'}
                        label={t(VEHICLE_TYPE_LABEL)}
                        noOptionsText={t(NO_OPTIONS_MESSAGE)}
                        onBlur={handleBlur}
                        onChange={handleSelect}
                        openOnFocus={true}
                        placeholder={t(VEHICLE_TYPE_PLACEHOLDER)}
                        value={vehicleType}
                        {...{ customCAN, options }}
                    />
                    <Controller
                        control={control}
                        name={MODEL}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                error={!!errors.model}
                                errorMessage={errors.model?.message}
                                id={MODEL}
                                inputFieldWrapper={'col-4'}
                                label={t(MODEL_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(MODEL_PLACEHOLDER)}
                                reference={ref}
                                value={claimVehicle?.model?.toString()}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name={CHASSIS_NUMBER}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                error={!!errors.chassisNumber}
                                errorMessage={errors.chassisNumber?.message}
                                id={CHASSIS_NUMBER}
                                inputFieldWrapper={'col-4'}
                                label={t(CHASSIS_NUMBER_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(CHASSIS_NUMBER_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                </Grid>
            </form>
        </PageLayout>
    );
};
