import React from 'react';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    Clickable,
    CyclistIcon,
    DitchIcon,
    findClaimVehicle,
    is,
    isVehicleHeavy,
    MotorClaimCauseKeys,
    OtherInjuriesIcon,
    PedestrianIcon,
    StationaryObjectIcon,
    WildAnimalIcon,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../../utils/carouselPageLookup';
import { MotorRoutePaths } from '../../../../wizardRouter/motorWizardRoutes';
import { selectClaimCause, selectVehicles } from '../../../../../sagas/selectors/motorSelectors';
import { wizardRouterActions as wizardActions } from '../../../../../sagas/wizardRouter';
import dispatcherWithPromise from '../../../../../utils/dispatcherWithPromise';
import { motorActions } from '../../../../../sagas/motor';
import { commonActions } from '../../../../../sagas/common';
import { flowActions } from '../../../../../sagas/flow';

/**
 * Destructure necessary imports
 */
const {
    ANIMAL_COLLISION,
    CYCLIST_COLLISION,
    DITCH_COLLISION,
    OTHER,
    PEDESTRIAN_COLLISION,
    STATIONARY_OBJECT_COLLISION,
} = MotorClaimCauseKeys;
const { DRIVING_NO_VEHICLE_DITCH_CLAIM_DESCRIPTION } = MotorRoutePaths;

/**
 * Page view and page logic
 */
export const drivingNoVehicleClaimCausePage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<MotorRoutePaths> => {
    const claimCause = selectClaimCause(state);
    const vehicles = selectVehicles(state);
    const claimVehicle = findClaimVehicle(vehicles);
    const options = [
        {
            id: ANIMAL_COLLISION,
            name: t(`motor.driving.noVehicleClaimCause.select.${ANIMAL_COLLISION}`),
            icon: <WildAnimalIcon />,
        },
        {
            id: STATIONARY_OBJECT_COLLISION,
            name: t(`motor.driving.noVehicleClaimCause.select.${STATIONARY_OBJECT_COLLISION}`),
            icon: <StationaryObjectIcon />,
        },
        {
            id: CYCLIST_COLLISION,
            name: t(`motor.driving.noVehicleClaimCause.select.${CYCLIST_COLLISION}`),
            icon: <CyclistIcon />,
        },
        {
            id: DITCH_COLLISION,
            name: t(`motor.driving.noVehicleClaimCause.select.${DITCH_COLLISION}`),
            icon: <DitchIcon />,
        },
        {
            id: PEDESTRIAN_COLLISION,
            name: t(`motor.driving.noVehicleClaimCause.select.${PEDESTRIAN_COLLISION}`),
            icon: <PedestrianIcon />,
        },
        { id: OTHER, name: t(`motor.driving.noVehicleClaimCause.select.${OTHER}`), icon: <OtherInjuriesIcon /> },
    ];

    return {
        slug: MotorRoutePaths.DYN_CAROUSEL_DRIVING_NO_VEHICLE_CLAIM_CAUSE,
        stateKey: 'claimCause',
        i18n: {
            ns: 'motor.driving.noVehicleClaimCause',
        },
        selectorValue: selectClaimCause(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                if (!is(option.id, claimCause)) {
                    dispatch(wizardActions.skipBackToPrev(true));
                }

                if (is(option.id, DITCH_COLLISION) && isVehicleHeavy(claimVehicle?.vehicleType, claimVehicle?.weight)) {
                    dispatcherWithPromise(dispatch, motorActions.update, { claimCause: option.id })
                        .then(() => dispatcherWithPromise(dispatch, flowActions.update, option.id))
                        .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                        .then(() => dispatch(wizardActions.goTo(DRIVING_NO_VEHICLE_DITCH_CLAIM_DESCRIPTION)));
                }

                dispatcherWithPromise(dispatch, motorActions.update, { claimCause: option.id })
                    .then(() => dispatcherWithPromise(dispatch, flowActions.update, option.id))
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext(option.id)));
            }
        },
    };
};
