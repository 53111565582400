import React, { SyntheticEvent } from 'react';
import { customCANProps, Nullable, OptionType, VehicleModel, VehicleObject } from '@protectorinsurance/ds-can';

/**
 * Component view and logic
 */
export const DisplayVehicleInformation = ({
    buttonAction,
    buttonText,
    customCAN,
    id,
    label,
    onChange,
    placeholder,
    vehicle,
    vehicleSelect,
    vehicleTypeOptions,
    vehicleTypeSelectedOption,
}: {
    buttonAction: () => void;
    buttonText: string;
    customCAN?: customCANProps;
    id?: string;
    label?: string;
    onChange?: (e: SyntheticEvent, option: any) => void;
    placeholder?: string;
    vehicle: Nullable<VehicleModel>;
    vehicleSelect?: boolean;
    vehicleTypeOptions?: Array<OptionType>;
    vehicleTypeSelectedOption?: Nullable<OptionType>;
}) => {
    return (
        vehicle && (
            <VehicleObject
                className={'col-12'}
                {...vehicle}
                {...{
                    buttonAction,
                    buttonText,
                    customCAN,
                    id,
                    label,
                    onChange,
                    placeholder,
                    vehicleSelect,
                    vehicleTypeOptions,
                    vehicleTypeSelectedOption,
                }}
            />
        )
    );
};
