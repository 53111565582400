import { api } from './api';
import { isProd, isTest } from './env';

/**
 * Utility logic
 */
export const verifyCaptchaToken = (token: string) => {
    let apiUrl: string;
    if (isProd()) {
        apiUrl = 'https://claims-at-net.protectorinsurance.co.uk/services/captcha/verify';
    } else if (isTest()) {
        apiUrl = 'https://claims-at-net-test.protectorinsurance.co.uk/services/captcha/verify';
    } else {
        apiUrl = 'http://localhost:8080/captcha/verify';
    }
    return api.post(apiUrl, { token });
};
