import React from 'react';
import { is, LobKeys, Nullable, SummaryItem, SummaryItemProps } from '@protectorinsurance/ds-can';

/**
 * Destructure necessary imports
 */
const { PROPERTY } = LobKeys;

/**
 * Component view and component logic
 */
export const renderTypeOfProperty = (
    { inputWrapperClass, textKey, dataTestId, buttonText, path, handleClick }: SummaryItemProps,
    lob: Nullable<LobKeys>
) => {
    return (
        <>
            {is(lob, PROPERTY) && (
                <SummaryItem {...{ buttonText, dataTestId, handleClick, inputWrapperClass, path, textKey }} />
            )}
        </>
    );
};
