import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLpoState } from '../../../sagas/selectors/lpoSelectors';
import { selectUpload } from '../../../sagas/selectors/uploadSelectors';
import { useI18n } from '../../../hooks/useI18n';
import moment from 'moment';
import { useGoBack } from '../../../hooks/useGoBack';
import {
    DNK_DATE_FORMAT,
    Grid,
    InputValidationError,
    PageLayout,
    pdfServiceActions,
    PDFServiceActionTypes,
    Printable,
} from '@protectorinsurance/ds-can';
import {
    selectApiId,
    selectCommonState,
    selectLob,
    selectLocaleCountryCode,
    selectLocaleLanguageCode,
    selectRequestId,
    selectSubmitted,
} from '../../../sagas/selectors/commonSelectors';
import { DisplayStartSummary } from './DisplayStartSummary';
import { DisplayEndSummary } from './DisplayEndSummary';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../sagas/common';
import { PhraseKeys } from '../../../config/phraseKeys';
import useErrorLoader from '../../../hooks/useErrorSelector';

/**
 * Destructure necessary imports
 */
const { BACK_BUTTON, HELP_TEXT, PAGE_NAME, SEND_BUTTON, SUB_TITLE, SUBMITTING_BUTTON, TITLE } = PhraseKeys;
const reportDate = moment().format(DNK_DATE_FORMAT);

/**
 * Interface
 */
interface PrintableSummaryProps {
    showSummary: boolean;
}

/**
 * Page view and page logic
 */
export const PrintableSummary = ({ showSummary = true }: PrintableSummaryProps) => {
    const dispatch = useDispatch();
    const uuid = useSelector(selectApiId);
    const country = useSelector(selectLocaleCountryCode);
    const language = useSelector(selectLocaleLanguageCode);
    const lob = useSelector(selectLob);
    const requestId = useSelector(selectRequestId);
    const lpoState = useSelector(selectLpoState);
    const common = useSelector(selectCommonState);
    const upload = useSelector(selectUpload);
    const { error: pdfError } = useErrorLoader(PDFServiceActionTypes.FAILURE);
    const { t } = useI18n();
    const tWithNS = useI18n('lpo.end.finalSummary');
    const submitted = useSelector(selectSubmitted);

    const model = { ...lpoState };

    const handleBackButton = useGoBack();

    const handleContinueButton = () => {
        const data = { uuid, requestId, lob, locale: { country, language }, model };
        dispatch(commonActions.update({ submitted: true }));
        dispatcherWithPromise(dispatch, commonActions.send)
            .then(() => dispatcherWithPromise(dispatch, pdfServiceActions.request, data))
            .then(() => dispatch(commonActions.submit()))
            .catch(() => dispatch(commonActions.update({ submitted: false })));
    };

    return (
        <PageLayout
            backBtnAriaLabel={t(BACK_BUTTON)}
            backBtnText={t(BACK_BUTTON)}
            continueBtnAriaLabel={submitted ? t(SUBMITTING_BUTTON) : t(SEND_BUTTON)}
            continueBtnText={submitted ? t(SUBMITTING_BUTTON) : t(SEND_BUTTON)}
            disableBackButton={submitted}
            disableContinueButton={submitted}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            pageClassName={!showSummary ? 'hide-content' : ''}
            {...{ handleBackButton, handleContinueButton }}
        >
            <Printable title={tWithNS.t('print.title', { date: reportDate })}>
                <Grid>
                    <DisplayStartSummary tWithNS={tWithNS.t} {...{ model, upload, t, common }} />

                    <DisplayEndSummary tWithNS={tWithNS.t} {...{ model, upload, t, common }} />
                    {pdfError ? (
                        <div className={'col-12'}>
                            <InputValidationError
                                fieldName={'PdfError'}
                                error={t('generatePDF.' + pdfError.status + 'Error')}
                            />
                        </div>
                    ) : (
                        ''
                    )}
                </Grid>
            </Printable>
        </PageLayout>
    );
};
