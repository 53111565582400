import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { optionalContactDetailsSchema } from '../fieldSchemas/optionalContactDetailsSchema';
import { addressSchema } from '../fieldSchemas/addressSchema';
import { nationalIdentitySchema } from '../fieldSchemas/nationalIdentitySchema';
import { firstNameSchema } from '../fieldSchemas/firstNameSchema';
import { lastNameSchema } from '../fieldSchemas/lastNameSchema';

/**
 * Validation logic
 */
export const claimantInformationSchema = (t: TFunction) => {
    return Yup.object().shape({
        ...firstNameSchema(t, true),
        ...lastNameSchema(t, false),
        ...nationalIdentitySchema(t),
        ...optionalContactDetailsSchema(t),
        ...addressSchema(t),
    });
};
