import { AppState } from '../../store';

// Common Selectors
export const selectLpoState = (state: AppState) => state.lpo;
export const selectAcceptedPrivacy = (state: AppState) => state.lpo.acceptedPrivacy;
export const selectCompanyInformation = (state: AppState) => state.lpo.companyInformation;
export const selectClaimReporterRole = (state: AppState) => state.lpo.claimReporterRole;
export const selectClaimType = (state: AppState) => state.lpo.claimType;
export const selectClaimCause = (state: AppState) => state.lpo.claimCause;
export const selectClaimDate = (state: AppState) => state.lpo.claimDate;
export const selectAccidentLocation = (state: AppState) => state.lpo.accidentLocation;
export const selectClaimDescription = (state: AppState) => state.lpo.claimDescription;

// Liability Selectors
export const selectClaimantInformation = (state: AppState) => state.lpo.claimantInformation;
export const selectIsEmployer = (state: AppState) => state.lpo.isEmployer;
export const selectHasInjury = (state: AppState) => state.lpo.hasInjury;

// Property Selectors
export const selectLeakCause = (state: AppState) => state.lpo.leakCause;
export const selectLeakOrigin = (state: AppState) => state.lpo.leakOrigin;
export const selectTypeOfProperty = (state: AppState) => state.lpo.typeOfProperty;
export const selectExternalPropertyDamage = (state: AppState) => state.lpo.externalPropertyDamage;
export const selectInternalPropertyDamage = (state: AppState) => state.lpo.internalPropertyDamage;
export const selectIsLeakFixed = (state: AppState) => state.lpo.isLeakFixed;
export const selectIsPropertyOccupied = (state: AppState) => state.lpo.isPropertyOccupied;
export const selectIsPropertyTenanted = (state: AppState) => state.lpo.isPropertyTenanted;
export const selectHasAccessToWater = (state: AppState) => state.lpo.hasAccessToWater;
export const selectHasAccessToElectricity = (state: AppState) => state.lpo.hasAccessToElectricity;
export const selectHasAccessToGas = (state: AppState) => state.lpo.hasAccessToGas;
export const selectHasExistingClaim = (state: AppState) => state.lpo.hasExistingClaim;
export const selectPropertyClaimDescription = (state: AppState) => state.lpo.propertyClaimDescription;
export const selectIsThirdPartyInvolved = (state: AppState) => state.lpo.isThirdPartyInvolved;
export const selectThirdPartyInformation = (state: AppState) => state.lpo.thirdPartyInformation;

// End Selectors
export const selectCrimeReference = (state: AppState) => state.lpo.crimeReference;
export const selectConstabulary = (state: AppState) => state.lpo.constabulary;
export const selectHasOtherInsurance = (state: AppState) => state.lpo.otherInsurance;
export const selectOtherInsuranceCompany = (state: AppState) => state.lpo.otherInsuranceCompany;
export const selectOtherInsurancePolicyNumber = (state: AppState) => state.lpo.otherInsurancePolicyNumber;
export const selectReporterInformation = (state: AppState) => state.lpo.reporterInformation;
export const selectPolicyHoldersContact = (state: AppState) => state.lpo.policyHoldersContact;
export const selectExternalReference = (state: AppState) => state.lpo.externalReference;
