import { put, takeEvery } from 'redux-saga/effects';
import {
    actionWithPromise,
    ClaimLocationKeys,
    emptyFn,
    FlowModel,
    MotorClaimCauseKeys,
    Rejectable,
    Resolvable,
} from '@protectorinsurance/ds-can';

/**
 * Constants
 */
export enum FlowActionTypes {
    UPDATE = '@flow/UPDATE',
    UPDATED = '@flow/UPDATED',
}

/**
 * Interfaces
 */
export type FlowState = FlowModel | MotorClaimCauseKeys | ClaimLocationKeys;

export interface FlowAction {
    type: FlowActionTypes;
    data?: FlowState;
    resolve?: Resolvable;
    reject?: Rejectable;
}

/**
 * Selectors
 */

/**
 * Initial state
 */
export const flowInitState: FlowModel = null;

/**
 * Defualt reducer
 *
 * @param state
 * @param action
 */
export default function (state: FlowState = flowInitState, { type, data }: FlowAction) {
    switch (type) {
        case FlowActionTypes.UPDATED:
            return data;
        default:
            return state;
    }
}

/**
 * Redux Actions
 */
export const flowActions = {
    update: actionWithPromise<FlowActionTypes, FlowState>(FlowActionTypes.UPDATE),
    updated: actionWithPromise<FlowActionTypes, FlowState>(FlowActionTypes.UPDATED),
};

/**
 * Saga watchers
 */
export const flowWatcher = function* () {
    yield takeEvery(FlowActionTypes.UPDATE, flowSagas.update);
};

/**
 * Saga functions
 */
export const flowSagas = {
    *update({ data, resolve = emptyFn }: FlowAction) {
        yield put(flowActions.updated(data));
        resolve();
    },
};
