import React from 'react';
import {
    isYes,
    LobKeys,
    Nullable,
    SummaryItemProps,
    SummaryNavButton,
    SummaryText,
    YesNoModel,
} from '@protectorinsurance/ds-can';
import { TEST_ID_PREFIX } from '../../../../../utils/testIdUtil';
import { displayThirdParty } from '../../../../../utils/personUtils';
import {
    ThirdPartyInformationModel,
    ThirdPartyInformationTypeModel,
} from '../../../../../models/ThirdPartyInformation';

/**
 * Destructure necessary imports
 */
const { LIABILITY, PROPERTY } = LobKeys;

/**
 * Component view and component logic
 */
export const renderThirdPartyInformation = (
    { inputWrapperClass, textKey, dataTestId, buttonText, path, handleClick }: SummaryItemProps,
    lob: Nullable<LobKeys>,
    isThirdPartyInvolved: YesNoModel,
    thirdPartyInformation: ThirdPartyInformationTypeModel
) => {
    const renderThirdParty = (thirdParty: ThirdPartyInformationModel, idx: number) => (
        <SummaryNavButton
            key={`${TEST_ID_PREFIX.thirdPartyInformation}-${idx}`}
            dataTestId={`${TEST_ID_PREFIX.thirdPartyInformation}-${idx}`}
            buttonText={displayThirdParty(thirdParty)}
            {...{ path }}
        />
    );

    const lobs = [LIABILITY, PROPERTY];
    if (lob && lobs.includes(lob) && isYes(isThirdPartyInvolved)) {
        const renderThirdParties = () =>
            thirdPartyInformation.map((thirdParty: ThirdPartyInformationModel, idx: number) =>
                renderThirdParty(thirdParty, idx)
            );
        return (
            <>
                <div className={inputWrapperClass}>
                    <SummaryText text={textKey} />
                    {renderThirdParties()}
                </div>
            </>
        );
    }
    return <></>;
};
