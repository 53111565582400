import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../hooks/useI18n';
import {
    AddIcon,
    ButtonClickable,
    CircleIconButton,
    Clickable,
    findClaimVehicle,
    findCounterpartyVehicle,
    findSelectedVehicle,
    Grid,
    initVehicleModel,
    InputValidationError,
    is,
    PageLayout,
    PartyRelationKeys,
    SelectedVehicleTypeModel,
    VehicleListItem,
    VehicleModel,
    YesNoKeys,
} from '@protectorinsurance/ds-can';
import { useGoBack } from '../../../hooks/useGoBack';
import { PhraseKeys } from '../../../config/phraseKeys';
import { selectVehicles } from '../../../sagas/selectors/motorSelectors';
import { MotorRoutePaths } from '../../../config/wizardRouter/motorWizardRoutes';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { motorActions } from '../../../sagas/motor';
import { TEST_ID_PREFIX } from '../../../utils/testIdUtil';
import { commonActions } from '../../../sagas/common';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const {
    ADD_BUTTON,
    BACK_BUTTON,
    CONTINUE_BUTTON,
    HELP_TEXT,
    PAGE_NAME,
    SUB_TITLE,
    TITLE,
    SELECT_YES,
    SELECT_NO,
    DONT_KNOW,
} = PhraseKeys;
const { END_BODY, END_VEHICLE_REGISTRATION_NUMBER, DYN_CAROUSEL_END_HAS_NON_VEHICLE_DAMAGES } = MotorRoutePaths;
const { vehicleList } = TEST_ID_PREFIX;
const { OTHER } = PartyRelationKeys;
const { YES } = YesNoKeys;

/**
 * Page view and page logic
 */
export const DamageOverviewPage = () => {
    const dispatch = useDispatch();
    const vehicles = useSelector(selectVehicles);
    const customCAN = useSelector(selectCustomCAN);
    const claimVehicle = findClaimVehicle(vehicles);
    const counterpartyVehicle = findCounterpartyVehicle(vehicles);
    const otherVehicles = vehicles.filter((v: VehicleModel) => v.partyRelation === OTHER);
    const [validation, setValidation] = useState<boolean>(true);
    const { t } = useI18n();
    const tWithNS = useI18n('motor.end.damageOverview');

    const checkValidation = (v: VehicleModel[]) => {
        let noValidation = true;

        v.map(async (vehicle) => {
            if (vehicle.vehicleHasDamages == null) {
                noValidation = false;
            }
        });
        return noValidation;
    };

    const handleBackButton = useGoBack();

    const handleContinueButton = async (e: Clickable) => {
        e.preventDefault();

        setValidation(checkValidation(vehicles));

        if (checkValidation(vehicles)) {
            let nextAction = wizardActions.goToNext();
            if (claimVehicle?.damages.length === 0) {
                nextAction = wizardActions.goTo(DYN_CAROUSEL_END_HAS_NON_VEHICLE_DAMAGES);
            }
            dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(nextAction));
        }
    };

    const handleAddButton = (e: Clickable) => {
        e.preventDefault();
        dispatch(wizardActions.goTo(END_VEHICLE_REGISTRATION_NUMBER));
    };

    const handleClick = (id: SelectedVehicleTypeModel, e?: ButtonClickable) => {
        e?.preventDefault();
        const hasDamages = e?.currentTarget.value as YesNoKeys;
        const currentVehicle = findSelectedVehicle(vehicles, id);
        const updatedVehicleList = vehicles.map((vehicle) => {
            if (is(vehicle.internalId, id)) {
                if (hasDamages !== YES) {
                    return {
                        ...initVehicleModel,
                        ...currentVehicle,
                        vehicleHasDamages: hasDamages,
                        damages: [],
                        otherDamageComment: null,
                    };
                } else {
                    return {
                        ...initVehicleModel,
                        ...currentVehicle,
                        vehicleHasDamages: hasDamages,
                    };
                }
            } else {
                return vehicle;
            }
        });

        dispatcherWithPromise(dispatch, motorActions.update, {
            selectedVehicleId: id,
            vehicles: updatedVehicleList,
        }).then(() => {
            hasDamages === YES && dispatch(wizardActions.goTo(END_BODY));
        });

        if (checkValidation(updatedVehicleList)) {
            setValidation(checkValidation(updatedVehicleList));
        }
    };

    const handleDelete = (id: SelectedVehicleTypeModel, e?: Clickable) => {
        e?.preventDefault();
        const vehicleArray = vehicles.filter((vehicle) => vehicle.internalId !== id);
        dispatcherWithPromise(dispatch, motorActions.update, { vehicles: vehicleArray });
    };

    const handleDamages = (v: VehicleModel) => {
        const damages = v.damages.map((item) => tWithNS.t(`select.${item.body}`));
        const damagesList = damages.reduce((damagesCount: any, currentDamage) => {
            damagesCount[currentDamage] = (damagesCount[currentDamage] || 0) + 1;
            return damagesCount;
        }, {});

        const reducedDamagesList = Object.entries(damagesList).map(([key, value]) => ({ key, value }));
        return reducedDamagesList;
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            {claimVehicle && (
                <>
                    <h2 className={'vehicle-list-header'}>{tWithNS.t('heading.claimVehicle')}</h2>
                    <Grid>
                        <VehicleListItem
                            registrationNumber={claimVehicle?.registrationNumber}
                            vehicleType={claimVehicle?.vehicleType}
                            make={claimVehicle?.make}
                            type={claimVehicle?.type}
                            model={claimVehicle?.model}
                            dataTestId={`${vehicleList}claimVehicle`}
                            handleClick={(e) => handleClick(claimVehicle?.internalId, e)}
                            vehicleHasDamages={claimVehicle?.vehicleHasDamages}
                            damages={handleDamages(claimVehicle)}
                            hasDamages={true}
                            buttonYesTitle={t(SELECT_YES)}
                            buttonNoTitle={t(SELECT_NO)}
                            buttonUnknownTitle={t(DONT_KNOW)}
                            {...{ customCAN }}
                        />
                    </Grid>
                </>
            )}

            {counterpartyVehicle && (
                <>
                    <h2 className={'vehicle-list-header'}>{tWithNS.t('heading.counterpartyVehicle')}</h2>
                    <Grid>
                        <VehicleListItem
                            registrationNumber={counterpartyVehicle?.registrationNumber}
                            vehicleType={counterpartyVehicle?.vehicleType}
                            make={counterpartyVehicle?.make}
                            type={counterpartyVehicle?.type}
                            model={counterpartyVehicle?.model}
                            dataTestId={`${vehicleList}counterpartyVehicle`}
                            handleDelete={() => handleDelete(counterpartyVehicle?.internalId)}
                            handleClick={(e) => handleClick(counterpartyVehicle?.internalId, e)}
                            vehicleHasDamages={counterpartyVehicle?.vehicleHasDamages}
                            damages={handleDamages(counterpartyVehicle)}
                            hasDamages={true}
                            buttonYesTitle={t(SELECT_YES)}
                            buttonNoTitle={t(SELECT_NO)}
                            buttonUnknownTitle={t(DONT_KNOW)}
                            {...{ customCAN }}
                        />
                    </Grid>
                </>
            )}

            {otherVehicles.length > 0 && (
                <>
                    <h2 className={'vehicle-list-header'}>{tWithNS.t('heading.otherVehicles')}</h2>
                    <Grid>
                        {otherVehicles.map((vehicle: VehicleModel, idx: number) => {
                            const {
                                registrationNumber,
                                vehicleType,
                                make,
                                type,
                                model,
                                internalId,
                                vehicleHasDamages,
                            } = vehicle;
                            return (
                                <VehicleListItem
                                    {...{ registrationNumber, vehicleType, make, type, model }}
                                    dataTestId={`${vehicleList}${idx}`}
                                    handleDelete={() => handleDelete(internalId)}
                                    handleClick={(e) => handleClick(internalId, e)}
                                    vehicleHasDamages={vehicleHasDamages}
                                    damages={handleDamages(vehicle)}
                                    hasDamages={true}
                                    buttonYesTitle={t(SELECT_YES)}
                                    buttonNoTitle={t(SELECT_NO)}
                                    buttonUnknownTitle={t(DONT_KNOW)}
                                    {...{ customCAN }}
                                />
                            );
                        })}
                    </Grid>
                </>
            )}
            {!validation ? <InputValidationError fieldName={'Vehicle Damage'} error={tWithNS.t('error')} /> : ''}
            <CircleIconButton
                icon={<AddIcon />}
                dataTestId={'btn-add-vehicle'}
                ariaLabel={t(ADD_BUTTON)}
                handleClick={handleAddButton}
                className={'dual add-btn'}
                label={tWithNS.t('add.button')}
            />
        </PageLayout>
    );
};
