import React from 'react';
import {
    AlreadyTowedIcon,
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    Clickable,
    is,
    MotorClaimCauseKeys,
    NeedVehicleTowingKeys,
    NoIcon,
    YesIcon,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../utils/carouselPageLookup';
import { MotorRoutePaths } from '../../../wizardRouter/motorWizardRoutes';
import { selectClaimCause, selectNeedVehicleTowing } from '../../../../sagas/selectors/motorSelectors';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { motorActions } from '../../../../sagas/motor';
import { commonActions } from '../../../../sagas/common';
import { WizardRouterAction, wizardRouterActions as wizardActions } from '../../../../sagas/wizardRouter';

/**
 * Destructure necessary imports
 */
const { ALREADY_TOWED, NO, YES } = NeedVehicleTowingKeys;
const { ENGINE, NATURAL_DISASTER, PARKING, THEFT_AND_DAMAGE } = MotorClaimCauseKeys;
const { DYN_CAROUSEL_END_IS_POLICE_CONTACTED, END_UPLOAD_ATTACHMENT } = MotorRoutePaths;

/**
 * Page view and page logic
 */
export const endNeedVehicleTowingPage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<MotorRoutePaths> => {
    const claimCause = selectClaimCause(state);
    const options = [
        { id: YES, name: t(`motor.end.needVehicleTowing.select.${YES}`), icon: <YesIcon /> },
        { id: NO, name: t(`motor.end.needVehicleTowing.select.${NO}`), icon: <NoIcon /> },
        {
            id: ALREADY_TOWED,
            name: t(`motor.end.needVehicleTowing.select.${ALREADY_TOWED}`),
            icon: <AlreadyTowedIcon />,
        },
    ];

    return {
        slug: MotorRoutePaths.DYN_CAROUSEL_END_NEED_VEHICLE_TOWING,
        stateKey: 'needVehicleTowing',
        i18n: {
            ns: 'motor.end.needVehicleTowing',
        },
        selectorValue: selectNeedVehicleTowing(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                let nextAction: WizardRouterAction<string | undefined> = wizardActions.goToNext(option.id);
                if (is(option.id, YES)) {
                    dispatch(wizardActions.skipBackToPrev(true));
                } else if (claimCause && [THEFT_AND_DAMAGE, ENGINE, PARKING].includes(claimCause)) {
                    nextAction = wizardActions.goTo(DYN_CAROUSEL_END_IS_POLICE_CONTACTED);
                } else if (is(claimCause, NATURAL_DISASTER) && is(option.id, NO)) {
                    nextAction = wizardActions.goTo(END_UPLOAD_ATTACHMENT);
                }

                dispatcherWithPromise(dispatch, motorActions.update, { needVehicleTowing: option.id })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(nextAction));
            }
        },
    };
};
