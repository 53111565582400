export enum TEST_ID_PREFIX {
    acceptedPrivacy = 'accepted-privacy',
    acceptedPrivacyCheck = 'acceptedPrivacy',
    accidentLocation = 'accidentLocation',
    accountNumber = 'accountNumber',
    bankName = 'bankName',
    businessNumber = 'businessNumber',
    chassisNumber = 'chassisNumber',
    city = 'city',
    claimCause = 'claimCause',
    claimCauseDetails = 'claimCauseDetails',
    claimCauseLabel = 'label-claimCause',
    claimDate = 'claim-date',
    claimDescription = 'claimDescription',
    claimDiscovererInformation = 'claimDiscovererInformation',
    claimLocation = 'claimLocation',
    claimNumber = 'claimNumber',
    claimReporterRole = 'claimReporterRole',
    claimType = 'claimType',
    claimantInformation = 'claimantInformation',
    claimantRoundaboutPositioning = 'claimantRoundaboutPositioning',
    clearingNumber = 'clearingNumber',
    collisionSituation = 'collisionSituation',
    companyInformation = 'companyInformation',
    companyName = 'name',
    constabulary = 'constabulary',
    counterpartyCompanyName = 'companyName',
    counterpartyInformation = 'counterpartyInformation',
    counterpartyRoundaboutPositioning = 'counterpartyRoundaboutPositioning',
    country = 'country',
    crimeReference = 'crimeReference',
    damagedItem = 'damagedItem',
    dateOfBirth = 'dateOfBirth',
    ditchClaimDescription = 'ditchClaimDescription',
    distanceFromRoadSide = 'distanceFromRoadSide',
    driverInformation = 'driverInformation',
    drivingSpeed = 'drivingSpeed',
    email = 'email',
    engineClaimDescription = 'engineClaimDescription',
    externalPropertyDamage = 'externalPropertyDamage',
    externalReference = 'externalReference',
    fireClaimDescription = 'fireClaimDescription',
    firstName = 'firstName',
    freightWeight = 'freightWeight',
    hasAccessToElectricity = 'hasAccessToElectricity',
    hasAccessToGas = 'hasAccessToGas',
    hasAccessToWater = 'hasAccessToWater',
    hasAllKeys = 'hasAllKeys',
    hasAnimalEscaped = 'hasAnimalEscaped',
    hasAttachments = 'has-attachments',
    hasDigitalServiceBook = 'hasDigitalServiceBook',
    hasExistingClaim = 'hasExistingClaim',
    hasExpenses = 'has-expenses',
    hasInjury = 'hasInjury',
    hasNonVehicleDamages = 'hasNonVehicleDamages',
    hasOtherInsurance = 'hasOtherInsurance',
    hasPassengers = 'hasPassengers',
    hasPersonInjuries = 'hasPersonInjuries',
    hasVehicleDamages = 'hasVehicleDamages',
    hasWitnesses = 'hasWitnesses',
    injuredPersonInformationList = 'injuredPersonInformationList',
    insuranceType = 'insurance-type',
    insuredByProtector = 'insuredByProtector',
    internalPropertyDamage = 'internalPropertyDamage',
    isCompany = 'isCompany-checkbox-input',
    isCounterpartyKnown = 'isCounterpartyKnown',
    isCounterpartyStationary = 'isCounterpartyStationary',
    isDriver = 'isDriver-checkbox-input',
    isEmployer = 'isEmployer',
    isItemMissing = 'isItemMissing',
    isLaneChange = 'isLaneChange',
    isLeakFixed = 'isLeakFixed',
    isPropertyOccupied = 'isPropertyOccupied',
    isPropertyTenanted = 'isPropertyTenanted',
    isReporter = 'isReporter-checkbox-input',
    isReversing = 'isReversing',
    isRiding = 'isRiding',
    isDriving = 'isDriving',
    isOtherVehicleInvolved = 'isOtherVehicleInvolved',
    isPoliceContacted = 'isPoliceContacted',
    isSelfDiscoveredClaim = 'isSelfDiscoveredClaim',
    isThirdPartyInvolved = 'isThirdPartyInvolved',
    isVehicleRecovered = 'isVehicleRecovered',
    isVehicleStolen = 'isVehicleStolen',
    lastName = 'lastName',
    leakCause = 'leakCause',
    leakOrigin = 'leakOrigin',
    lob = 'lob',
    make = 'make',
    mileage = 'mileage',
    missingItems = 'missingItems',
    model = 'model',
    vehicleMileage = 'vehicleMileage',
    name = 'name',
    nationalIdentity = 'nationalIdentity',
    needVehicleTowing = 'needVehicleTowing',
    otherId = 'otherId',
    otherInsuranceCompany = 'otherInsuranceCompany',
    otherInsurancePolicyNumber = 'otherInsurancePolicyNumber',
    otherMissingItemsClaimDescription = 'otherMissingItemsClaimDescription',
    ownerGivenName = 'ownerGivenName',
    ownerFamilyName = 'ownerFamilyName',
    ownerInformationList = 'ownerInformationList',
    parkedVehicleInformation = 'parkedVehicleInformation',
    parkingDate = 'parkingDate',
    passengers = 'passengers-0',
    phone = 'phone',
    policeCaseNumber = 'policeCaseNumber',
    policyHolder = 'policyHolder',
    policyHoldersContact = 'policyHoldersContact',
    policyNumber = 'policyNumber',
    propertyAddress = 'propertyAddress',
    propertyClaimDescription = 'propertyClaimDescription',
    propertyUnit = 'unit',
    recoveryDate = 'recoveryDate',
    reference = 'reference',
    registrationNumber = 'registrationNumber',
    reporterInformation = 'reporterInformation',
    reversingClaimDescription = 'reversingClaimDescription',
    roadConditionClaimDescription = 'roadConditionClaimDescription',
    roadWidth = 'roadWidth',
    role = 'role',
    salary = 'salary',
    speedLimit = 'speedLimit',
    speedOnImpact = 'speedOnImpact',
    street = 'street',
    theftAndDamagesClaimDescription = 'theftAndDamagesClaimDescription',
    thirdPartyInformation = 'thirdPartyInformation',
    thirdPartySpeed = 'thirdPartySpeed',
    timeSinceAction = 'timeSinceAction',
    type = 'type',
    typeOfAnimal = 'typeOfAnimal',
    typeOfIntersection = 'typeOfIntersection',
    typeOfProperty = 'typeOfProperty',
    typeOfRoad = 'typeOfRoad',
    unidentifiedType = 'unidentifiedType',
    uploadAttachments = 'upload-attachments-',
    uploadPoliceReport = 'upload-police-report-',
    uploadReceipt = 'upload-receipt-',
    recovererInformation = 'recovererInformation',
    whoReport = 'who-report',
    vehicleInformation = 'vehicleInformation',
    vehicleList = 'vehicleList-',
    vehicleType = 'vehicleType',
    whoAtFault = 'whoAtFault',
    whoResponsibleClaimDescription = 'whoResponsibleClaimDescription',
    witnesses = 'witnesses',
    zip = 'zip',
}
