import { motorProgressBarRoutes } from '../config/wizardRouter/motorProgressBarRoutes';
import { is, LobKeys, Nullable } from '@protectorinsurance/ds-can';
import { lpoProgressBarRoutes } from '../config/wizardRouter/lpoProgressBarRoutes';

/**
 * Destructure necessary imports
 */
const { AUTO } = LobKeys;

/**
 * Utility logic
 */
export const getLobProgressBarRoutes = (lob: Nullable<LobKeys>) => {
    if (is(lob, AUTO)) {
        return motorProgressBarRoutes;
    } else {
        return lpoProgressBarRoutes;
    }
};
