import { ObjectWithDynamicKey } from '@protectorinsurance/ds-can';
import { UnitTestPaths } from './unitTestWizardRoutes';

export enum BaseRoutePaths {
    DEFAULT_INDEX = '/gbr/en',
    INDEX = '/gbr/:language',
    CAPTCHA = '/gbr/:language/captcha',
    ERROR = '/error',
    SESSION_TIMEOUT = '/session-timeout',

    // FOR UNIT TESTING
    UNIT_TEST_DYN = 'unit-test/:flow/first',
    UNIT_TEST_INDEX = 'unit-test/index',
    UNIT_TEST_FIRST = 'unit-test/accident/first',
    UNIT_TEST_SECOND = 'unit-test/second',
    UNIT_TEST_THIRD = 'unit-test/third',
    UNIT_TEST_SUMMARY = 'unit-test/summary',
}

export type BaseRoutePathsType = BaseRoutePaths | UnitTestPaths;
export const baseWizardRoutes: ObjectWithDynamicKey = {};
