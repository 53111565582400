import { ClaimDescriptionTypeModel } from '@protectorinsurance/ds-can';
import { YesNoKeys } from '@protectorinsurance/ds-can';
import { MotorState } from '../../sagas/motor';

export const getClaimDescriptionHistory = (motorState: MotorState): ClaimDescriptionTypeModel => {
    const {
        theftAndDamagesClaimDescription,
        engineClaimDescription,
        otherMissingItemsClaimDescription,
        fireClaimDescription,
        roadConditionClaimDescription,
        whoResponsibleClaimDescription,
        ditchClaimDescription,
        reversingClaimDescription,
        isDriving,
    } = motorState;

    if (isDriving === YesNoKeys.YES) {
        return `${ditchClaimDescription && ditchClaimDescription + ' '}${
            roadConditionClaimDescription && roadConditionClaimDescription + ' '
        }${whoResponsibleClaimDescription && whoResponsibleClaimDescription + ' '}${
            reversingClaimDescription && reversingClaimDescription
        }`;
    } else {
        return `${theftAndDamagesClaimDescription && theftAndDamagesClaimDescription + ' '}${
            engineClaimDescription && engineClaimDescription + ' '
        }${otherMissingItemsClaimDescription && otherMissingItemsClaimDescription + ' '}${
            fireClaimDescription && fireClaimDescription
        }`;
    }
};
