import { useSelector } from 'react-redux';
import { selectSelectedVehicleId, selectVehicles } from '../sagas/selectors/motorSelectors';
import {
    findClaimVehicle,
    findCounterpartyVehicle,
    findSelectedVehicle,
    PartyRelationKeys,
    VehicleModel,
} from '@protectorinsurance/ds-can';

const { OTHER, CLAIM_VEHICLE } = PartyRelationKeys;

export const useVehicles = () => {
    const vehicles = useSelector(selectVehicles);
    const claimVehicle = findClaimVehicle(vehicles);
    const otherVehicles = vehicles.filter((v: VehicleModel) => v.partyRelation === OTHER);
    const notClaimVehicle = vehicles.filter((v: VehicleModel) => v.partyRelation !== CLAIM_VEHICLE);
    const counterpartyVehicle = findCounterpartyVehicle(vehicles);
    const selectedVehicleInternalId = useSelector(selectSelectedVehicleId);
    const selectedVehicle = findSelectedVehicle(vehicles, selectedVehicleInternalId);

    return {
        vehicles,
        claimVehicle,
        otherVehicles,
        counterpartyVehicle,
        notClaimVehicle,
        selectedVehicleInternalId,
        selectedVehicle,
    };
};
