import React, { SyntheticEvent, useEffect, useState } from 'react';
import { PhraseKeys } from '../../../config/phraseKeys';
import { useDispatch, useSelector } from 'react-redux';
import { selectLeakCause, selectLeakOrigin } from '../../../sagas/selectors/lpoSelectors';
import { useI18n } from '../../../hooks/useI18n';
import {
    Clickable,
    FormChangeable,
    Grid,
    is,
    LeakCauseEnums,
    LeakOriginEnums,
    MuiAutocomplete,
    OptionType,
    PageLayout,
} from '@protectorinsurance/ds-can';
import { useGoBack } from '../../../hooks/useGoBack';
import { lpoActions } from '../../../sagas/lpo';
import { wizardRouterActions } from '../../../sagas/wizardRouter';
import { FormFieldErrors, FormFieldNames } from '../../../config/formFieldNames';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../sagas/common';
import { getLeakOriginOption } from '../../../utils/lpo/leakOriginUtils';
import { getLeakCauseOption } from '../../../utils/lpo/leakCauseUtils';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const {
    BACK_BUTTON,
    CONTINUE_BUTTON,
    HELP_TEXT,
    LEAK_CAUSE_LABEL,
    LEAK_CAUSE_PLACEHOLDER,
    LEAK_ORIGIN_LABEL,
    LEAK_ORIGIN_PLACEHOLDER,
    NO_OPTIONS_MESSAGE,
    PAGE_NAME,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;
const { LEAK_CAUSE, LEAK_ORIGIN } = FormFieldNames;
const { REQUIRED_ERROR_MESSAGE } = FormFieldErrors;
const {
    BATH,
    BLOCKED_DRAINS,
    BOILER,
    CARELESSNESS,
    PIPE,
    SEAL,
    SHOWER,
    TOILET,
    TP_CONTRACTOR,
    UNKNOWN,
    WASHING_MACHINE,
    WATER_TANK,
} = LeakCauseEnums;
const { COMMUNAL_AREA, NEIGHBOURING_PROPERTY, OWN_PROPERTY, TO_BE_CONFIRMED } = LeakOriginEnums;

/**
 * Page view and page logic
 */
export const PropertyLeakPage = () => {
    const dispatch = useDispatch();
    const leakCause = useSelector(selectLeakCause);
    const leakOrigin = useSelector(selectLeakOrigin);
    const customCAN = useSelector(selectCustomCAN);
    const [cause, setCause] = useState<OptionType | null>(null);
    const [causeError, setCauseError] = useState<string>('');
    const [origin, setOrigin] = useState<OptionType | null>(null);
    const [originError, setOriginError] = useState<string>('');
    const { t } = useI18n();
    const tWithNS = useI18n('lpo.property.leak');

    // Cause Options
    const bathOption = getLeakCauseOption(t, BATH, '2029862');
    const blockedDrainsOption = getLeakCauseOption(t, BLOCKED_DRAINS, '2029871');
    const boilerOption = getLeakCauseOption(t, BOILER, '2029866');
    const carelessnessOption = getLeakCauseOption(t, CARELESSNESS, '2029872');
    const pipeOption = getLeakCauseOption(t, PIPE, '2029867');
    const sealOption = getLeakCauseOption(t, SEAL, '2029869');
    const showerOption = getLeakCauseOption(t, SHOWER, '2029863');
    const toiletOption = getLeakCauseOption(t, TOILET, '2029865');
    const tpContractorOption = getLeakCauseOption(t, TP_CONTRACTOR, '2029870');
    const unknownOption = getLeakCauseOption(t, UNKNOWN, '2029873');
    const washingMachineOption = getLeakCauseOption(t, WASHING_MACHINE, '2029864');
    const waterTankOption = getLeakCauseOption(t, WATER_TANK, '2029868');

    const causeOptions = [
        bathOption,
        showerOption,
        washingMachineOption,
        toiletOption,
        boilerOption,
        pipeOption,
        waterTankOption,
        sealOption,
        tpContractorOption,
        blockedDrainsOption,
        carelessnessOption,
        unknownOption,
    ];

    // Origin Options
    const communalAreaOption = getLeakOriginOption(t, COMMUNAL_AREA, '2029860');
    const neighbouringPropertyOption = getLeakOriginOption(t, NEIGHBOURING_PROPERTY, '2029859');
    const ownPropertyOption = getLeakOriginOption(t, OWN_PROPERTY, '2029858');
    const toBeConfirmedOption = getLeakOriginOption(t, TO_BE_CONFIRMED, '2029861');

    const originOptions = [ownPropertyOption, neighbouringPropertyOption, communalAreaOption, toBeConfirmedOption];

    useEffect(() => {
        const selected = causeOptions.find((x) => Number(x.value) === leakCause?.value);
        if (selected) {
            setCause(selected);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leakCause, setCause]);

    useEffect(() => {
        const selected = originOptions.find((x) => Number(x.value) === leakOrigin?.value);
        if (selected) {
            setOrigin(selected);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leakOrigin, setOrigin]);

    const handleBackButton = useGoBack();

    const handleCauseBlur = (e: FormChangeable) => {
        e.preventDefault();
        if (is(leakCause, null)) {
            setCauseError(t(REQUIRED_ERROR_MESSAGE));
        } else {
            setCauseError('');
        }
    };

    const handleOriginBlur = (e: FormChangeable) => {
        e.preventDefault();
        if (is(leakOrigin, null)) {
            setOriginError(t(REQUIRED_ERROR_MESSAGE));
        } else {
            setOriginError('');
        }
    };

    const handleCauseSelect = (e: SyntheticEvent, option: OptionType) => {
        const object = option ? (option as OptionType) : null;
        const value = object ? object.value : null;
        const key = object ? object.label : null;
        setCauseError('');
        setCause(object);
        if (object) {
            dispatch(
                lpoActions.update({
                    leakCause: { propertyId: 115, value: Number(value), key },
                })
            );
        } else {
            dispatch(
                lpoActions.update({
                    leakCause: null,
                })
            );
        }
    };

    const handleOriginSelect = (e: SyntheticEvent, option: OptionType) => {
        const object = option ? (option as OptionType) : null;
        const value = object ? object.value : null;
        const key = object ? object.label : null;
        setOriginError('');
        setOrigin(object);
        if (object) {
            dispatch(
                lpoActions.update({
                    leakOrigin: { propertyId: 114, value: Number(value), key },
                })
            );
        } else {
            dispatch(
                lpoActions.update({
                    leakOrigin: null,
                })
            );
        }
    };

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        if (is(leakCause, null) && is(leakOrigin, null)) {
            setCauseError(t(REQUIRED_ERROR_MESSAGE));
            setOriginError(t(REQUIRED_ERROR_MESSAGE));
        } else if (is(leakCause, null)) {
            setCauseError(t(REQUIRED_ERROR_MESSAGE));
        } else if (is(leakOrigin, null)) {
            setOriginError(t(REQUIRED_ERROR_MESSAGE));
        } else {
            setCauseError('');
            setOriginError('');
            dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(wizardRouterActions.goToNext()));
        }
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            <Grid className={'align-center'}>
                <MuiAutocomplete
                    error={!!causeError}
                    errorMessage={causeError}
                    id={LEAK_CAUSE}
                    inputFieldWrapper={'col-6'}
                    label={t(LEAK_CAUSE_LABEL)}
                    noOptionsText={t(NO_OPTIONS_MESSAGE)}
                    onBlur={handleCauseBlur}
                    onChange={handleCauseSelect}
                    openOnFocus={true}
                    placeholder={t(LEAK_CAUSE_PLACEHOLDER)}
                    options={causeOptions}
                    value={cause}
                    {...{ customCAN }}
                />
                <MuiAutocomplete
                    error={!!originError}
                    errorMessage={originError}
                    id={LEAK_ORIGIN}
                    inputFieldWrapper={'col-6'}
                    label={t(LEAK_ORIGIN_LABEL)}
                    noOptionsText={t(NO_OPTIONS_MESSAGE)}
                    onBlur={handleOriginBlur}
                    onChange={handleOriginSelect}
                    openOnFocus={true}
                    placeholder={t(LEAK_ORIGIN_PLACEHOLDER)}
                    options={originOptions}
                    value={origin}
                    {...{ customCAN }}
                />
            </Grid>
        </PageLayout>
    );
};
