import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { PhraseKeys } from '../../config/phraseKeys';
import { FormFieldErrors, MAX_LENGTH_250 } from '../../config/formFieldNames';

/**
 * Destructure necessary imports
 */
const { LAST_NAME_LABEL } = PhraseKeys;
const { MAX_LENGTH_ERROR_MESSAGE } = FormFieldErrors;

/**
 * Validation logic
 */
export const lastNameSchema = (t: TFunction, optional: boolean) => {
    if (optional) {
        return {
            lastName: Yup.string()
                .label(t(LAST_NAME_LABEL))
                .max(
                    MAX_LENGTH_250,
                    t(MAX_LENGTH_ERROR_MESSAGE, {
                        field: t(LAST_NAME_LABEL),
                        max: MAX_LENGTH_250,
                    })
                )
                .noSpecialChars()
                .nullable(),
        };
    } else {
        return {
            lastName: Yup.string()
                .label(t(LAST_NAME_LABEL))
                .required()
                .max(
                    MAX_LENGTH_250,
                    t(MAX_LENGTH_ERROR_MESSAGE, {
                        field: t(LAST_NAME_LABEL),
                        max: MAX_LENGTH_250,
                    })
                )
                .noSpecialChars()
                .nullable(),
        };
    }
};
