import React, { ReactElement, SyntheticEvent, useEffect, useState } from 'react';
import { customCANProps, MuiAutocomplete, Nullable, OptionType } from '@protectorinsurance/ds-can';
import { mapsServiceActions } from '../../sagas/services/mapsService';
import { useDispatch, useSelector } from 'react-redux';
import { selectMapsToken } from '../../sagas/selectors/mapsServiceSelector';

interface AutocompleteServiceProps {
    autoCompleteLabel: string;
    customCAN?: customCANProps;
    disabled?: boolean;
    error?: string | ReactElement;
    inputWrapperClass?: string;
    name: string;
    noOptionsText: string;
    onSelectedPlace: (selected: Nullable<OptionType>) => void;
    options: OptionType[];
    setOptions: (options: OptionType[]) => void;
    setSelectedOption: (selected: Nullable<OptionType>) => void;
    selectedOption: Nullable<OptionType>;
}

export const AutocompleteService = ({
    autoCompleteLabel,
    customCAN,
    disabled,
    error,
    inputWrapperClass = '',
    name,
    noOptionsText,
    onSelectedPlace,
    options,
    setOptions,
    setSelectedOption,
    selectedOption,
}: AutocompleteServiceProps) => {
    const [service, setService] = useState<google.maps.places.AutocompleteService | null>(null);
    const dispatch = useDispatch();
    const mapToken = useSelector(selectMapsToken);

    useEffect(() => {
        if (google && mapToken === null) {
            dispatch(mapsServiceActions.token());
        }
    }, [mapToken, dispatch]);
    useEffect(() => {
        if (!service) {
            const service = new google.maps.places.AutocompleteService();
            setService(service);
        }
    }, [service]);

    const handleChange = (e: SyntheticEvent, option: OptionType) => {
        const selected = option ? (option as OptionType) : null;
        setSelectedOption(selected);
        onSelectedPlace(selected);
    };

    const handleInputChange = (e: SyntheticEvent, input: string) => {
        if (service && input && mapToken) {
            service.getPlacePredictions({ input: input, sessionToken: mapToken }, function onGetPlaces(result, status) {
                if (status !== google.maps.places.PlacesServiceStatus.OK) {
                    setOptions([]);
                } else {
                    const temp = result.map((r) => {
                        return { label: r.description, value: r.place_id };
                    });
                    setOptions(temp);
                }
            });
        }
    };

    return (
        <MuiAutocomplete
            error={!!error}
            errorMessage={error}
            id={name}
            inputFieldWrapper={inputWrapperClass}
            label={autoCompleteLabel}
            onChange={handleChange}
            onInputChange={handleInputChange}
            openOnFocus={true}
            value={selectedOption}
            {...{ customCAN, disabled, noOptionsText, options }}
        />
    );
};
