import React from 'react';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    Clickable,
    is,
    NoIcon,
    YesIcon,
    YesNoKeys,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../utils/carouselPageLookup';
import { MotorRoutePaths } from '../../../wizardRouter/motorWizardRoutes';
import { selectIsDriving } from '../../../../sagas/selectors/motorSelectors';
import { wizardRouterActions as wizardActions } from '../../../../sagas/wizardRouter';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { motorActions } from '../../../../sagas/motor';
import { commonActions } from '../../../../sagas/common';

/**
 * Destructure necessary imports
 */
const { NO, YES } = YesNoKeys;

/**
 * Page view and page logic
 */
export const startIsDrivingPage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<MotorRoutePaths> => {
    const options = [
        { id: YES, name: t(`motor.start.isDriving.select.${YES}`), icon: <YesIcon /> },
        { id: NO, name: t(`motor.start.isDriving.select.${NO}`), icon: <NoIcon /> },
    ];

    return {
        slug: MotorRoutePaths.DYN_CAROUSEL_START_IS_DRIVING,
        stateKey: 'isDriving',
        i18n: {
            ns: 'motor.start.isDriving',
        },
        selectorValue: selectIsDriving(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                if (is(option.id, YES)) {
                    dispatch(wizardActions.skipBackToPrev(true));
                }
                dispatcherWithPromise(dispatch, motorActions.update, { isDriving: option.id })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext(option.id)));
            }
        },
    };
};
