import React, { useEffect, useState } from 'react';
import { Clickable, Grid, Link, PageLayout, PrintLink, StandardModal } from '@protectorinsurance/ds-can';
import { PhraseKeys } from '../../../config/phraseKeys';
import '../../../styles/utils/_mixins.scss';
import { useI18n } from '../../../hooks/useI18n';
import { PrintableSummary } from '../../../components/summary/lpo/PrintableSummary';
import { selectCustomCAN, selectLocaleLanguageCode } from '../../../sagas/selectors/commonSelectors';
import { useSelector } from 'react-redux';

/**
 * Destructure necessary imports
 */
const { COMPLETED_BUTTON, HELP_TEXT, PAGE_NAME, SUB_TITLE, TITLE } = PhraseKeys;

/**
 * Page view and page logic
 */
export const EndReportCompletedPage = () => {
    const tWithNs = useI18n('lpo.end.reportCompleted');
    const { t } = useI18n();
    const language = useSelector(selectLocaleLanguageCode);
    const customCAN = useSelector(selectCustomCAN);
    const [showModal, setShowModal] = useState<boolean>(false);

    const handleBackButton = (e: any) => {
        e.preventDefault();
        setShowModal(true);
    };

    const handleCloseModal = (e: Clickable) => {
        e.preventDefault();
        window.history.pushState(null, '', window.location.pathname);
        setShowModal(false);
    };

    const handleConfirmModal = (e: Clickable) => {
        e.preventDefault();
        window.location.href = `/nor/${language}`;
    };

    useEffect(() => {
        window.history.pushState(null, '', window.location.pathname);
        window.addEventListener('popstate', handleBackButton);

        return () => {
            window.removeEventListener('popstate', handleBackButton);
        };
    }, []);

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        window.location.replace(tWithNs.t('content.homePageLink'));
    };

    const printSummary = (e: Clickable) => {
        e.preventDefault();
        window.print();
    };

    return (
        <>
            <PageLayout
                backBtnText={''}
                continueBtnText={t(COMPLETED_BUTTON)}
                domainTitle={t(PAGE_NAME)}
                footerComponent={<PrintLink title={tWithNs.t(HELP_TEXT)} onClick={printSummary} />}
                handleContinueButton={handleContinueButton}
                headerSubTitle={tWithNs.t(SUB_TITLE)}
                headerTitle={tWithNs.t(TITLE)}
                pageClassName={'no-print'}
                showBackButton={false}
            >
                <Grid>
                    <p className={'no-print col-12 info-container'} tabIndex={0}>
                        {tWithNs.t('body.paragraph.one')}{' '}
                        <Link
                            linkText={tWithNs.t('body.email')}
                            url={`mailto:${tWithNs.t('body.email')}`}
                            {...{ customCAN }}
                        />
                    </p>
                </Grid>
            </PageLayout>
            <PrintableSummary showSummary={false} />
            {showModal && (
                <StandardModal
                    closeButtonText={t('modal.reportCompleted.closeButtonText')}
                    confirmButtonText={t('modal.reportCompleted.confirmButtonText')}
                    onClose={handleCloseModal}
                    onConfirm={handleConfirmModal}
                    showCloseButton={true}
                    showConfirmButton={true}
                    title={t('modal.reportCompleted.title')}
                >
                    <div className={'dialog__content text-center'}>{t('modal.reportCompleted.bodyText')}</div>
                </StandardModal>
            )}
        </>
    );
};
