import React from 'react';
import {
    ClaimTypeEnums,
    ClaimTypeTypeModel,
    is,
    LobKeys,
    Nullable,
    SummaryItem,
    SummaryItemProps,
} from '@protectorinsurance/ds-can';

/**
 * Destructure necessary imports
 */
const { PROPERTY } = LobKeys;
const { WATER } = ClaimTypeEnums;

/**
 * Component view and component logic
 */
export const renderLeakCause = (
    { inputWrapperClass, textKey, dataTestId, buttonText, path, handleClick }: SummaryItemProps,
    lob: Nullable<LobKeys>,
    claimType: ClaimTypeTypeModel
) => {
    if (is(lob, PROPERTY) && is(claimType, WATER)) {
        return <SummaryItem {...{ buttonText, dataTestId, handleClick, inputWrapperClass, path, textKey }} />;
    }
    return <></>;
};
