import React from 'react';
import { is, LobKeys, Nullable, SummaryItem, SummaryItemProps } from '@protectorinsurance/ds-can';

/**
 * Destructure necessary imports
 */
const { PROPERTY } = LobKeys;

/**
 * Component view and component logic
 */
export const renderHasExistingClaim = (
    { inputWrapperClass, textKey, dataTestId, buttonText, path, handleClick }: SummaryItemProps,
    lob: Nullable<LobKeys>
) => {
    if (is(lob, PROPERTY)) {
        return <SummaryItem {...{ buttonText, dataTestId, handleClick, inputWrapperClass, path, textKey }} />;
    }
    return <></>;
};
