import React from 'react';
import {
    is,
    LobKeys,
    LpoClaimCauseEnums,
    LpoClaimCauseTypeModel,
    Nullable,
    SummaryItem,
    SummaryItemProps,
} from '@protectorinsurance/ds-can';

/**
 * Destructure necessary imports
 */
const { PROPERTY } = LobKeys;
const { ESCAPE_WATER } = LpoClaimCauseEnums;

/**
 * Component view and component logic
 */
export const renderIsLeakFixed = (
    { inputWrapperClass, textKey, dataTestId, buttonText, path, handleClick }: SummaryItemProps,
    lob: Nullable<LobKeys>,
    claimCause: LpoClaimCauseTypeModel
) => {
    if (is(lob, PROPERTY) && is(claimCause, ESCAPE_WATER)) {
        return <SummaryItem {...{ buttonText, dataTestId, handleClick, inputWrapperClass, path, textKey }} />;
    }
    return <></>;
};
