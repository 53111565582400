import { StringSchema, TestContext, ValidationError } from 'yup';
import { yupCustomLocale } from '../../config/yup';
import { onlyAsciiValidator } from '@protectorinsurance/ds-can';

/**
 * Method logic
 */
export function onlyAscii(this: StringSchema, message: string = yupCustomLocale.string.onlyAscii) {
    // @ts-ignore
    return this.test('onlyAscii', message, function (this: TestContext, value: string):
        | boolean
        | ValidationError
        | Promise<boolean | ValidationError> {
        return !value ? true : onlyAsciiValidator(value).isValid();
    });
}
