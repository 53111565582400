import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Clickable,
    emptyFn,
    Grid,
    MuiAutocomplete,
    OptionType,
    PageLayout,
    PropertyDamageEnums,
} from '@protectorinsurance/ds-can';
import { selectExternalPropertyDamage } from '../../../sagas/selectors/lpoSelectors';
import { useI18n } from '../../../hooks/useI18n';
import { PhraseKeys } from '../../../config/phraseKeys';
import { useGoBack } from '../../../hooks/useGoBack';
import { FormFieldNames } from '../../../config/formFieldNames';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { lpoActions } from '../../../sagas/lpo';
import { wizardRouterActions } from '../../../sagas/wizardRouter';
import { commonActions } from '../../../sagas/common';
import { getPropertyDamageOption } from '../../../utils/lpo/propertyDamageUtils';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const {
    BACK_BUTTON,
    CONTINUE_BUTTON,
    EXTERNAL_PROPERTY_DAMAGE_LABEL,
    EXTERNAL_PROPERTY_DAMAGE_PLACEHOLDER,
    HELP_TEXT,
    NO_OPTIONS_MESSAGE,
    PAGE_NAME,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;
const { EXTERNAL_PROPERTY_DAMAGE } = FormFieldNames;
const {
    BOUNDARY_WALL,
    CHIMNEY,
    COMMUNAL_AREA,
    DOOR,
    DRIVE,
    EXTERNAL_GLAZING,
    FENCE,
    GARAGE,
    GARDEN,
    GATE,
    GREEN_HOUSE,
    OTHER,
    OTHER_OUTBUILDING,
    PATH,
    PATIO,
    ROOF,
    SHED,
    SWIMMING_POOL,
    WALL,
    WAREHOUSE,
} = PropertyDamageEnums;

/**
 * Page view and page logic
 */
export const PropertyExternalPropertyDamagePage = () => {
    const dispatch = useDispatch();
    const [damages, setDamages] = useState<OptionType[]>([]);
    const [error] = useState<string>('');
    const externalPropertyDamage = useSelector(selectExternalPropertyDamage);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('lpo.property.externalPropertyDamage');

    // Options
    const boundaryWallOption = getPropertyDamageOption(t, BOUNDARY_WALL, '2029874');
    const chimneyOption = getPropertyDamageOption(t, CHIMNEY, '2029875');
    const communalAreaOption = getPropertyDamageOption(t, COMMUNAL_AREA, '2029876');
    const doorOption = getPropertyDamageOption(t, DOOR, '2029877');
    const driveOption = getPropertyDamageOption(t, DRIVE, '2029878');
    const externalGlazingOption = getPropertyDamageOption(t, EXTERNAL_GLAZING, '2029879');
    const fenceOption = getPropertyDamageOption(t, FENCE, '2029880');
    const garageOption = getPropertyDamageOption(t, GARAGE, '2029881');
    const gardenOption = getPropertyDamageOption(t, GARDEN, '2029882');
    const gateOption = getPropertyDamageOption(t, GATE, '2029883');
    const greenHouseOption = getPropertyDamageOption(t, GREEN_HOUSE, '2029884');
    const otherOption = getPropertyDamageOption(t, OTHER, '2029892');
    const otherOutbuildingOption = getPropertyDamageOption(t, OTHER_OUTBUILDING, '2029893');
    const pathOption = getPropertyDamageOption(t, PATH, '2029885');
    const patioOption = getPropertyDamageOption(t, PATIO, '2029886');
    const roofOption = getPropertyDamageOption(t, ROOF, '2029887');
    const shedOption = getPropertyDamageOption(t, SHED, '2029888');
    const swimmingPoolOption = getPropertyDamageOption(t, SWIMMING_POOL, '2029889');
    const wallOption = getPropertyDamageOption(t, WALL, '2029890');
    const warehouseOption = getPropertyDamageOption(t, WAREHOUSE, '2029891');

    const options = [
        boundaryWallOption,
        chimneyOption,
        communalAreaOption,
        doorOption,
        driveOption,
        externalGlazingOption,
        fenceOption,
        garageOption,
        gardenOption,
        gateOption,
        greenHouseOption,
        pathOption,
        patioOption,
        roofOption,
        shedOption,
        swimmingPoolOption,
        wallOption,
        warehouseOption,
        otherOption,
        otherOutbuildingOption,
    ];

    useEffect(() => {
        const selected = externalPropertyDamage.map(
            (item: { propertyId: number; value: number; key: string }) =>
                options.find((x) => Number(x.value) === item.value) || { value: '', label: '' }
        );
        if (Array.isArray(selected) && selected.length !== 0) {
            setDamages(selected);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [externalPropertyDamage]);

    const handleBackButton = useGoBack();

    const handleBlur = emptyFn;

    const handleSelect = (e: SyntheticEvent, option: OptionType[]) => {
        const selected = option ? (option as OptionType[]) : null;
        if (selected) {
            setDamages([...selected]);
        }
    };

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        const items = damages.map((item: OptionType) => {
            return { propertyId: 116, value: Number(item.value), key: item.label };
        });
        dispatcherWithPromise(dispatch, lpoActions.update, { externalPropertyDamage: items })
            .then(() => dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => dispatch(wizardRouterActions.goToNext()));
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            <Grid className={'align-center'}>
                <MuiAutocomplete
                    error={!!error}
                    errorMessage={error}
                    filterSelectedOptions={true}
                    getOptionLabel={(option: OptionType) => option.label}
                    id={EXTERNAL_PROPERTY_DAMAGE}
                    inputFieldWrapper={'col-12'}
                    isOptionEqualToValue={(option: OptionType, value: OptionType) => option.value === value.value}
                    label={t(EXTERNAL_PROPERTY_DAMAGE_LABEL)}
                    multiple={true}
                    noOptionsText={t(NO_OPTIONS_MESSAGE)}
                    onBlur={handleBlur}
                    onChange={handleSelect}
                    openOnFocus={true}
                    placeholder={t(EXTERNAL_PROPERTY_DAMAGE_PLACEHOLDER)}
                    value={damages}
                    {...{ customCAN, options }}
                />
            </Grid>
        </PageLayout>
    );
};
