import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Layout } from './components/layout/Layout';
import { MotorRoutePaths } from './config/wizardRouter/motorWizardRoutes';
import { Motor } from './routes/motor';
import { initActions } from './sagas/init';
import { RequestErrorPage } from './pages/error/RequestError';
import { counter, SessionTimeoutTracker } from '@protectorinsurance/ds-can';
import { SessionTimeoutPage } from 'pages/error/SessionTimeout';
import { BaseRoutePaths } from './config/wizardRouter/baseWizardRoutes';
import { ClaimPage } from './pages/Claim';
import { LpoRoutePaths } from './config/wizardRouter/lpoWizardRoutes';
import { Lpo } from './routes/lpo';
import { MaintenanceModeTracker } from './components/maintenanceMode/maintenanceModeTracker/MaintenanceModeTracker';
import { useI18n } from './hooks/useI18n';
import { selectLoading, selectTimeoutWarning } from './sagas/selectors/commonSelectors';
import { commonActions } from './sagas/common';
import { CaptchaPage } from './pages/Captcha';
import { Loader } from './components/loader/Loader';

const App = () => {
    const dispatch = useDispatch();
    const timeoutWarning = useSelector(selectTimeoutWarning);
    const loading = useSelector(selectLoading);
    const { t } = useI18n();

    // Init of application
    useEffect(() => {
        dispatch(initActions.init());
    }, [dispatch]);

    const handleTimeoutWarning = () => {
        dispatch(commonActions.update({ timeoutWarning: timeoutWarning + 1 }));
        dispatch(commonActions.send());
    };

    return (
        <>
            {loading ? (
                <Loader fixed={false} />
            ) : (
                <Layout>
                    <Switch>
                        <Route exact path={BaseRoutePaths.CAPTCHA} component={CaptchaPage} />
                        <Route path={MotorRoutePaths.INDEX} component={Motor} />
                        <Route path={LpoRoutePaths.INDEX} component={Lpo} />
                        <Route exact path={BaseRoutePaths.INDEX} component={ClaimPage} />
                        <Route exact path={BaseRoutePaths.ERROR} component={RequestErrorPage} />
                        <Route exact path={MotorRoutePaths.SESSION_TIMEOUT} component={SessionTimeoutPage} />
                        <Route path="*" component={ClaimPage} />
                    </Switch>
                    <SessionTimeoutTracker
                        handleCallback={handleTimeoutWarning}
                        sessionTimeoutTitle={t('error.sessionTimeout.title')}
                        sessionTimeoutButton={t('error.sessionTimeout.button')}
                        sessionTimeoutText={t('error.sessionTimeout.text')}
                        sessionTimeoutWarningTitle={t('warning.sessionTimeout.title')}
                        sessionTimeoutWarningText={t('warning.sessionTimeout.text', { count: counter })}
                        sessionTimeoutWarningButton={t('form.buttons.confirm')}
                    />
                    <MaintenanceModeTracker />
                </Layout>
            )}
        </>
    );
};

export default App;
