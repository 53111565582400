import { AppState } from '../../store';

export const selectCommonState = (state: AppState) => state.common;
export const selectLocaleCountryCode = (state: AppState) => state.common.locale.country;
export const selectLocaleLanguageCode = (state: AppState) => state.common.locale.language;
export const selectLob = (state: AppState) => state.common.lob;
export const selectApiErrors = (state: AppState) => state.common.errors;
export const selectApiId = (state: AppState) => state.common.id;
export const selectRequestId = (state: AppState) => state.common.requestId;
export const selectTimeoutWarning = (state: AppState) => state.common.timeoutWarning;
export const selectSubmitted = (state: AppState) => state.common.submitted;
export const selectCustomCAN = (state: AppState) => state.common.customCAN;
export const selectLoading = (state: AppState) => state.common.loading;
