import React, { SyntheticEvent, useEffect, useState } from 'react';
import { PhraseKeys } from '../../../config/phraseKeys';
import { useDispatch, useSelector } from 'react-redux';
import { selectConstabulary, selectCrimeReference } from '../../../sagas/selectors/lpoSelectors';
import { useI18n } from '../../../hooks/useI18n';
import { Controller, useForm } from 'react-hook-form';
import {
    CrimeReferenceModel,
    emptyFn,
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    MuiAutocomplete,
    MuiTextInput,
    OptionType,
    PageLayout,
} from '@protectorinsurance/ds-can';
import { useGoBack } from '../../../hooks/useGoBack';
import { lpoActions } from '../../../sagas/lpo';
import { wizardRouterActions } from '../../../sagas/wizardRouter';
import { FormFieldNames } from '../../../config/formFieldNames';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../sagas/common';
import { getConstabularyOption } from '../../../utils/lpo/constabularyUtils';
import { ConstabularyEnums } from '../../../models/Constabulary';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const {
    BACK_BUTTON,
    CONSTABULARY_LABEL,
    CONSTABULARY_PLACEHOLDER,
    CONTINUE_BUTTON,
    CRIME_REFERENCE_LABEL,
    CRIME_REFERENCE_PLACEHOLDER,
    HELP_TEXT,
    NO_OPTIONS_MESSAGE,
    PAGE_NAME,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;
const { CONSTABULARY, CRIME_REFERENCE } = FormFieldNames;
const {
    AVON_SOMERSET,
    BEDFORDSHIRE,
    BRITISH_TRANSPORT,
    CAMBRIDGESHIRE,
    CHESHIRE,
    CITY_OF_LONDON,
    CLEVELAND,
    CUMBRIA,
    DERBYSHIRE,
    DEVON_CORNWALL,
    DORSET,
    DURHAM,
    DYFED_POWYS,
    ESSEX,
    GLOUCESTERSHIRE,
    GREATER_MANCHESTER,
    GWENT,
    HAMPSHIRE,
    HERTFORDSHIRE,
    HUMBERSIDE,
    KENT,
    LANCASHIRE,
    LEICESTERSHIRE,
    LINCOLNSHIRE,
    MERSEYSIDE,
    METROPOLITAN,
    MINISTRY_OF_DEFENCE,
    NORFOLK,
    NORTHAMPTONSHIRE,
    NORTHUMBRIA,
    NORTH_IRELAND,
    NORTH_WALES,
    NORTH_YORKSHIRE,
    NOTTINGHAMSHIRE,
    SCOTLAND,
    SOUTH_WALES,
    SOUTH_YORKSHIRE,
    STAFFORDSHIRE,
    SUFFOLK,
    SURREY,
    SUSSEX,
    THAMES,
    WARWICKSHIRE,
    WEST_MERCIA,
    WEST_MIDLANDS,
    WEST_YORKSHIRE,
    WILTSHIRE,
} = ConstabularyEnums;

/**
 * Page view and page logic
 */
export const EndCrimeReferencePage = () => {
    const dispatch = useDispatch();
    const crimeReference = useSelector(selectCrimeReference);
    const constabulary = useSelector(selectConstabulary);
    const customCAN = useSelector(selectCustomCAN);
    const [policeStation, setPoliceStation] = useState<OptionType | null>(null);
    const [error, setError] = useState<string>('');
    const { t } = useI18n();
    const tWithNS = useI18n('lpo.end.crimeReference');
    const { control, handleSubmit, setValue, trigger } = useForm<CrimeReferenceModel>({
        defaultValues: {
            crimeReference,
        },
    });

    // Options
    const avonAndSomersetOption = getConstabularyOption(t, AVON_SOMERSET, '2126107');
    const bedforshireOption = getConstabularyOption(t, BEDFORDSHIRE, '2126108');
    const britishTransportOption = getConstabularyOption(t, BRITISH_TRANSPORT, '2126109');
    const cambridgeshireOption = getConstabularyOption(t, CAMBRIDGESHIRE, '2126110');
    const cheshireOption = getConstabularyOption(t, CHESHIRE, '2126111');
    const cityOfLondonOption = getConstabularyOption(t, CITY_OF_LONDON, '2126112');
    const clevelandOption = getConstabularyOption(t, CLEVELAND, '2126113');
    const cumbriaOption = getConstabularyOption(t, CUMBRIA, '2126114');
    const derbyshireOption = getConstabularyOption(t, DERBYSHIRE, '2126115');
    const devonAndCornwallOption = getConstabularyOption(t, DEVON_CORNWALL, '2126116');
    const dorsetOption = getConstabularyOption(t, DORSET, '2126117');
    const durhamOption = getConstabularyOption(t, DURHAM, '2126118');
    const dyfedPowysOption = getConstabularyOption(t, DYFED_POWYS, '2126119');
    const essexOption = getConstabularyOption(t, ESSEX, '2126120');
    const gloucestershireOption = getConstabularyOption(t, GLOUCESTERSHIRE, '2126121');
    const greaterManchesterOption = getConstabularyOption(t, GREATER_MANCHESTER, '2126122');
    const gwentOption = getConstabularyOption(t, GWENT, '2126123');
    const hampshireOption = getConstabularyOption(t, HAMPSHIRE, '2126124');
    const hertfordshireOption = getConstabularyOption(t, HERTFORDSHIRE, '2126125');
    const humbersideOption = getConstabularyOption(t, HUMBERSIDE, '2126126');
    const kentOption = getConstabularyOption(t, KENT, '2126127');
    const lancashireOption = getConstabularyOption(t, LANCASHIRE, '2126128');
    const leicestershireOption = getConstabularyOption(t, LEICESTERSHIRE, '2126129');
    const lincolnshireOption = getConstabularyOption(t, LINCOLNSHIRE, '2126130');
    const merseysideOption = getConstabularyOption(t, MERSEYSIDE, '2126131');
    const metropolitanOption = getConstabularyOption(t, METROPOLITAN, '2126132');
    const ministryOfDefenceOption = getConstabularyOption(t, MINISTRY_OF_DEFENCE, '2126133');
    const norfolkOption = getConstabularyOption(t, NORFOLK, '2126134');
    const northWalesOption = getConstabularyOption(t, NORTH_WALES, '2126135');
    const northamptonshireOption = getConstabularyOption(t, NORTHAMPTONSHIRE, '2126136');
    const northumbriaOption = getConstabularyOption(t, NORTHUMBRIA, '2126137');
    const northYorkshireOption = getConstabularyOption(t, NORTH_YORKSHIRE, '2126138');
    const nottinghamshireOption = getConstabularyOption(t, NOTTINGHAMSHIRE, '2126139');
    const northernIrelandOption = getConstabularyOption(t, NORTH_IRELAND, '2126140');
    const scotlandOption = getConstabularyOption(t, SCOTLAND, '2126141');
    const southWalesOption = getConstabularyOption(t, SOUTH_WALES, '2126142');
    const southYorkshireOption = getConstabularyOption(t, SOUTH_YORKSHIRE, '2126143');
    const staffordshireOption = getConstabularyOption(t, STAFFORDSHIRE, '2126144');
    const suffolkOption = getConstabularyOption(t, SUFFOLK, '2126145');
    const surreyOption = getConstabularyOption(t, SURREY, '2126146');
    const sussexOption = getConstabularyOption(t, SUSSEX, '2126147');
    const thamesOption = getConstabularyOption(t, THAMES, '2126148');
    const warwickshireOption = getConstabularyOption(t, WARWICKSHIRE, '2126149');
    const westMerciaOption = getConstabularyOption(t, WEST_MERCIA, '2126150');
    const westMidlandsOption = getConstabularyOption(t, WEST_MIDLANDS, '2126151');
    const westYorkshireOption = getConstabularyOption(t, WEST_YORKSHIRE, '2126152');
    const wiltshireOption = getConstabularyOption(t, WILTSHIRE, '2126153');

    const options = [
        avonAndSomersetOption,
        bedforshireOption,
        britishTransportOption,
        cambridgeshireOption,
        cheshireOption,
        cityOfLondonOption,
        clevelandOption,
        cumbriaOption,
        derbyshireOption,
        devonAndCornwallOption,
        dorsetOption,
        durhamOption,
        dyfedPowysOption,
        essexOption,
        gloucestershireOption,
        greaterManchesterOption,
        gwentOption,
        hampshireOption,
        hertfordshireOption,
        humbersideOption,
        kentOption,
        lancashireOption,
        leicestershireOption,
        lincolnshireOption,
        merseysideOption,
        metropolitanOption,
        ministryOfDefenceOption,
        norfolkOption,
        northWalesOption,
        northamptonshireOption,
        northumbriaOption,
        northYorkshireOption,
        nottinghamshireOption,
        northernIrelandOption,
        scotlandOption,
        southWalesOption,
        southYorkshireOption,
        staffordshireOption,
        suffolkOption,
        surreyOption,
        sussexOption,
        thamesOption,
        warwickshireOption,
        westMerciaOption,
        westMidlandsOption,
        westYorkshireOption,
        wiltshireOption,
    ];

    useEffect(() => {
        const selected = options.find((x) => Number(x.value) === constabulary?.value);
        if (selected) {
            setPoliceStation(selected);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [constabulary, setPoliceStation]);

    const handleBackButton = useGoBack();

    const handleSelectBlur = emptyFn;

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        await setValue(id, value, { shouldValidate: true });
        dispatch(lpoActions.update({ crimeReference: value }));
    };

    const handleSelect = (e: SyntheticEvent, option: OptionType) => {
        const object = option ? (option as OptionType) : null;
        const value = object ? object.value : null;
        const key = object ? object.label : null;
        setError('');
        setPoliceStation(object);
        dispatch(
            lpoActions.update({
                constabulary: { key: key, propertyId: 120, value: Number(value) },
            })
        );
    };

    const onSubmit = (values: CrimeReferenceModel) => {
        dispatcherWithPromise(dispatch, lpoActions.update, { crimeReference: values.crimeReference })
            .then(() => dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => dispatch(wizardRouterActions.goToNext()));
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={handleSubmit(onSubmit)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <HiddenInputSubmit />
                <Grid className={'align-center'}>
                    <Controller
                        control={control}
                        name={CRIME_REFERENCE}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                id={CRIME_REFERENCE}
                                inputFieldWrapper={!crimeReference ? 'col-12' : 'col-6'}
                                label={t(CRIME_REFERENCE_LABEL)}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(CRIME_REFERENCE_PLACEHOLDER)}
                                reference={ref}
                                {...{ customCAN }}
                            />
                        )}
                    />
                    {crimeReference && (
                        <MuiAutocomplete
                            error={!!error}
                            errorMessage={error}
                            id={CONSTABULARY}
                            inputFieldWrapper={'col-6'}
                            label={t(CONSTABULARY_LABEL)}
                            noOptionsText={t(NO_OPTIONS_MESSAGE)}
                            onBlur={handleSelectBlur}
                            onChange={handleSelect}
                            openOnFocus={true}
                            placeholder={t(CONSTABULARY_PLACEHOLDER)}
                            value={policeStation}
                            {...{ customCAN, options }}
                        />
                    )}
                </Grid>
            </form>
        </PageLayout>
    );
};
