import { StringSchema, TestContext, ValidationError } from 'yup';
import { yupCustomLocale } from '../../config/yup';
import { NO_SPECIAL_CHARS_REGEX } from '../../utils/validation/stringFormats';

export function noSpecialChars(
    this: StringSchema,
    key: string,
    message: string = yupCustomLocale.string.noSpecialChars
) {
    // @ts-ignore
    return this.test('noSpecialChars', message, function (this: TestContext, value: string):
        | boolean
        | ValidationError
        | Promise<boolean | ValidationError> {
        return !value || !!value.match(NO_SPECIAL_CHARS_REGEX);
    });
}
