import {
    InsuredByProtectorKeys,
    is,
    Nullable,
    PartyRelationKeys,
    RegistrationNumberTypeModel,
    VehicleModel,
    vehicleServiceActions,
    VehicleServiceModel,
} from '@protectorinsurance/ds-can';
import dispatcherWithPromise from '../utils/dispatcherWithPromise';
import { motorActions } from '../sagas/motor';
import { commonActions } from '../sagas/common';
import { wizardRouterActions as wizardActions } from '../sagas/wizardRouter';
import { MotorRoutePaths } from '../config/wizardRouter/motorWizardRoutes';
import { useDispatch } from 'react-redux';

const { YES, YES_RECENTLY_INSURED_BY_PROTECTOR } = InsuredByProtectorKeys;
const { DYN_CAROUSEL_START_INSURED_BY_PROTECTOR, DYN_CAROUSEL_START_IS_DRIVING, START_VEHICLE_INFORMATION } =
    MotorRoutePaths;

function findNextPageLink(
    registrationNumber: RegistrationNumberTypeModel,
    claimVehicle: Nullable<VehicleModel>,
    vehicleServiceData: Nullable<VehicleServiceModel>
) {
    let goToLink = wizardActions.goTo(START_VEHICLE_INFORMATION);
    if (
        claimVehicle &&
        claimVehicle.registrationNumber !== '' &&
        (is(YES, claimVehicle.insuredByProtector) ||
            is(YES_RECENTLY_INSURED_BY_PROTECTOR, claimVehicle.insuredByProtector))
    ) {
        goToLink = wizardActions.goTo(DYN_CAROUSEL_START_IS_DRIVING);
    } else if (
        (claimVehicle && claimVehicle.registrationNumber === '') ||
        registrationNumber === '' ||
        vehicleServiceData === undefined ||
        vehicleServiceData === null
    ) {
        goToLink = wizardActions.goTo(START_VEHICLE_INFORMATION);
    } else if (claimVehicle && claimVehicle.registrationNumber !== '' && !is(YES, claimVehicle.insuredByProtector)) {
        goToLink = wizardActions.goTo(DYN_CAROUSEL_START_INSURED_BY_PROTECTOR);
    }
    return goToLink;
}

export const useSubmitClaimVehicle = () => {
    const dispatch = useDispatch();

    return function submitClaimVehicle(
        registrationNumber: Nullable<string>,
        claimVehicle: Nullable<VehicleModel>,
        vehicleServiceData: Nullable<VehicleServiceModel>
    ) {
        let goToLink = findNextPageLink(registrationNumber, claimVehicle, vehicleServiceData);

        if (!!claimVehicle) {
            dispatcherWithPromise(dispatch, motorActions.updateClaimVehicle, {
                ...claimVehicle,
                registrationNumber: registrationNumber?.toUpperCase(),
                partyRelation: PartyRelationKeys.CLAIM_VEHICLE,
            })
                .then(() => dispatcherWithPromise(dispatch, vehicleServiceActions.initialize))
                .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                .then(() => dispatch(goToLink));
        } else {
            dispatcherWithPromise(dispatch, motorActions.updateClaimVehicle, {
                registrationNumber: registrationNumber?.toUpperCase(),
            })
                .then(() => dispatcherWithPromise(dispatch, vehicleServiceActions.initialize))
                .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                .then(() => dispatch(goToLink));
        }
    };
};
