import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import {
    Carousel,
    CarouselConfigOptionProps,
    CarouselConfigOptionType,
    CarouselItem,
    Clickable,
    is,
    joinNSWithKey,
    LobKeys,
    PageLayout,
} from '@protectorinsurance/ds-can';
import { PhraseKeys } from '../config/phraseKeys';
import { useI18n } from '../hooks/useI18n';
import dispatcherWithPromise from '../utils/dispatcherWithPromise';
import { motorActions } from '../sagas/motor';
import { useCarouselPage } from '../hooks/useCarouselPage';
import { commonActions } from '../sagas/common';
import { useGoBack } from '../hooks/useGoBack';
import { selectCustomCAN, selectLob } from '../sagas/selectors/commonSelectors';
import { lpoActions } from '../sagas/lpo';

/**
 * Destructure models, phrases etc.
 */
const { CONTINUE_BUTTON, BACK_BUTTON, PAGE_NAME, TITLE, SUB_TITLE, HELP_TEXT } = PhraseKeys;
const { AUTO } = LobKeys;

/**
 * Page view and page logic
 */
export const DynCarouselPage = () => {
    const { t } = useI18n();
    const dispatch = useDispatch();
    const carousel = useCarouselPage();
    const handleBackButton = useGoBack();
    const lob = useSelector(selectLob);
    const customCAN = useSelector(selectCustomCAN);

    if (!carousel) {
        return null;
    }

    const { options, stateKey, i18n, pageLayoutOptions } = carousel;
    const tWithNS = (value: string) => t(joinNSWithKey(value, i18n?.ns));
    const defaultPageLayoutOptions = {
        backBtnText: t(BACK_BUTTON),
        continueBtnText: t(CONTINUE_BUTTON),
        domainTitle: t(PAGE_NAME),
        footerText: tWithNS(HELP_TEXT),
        headerSubTitle: tWithNS(SUB_TITLE),
        headerTitle: tWithNS(TITLE),
        showBackButton: true,
        showContinueButton: false,
    };

    let action: any = lpoActions.update;
    if (is(lob, AUTO)) {
        action = motorActions.update;
    }

    const selected = (id: CarouselConfigOptionType, e: Clickable) => {
        e.preventDefault();
        const option = options?.find((x: CarouselConfigOptionProps) => x.id === id);

        if (option) {
            dispatcherWithPromise(dispatch, action, {
                [stateKey]: option.id,
            })
                .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                .then(() => dispatch(wizardActions.goToNext(option.id)));
        }
    };
    const onSelect = carousel?.onSelect ? carousel?.onSelect : selected;

    /**
     * Render functions
     */
    const renderSelection = options?.map((option: CarouselConfigOptionProps, idx: number) => {
        const isSelect = carousel.isSelected ? carousel.isSelected(option.id) : option.id === carousel.selectorValue;

        return <CarouselItem key={idx} {...option} handleClick={onSelect} isSelected={isSelect} {...{ customCAN }} />;
    });

    const renderCarousel = () => {
        if (!renderSelection) return null;

        return <Carousel>{renderSelection}</Carousel>;
    };

    return (
        <PageLayout {...{ ...defaultPageLayoutOptions, ...pageLayoutOptions, handleBackButton }}>
            {renderCarousel()}
        </PageLayout>
    );
};
