import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Clickable,
    emptyFn,
    Grid,
    MuiAutocomplete,
    OptionType,
    PageLayout,
    PropertyDamageEnums,
} from '@protectorinsurance/ds-can';
import { selectInternalPropertyDamage } from '../../../sagas/selectors/lpoSelectors';
import { useI18n } from '../../../hooks/useI18n';
import { PhraseKeys } from '../../../config/phraseKeys';
import { useGoBack } from '../../../hooks/useGoBack';
import { FormFieldNames } from '../../../config/formFieldNames';
import dispatcherWithPromise from '../../../utils/dispatcherWithPromise';
import { lpoActions } from '../../../sagas/lpo';
import { wizardRouterActions } from '../../../sagas/wizardRouter';
import { commonActions } from '../../../sagas/common';
import { getPropertyDamageOption } from '../../../utils/lpo/propertyDamageUtils';
import { selectCustomCAN } from '../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const {
    BACK_BUTTON,
    CONTINUE_BUTTON,
    HELP_TEXT,
    INTERNAL_PROPERTY_DAMAGE_LABEL,
    INTERNAL_PROPERTY_DAMAGE_PLACEHOLDER,
    NO_OPTIONS_MESSAGE,
    PAGE_NAME,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;
const { INTERNAL_PROPERTY_DAMAGE } = FormFieldNames;
const {
    AIRING_CUPBOARD,
    ATTIC,
    BASEMENT,
    BATHROOM,
    BEDROOM,
    COMMUNAL_AREA,
    CONSERVATORY,
    DINING_ROOM,
    GARAGE,
    HALL,
    KITCHEN,
    LOFT,
    LOUNGE,
    OFFICE,
    OTHER,
    OTHER_PROPERTY,
    PORCH,
    RECEPTION,
    SHOWROOM,
    STOCKROOM,
    SWIMMING_POOL,
    UTILITY_ROOM,
    WC,
} = PropertyDamageEnums;

/**
 * Page view and page logic
 */
export const PropertyInternalPropertyDamagePage = () => {
    const dispatch = useDispatch();
    const [damages, setDamages] = useState<OptionType[]>([]);
    const [error] = useState<string>('');
    const internalPropertyDamage = useSelector(selectInternalPropertyDamage);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('lpo.property.internalPropertyDamage');

    // Options
    const airingCupboardOption = getPropertyDamageOption(t, AIRING_CUPBOARD, '2029894');
    const atticOption = getPropertyDamageOption(t, ATTIC, '2029905');
    const basementOption = getPropertyDamageOption(t, BASEMENT, '2029895');
    const bathroomOption = getPropertyDamageOption(t, BATHROOM, '2029896');
    const bedroomOption = getPropertyDamageOption(t, BEDROOM, '2029897');
    const communalAreaOption = getPropertyDamageOption(t, COMMUNAL_AREA, '2029898');
    const conservatoryOption = getPropertyDamageOption(t, CONSERVATORY, '2029899');
    const diningRoomOption = getPropertyDamageOption(t, DINING_ROOM, '2029900');
    const garageOption = getPropertyDamageOption(t, GARAGE, '2029901');
    const hallOption = getPropertyDamageOption(t, HALL, '2029902');
    const kitchenOption = getPropertyDamageOption(t, KITCHEN, '2029903');
    const loftOption = getPropertyDamageOption(t, LOFT, '2029904');
    const loungeOption = getPropertyDamageOption(t, LOUNGE, '2029906');
    const officeOption = getPropertyDamageOption(t, OFFICE, '2029907');
    const otherOption = getPropertyDamageOption(t, OTHER, '2029916');
    const otherPropertyOption = getPropertyDamageOption(t, OTHER_PROPERTY, '2029915');
    const porchOption = getPropertyDamageOption(t, PORCH, '2029908');
    const receptionOption = getPropertyDamageOption(t, RECEPTION, '2029909');
    const showroomOption = getPropertyDamageOption(t, SHOWROOM, '2029910');
    const stockroomOption = getPropertyDamageOption(t, STOCKROOM, '2029911');
    const swimmingPoolOption = getPropertyDamageOption(t, SWIMMING_POOL, '2029912');
    const utilityRoomOption = getPropertyDamageOption(t, UTILITY_ROOM, '2029913');
    const wcOption = getPropertyDamageOption(t, WC, '2029916');

    const options = [
        airingCupboardOption,
        atticOption,
        basementOption,
        bathroomOption,
        bedroomOption,
        communalAreaOption,
        conservatoryOption,
        diningRoomOption,
        garageOption,
        hallOption,
        kitchenOption,
        loftOption,
        loungeOption,
        officeOption,
        otherOption,
        otherPropertyOption,
        porchOption,
        receptionOption,
        showroomOption,
        stockroomOption,
        swimmingPoolOption,
        utilityRoomOption,
        wcOption,
    ];

    useEffect(() => {
        const selected = internalPropertyDamage.map(
            (item: { propertyId: number; value: number; key: string }) =>
                options.find((x) => Number(x.value) === item.value) || { value: '', label: '' }
        );
        if (Array.isArray(selected) && selected.length !== 0) {
            setDamages(selected);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [internalPropertyDamage]);

    const handleBackButton = useGoBack();

    const handleBlur = emptyFn;

    const handleSelect = (e: SyntheticEvent, option: OptionType[]) => {
        const selected = option ? (option as OptionType[]) : null;
        if (selected) {
            setDamages([...selected]);
        }
    };

    const handleContinueButton = (e: Clickable) => {
        e.preventDefault();
        const items = damages.map((item: OptionType) => {
            return { propertyId: 117, value: Number(item.value), key: item.label };
        });
        dispatcherWithPromise(dispatch, lpoActions.update, { internalPropertyDamage: items })
            .then(() => dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => dispatch(wizardRouterActions.goToNext()));
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton, handleContinueButton }}
        >
            <Grid className={'align-center'}>
                <MuiAutocomplete
                    error={!!error}
                    errorMessage={error}
                    filterSelectedOptions={true}
                    getOptionLabel={(option: OptionType) => option.label}
                    id={INTERNAL_PROPERTY_DAMAGE}
                    inputFieldWrapper={'col-12'}
                    isOptionEqualToValue={(option: OptionType, value: OptionType) => option.value === value.value}
                    label={t(INTERNAL_PROPERTY_DAMAGE_LABEL)}
                    multiple={true}
                    noOptionsText={t(NO_OPTIONS_MESSAGE)}
                    onBlur={handleBlur}
                    onChange={handleSelect}
                    openOnFocus={true}
                    placeholder={t(INTERNAL_PROPERTY_DAMAGE_PLACEHOLDER)}
                    value={damages}
                    {...{ customCAN, options }}
                />
            </Grid>
        </PageLayout>
    );
};
