import { TFunction } from 'i18next';
import { ClaimTypeEnums } from '@protectorinsurance/ds-can';

/**
 * Utility logic
 */
export const createClaimTypeKey = (id: string) => {
    return `lpo.claimType.select.${id}`;
};

export const getClaimTypeOption = (t: TFunction, key: ClaimTypeEnums) => {
    return {
        value: key,
        label: t(createClaimTypeKey(key)),
    };
};
