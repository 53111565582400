import { FlowKeys } from '@protectorinsurance/ds-can';
import { YesNoKeys } from '@protectorinsurance/ds-can';
import { ObjectWithDynamicKey } from '@protectorinsurance/ds-can';

export enum UnitTestPaths {
    UNIT_TEST_DYN_INDEX = '/unit-test/:language/:flow/index',
    UNIT_TEST_DYN_FIRST = '/unit-test/:language/first',
    UNIT_TEST_DYN_SECOND = '/unit-test/:language/second',
    UNIT_TEST_DYN_THIRD = '/unit-test/:language/third',
    UNIT_TEST_DYN_SITUATION_SUMMARY = '/unit-test/:language/situation-summary',

    UNIT_TEST_STATIC_INDEX = '/unit-test/en/start/index',
    UNIT_TEST_STATIC_FIRST = '/unit-test/en/first',
    UNIT_TEST_STATIC_SITUATION_SUMMARY = '/unit-test/en/situation-summary',
}

export const unitTestWizardRoutes: ObjectWithDynamicKey = {
    [UnitTestPaths.UNIT_TEST_DYN_INDEX]: UnitTestPaths.UNIT_TEST_DYN_FIRST,
    [UnitTestPaths.UNIT_TEST_DYN_FIRST]: {
        [FlowKeys.START]: UnitTestPaths.UNIT_TEST_DYN_SECOND,
        [YesNoKeys.NO]: UnitTestPaths.UNIT_TEST_DYN_THIRD,
        [YesNoKeys.YES]: UnitTestPaths.UNIT_TEST_DYN_THIRD,
    },
    [UnitTestPaths.UNIT_TEST_DYN_SECOND]: UnitTestPaths.UNIT_TEST_DYN_THIRD,
    [UnitTestPaths.UNIT_TEST_DYN_THIRD]: UnitTestPaths.UNIT_TEST_DYN_SITUATION_SUMMARY,
};
