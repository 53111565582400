import React from 'react';
import { LpoSummaryProps } from '../../../types/SummaryProps';
import { TEST_ID_PREFIX } from 'utils/testIdUtil';
import { LpoRoutePaths } from '../../../config/wizardRouter/lpoWizardRoutes';
import { BaseRoutePaths } from '../../../config/wizardRouter/baseWizardRoutes';
import { dtParseAndFormat } from '../../../utils/date/dateUtils';
import { renderLob } from './components/start/RenderLob';
import { renderPrivacy } from './components/start/RenderPrivacy';
import { renderClaimDate } from './components/start/RenderClaimDate';
import { renderClaimReporterRole } from './components/start/RenderClaimReporterRole';
import { renderClaimType } from './components/start/RenderClaimType';
import { renderLocation } from './components/start/RenderLocation';
import { formatClaimant, is, trueFalseToYesNo } from '@protectorinsurance/ds-can';
import classNames from 'classnames';
import { createTextYesNoDontKnow } from '../../../utils/displayUtils';
import { renderPolicyHolder } from './components/start/RenderPolicyHolder';
import { formatCompanyInformation } from 'utils/strings/formatCompanyInformation';
import { renderIsEmployer } from './components/start/RenderIsEmployer';
import { renderHasInjury } from './components/start/RenderHasInjury';
import { renderClaimDescription } from './components/start/RenderClaimDescription';
import { renderClaimCause } from './components/start/RenderClaimCause';
import { renderTypeOfProperty } from './components/start/RenderTypeOfProperty';
import { renderUnit } from './components/start/RenderUnit';
import { renderExternalPropertyDamage } from './components/start/RenderExternalPropertyDamage';
import { renderInternalPropertyDamage } from './components/start/RenderInternalPropertyDamage';
import { renderLeakOrigin } from './components/start/RenderLeakOrigin';
import { renderLeakCause } from './components/start/RenderLeakCause';
import { renderClaimantInformation } from './components/start/RenderClaimantInformation';

/**
 * Destructure necessary imports
 */
const { INDEX } = BaseRoutePaths;
const {
    DYN_CAROUSEL_LIABILITY_HAS_INJURY,
    DYN_CAROUSEL_LIABILITY_IS_EMPLOYER,
    DYN_CAROUSEL_PROPERTY_TYPE_OF_PROPERTY,
    DYN_CAROUSEL_START_CLAIM_REPORTER_ROLE,
    LIABILITY_CLAIM_CAUSE,
    LIABILITY_CLAIM_TYPE,
    LIABILITY_CLAIMANT_INFORMATION,
    PROPERTY_EXTERNAL_PROPERTY_DAMAGE,
    PROPERTY_INTERNAL_PROPERTY_DAMAGE,
    PROPERTY_LEAK,
    PROPERTY_PROPERTY_ADDRESS,
    START_ACCIDENT_LOCATION,
    START_CLAIM_DATE,
    START_CLAIM_DESCRIPTION,
    START_POLICY_HOLDER,
    START_PRIVACY,
} = LpoRoutePaths;

/**
 * Component view and component logic
 */
export const DisplayStartSummary = ({ model, t, tWithNS, handleClick, upload, common }: LpoSummaryProps) => {
    const {
        acceptedPrivacy,
        accidentLocation,
        claimCause,
        claimDate,
        claimDescription,
        claimReporterRole,
        claimType,
        claimantInformation,
        companyInformation,
        externalPropertyDamage,
        hasInjury,
        internalPropertyDamage,
        isEmployer,
        leakCause,
        leakOrigin,
        typeOfProperty,
    } = model;
    const { lob } = common;

    const internalPropertyItems = internalPropertyDamage.map(
        (item: { propertyId: number; value: number; key: string }) => item.key
    );
    const externalPropertyItems = externalPropertyDamage.map(
        (item: { propertyId: number; value: number; key: string }) => item.key
    );

    // Classes
    const locationClass = classNames(
        `${!is(accidentLocation.unit, null) ? 'col-6' : 'col-12'}`,
        'divider text-align-left'
    );

    return (
        <>
            {renderLob({
                inputWrapperClass: 'col-12 divider text-align-left',
                textKey: tWithNS('lob.heading.title'),
                dataTestId: TEST_ID_PREFIX.lob,
                buttonText: t(`lob.select.${lob}`),
                path: INDEX,
                handleClick,
            })}

            {renderPrivacy({
                inputWrapperClass: 'col-12 divider text-align-left',
                textKey: tWithNS('privacy.heading.title'),
                dataTestId: TEST_ID_PREFIX.acceptedPrivacy,
                buttonText: t(trueFalseToYesNo(acceptedPrivacy)),
                path: START_PRIVACY,
                handleClick,
            })}

            {renderPolicyHolder({
                inputWrapperClass: 'col-12 divider text-align-left',
                textKey: tWithNS('policyHolder.heading.title'),
                dataTestId: TEST_ID_PREFIX.policyHolder,
                buttonText: companyInformation ? formatCompanyInformation(companyInformation) : '-',
                path: START_POLICY_HOLDER,
                handleClick,
            })}

            {renderClaimReporterRole({
                inputWrapperClass: 'col-12 divider text-align-left',
                textKey: tWithNS('claimReporterRole.heading.title'),
                dataTestId: TEST_ID_PREFIX.claimReporterRole,
                buttonText: claimReporterRole ? t(`lpo.start.claimReporterRole.select.${claimReporterRole}`) : '-',
                path: DYN_CAROUSEL_START_CLAIM_REPORTER_ROLE,
                handleClick,
            })}

            {renderTypeOfProperty(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('typeOfProperty.heading.title'),
                    dataTestId: TEST_ID_PREFIX.typeOfProperty,
                    buttonText: typeOfProperty ? t(`lpo.typeOfProperty.select.${typeOfProperty}`) : '-',
                    path: DYN_CAROUSEL_PROPERTY_TYPE_OF_PROPERTY,
                    handleClick,
                },
                lob
            )}

            {renderClaimantInformation(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('claimantInformation.heading.title'),
                    dataTestId: TEST_ID_PREFIX.claimantInformation,
                    buttonText: claimantInformation ? formatClaimant(claimantInformation) : '-',
                    path: LIABILITY_CLAIMANT_INFORMATION,
                    handleClick,
                },
                lob
            )}

            {renderIsEmployer(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('isEmployer.heading.title'),
                    dataTestId: TEST_ID_PREFIX.isEmployer,
                    buttonText: t(createTextYesNoDontKnow(isEmployer)),
                    path: DYN_CAROUSEL_LIABILITY_IS_EMPLOYER,
                    handleClick,
                },
                lob
            )}

            {renderHasInjury(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('hasInjury.heading.title'),
                    dataTestId: TEST_ID_PREFIX.hasInjury,
                    buttonText: t(createTextYesNoDontKnow(hasInjury)),
                    path: DYN_CAROUSEL_LIABILITY_HAS_INJURY,
                    handleClick,
                },
                lob
            )}

            {renderClaimType(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('claimType.heading.title'),
                    dataTestId: TEST_ID_PREFIX.claimType,
                    buttonText: claimType ? t(`lpo.claimType.select.${claimType}`) : '-',
                    path: LIABILITY_CLAIM_TYPE,
                    handleClick,
                },
                lob
            )}

            {renderClaimCause({
                inputWrapperClass: 'col-12 divider text-align-left',
                textKey: tWithNS('claimCause.heading.title'),
                dataTestId: TEST_ID_PREFIX.claimCause,
                buttonText: claimCause ? t(`lpo.claimCause.select.${claimCause}`) : '-',
                path: LIABILITY_CLAIM_CAUSE,
                handleClick,
            })}

            {renderLeakOrigin(
                {
                    inputWrapperClass: 'col-6 divider text-align-left',
                    textKey: tWithNS('leakOrigin.heading.title'),
                    dataTestId: TEST_ID_PREFIX.leakOrigin,
                    buttonText: leakOrigin && leakOrigin.key ? leakOrigin.key : '-',
                    path: PROPERTY_LEAK,
                    handleClick,
                },
                lob,
                claimType
            )}

            {renderLeakCause(
                {
                    inputWrapperClass: 'col-6 divider text-align-left',
                    textKey: tWithNS('leakCause.heading.title'),
                    dataTestId: TEST_ID_PREFIX.leakCause,
                    buttonText: leakCause && leakCause.key ? leakCause.key : '-',
                    path: PROPERTY_LEAK,
                    handleClick,
                },
                lob,
                claimType
            )}

            {renderClaimDate({
                inputWrapperClass: 'col-12 divider text-align-left',
                textKey: tWithNS('claimDate.heading.title'),
                dataTestId: TEST_ID_PREFIX.claimDate,
                buttonText: claimDate ? dtParseAndFormat(claimDate) : '-',
                path: START_CLAIM_DATE,
                handleClick,
            })}

            {renderLocation(
                {
                    inputWrapperClass: locationClass,
                    textKey: tWithNS('accidentLocation.heading.title'),
                    dataTestId: TEST_ID_PREFIX.accidentLocation,
                    buttonText: accidentLocation.note
                        ? accidentLocation.note
                        : `${accidentLocation.latitude}, ${accidentLocation.longitude}`,
                    path: START_ACCIDENT_LOCATION,
                    handleClick,
                },
                accidentLocation
            )}

            {renderUnit(
                {
                    inputWrapperClass: 'col-6 divider text-align-left',
                    textKey: tWithNS('propertyAddress.heading.title'),
                    dataTestId: TEST_ID_PREFIX.propertyAddress,
                    buttonText: accidentLocation.unit ?? '-',
                    path: PROPERTY_PROPERTY_ADDRESS,
                    handleClick,
                },
                accidentLocation
            )}

            {renderExternalPropertyDamage(
                {
                    inputWrapperClass: 'col-6 divider text-align-left',
                    textKey: tWithNS('externalPropertyDamage.heading.title'),
                    dataTestId: TEST_ID_PREFIX.externalPropertyDamage,
                    buttonText: externalPropertyItems.join(', '),
                    path: PROPERTY_EXTERNAL_PROPERTY_DAMAGE,
                    handleClick,
                },
                lob
            )}

            {renderInternalPropertyDamage(
                {
                    inputWrapperClass: 'col-6 divider text-align-left',
                    textKey: tWithNS('internalPropertyDamage.heading.title'),
                    dataTestId: TEST_ID_PREFIX.internalPropertyDamage,
                    buttonText: internalPropertyItems.join(', '),
                    path: PROPERTY_INTERNAL_PROPERTY_DAMAGE,
                    handleClick,
                },
                lob
            )}

            {renderClaimDescription(
                {
                    inputWrapperClass: 'col-12 divider text-align-left',
                    textKey: tWithNS('claimDescription.heading.title'),
                    dataTestId: TEST_ID_PREFIX.claimDescription,
                    buttonText: claimDescription ?? '-',
                    path: START_CLAIM_DESCRIPTION,
                    handleClick,
                },
                claimDescription
            )}
        </>
    );
};
