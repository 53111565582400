import { APP_ENV } from '../config/env';

/**
 * Internal helper functions
 */
const indexOfHref = (value: string) => window.location.href.indexOf(value);

/**
 * Exported functions
 */
export const getEnv = () => APP_ENV;
export const isDev: () => boolean = () => getEnv() === 'development';
export const isTest: () => boolean = () => indexOfHref('claims-at-net-test.') > 0;
export const isTestEnv: () => boolean = () => getEnv() === 'test';
export const isProd: () => boolean = () => indexOfHref('claims-at-net.') > 0;
export const isCypress = () => (window as any).Cypress;
