import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCustomCAN, selectLob } from '../sagas/selectors/commonSelectors';
import { useI18n } from '../hooks/useI18n';
import {
    CarIcon,
    Carousel,
    CarouselItem,
    CarouselModel,
    CarouselTypeModel,
    Clickable,
    is,
    LiabilityIcon,
    LobKeys,
    PageLayout,
    PropertyDamageIcon,
} from '@protectorinsurance/ds-can';
import { PhraseKeys } from '../config/phraseKeys';
import { MotorRoutePaths } from '../config/wizardRouter/motorWizardRoutes';
import { useGoBack } from '../hooks/useGoBack';
import { wizardRouterActions } from '../sagas/wizardRouter';
import { commonActions } from '../sagas/common';
import { LpoRoutePaths } from '../config/wizardRouter/lpoWizardRoutes';
import dispatcherWithPromise from '../utils/dispatcherWithPromise';

/**
 * Destructure necessary imports
 */
const { AUTO, LIABILITY, PROPERTY } = LobKeys;
const { BACK_BUTTON, CONTINUE_BUTTON, HELP_TEXT, PAGE_NAME, SUB_TITLE, TITLE } = PhraseKeys;
const { INDEX: MOTOR_INDEX } = MotorRoutePaths;
const { START_ONBOARDING } = LpoRoutePaths;

/**
 * Page view and page logic
 */
export const ClaimPage = () => {
    const dispatch = useDispatch();
    const lob = useSelector(selectLob);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('lob');

    // Options
    const optLiability = {
        description: tWithNS.t(`description.${LIABILITY}`),
        icon: <LiabilityIcon />,
        id: LIABILITY,
        name: tWithNS.t(`select.${LIABILITY}`),
    };

    const optMotor = {
        description: tWithNS.t(`description.${AUTO}`),
        icon: <CarIcon />,
        id: AUTO,
        name: tWithNS.t(`select.${AUTO}`),
    };

    const optProperty = {
        description: tWithNS.t(`description.${PROPERTY}`),
        icon: <PropertyDamageIcon />,
        id: PROPERTY,
        name: tWithNS.t(`select.${PROPERTY}`),
    };

    const options: CarouselTypeModel = [optMotor, optLiability, optProperty];

    const handleBackButton = useGoBack();

    const handleSelect = (id: LobKeys, e: Clickable) => {
        e.preventDefault();

        const option = options.find((x: CarouselModel) => x.id === id);

        if (option) {
            const lobs = [LIABILITY, PROPERTY];
            let nextAction = wizardRouterActions.goTo(MOTOR_INDEX);

            if (lobs.includes(option.id)) {
                nextAction = wizardRouterActions.goTo(START_ONBOARDING);
            }

            dispatch(commonActions.update({ lob: option.id }));
            dispatcherWithPromise(dispatch, commonActions.send).then(() => dispatch(nextAction));
        }
    };

    const renderSelection = options.map((option: CarouselModel, idx: number) => {
        return (
            <CarouselItem
                {...option}
                handleClick={handleSelect}
                isSelected={is(option.id, lob)}
                key={idx}
                {...{ customCAN }}
            />
        );
    });

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            pageClassName={'landing-page'}
            showBackButton={false}
            showContinueButton={false}
            {...{ handleBackButton }}
        >
            <Carousel>{renderSelection}</Carousel>
        </PageLayout>
    );
};
