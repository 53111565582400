import React from 'react';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    Clickable,
    FlowKeys,
    is,
    LaneChangeIcon,
    MotorClaimCauseKeys,
    MultipleCollisionIcon,
    OtherInjuriesIcon,
    RearEndedIcon,
    ReversingIcon,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../../../utils/carouselPageLookup';
import { MotorRoutePaths } from '../../../../../wizardRouter/motorWizardRoutes';
import { selectClaimCause } from '../../../../../../sagas/selectors/motorSelectors';
import { wizardRouterActions as wizardActions } from '../../../../../../sagas/wizardRouter';
import dispatcherWithPromise from '../../../../../../utils/dispatcherWithPromise';
import { motorActions } from '../../../../../../sagas/motor';
import { commonActions } from '../../../../../../sagas/common';
import { flowActions } from '../../../../../../sagas/flow';

/**
 * Destructure necessary imports
 */
const { CYCLIST_COLLISION, LANE_CHANGE, MULTIPLE_COLLISION, OTHER, REAR_ENDED, REVERSING } = MotorClaimCauseKeys;
const { END } = FlowKeys;
/**
 * Page view and page logic
 */
export const drivingVehiclesRoundaboutClaimCausePage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<MotorRoutePaths> => {
    const claimCause = selectClaimCause(state);
    const options = [
        { id: REAR_ENDED, name: t(`motor.driving.roundaboutClaimCause.select.${REAR_ENDED}`), icon: <RearEndedIcon /> },
        {
            id: LANE_CHANGE,
            name: t(`motor.driving.roundaboutClaimCause.select.${LANE_CHANGE}`),
            icon: <LaneChangeIcon />,
        },
        { id: REVERSING, name: t(`motor.driving.roundaboutClaimCause.select.${REVERSING}`), icon: <ReversingIcon /> },
        {
            id: MULTIPLE_COLLISION,
            name: t(`motor.driving.roundaboutClaimCause.select.${MULTIPLE_COLLISION}`),
            icon: <MultipleCollisionIcon />,
        },
        { id: OTHER, name: t(`motor.driving.roundaboutClaimCause.select.${OTHER}`), icon: <OtherInjuriesIcon /> },
    ];

    return {
        slug: MotorRoutePaths.DYN_CAROUSEL_DRIVING_VEHICLES_ROUNDABOUT_CLAIM_CAUSE,
        stateKey: 'claimCause',
        i18n: {
            ns: 'motor.driving.roundaboutClaimCause',
        },
        selectorValue: selectClaimCause(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                let flow: MotorClaimCauseKeys | FlowKeys = option.id;
                if (!is(option.id, claimCause)) {
                    dispatch(wizardActions.skipBackToPrev(true));
                }

                if (!is(claimCause, CYCLIST_COLLISION)) {
                    dispatcherWithPromise(dispatch, motorActions.update, { claimCause: option.id });
                }

                if (is(option.id, MULTIPLE_COLLISION) || is(option.id, OTHER)) {
                    flow = END;
                }

                dispatcherWithPromise(dispatch, flowActions.update, flow)
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(wizardActions.goToNext(option.id)));
            }
        },
    };
};
