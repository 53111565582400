import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { wizardRouterActions as wizardActions } from 'sagas/wizardRouter';
import { useI18n } from '../../../../../hooks/useI18n';
import { useGoBack } from '../../../../../hooks/useGoBack';
import {
    FlowKeys,
    FormChangeable,
    Grid,
    HiddenInputSubmit,
    MuiTextInput,
    PageLayout,
    RoadConditionClaimDescriptionModel,
} from '@protectorinsurance/ds-can';
import { PhraseKeys } from '../../../../../config/phraseKeys';
import { selectRoadConditionClaimDescription } from '../../../../../sagas/selectors/motorSelectors';
import { FormFieldNames } from '../../../../../config/formFieldNames';
import { Controller, useForm } from 'react-hook-form';
import { motorActions } from '../../../../../sagas/motor';
import dispatcherWithPromise from '../../../../../utils/dispatcherWithPromise';
import { flowActions } from '../../../../../sagas/flow';
import { roadConditionClaimDescriptionSchema } from '../../../../../validations/schemas/roadConditionClaimDescriptionSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { commonActions } from '../../../../../sagas/common';
import { selectCustomCAN } from '../../../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const {
    BACK_BUTTON,
    CLAIM_DESCRIPTION_LABEL,
    CLAIM_DESCRIPTION_PLACEHOLDER,
    CONTINUE_BUTTON,
    HELP_TEXT,
    PAGE_NAME,
    SUB_TITLE,
    TITLE,
} = PhraseKeys;
const { ROAD_CONDITION_CLAIM_DESCRIPTION } = FormFieldNames;
const { END } = FlowKeys;

/**
 * Page view and page logic
 */
export const DNDitchRoadConditionDescriptionPage = () => {
    const dispatch = useDispatch();
    const claimDescription = useSelector(selectRoadConditionClaimDescription);
    const customCAN = useSelector(selectCustomCAN);
    const { t } = useI18n();
    const tWithNS = useI18n('motor.driving.roadConditionDescription');
    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        trigger,
    } = useForm<RoadConditionClaimDescriptionModel>({
        resolver: yupResolver(roadConditionClaimDescriptionSchema(t, CLAIM_DESCRIPTION_LABEL)),
        defaultValues: {
            roadConditionClaimDescription: claimDescription,
        },
    });

    const handleBackButton = useGoBack();

    const handleBlur = async (e: FormChangeable) => {
        e.preventDefault();
        const { id } = e.currentTarget;
        await trigger(id);
    };

    const handleChange = async (e: FormChangeable) => {
        e.preventDefault();
        const { id, value } = e.currentTarget;
        await trigger(id);
        await setValue(id, value, { shouldValidate: true });
    };

    const onSubmit = ({ roadConditionClaimDescription }: RoadConditionClaimDescriptionModel, e?: FormChangeable) => {
        e?.preventDefault();
        dispatcherWithPromise(dispatch, motorActions.update, { roadConditionClaimDescription })
            .then(() => dispatcherWithPromise(dispatch, flowActions.update, END))
            .then(() => dispatcherWithPromise(dispatch, commonActions.send))
            .then(() => dispatch(wizardActions.goToNext()));
    };

    return (
        <PageLayout
            backBtnText={t(BACK_BUTTON)}
            continueBtnText={t(CONTINUE_BUTTON)}
            domainTitle={t(PAGE_NAME)}
            footerText={tWithNS.t(HELP_TEXT)}
            handleContinueButton={handleSubmit(onSubmit)}
            headerSubTitle={tWithNS.t(SUB_TITLE)}
            headerTitle={tWithNS.t(TITLE)}
            {...{ handleBackButton }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <HiddenInputSubmit />
                <Grid className={'align-center'}>
                    <Controller
                        control={control}
                        name={ROAD_CONDITION_CLAIM_DESCRIPTION}
                        render={({ field: { ref, ...field } }) => (
                            <MuiTextInput
                                {...field}
                                error={!!errors.roadConditionClaimDescription}
                                errorMessage={errors.roadConditionClaimDescription?.message}
                                id={ROAD_CONDITION_CLAIM_DESCRIPTION}
                                inputFieldWrapper={'col-12'}
                                label={t(CLAIM_DESCRIPTION_LABEL)}
                                multiline={true}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={t(CLAIM_DESCRIPTION_PLACEHOLDER)}
                                reference={ref}
                                rows={10}
                                {...{ customCAN }}
                            />
                        )}
                    />
                </Grid>
            </form>
        </PageLayout>
    );
};
