import React from 'react';
import { wizardRouterActions as wizardActions } from '../../../../sagas/wizardRouter';
import {
    CarouselConfigLookupResponse,
    CarouselConfigOptionType,
    Clickable,
    is,
    isYes,
    LobKeys,
    NoIcon,
    YesIcon,
    YesNoKeys,
} from '@protectorinsurance/ds-can';
import { CarouselConfigLookupProps } from '../../../../utils/carouselPageLookup';
import dispatcherWithPromise from '../../../../utils/dispatcherWithPromise';
import { commonActions } from '../../../../sagas/common';
import { LpoRoutePaths } from '../../../wizardRouter/lpoWizardRoutes';
import { selectHasOtherInsurance } from '../../../../sagas/selectors/lpoSelectors';
import { lpoActions } from '../../../../sagas/lpo';
import { selectLob } from '../../../../sagas/selectors/commonSelectors';

/**
 * Destructure necessary imports
 */
const { YES, NO } = YesNoKeys;
const { LIABILITY } = LobKeys;
const { DYN_CAROUSEL_LIABILITY_IS_THIRD_PARTY_INVOLVED } = LpoRoutePaths;

/**
 * Page view and page logic
 */
export const endHasOtherInsurancePage = ({
    t,
    state,
    dispatch,
}: CarouselConfigLookupProps): CarouselConfigLookupResponse<LpoRoutePaths> => {
    const lob = selectLob(state);
    const options = [
        { id: YES, name: t(`lpo.end.hasOtherInsurance.select.${YES}`), icon: <YesIcon /> },
        { id: NO, name: t(`lpo.end.hasOtherInsurance.select.${NO}`), icon: <NoIcon /> },
    ];

    return {
        slug: LpoRoutePaths.DYN_CAROUSEL_END_HAS_OTHER_INSURANCE,
        stateKey: 'otherInsurance',
        i18n: {
            ns: 'lpo.end.hasOtherInsurance',
        },
        selectorValue: selectHasOtherInsurance(state),
        options,
        onSelect: (id: CarouselConfigOptionType, e: Clickable) => {
            e.preventDefault();
            const option = options?.find((x) => x.id === id);

            if (option) {
                let nextAction = wizardActions.goToNext(option.id);
                if (isYes(option.id)) {
                    dispatch(wizardActions.skipBackToPrev(true));
                }

                if (is(option.id, NO) && is(lob, LIABILITY)) {
                    nextAction = wizardActions.goTo(DYN_CAROUSEL_LIABILITY_IS_THIRD_PARTY_INVOLVED);
                }

                dispatcherWithPromise(dispatch, lpoActions.update, {
                    otherInsurance: option.id,
                })
                    .then(() => dispatcherWithPromise(dispatch, commonActions.send))
                    .then(() => dispatch(nextAction));
            }
        },
    };
};
