import * as Yup from 'yup';
import { PhraseKeys } from '../../config/phraseKeys';
import { TFunction } from 'i18next';

/**
 * Destructure necessary imports
 */
const { OTHER_INSURANCE_POLICY_NUMBER_LABEL } = PhraseKeys;

/**
 * Validation logic
 */
export const otherInsurancePolicyNumberSchema = (t: TFunction) => {
    return {
        otherInsurancePolicyNumber: Yup.string().label(t(OTHER_INSURANCE_POLICY_NUMBER_LABEL)).required().nullable(),
    };
};
