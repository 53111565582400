import { TFunction } from 'i18next';
import { ConstabularyEnums } from '../../models/Constabulary';

/**
 * Utility logic
 */
export const createConstabularyKey = (id: string) => {
    return `lpo.constabulary.select.${id}`;
};

export const getConstabularyOption = (t: TFunction, key: ConstabularyEnums, value: string) => {
    return {
        value,
        label: t(createConstabularyKey(key)),
        key,
    };
};
